import { DownloadIcon, RenderDownloadProps } from '@react-pdf-viewer/get-file';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface props {
    DownloadElement: any;
    documentData: any;
}

const SidebarDocumentInfo = ({ DownloadElement, documentData }: props) => {
    const { t } = useTranslation();

    const dateParsedCreated: Date = new Date(Date.parse(documentData.updatedDate));
    const formatedDateCreated: string = `${dateParsedCreated.getDate()}.${dateParsedCreated.getMonth() + 1}.${dateParsedCreated.getFullYear()} ${dateParsedCreated.getHours()}:${dateParsedCreated.getMinutes()}`;
    const dateParsedUpdated: Date = new Date(Date.parse(documentData.updatedDate));
    const formatedDateUpdated: string = `${dateParsedUpdated.getDate()}.${dateParsedUpdated.getMonth() + 1}.${dateParsedUpdated.getFullYear()} ${dateParsedUpdated.getHours()}:${dateParsedUpdated.getMinutes()}`;

    return (
        <div className='p-4 overflow-y-auto'>
            <div className='space-y-4'>

                <div>
                    <label className="block text-sm font-medium text-gray-900">{t('sidebarDocumentInfo.vendor')}</label>
                    <div className="border border-transparent text-gray-900 text-md rounded-lg block w-full py-2.5">{documentData.selectionSoftwareProduct.softwareProduct.vendorDetails.company}</div>
                </div>
                
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t('sidebarDocumentInfo.system')}</label>
                    <div className="border border-transparent text-gray-900 text-md rounded-lg block w-full py-2.5">{documentData.selectionSoftwareProduct.softwareProduct.productName}</div>
                </div>
                
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t('sidebarDocumentInfo.documentName')}</label>
                    <input 
                    placeholder={t('sidebarDocumentInfo.placeholderDocumentName')} value={documentData.name} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required></input>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-900">{t('sidebarDocumentInfo.version')}</label>
                    <input 
                    placeholder={t('sidebarDocumentInfo.placeholderVersion')} value={"1"} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required></input>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-900">{t('sidebarDocumentInfo.status')}</label>
                    <input 
                    placeholder={t('sidebarDocumentInfo.placeholderStatus')} value={""} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required></input>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-900">{t('sidebarDocumentInfo.dateUpdated')}</label>
                    <div className="border border-transparent text-gray-900 text-md rounded-lg block w-full py-2.5">{formatedDateUpdated}</div>
                </div>
                
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t('sidebarDocumentInfo.dateCreated')}</label>
                    <div className="border border-transparent text-gray-900 text-md rounded-lg block w-full py-2.5">{formatedDateCreated}</div>
                </div>

                <div>
                    <p className="block text-sm font-medium mb-2.5 text-gray-900">{t('sidebarDocumentInfo.downloadDocument')}</p>
                    <DownloadElement>
                    {
                        (props: RenderDownloadProps) => (
                            <button className='w-8 h-8 hover:bg-gray-200 flex items-center justify-center rounded-full'
                            onClick={props.onClick}>
                                <DownloadIcon />
                            </button>
                        )
                    }
                    </DownloadElement>
                </div>
            
            </div>

        </div>
    );
}

export default SidebarDocumentInfo;