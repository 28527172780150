import React, { useEffect, useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import { changeRankingDisabled, getSelectionRankingFull } from '../../../../../../data/SelectionPortalRequests';
import { ArrowDownIcon, LoadingSpinner } from 'swap-frontend-library';
import Toggle from '../../../../../../components/Toggle';
import SelectionRankingSettingRow from './components/SelectionRankingSettingRow';
import SearchBarFuzzy from '../../../../../../components/SearchBarFuzzy';
import MainButton from '../../../../../../components/MainButton';
import FormModal from '../../../../../../components/FormModal';
import FormField from '../../../../../../components/FormField';
import { useTranslation } from 'react-i18next';
import { addProductDataCustomer } from '../../../../../../data/ProductRequests';

const SelectionRankingSettings = () => {
    const {t} = useTranslation();
    const { selectionId }  = useOutletContext<any>();
    const [addSystemModalOpen, setAddSystemModalOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [vendorRanking, setVendorRanking] = useState<any[]>();
    const [vendorRankingFiltered, setVendorRankingFiltered] = useState<any[]>([]);
    const [searchTextEmpty, setSearchTextEmpty] = useState(true);
    
    const handleFetchRanking = async () => {
        const response = await getSelectionRankingFull(selectionId);
        if (response.ok) {
            const sortedSelectionSoftwareProducts = response.data.data.selectionSoftwareProducts.sort((a:any, b:any) => a.softwareProduct.productName.toLowerCase() > b.softwareProduct.productName.toLowerCase())
            // console.log(sortedSelectionSoftwareProducts.filter((item:any) => !item.disabled))
            setVendorRanking(sortedSelectionSoftwareProducts);
        }
    }

    // useEffect(() => {
    //     handleFetchRanking();
    // }, [])

    useEffect(() => { 
        if (typeof vendorRanking !== "undefined") setVendorRankingFiltered(vendorRanking);
    }, [vendorRanking])
    
    useEffect(() => { 
        if (searchTextEmpty) {handleFetchRanking();}
    }, [searchTextEmpty])

  return (
    <div className="mt-4 shadow-md rounded-lg w-full bg-white p-5 flex flex-col gap-4">
        <div className="text-lg font-semibold text-left text-gray-900 bg-white">
            {t('selectionRanking.title')}
        </div>

        <div className={`relative flex flex-col items-center`}>
            {
                (typeof vendorRankingFiltered === "undefined" || typeof vendorRanking === "undefined") ? (<LoadingSpinner />) : (
                    <>
                    <div className='pl-3 py-2 w-full'>
                        <SearchBarFuzzy translatedPlaceholderItem={t('selectionRanking.system')} originalData={vendorRanking} setFilteredData={setVendorRankingFiltered} filterKeys={["softwareProduct.productName", "softwareProduct.vendorDetails.company"]} setSearchTextEmpty={setSearchTextEmpty} />
                    </div>

                    {
                        // vendorRanking.length == vendorRankingFiltered.length || vendorRankingFiltered.length == 0 ? <></> : (
                            searchTextEmpty ? <></> : (
                            <>
                                <div className='absolute top-16 rounded-lg border bg-gray-50 h-96 shadow-lg z-[3] mx-auto w-[95%] justify-between overflow-hidden'>
                                    <div className='overflow-y-auto h-full grid grid-cols-1 gap-x-8  divide-y px-3'>
                                        {
                                            vendorRankingFiltered.filter(item => item.disabled).length == 0 ? 
                                            <div className='flex flex-col items-center justify-center gap-4'>
                                                <p className='text-gray-600'>{t('selectionRanking.noSystemsFound')}</p>
                                                <div>
                                                    <MainButton text={t('selectionRanking.addSystem')} func={() => setAddSystemModalOpen(true)} />
                                                </div>
                                            </div> :
                                            vendorRankingFiltered.filter(item => item.disabled).map((selectionSoftwareProduct:any, index:number) => {
                                                return (
                                                    <SelectionRankingSettingRow selectionSoftwareProduct={selectionSoftwareProduct} selectionId={selectionId} handleFetchRanking={handleFetchRanking} quickReload={false} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    }

                    <div className={`relative flex flex-col items-center grid grid-cols-1 gap-x-8 mx-auto w-full px-3 justify-between divide-y`}>
                        {
                            vendorRanking.filter(item => !item.disabled).slice(0,expanded ? vendorRanking.length : 5).map((selectionSoftwareProduct:any, index:number) => {
                                return (
                                    <SelectionRankingSettingRow selectionSoftwareProduct={selectionSoftwareProduct} selectionId={selectionId} handleFetchRanking={handleFetchRanking} quickReload={true} />
                                )
                            })
                        }
                    </div>
                    </>
                )
            }
        </div>

        <div onClick={() => setExpanded(prev => !prev)} className='flex items-center justify-center border rounded shadow p-1 cursor-pointer'>
            {
                expanded ? (
                    <div className='w-6 h-6 rotate-180'><ArrowDownIcon /></div>
                ) : (
                    <div className='w-6 h-6'><ArrowDownIcon /></div>
                )
            }
        </div>

        { addSystemModalOpen ? <FormModal isOpen={addSystemModalOpen} setOpen={setAddSystemModalOpen}><AddSystemModal selectionId={selectionId} setAddSystemModalOpen={setAddSystemModalOpen} handleFetchRanking={handleFetchRanking} /></FormModal> : <></>}
    </div>
  )
}

const AddSystemModal = ({selectionId, productId, setAddSystemModalOpen, handleFetchRanking} : {selectionId: string, productId?: string, setAddSystemModalOpen: React.Dispatch<React.SetStateAction<boolean>>, handleFetchRanking: () => void}) => {
    const {t} = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);
    
    const [productFormData, setProductFormData] = useState<{
        productName?: string,
        productDescription?: string,
        productUrl?: string,
        salesEmail?: string,
    }>();
    
    const [vendorFormData, setVendorFormData] = useState<{
        company?: string,
        vendorUrl?: string,
    }>();

    const handleSubmit = async () => {
        if (formRef.current?.checkValidity()) {
            const response = await addProductDataCustomer(selectionId, vendorFormData, productFormData);
            if (response.success) {
                console.log(response);
                await changeRankingDisabled(selectionId, response.data.fulfillmentId, undefined, false);
                handleFetchRanking();

                setAddSystemModalOpen(false);
            }
        } else {
            formRef.current?.reportValidity();
        }
    };

    return (
        <form ref={formRef} className="max-h-[75vh] overflow-y-auto p-2 space-y-6" onSubmit={(e) => { e.preventDefault(); }}>
            <p className='text-2xl font-semibold mb-4'>{t("selectionRanking.createProduct")}</p>
            
            <div>
                <p className='text-xl font-semibold mb-4'>{t("selectionRanking.softwareVendor")}</p>
                <div className='space-y-2'>
                    <FormField existingText={vendorFormData?.company || ""} label={t("selectionRanking.companyName")+"*"} setFormData={setVendorFormData} formDataKey='company' editing={true} required={true} />
                    <FormField existingText={vendorFormData?.vendorUrl || ""} label={t("selectionRanking.url")} setFormData={setVendorFormData} formDataKey='vendorUrl' editing={true} />
                </div>
            </div>

            <div>
                <p className='text-xl font-semibold mb-4'>{t("selectionRanking.softwareProduct")}</p>
                <div className='space-y-2'>
                    <FormField existingText={productFormData?.productName || ""} label={t("selectionRanking.productName")+"*"} setFormData={setProductFormData} formDataKey='productName' editing={true} required={true} />
                    <FormField existingText={productFormData?.productDescription || ""} label={t("selectionRanking.description")} setFormData={setProductFormData} formDataKey='productDescription' editing={true} rows={4} />
                    <FormField existingText={productFormData?.productUrl || ""} label={t("selectionRanking.url")} setFormData={setProductFormData} formDataKey='productUrl' editing={true} />
                    <FormField existingText={productFormData?.salesEmail || ""} label={t("selectionRanking.salesContact")+" "+t("selectionRanking.email")} setFormData={setProductFormData} formDataKey='salesEmail' editing={true} />
                </div>
            </div>
            <p className='text-sm font-light'>*: {t("selectionRanking.required")}</p>

            <div className='w-full'><MainButton text={t("selectionRanking.save")} func={handleSubmit} /></div>
        </form>
    )
}

export default SelectionRankingSettings