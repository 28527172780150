import React, { useEffect, useState } from 'react'
import SidebarElement from '../../components/SidebarElement'
import { RootState } from '../../../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLoaderData, useLocation, useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getSelectionPhase, getUserSelectionRole } from '../../../../data/SelectionPortalRequests'
import SearchProcessSection from '../../../../components/SearchProcessSection'
import MainButton from '../../../../components/MainButton'
import { setProjectRole } from '../../../../redux/selectionSlice'
import { ArrowLeftIcon, ArrowRightIcon, CakeChartIcon, CheckmarkIcon, InboxFilledIcon, ListFilledIcon, LoadingSpinner, LockIcon, SettingsFilledIcon } from 'swap-frontend-library'

export const loader = async ({ params } : any) : Promise<String> => {
  if (params.selectionId) {
      return params.selectionId;
  } else {
      return "";
  }
}

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const role = useSelector((state: RootState) => state.user.role);
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const [collapsedSidebar, setCollapsedSidebar] = useState(false);
    const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const [outerSidebarOpen, outerSidebarWidth, setOuterCollapsedSidebar] = useOutletContext<any>();
    const [initializationComplete, setInitializationComplete] = useState(false);
    const [selectionPhase, setSelectionPhase] = useState("");
    const [selectionTaskId, setSelectionTaskId] = useState("");
    const [selectedSelectionPhase, setSelectedSelectionPhase] = useState("");

    const location = useLocation();

    const SELECTION_PHASES : any = {
      requirements: 0,
      scenarios: 1,
      presentations: 2,
      contracts: 3,
    }
    
    const SELECTION_PHASES_LINKS : any = {
      requirements: "requirement-manager",
      scenarios: "scenario-manager",
      presentations: "presentation-manager",
      contracts: "contract-manager",
    }

    const selectionId : string = String(useLoaderData());

    useEffect(() => {setOuterCollapsedSidebar(true); setInitializationComplete(true); if (!role.startsWith("guest")) {handleFetchSelectionPhase(); handleFetchUserSelectionRole();}}, [])
    
    // useEffect(() => {}, [outerSidebarOpen])

    const handleFetchSelectionPhase = async () => {
      if (typeof selectionId === "undefined") return;

      const response = await getSelectionPhase(selectionId);
      if (response.ok) {
        // console.log(response.data.data.selectionPhase)
        setSelectionPhase(response.data.data.selectionPhase);

        if (typeof response.data.data.selectionSelectionPhaseTask !== "undefined") {
          // console.log(response.data.data.selectionSelectionPhaseTask[0]);
          const selectionSelectionPhaseTask = response.data.data.selectionSelectionPhaseTask[0];
          setSelectionTaskId(selectionSelectionPhaseTask.id);
        }
      }
    }
    
    const handleFetchUserSelectionRole = async () => {
      if (typeof selectionId === "undefined") return;

      const response = await getUserSelectionRole(selectionId);
      if (response.ok) {
        dispatch(setProjectRole(response.data.selectionRole));
      }
    }

    const handleCollapseSidebar = () => {
        setCollapsedSidebar(prev => !prev)
    }
    
    const menuItems = [
        {
          label: t("selectionPortalMenu.dashboard"),
          icon: <div className='w-6 h-6'><CakeChartIcon /></div>,
          roleLink: {
            "customer:*": "",
            "platform_manager:*": "",
            "guest_vendor:*": "",
            "guest_seeker:*": "",
          },
          topAlign: true,
        },
        {
          label: t("selectionPortalMenu.ranking"),
          icon: <div className='w-6 h-6'><ListFilledIcon /></div>,
          roleLink: {
            "customer:*": "vendor-ranking",
            "platform_manager:*": "vendor-ranking"
          },
          topAlign: true
        },
        {
          label: t("selectionPortalMenu.requirements"),
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 0) ? "bg-primary" : "bg-gray-200"} rounded-full w-6 h-6 text-white font-bold flex items-center justify-center`}><p>1</p></div>,
          roleLink: {
            "customer:*": SELECTION_PHASES_LINKS["requirements"],
            "platform_manager:*": SELECTION_PHASES_LINKS["requirements"],
            "guest_vendor:*": SELECTION_PHASES_LINKS["requirements"]+"?mode=evaluate",
            "guest_seeker:*": SELECTION_PHASES_LINKS["requirements"]+"?mode=prioritize",
          },
          topAlign: true
        },
        {
          label: t("selectionPortalMenu.scenarios"),
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 1) ? "bg-primary" : "bg-gray-200"} rounded-full w-6 h-6 text-white font-bold flex items-center justify-center`}><p>2</p></div>,
          roleLink: {
            "customer:*": SELECTION_PHASES_LINKS["scenarios"],
            "platform_manager:*": SELECTION_PHASES_LINKS["scenarios"],
            "guest_vendor:*": SELECTION_PHASES_LINKS["scenarios"],
          },
          topAlign: true
        },
        {
          label: t("selectionPortalMenu.presentations"),
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 2) ? "bg-primary" : "bg-gray-200"} rounded-full w-6 h-6 text-white font-bold flex items-center justify-center`}><p>3</p></div>,
          roleLink: {
            "customer:*": SELECTION_PHASES_LINKS["presentations"],
            "platform_manager:*": SELECTION_PHASES_LINKS["presentations"],
            "guest_vendor:*": SELECTION_PHASES_LINKS["presentations"],
            "guest_seeker:*": SELECTION_PHASES_LINKS["presentations"]
          },
          topAlign: true
        },
        {
          label: t("selectionPortalMenu.contract"),
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 3) ? "bg-primary" : "bg-gray-200"} rounded-full w-6 h-6 text-white font-bold flex items-center justify-center`}><p>4</p></div>,
          roleLink: {
            "customer:*": SELECTION_PHASES_LINKS["contracts"],
            "platform_manager:*": SELECTION_PHASES_LINKS["contracts"],
          },
          topAlign: true
        },
        {
          label: t("selectionPortalMenu.inbox"),
          icon: <div className='w-6 h-6'><InboxFilledIcon /></div>,
          roleLink: { 
            "vendor": "inbox", 
            "customer:*": "inbox",
            "platform_manager:*": "inbox",
          },
          topAlign: false
        },
        {
          label: t("selectionPortalMenu.settings"),
          icon: <div className='w-6 h-6'><SettingsFilledIcon/></div>,
          roleLink: {
            "customer:selection_admin": "settings",
            "platform_manager:*": "settings"
          },
          topAlign: false
        },
        {
          label: t("selectionPortalMenu.close"),
          icon: (collapsedSidebar) ? (<div className='w-4 h-4 text-gray-700'><ArrowRightIcon /></div>) : (<div className='w-4 h-4 text-gray-700'><ArrowLeftIcon /></div>),
          roleLink: {
            "platform_manager:*": "#", 
            "vendor:*": "#", 
            "customer:*": "#",
            "guest_vendor:*": "#",
          },
          onClick: handleCollapseSidebar,
          topAlign: false
        }
    ]

  if (!initializationComplete) {
    return (<LoadingSpinner />)
  } else {
  return (
    <>
    <div className='h-full flex max-sm:hidden'>

      <div className="fixed z-[50] top-0 left-[50%] -translate-x-[50%] mx-auto h-20 flex justify-center items-center">
        <SearchProcessSection processStep={SELECTION_PHASES[selectionPhase]} steps={[t("steps.requirements"), t("steps.scenarios"), t("steps.presentations"), t("steps.contract")]} links={["requirement-manager", "scenario-manager", "presentation-manager", "contract-manager"]} style='mt-0 w-[854px] timeline max-lg:hidden' />
      </div>

      <aside id="logo-sidebar" className={`z-40 ${(collapsedSidebar) ? ("w-16") : ("w-fit")} h-full translate-x-[-200%] sm:translate-x-0 transition-transform bg-white border-r border-gray-200`}>
        <div className="h-full px-3 pb-4 pt-3 overflow-y-auto bg-white flex flex-col">
          <ul className="space-y-2">
            {
              menuItems.map((value: any, index: number) => {
                let roleLinkKey = "";
                if (Object.keys(value.roleLink).includes(role+":"+projectRole)) {
                  roleLinkKey = role+":"+projectRole;
                } else if (Object.keys(value.roleLink).includes(role+":*")) {
                  roleLinkKey = role+":*";
                }

                if (value.topAlign && roleLinkKey != "") {
                  return (<SidebarElement label={value.label} link={value.roleLink[roleLinkKey]} icon={value.icon} labelHidden={collapsedSidebar} onClick={value.onClick} active={(value.roleLink[roleLinkKey] != "" && (location.pathname + location.search).includes(value.roleLink[roleLinkKey])) || (value.roleLink[roleLinkKey] == "" && location.pathname.endsWith(selectionId))} />)
                }
              })
            }
          </ul>
          <ul className="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200">
            {
              menuItems.map((value: any, index: number) => {
                let roleLinkKey = "";
                if (Object.keys(value.roleLink).includes(role+":"+projectRole)) {
                  roleLinkKey = role+":"+projectRole;
                } else if (Object.keys(value.roleLink).includes(role+":*")) {
                  roleLinkKey = role+":*";
                }

                if (!value.topAlign && roleLinkKey != "") {
                  return (<SidebarElement label={value.label} link={value.roleLink[roleLinkKey]} icon={value.icon} labelHidden={collapsedSidebar} onClick={value.onClick} active={(location.pathname + location.search).includes(value.roleLink[roleLinkKey])} />)
                }
            }
            )}
          </ul>
          <div className={`grow flex items-end text-gray-300 text-sm text-light gap-1 ${collapsedSidebar ? "hidden": ""}`}>
            {
              role == "platform_manager" ? <></> :
              <><span className='font-semibold'>{t("role.label")}: </span><span>{role.startsWith("guest") ? t("role.guest", { role: role.split("_")[1] }) : projectRole == "selection_admin" ? t("role.projectLead") : t("role.keyUser")}</span></>
            }
          </div>
        </div>
      </aside>

      <div className='h-full w-full overflow-y-hidden'>
        <div className={`relative h-full w-full ${(selectedSelectionPhase != selectionPhase && selectionPhase != "") && (selectedSelectionPhase != "") ? ("pt-20 border-4 border-amber-200") : ("")}`}>

          {
            (selectedSelectionPhase != selectionPhase) && (selectedSelectionPhase != "") && (selectionPhase != "") ? (
              <div className='absolute z-[2] top-0 grid grid-cols-12 space-x-2 bg-amber-100 border-b-4 border-amber-200 h-20 w-full p-2'>
                <div className='col-span-8 lg:col-span-10'>
                  <p className='text-md font-semibold'>{t("inactivePhase.title")}</p>
                  <p className='line-clamp-2 text-sm'>{t("inactivePhase.description")}</p>
                </div>
                <div className='col-span-4 lg:col-span-2'>
                  <MainButton text={t("inactivePhase.resumeButton")} icon={<div className='text-white h-6 w-6'><ArrowRightIcon/></div>} link={SELECTION_PHASES_LINKS[selectionPhase]} />
                </div>
              </div>
            ) : (<></>)
          }
          
          <Outlet context={{selectionId, selectionPhase, setSelectionPhase, selectedSelectionPhase, setSelectedSelectionPhase, selectionTaskId, setSelectionTaskId, handleFetchSelectionPhase}} />
        </div>
      </div>
    </div>
    <div className='sm:hidden p-4 h-full flex flex-col justify-center items-center gap-4'>
      <div className='w-10 h-10 text-gray-700'><LockIcon /></div>
      <p className='text-gray-700 text-2xl font-bold text-center'>{t("mobileNotAvailable.title")}</p>
      <p className='text-gray-700 text-sm font-light text-center'>{t("mobileNotAvailable.description")}</p>
      <div className='w-fit'><MainButton text={t("mobileNotAvailable.backButton")} link='/' /></div>
    </div>
    </>
  )
  }
}

export default Index
