import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ArrowRightIcon, PlusIcon } from 'swap-frontend-library'
import ParticipantIcon from './ParticipantIcon'
import { useTranslation } from 'react-i18next'

interface props {
    meetingItem : any
}

const AppointmentRow = ({meetingItem} : props) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const dateParsed : Date = new Date(meetingItem.timeFrom);
    const dateString : string = `${dateParsed.getDate().toString().padStart(2, '0')}.${(dateParsed.getMonth()+1).toString().padStart(2, '0')}.${dateParsed.getFullYear()}`;
    
    const timeFrom = `${(new Date(meetingItem.timeFrom)).getHours().toString().padStart(2, '0')}:${(new Date(meetingItem.timeFrom)).getMinutes().toString().padStart(2, '0')}`;
    const timeTo = `${(new Date(meetingItem.timeTo)).getHours().toString().padStart(2, '0')}:${(new Date(meetingItem.timeTo)).getMinutes().toString().padStart(2, '0')}`;

    const now = new Date(); // Get the current timestamp
    const isLive = now >= new Date(meetingItem.timeFrom) && now <= new Date(meetingItem.timeTo);

    return (
    <div onClick={() => navigate(meetingItem.id)} className='grid grid-cols-8 rounded-lg shadow hover:shadow-lg bg-white p-4 gap-2 group cursor-pointer transition-all'>
        <div className='col-span-1 flex flex-col gap-4'>
            <p className='text-xl font-semibold'>{dateString}</p>
            <p className='text-md'>{timeFrom} - {timeTo}</p>

            { 
            meetingItem.presentationStatus == "completed" ? (<div className='w-fit h-fit pr-4'><div className='border-2 border-dashed border-primary bg-primary/10 h-full rounded flex items-center justify-center text-center text-xs font-semibold text-primary'>{t('presentation.completed')}</div></div>) :
            meetingItem.presentationStatus == "canceled" ? (<div className='w-fit h-fit pr-6'><div className='border-2 border-dashed border-red-400 bg-red-400/10 h-full rounded flex items-center justify-center text-center text-xs font-semibold text-red-400'>{t('presentation.canceled')}</div></div>) :
            isLive || meetingItem.presentationStatus == "live" ? (
                <div className='flex items-center gap-2'>
                    <div className='relative w-4 h-4'>
                        <div className='absolute left-0 top-0 bg-primary w-full h-full rounded-full'></div>
                        <div className='absolute left-0 top-0 animate-ping bg-primary w-full h-full rounded-full'></div>
                    </div>
                    <p className='font-semibold text-xl text-primary'>{t('presentation.live')}</p> 
                </div>
            )
            : <></> }

        </div>
        
        <div className='col-span-6 flex flex-col justify-between gap-4'>
            <div className='flex gap-2'>
                <p className='font-semibold text-xl text-primary'>{meetingItem.selectionSoftwareProduct.softwareProduct.vendorDetails.company}</p>
                <p className='font-semibold text-xl text-primary'>{meetingItem.selectionSoftwareProduct.softwareProduct.productName}</p>
                <p className='text-xl'>|</p>
                <p className='text-xl'>{meetingItem.title || t('presentation.defaultTitle')}</p>
            </div>
            <div>
                <p className='text-md'>{t('participants.label')}</p>
                <div className='flex gap-4 overflow-x-auto p-4'>
                    {
                        meetingItem.selectionPresentationParticipant.map((participant : any) => {
                            // console.log(participant.userSelection)
                            // If user account deleted
                            if (participant.userSelection == null) return <></>;
                            return (
                                <ParticipantIcon name={(participant.userSelection.user?.email || participant.userSelection?.guestEmail).replace("@", " ")} />
                            )
                        })
                    }
                </div>
            </div>

        </div>
        
        <div className='col-span-1 flex justify-end'>
            <div className='h-6 w-6'><ArrowRightIcon /></div>
        </div>
    </div>
  )
}

export default AppointmentRow