import React, { useState } from 'react'
import { MainButton, TextInput } from 'swap-frontend-library';

const PlatformCrmSettings = () => {
    const [pipedriveAPIKey, setPipedriveAPIKey] = useState<string>('');
    const [pipedriveAPIURL, setPipedriveAPIURL] = useState<string>('');
    const [pipedriveLabels, setPipedriveLabels] = useState<string>('');
  
    return (
      <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            CRM Settings
            <p className="mt-1 text-sm font-normal text-gray-500">Set email settings</p>
        </div>
  
        <div className='p-4 bg-white space-y-4'>
          <TextInput textValue={pipedriveAPIKey} setTextValue={setPipedriveAPIKey} label='Pipedrive API key' />
          <TextInput textValue={pipedriveAPIURL} setTextValue={setPipedriveAPIURL} label='Pipedrive API URL' />
          <TextInput textValue={pipedriveLabels} setTextValue={setPipedriveLabels} label='Pipedrive labels' />
          
          <div className='ml-auto w-32'>
            <MainButton text='Save' func={() => {}} />
          </div>
        </div>
  
      </div>
    )
}

export default PlatformCrmSettings