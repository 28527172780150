"use client";
import React, { useState } from 'react';

// import dynamic from "next/dynamic";
// const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });
import Chart from 'react-apexcharts';

interface props {
    subTitle: string;
    title: string;
    labels: string[]
    data: number[]
    colors?: string[]
}

const BigRingChartFilter = ({subTitle, title, labels, colors, data} : props) => {
    const [options, setOptions] = useState({
        labels: labels,
        colors: colors || ['#16BDCA', '#FDBA8C', '#1A56DB'],
        stroke: {
          colors: ["white"],
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 0.9,
            },
          },
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        });
    const [series, setSeries] = useState(data);

  return (
        <div className='flex max-md:flex-col items-center'>
          <div className='h-full'>
            <Chart 
              type='donut'
              options={options}
              series={series}
            />
          </div>

          {/* <!-- Card Footer --> */}
          <div className="grid grid-cols-2 gap-8 py-16">
            {
              data.map((item:any, index:number) => {
                return (
                <div className=''>
                  <h3 className="text-lg">{labels[index].charAt(0).toUpperCase() + labels[index].slice(1)}</h3>
                  <h4 className="text-xl font-bold">{Math.round(data[index])} %</h4>
                </div>
                )
              })
            }
          </div>
        </div>
  )
}

export default BigRingChartFilter