import React, { useEffect, useState } from 'react'
import MainButton from '../../../../../../components/MainButton'
import { updateSelectionPhaseTaskDetails } from '../../../../../../data/SelectionPortalRequests';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import RequirementsResponseTracking from '../../requirement-manager/components/RequirementsResponseTracking';
import { useTranslation } from 'react-i18next'; // Import useTranslation

interface props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequirementsSubmitted = ({ setOpen } : props) => {
  const { t } = useTranslation(); // Initialize t function
  const { selectionTaskId, setSelectionTaskId, setSelectionPhase } = useOutletContext<any>();
  const handleUpdateSelectionPhase = async (redirect? : string) => {
    const response = await updateSelectionPhaseTaskDetails(selectionTaskId);
    if (response.data.newSelectionSelectionPhaseTaskSaved != null)  {
      setSelectionTaskId(response.data.newSelectionSelectionPhaseTaskSaved.id);
    } else {
      setSelectionTaskId("");
    }
    if (response.data.nextSelectionPhaseTask != null) setSelectionPhase(response.data.nextSelectionPhaseTask.selectionPhase);
  }
  
  const handleFinishEvaluation = async  () => {
    handleUpdateSelectionPhase();
    setOpen(false);
    // navigate(`${location.pathname.replace("requirement-manager", "vendor-ranking")}?endPhase=requirements`)
  }

  return (
    <div className='w-full min-h-[50vh] max-h-[80vh] flex flex-col justify-between overflow-y-auto px-4'>
      <div className='mt-16 flex items-center'>
        <div className='w-full h-fit flex flex-col items-center'>
          <p className='text-5xl font-bold text-center'>{t('requirementsSubmitted.submitToVendors')}</p>
          <p className='max-w-64 whitespace-normal mt-10 px-16 text-lg font-light text-center mb-4'>{t('requirementsSubmitted.sendRequirements')}</p>
        </div>   
      </div>

      <RequirementsResponseTracking />

      <div className='my-2'>
        <p className='max-w-64 whitespace-normal px-16 text-lg font-light text-center'>{t('requirementsSubmitted.finalizeFeedback')}</p>
        <div className='mt-2 gap-2 flex justify-center items-center'>
            <div className='w-fit'><MainButton text={t('requirementsSubmitted.finishEvaluation')} func={() => handleFinishEvaluation()} /></div>
        </div>
      </div>
    </div>
  )
}

export default RequirementsSubmitted