import { HighlightArea } from "@react-pdf-viewer/highlight";
import NotificationSlice, { actions } from "../redux/notificationSlice";
import { store } from "../redux/store";
import i18n from "../18n";

export function createNotification(message: string, type:string) {
    store.dispatch(actions.createNotification({
        id: String(Date.now()),
        message: i18n.t("notifications."+message),
        type: type
    }))
}

export async function startNewSelection(customerSearchCustomerDetailsId: string | null, softwareCategory: string) {

    const payload = {
        customerSearchCustomerDetailsId, 
        softwareCategory
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/start-new-selection`, options)
        if (res.ok) {
            console.log("Started selection successfully");
            const data = await res.json();
            console.log(data);
            return {ok: true, selectionId:data.selectionId};
        } else {
            console.error(`startNewSelection: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`startNewSelection: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getSelections(email: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selections?email=${email}`, options)
        if (res.ok) {
            console.log("Queried selections successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getSelections: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelections: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getSelectionRequirements(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-requirements?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried individual requirements successfully");
            const data = await res.json();
            // console.log(data);
            return {ok: true, data};
        } else {
            console.error(`getSelectionRequirements: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionRequirements: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getUniqueSelectionRequirementCategories(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection//get-unique-selection-requirement-categories?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried unique requirement categories successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getUniqueSelectionRequirementCategories: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getUniqueSelectionRequirementCategories: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getSelectionRequirement(requirementId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-requirement?requirementId=${requirementId}`, options)
        if (res.ok) {
            console.log("Queried individual requirement successfully");
            const data = await res.json();
            // console.log(data);
            return {ok: true, data};
        } else {
            console.error(`getSelectionRequirement: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionRequirement: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getRequirementTemplates(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-requirement-templates?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried requirement templates successfully");
            const data = await res.json();
            // console.log(data);
            return {ok: true, data};
        } else {
            console.error(`getRequirementTemplates: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getRequirementTemplates: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function setSelectionRequirementStatus(requirementId: string, status: string) {

    const payload = {
        requirementId,
        status
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/set-selection-requirement-status`, options)
        if (res.ok) {
            console.log("Change requirement status successfully");
            createNotification("Successfully changed requirement status.", "success");
            return true;
        } else {
            console.error(`setSelectionRequirementStatus: Bad response ${res.status}`);
            createNotification("Failed to change requirement status.", "error");
            return false;
        }
    } catch (error) {
        console.error(`setSelectionRequirementStatus: Error occurred ${error}`);
        createNotification("Failed to change requirement status.", "error");
        return false;
    }
}

export async function createNewSelectionRequirement(selectionId: string, requirementTitle: string, requirementDescription: string, category: string, importance: Number | null, newRequirementStaticRequirementId: string | null, existingSelectionRequirementId: string | null) {

    const payload = {
        selectionId,
        requirementTitle,
        requirementDescription,
        importance,
        category,
        newRequirementStaticRequirementId,
        existingSelectionRequirementId
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/create-selection-requirement`, options)
        if (res.ok) {
            const data = await res.json();
            console.log("Change requirement importance successfully");
            // createNotification("Successfully created requirement.", "success");
            return {ok: true, data};
        } else {
            console.error(`createNewSelectionRequirement: Bad response ${res.status}`);
            createNotification("Failed to create requirement.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`createNewSelectionRequirement: Error occurred ${error}`);
        createNotification("Failed to create requirement.", "error");
        return {ok: false};
    }
}

export async function deleteSelectionRequirement(requirementId: string) {

    const payload = {
        requirementId
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/delete-selection-requirement`, options)
        if (res.ok) {
            console.log("Deleted requirement successfully");
            createNotification("Successfully deleted requirement.", "success");
            return {ok: true};
        } else {
            console.error(`deleteSelectionRequirement: Bad response ${res.status}`);
            createNotification("Failed to delete requirement.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`deleteSelectionRequirement: Error occurred ${error}`);
        createNotification("Failed to delete requirement.", "error");
        return {ok: false};
    }
}

export interface UpdateSelectionRequirementKeywords {
    title?: string, 
    description?: string, 
    category?: string, 
    importance?: number
}

export async function updateSelectionRequirement(requirementId: string, title?: string, description?: string, category?: string, importance?: number) {

    const payload = {
        requirementId,
        title, 
        description,
        category,
        importance
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/update-selection-requirement`, options)
        if (res.ok) {
            const data = await res.json();
            console.log("Updated requirement successfully");
            // createNotification("Successfully updated requirement.", "success");
            return {ok: true, data};
        } else {
            console.error(`updateSelectionRequirement: Bad response ${res.status}`);
            createNotification("Failed to update requirement.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`updateSelectionRequirement: Error occurred ${error}`);
        createNotification("Failed to update requirement.", "error");
        return {ok: false};
    }
}

export async function vendorEvaluateRequirement(selectionRequirementId: string, score: number | null, customizingEffort: number | null, comment: string | null) {

    const payload = {
        selectionRequirementId,
        score,
        customizingEffort,
        comment
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/vendor-evaluate-requirement`, options)
        if (res.ok) {
            const data = await res.json();
            console.log("Evaluated requirement successfully");
            // createNotification("Successfully evaluated requirement.", "success");
            return {ok: true, data};
        } else {
            console.error(`vendorEvaluateRequirement: Bad response ${res.status}`);
            createNotification("Failed to evaluate requirement.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`vendorEvaluateRequirement: Error occurred ${error}`);
        createNotification("Failed to evaluate requirement.", "error");
        return {ok: false};
    }
}

export async function getSelectionRanking(selectionId: string, refreshScores?: boolean) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-ranking?selectionId=${selectionId}${typeof refreshScores !== "undefined" ? `&refreshScores=${refreshScores}` : ""}`, options)
        if (res.ok) {
            console.log("Queried ranking successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getSelectionRanking: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionRanking: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getRequirementComparison(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection//get-requirement-comparison?selectionId=${selectionId}`, options)
        const data = await res.json();
        if (res.ok) {
            console.log("Queried comparison successfully");
            return {ok: true, data};
        } else {
            console.error(`getRequirementComparison: Bad response ${res.status}`);
            return {ok: false, data};
        }
    } catch (error) {
        console.error(`getRequirementComparison: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getCostComparison(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection//get-cost-comparison?selectionId=${selectionId}`, options)
        const data = await res.json();
        if (res.ok) {
            console.log("Queried cost comparison successfully");
            return {ok: true, data};
        } else {
            console.error(`getCostComparison: Bad response ${res.status}`);
            return {ok: false, data};
        }
    } catch (error) {
        console.error(`getCostComparison: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getSelectionRankingFull(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-ranking-full?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried ranking successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getSelectionRanking: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionRanking: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getSelectionVendorAccessSecret(selectionSoftwareProductId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-vendor-access-secret?selectionSoftwareProductId=${selectionSoftwareProductId}`, options)
        if (res.ok) {
            console.log("Queried vendor access secret successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getSelectionVendorAccessSecret: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionVendorAccessSecret: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function submitRequirementsToVendors(selectionSoftwareProductId: string, submit?: boolean) {

    const payload = {
        selectionSoftwareProductId,
        submit
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/submit-requirements-to-vendors`, options)
        if (res.ok) {
            // console.log("Changed inlcusion successfully");
            // createNotification("Successfully changed ranking inclusion.", "success");
            const data = await res.json();
            return {data, success:true};
        } else {
            console.error(`submitRequirementsToVendors: Bad response ${res.status}`);
            createNotification("Failed to submit requirements to vendors.", "error");
            return {data:null, success:false};
        }
    } catch (error) {
        console.error(`submitRequirementsToVendors: Error occurred ${error}`);
        createNotification("Failed to submit requirements to vendors.", "error");
        return {data:null, success:false};
    }
}

export async function submitRequirementsToVendorsAndSendEmails(selectionId: string, selectionSoftwareProductIds?: string[]) {

    const payload = {
        selectionId,
        selectionSoftwareProductIds
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/submit-requirements-to-vendors-and-send-emails`, options)
        if (res.ok) {
            // console.log("Changed inlcusion successfully");
            // createNotification("Successfully changed ranking inclusion.", "success");
            const data = await res.json();
            return {data, success:true};
        } else {
            console.error(`submitRequirementsToVendorsAndSendEmails: Bad response ${res.status}`);
            createNotification("Failed to submit requirements to vendors.", "error");
            return {data:null, success:false};
        }
    } catch (error) {
        console.error(`submitRequirementsToVendorsAndSendEmails: Error occurred ${error}`);
        createNotification("Failed to submit requirements to vendors.", "error");
        return {data:null, success:false};
    }
}

export async function submitScenariosToVendorsAndSendEmails(selectionId: string, selectionSoftwareProductIds?: string[]) {

    const payload = {
        selectionId,
        selectionSoftwareProductIds
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/submit-scenarios-to-vendors-and-send-emails`, options)
        if (res.ok) {
            // console.log("Changed inlcusion successfully");
            // createNotification("Successfully changed ranking inclusion.", "success");
            const data = await res.json();
            return {data, success:true};
        } else {
            console.error(`submitScenariosToVendorsAndSendEmails: Bad response ${res.status}`);
            createNotification("Failed to submit scenarios to vendors.", "error");
            return {data:null, success:false};
        }
    } catch (error) {
        console.error(`submitScenariosToVendorsAndSendEmails: Error occurred ${error}`);
        createNotification("Failed to submit scenarios to vendors.", "error");
        return {data:null, success:false};
    }
}

export async function submitScenariosToVendors(selectionSoftwareProductId: string, submit?: boolean) {

    const payload = {
        selectionSoftwareProductId,
        submit
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/submit-scenarios-to-vendors`, options)
        if (res.ok) {
            const data = await res.json();
            return {data, success:true};
        } else {
            console.error(`submitScenariosToVendors: Bad response ${res.status}`);
            createNotification("Failed to submit scenarios to vendors.", "error");
            return {data:null, success:false};
        }
    } catch (error) {
        console.error(`submitScenariosToVendors: Error occurred ${error}`);
        createNotification("Failed to submit scenarios to vendors.", "error");
        return {data:null, success:false};
    }
}

export async function changeRankingInclusion(selectionSoftwareProductId: string, included: boolean) {

    const payload = {
        selectionSoftwareProductId,
        included
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/change-ranking-inclusion`, options)
        if (res.ok) {
            // console.log("Changed inlcusion successfully");
            // createNotification("Successfully changed ranking inclusion.", "success");
            return true;
        } else {
            console.error(`changeRankingInclusion: Bad response ${res.status}`);
            createNotification("Failed to change ranking inclusion.", "error");
            return false;
        }
    } catch (error) {
        console.error(`changeRankingInclusion: Error occurred ${error}`);
        createNotification("Failed to change ranking inclusion.", "error");
        return false;
    }
}

export async function changeRankingDisabled(selectionId: string, softwareFulfillmentId: string, selectionSoftwareProductId: string | undefined, disabled: boolean) {

    const payload = {
        selectionId,
        softwareFulfillmentId,
        selectionSoftwareProductId,
        disabled
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/change-ranking-disabled`, options)
        if (res.ok) {
            console.log("Changed disabled successfully");
            // createNotification("Successfully changed ranking inclusion.", "success");
            return true;
        } else {
            console.error(`changeRankingDisabled: Bad response ${res.status}`);
            createNotification("Failed to change ranking inclusion.", "error");
            return false;
        }
    } catch (error) {
        console.error(`changeRankingDisabled: Error occurred ${error}`);
        createNotification("Failed to change ranking inclusion.", "error");
        return false;
    }
}

export async function changeVendorContactEmail(selectionSoftwareProductId: string, vendorContactEmail: string) {

    const payload = {
        selectionSoftwareProductId,
        vendorContactEmail
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/change-vendor-contact-email`, options)
        if (res.ok) {
            console.log("Changed vendor contact email successfully");
            // createNotification("Successfully changed ranking inclusion.", "success");
            return true;
        } else {
            console.error(`changeVendorContactEmail: Bad response ${res.status}`);
            createNotification("Failed to change vendor contact email.", "error");
            return false;
        }
    } catch (error) {
        console.error(`changeVendorContactEmail: Error occurred ${error}`);
        createNotification("Failed to change vendor contact email.", "error");
        return false;
    }
}

export async function changeVendorFinancialData(selectionSoftwareProductId: string, financialFormData: any) {

    const payload = {
        selectionSoftwareProductId,
        financialFormData
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/change-vendor-financial-data`, options)
        if (res.ok) {
            console.log("Changed vendor financial data successfully");
            // createNotification("Successfully changed ranking inclusion.", "success");
            return true;
        } else {
            console.error(`changeVendorFinancialData: Bad response ${res.status}`);
            createNotification("Failed to change vendor financial data.", "error");
            return false;
        }
    } catch (error) {
        console.error(`changeVendorFinancialData: Error occurred ${error}`);
        createNotification("Failed to change vendor financial data.", "error");
        return false;
    }
}

export async function changeRankingInclusionMultiple(selectionId: string, maxRankingToBeExcluded: number) {

    const payload = {
        selectionId,
        maxRankingToBeExcluded
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/change-ranking-inclusion-multiple`, options)
        if (res.ok) {
            console.log("Changes inlcusion multiple successfully");
            // createNotification("Successfully changed ranking inclusion multiple.", "success");
            return true;
        } else {
            console.error(`changeRankingInclusionMultiple: Bad response ${res.status}`);
            createNotification("Failed to change ranking inclusion.", "error");
            return false;
        }
    } catch (error) {
        console.error(`changeRankingInclusionMultiple: Error occurred ${error}`);
        createNotification("Failed to change ranking inclusion.", "error");
        return false;
    }
}

export async function getDocuments(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-documents?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried documents successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getDocuments: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getDocuments: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getDocument(documentId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-document?documentId=${documentId}`, options)
        if (res.ok) {
            console.log("Queried document successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getDocument: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getDocument: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function uploadDocument(selectionSoftwareProduct: string, documentName: string, documentType: string, document: string | ArrayBuffer) {

    const payload = {
        selectionSoftwareProduct,
        documentName,
        documentType,
        document
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/upload-selection-document`, options)
        if (res.ok) {
            console.log("Uploaded document successfully");
            createNotification("Successfully uploaded document.", "success");
            return true;
        } else {
            console.error(`uploadDocument: Bad response ${res.status}`);
            createNotification("Uploading document failed.", "error");
            return false;
        }
    } catch (error) {
        console.error(`uploadDocument: Error occurred ${error}`);
        createNotification("Uploading document failed.", "error");
        return false;
    }
}

export async function deleteDocument(selectionSoftwareProductDocumentId: string) {

    const payload = {
        selectionSoftwareProductDocumentId
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/delete-selection-document`, options)
        if (res.ok) {
            console.log("Deleted document successfully");
            createNotification("Successfully deleted document.", "success");
            return true;
        } else {
            console.error(`deleteDocument: Bad response ${res.status}`);
            createNotification("Deleting document failed.", "error");
            return false;
        }
    } catch (error) {
        console.error(`deleteDocument: Error occurred ${error}`);
        createNotification("Deleting document failed.", "error");
        return false;
    }
}

export async function getDocumentComments(documentId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-document-comments?documentId=${documentId}`, options)
        if (res.ok) {
            console.log("Queried document comments successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getDocumentComments: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getDocumentComments: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function addDocumentComment(selectionSoftwareProductDocumentId: string, comment: string, quote: string, highlightAreas: HighlightArea[], checklistItem: string | undefined) {

    const payload = {
        selectionSoftwareProductDocumentId,
        comment,
        quote,
        highlightAreas,
        checklistItem
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/add-selection-document-comment`, options)
        if (res.ok) {
            console.log("Added document comment successfully");
            createNotification("Added comment successfully.", "success");
            return true;
        } else {
            console.error(`addDocumentComment: Bad response ${res.status}`);
            createNotification("Adding comment failed.", "error");
            return false;
        }
    } catch (error) {
        console.error(`addDocumentComment: Error occurred ${error}`);
        createNotification("Adding comment failed.", "error");
        return false;
    }
}

export async function getSelectionDetails(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-details?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried selection details successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getSelectionDetails: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionDetails: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getCockpitDataGuest(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-cockpit-data-guest?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried cockpit data successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getCockpitDataVendor: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getCockpitDataVendor: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function updateSelectionTitle(selectionId: string, selectionTitle: string) {

    const payload = {
        selectionId,
        selectionTitle
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/set-selection-title`, options)
        if (res.ok) {
            console.log("Updated selection title successfully");
            const data = await res.json();
            return {ok: true};
        } else {
            console.error(`setSelectionTitle: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`setSelectionTitle: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function updateSelectionData(selectionId: string, selectionData: any) {

    const payload = {
        selectionId,
        selectionData
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/set-selection-data`, options)
        if (res.ok) {
            console.log("Updated selection data successfully");
            const data = await res.json();
            return {ok: true};
        } else {
            console.error(`updateSelectionData: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`updateSelectionData: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function uploadSelectionLogo(selectionId : string, image : Blob, imageName: string) {
    const formData = new FormData();
    formData.append("selectionLogo", image, imageName);
    formData.append("selectionId", selectionId);
    formData.append("filename", imageName);

    const options : RequestInit = {
        method: "POST",
        credentials: "include",
        body: formData
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/upload-selection-logo`, options)
        if (res.ok) {
            console.log("Upload Successful");
            return true;
        } else {
            console.error(`uploadSelectionLogo: Bad response ${res.status}`);
            return false;
        }
    } catch (error) {
        console.error(`uploadSelectionLogo: Error occurred ${error}`);
        return false;
    }
}

export async function getSelectionLogo(selectionId : string, placeholder: any) {
    try {
        const options : RequestInit = {
            method: "GET",
            headers: {
                'Content-Type': 'image/jpeg'
            },
            credentials: "include",
        }

        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-logo?selectionId=${selectionId}`, options)
        if (res.status == 200) {
            const blob = await res.blob();
            return URL.createObjectURL(blob);
        } else if (res.status == 204) {
            console.error(`getSelectionLogo: Bad response ${res.status}`);
            return placeholder;
        } else {
            console.error(`getSelectionLogo: Bad response ${res.status}`);
            return placeholder;
        }
    } catch (error) {
        console.error(`getSelectionLogo: Error occurred ${error}`);
        return placeholder;
    }
}

export async function getUsersSelection(selectionId: string, includeAccessCode?: boolean) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-users-selection?selectionId=${selectionId}`+(includeAccessCode ? "&includeAccessCode=true" : ""), options)
        if (res.ok) {
            console.log("Queried user selection successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getUsersSelection: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getUsersSelection: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getUserSelectionRole(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-user-selection-role?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried user selection role successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getUserSelectionRole: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getUserSelectionRole: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function addUserSelection(selectionId: string, email: string) {

    const payload = {
        selectionId,
        email
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/add-user-selection`, options)
        if (res.ok) {
            console.log("Added user selection successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Added user to selection successfully.", "success");
            return {ok: true};
        } else {
            console.error(`getUsersSelection: Bad response ${res.status}`);
            createNotification("Adding user to selection failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`getUsersSelection: Error occurred ${error}`);
        createNotification("Adding user to selection failed.", "error");
        return {ok: false};
    }
}

export async function changeSelectionEditingRights(selectionId: string, userSelectionId: string, role: string) {

    const payload = {
        selectionId,
        userSelectionId,
        role
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/change-selection-editing-rights`, options)
        if (res.ok) {
            console.log("Changed user selection role successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Changed user editing rights successfully.", "success");
            return {ok: true};
        } else if (res.status == 403) {
            console.error(`changeSelectionEditingRights: Bad response ${res.status}`);
            createNotification("At least one project lead must remain.", "error");
            return {ok: false};
        } else {
            console.error(`changeSelectionEditingRights: Bad response ${res.status}`);
            createNotification("Changing user rights failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`changeSelectionEditingRights: Error occurred ${error}`);
        createNotification("Changing user rights failed.", "error");
        return {ok: false};
    }
}

export async function changeSelectionParticipantGroup(selectionId: string, userSelectionId: string, group: string) {

    const payload = {
        selectionId,
        userSelectionId,
        group
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/change-selection-participant-group`, options)
        if (res.ok) {
            console.log("Changed user selection role successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Changed group selection successfully.", "success");
            return {ok: true};
        } else if (res.status == 403) {
            console.error(`changeSelectionParticipantGroup: Bad response ${res.status}`);
            createNotification("Changing group failed.", "error");
            return {ok: false};
        } else {
            console.error(`changeSelectionParticipantGroup: Bad response ${res.status}`);
            createNotification("Changing group failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`changeSelectionParticipantGroup: Error occurred ${error}`);
        createNotification("Changing user role failed.", "error");
        return {ok: false};
    }
}

export async function changeSelectionCustomGroup(selectionId: string, userSelectionId: string, group: string) {

    const payload = {
        selectionId,
        userSelectionId,
        group
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/change-selection-custom-group`, options)
        if (res.ok) {
            console.log("Changed user selection role successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Changed group selection successfully.", "success");
            return {ok: true};
        } else if (res.status == 403) {
            console.error(`changeSelectionCustomGroup: Bad response ${res.status}`);
            createNotification("Changing group failed.", "error");
            return {ok: false};
        } else {
            console.error(`changeSelectionCustomGroup: Bad response ${res.status}`);
            createNotification("Changing group failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`changeSelectionCustomGroup: Error occurred ${error}`);
        createNotification("Changing user role failed.", "error");
        return {ok: false};
    }
}

export async function deleteSelection(selectionId: string) {

    const payload = {
        selectionId,
    }

    const options : RequestInit = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/delete-selection`, options)
        if (res.ok) {
            return {ok: true};
        } else if (res.status == 403) {
            console.error(`deleteSelection: Bad response ${res.status}`);
            createNotification("At least one project lead must remain.", "error");
            return {ok: false};
        } else {
            console.error(`deleteSelection: Bad response ${res.status}`);
            createNotification("Deleting selection failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`deleteSelection: Error occurred ${error}`);
        createNotification("Deleting selection failed.", "error");
        return {ok: false};
    }
}

export async function exportSelection(selectionId: string, dataToExport: string, exportFormat: string) {

    const payload = {
        selectionId,
        dataToExport,
        exportFormat
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/export-selection-data`, options)
        if (res.ok) {
            createNotification("Exported data successfully.", "success");
            return {ok:true, ...(await res.json()).exportsData};
        } else {
            console.error(`exportSelection: Bad response ${res.status}`);
            createNotification("Exporting selection failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`exportSelection: Error occurred ${error}`);
        createNotification("Exporting selection failed.", "error");
        return {ok: false};
    }
}

export async function addRequirementComment(requirementId: string, email: string, comment: string) {

    const payload = {
        requirementId,
        email,
        comment
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/add-requirement-comment`, options)
        if (res.ok) {
            console.log("Added requirement comment successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Added comment successfully.", "success");
            return {ok: true};
        } else {
            console.error(`addRequirementComment: Bad response ${res.status}`);
            createNotification("Adding comment failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`addRequirementComment: Error occurred ${error}`);
        createNotification("Adding comment failed.", "error");
        return {ok: false};
    }
}

export async function getRequirementComment(requirementId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-requirement-comments?requirementId=${requirementId}`, options)
        if (res.ok) {
            console.log("Queried requirement comments successfully");
            const data = await res.json();
            // console.log(data);
            return {ok: true, data};
        } else {
            console.error(`getRequirementComment: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getRequirementComment: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getAllComments(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-all-comments?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried all comments successfully");
            const data = await res.json();
            // console.log(data);
            return {ok: true, data};
        } else {
            console.error(`getAllMessages: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getAllMessages: Error occurred ${error}`);
        return {ok: false};
    }
}

// Chat
export async function getAllChats(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-all-chats?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried all chats successfully");
            const data = await res.json();
            // console.log(data);
            return {ok: true, data};
        } else {
            console.error(`getAllChats: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getAllChats: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function addChat(recipientIds: string[], selectionId: string) {

    const payload = {
        recipientIds,
        selectionId,
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/add-chat`, options)
        if (res.ok) {
            console.log("Added chat successfully");
            // const data = await res.json();
            // console.log(data);
            return {ok: true};
        } else {
            console.error(`addChat: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`addChat: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getMessages(chatId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-messages?chatId=${chatId}`, options)
        if (res.ok) {
            console.log("Fetched message successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getMessages: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getMessages: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function addMessage(chatId: string, message: string) {

    const payload = {
        chatId, 
        message
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/add-message`, options)
        if (res.ok) {
            console.log("Added message successfully");
            // const data = await res.json();
            // console.log(data);
            return {ok: true};
        } else {
            console.error(`addMessage: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`addMessage: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getSelectionScenarioItems(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-scenario-items?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried individual scenario items successfully");
            const data = await res.json();
            // console.log(data);
            return {ok: true, data};
        } else {
            console.error(`getSelectionScenarioItems: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionScenarioItems: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function addSelectionScenario(scenarioTitle: string, scenarioDescription: string, orderNumber: number, selectionId: string) {

    const payload = {
        scenarioTitle, 
        scenarioDescription,
        orderNumber, 
        selectionId
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/add-selection-scenario`, options)
        if (res.ok) {
            console.log("Added scenario successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Added scenario successfully.", "success");
            return {ok: true};
        } else {
            console.error(`addSelectionScenario: Bad response ${res.status}`);
            createNotification("Adding scenario failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`addSelectionScenario: Error occurred ${error}`);
        createNotification("Adding scenario failed.", "error");
        return {ok: false};
    }
}

export async function updateSelectionScenarioOrder(scenarioId: string, orderNumber: number) {

    const payload = {
        scenarioId, 
        orderNumber,
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/update-selection-scenario-order`, options)
        if (res.ok) {
            console.log("Updated scenario order successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Updated scenario order successfully.", "success");
            return {ok: true};
        } else {
            console.error(`updateSelectionScenarioOrder: Bad response ${res.status}`);
            createNotification("Updating scenario order failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`updateSelectionScenarioOrder: Error occurred ${error}`);
        createNotification("Updating scenario order failed.", "error");
        return {ok: false};
    }
}

export async function updateSelectionScenarioTaskOrder(scenarioTaskId: string, orderNumber: number) {

    const payload = {
        scenarioTaskId, 
        orderNumber,
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/update-selection-scenario-task-order`, options)
        if (res.ok) {
            console.log("Updated scenario task order successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Updated scenario task order successfully.", "success");
            return {ok: true};
        } else {
            console.error(`updateSelectionScenarioTaskOrder: Bad response ${res.status}`);
            createNotification("Updating scenario task order failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`updateSelectionScenarioTaskOrder: Error occurred ${error}`);
        createNotification("Updating scenario task order failed.", "error");
        return {ok: false};
    }
}

export async function updateSelectionScenarioTaskCategoryOrder(scenarioTaskCategoryId: string, orderNumber: number) {

    const payload = {
        scenarioTaskCategoryId, 
        orderNumber,
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/update-selection-scenario-task-category-order`, options)
        if (res.ok) {
            console.log("Updated scenario task category order successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Updated scenario task category order successfully.", "success");
            return {ok: true};
        } else {
            console.error(`updateSelectionScenarioTaskCategoryOrder: Bad response ${res.status}`);
            createNotification("Updating scenario task category order failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`updateSelectionScenarioTaskCategoryOrder: Error occurred ${error}`);
        createNotification("Updating scenario task category order failed.", "error");
        return {ok: false};
    }
}

export async function addSelectionScenarioTask(scenarioTaskTitle: string, scenarioTaskDescription: string, orderNumber: number, scenarioTaskCategoryId : string, newScenarioTaskCategoryTitle : string, scenarioId: string) {

    const payload = {
        scenarioTaskTitle, 
        scenarioTaskDescription,
        orderNumber, 
        scenarioTaskCategoryId,
        newScenarioTaskCategoryTitle,
        scenarioId
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/add-selection-scenario-task`, options)
        if (res.ok) {
            console.log("Added scenario task successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Added scenario task successfully.", "success");
            return {ok: true};
        } else {
            console.error(`addSelectionScenario: Bad response ${res.status}`);
            createNotification("Adding scenario task failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`addSelectionScenario: Error occurred ${error}`);
        createNotification("Adding scenario task failed.", "error");
        return {ok: false};
    }
}

export async function updateSelectionScenario(scenarioId: string, scenarioTitle?: string, scenarioDescription?: string) {

    const payload = {
        scenarioId,
        scenarioTitle,
        scenarioDescription
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/update-selection-scenario`, options)
        if (res.ok) {
            console.log("Updated scenario successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Updated scenario successfully.", "success");
            return {ok: true};
        } else {
            console.error(`updateSelectionScenario: Bad response ${res.status}`);
            createNotification("Updating scenario failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`updateSelectionScenario: Error occurred ${error}`);
        createNotification("Updating scenario failed.", "error");
        return {ok: false};
    }
}

export async function updateSelectionScenarioTask(scenarioTaskId: string, scenarioTaskTitle?: string, scenarioTaskDescription?: string) {

    const payload = {
        scenarioTaskId,
        scenarioTaskTitle,
        scenarioTaskDescription
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/update-selection-scenario-task`, options)
        if (res.ok) {
            console.log("Updated scenario task successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Updated scenario task successfully.", "success");
            return {ok: true};
        } else {
            console.error(`updateSelectionScenarioTask: Bad response ${res.status}`);
            createNotification("Updating scenario task failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`updateSelectionScenarioTask: Error occurred ${error}`);
        createNotification("Updating scenario task failed.", "error");
        return {ok: false};
    }
}

export async function updateSelectionScenarioTaskCategory(scenarioTaskCategoryId: string, scenarioTaskCategoryTitle?: string) {

    const payload = {
        scenarioTaskCategoryId,
        scenarioTaskCategoryTitle
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/update-selection-scenario-task-category`, options)
        if (res.ok) {
            console.log("Updated scenario task category successfully");
            // const data = await res.json();
            // console.log(data);
            createNotification("Updated scenario task category successfully.", "success");
            return {ok: true};
        } else {
            console.error(`updateSelectionScenarioTaskCategory: Bad response ${res.status}`);
            createNotification("Updating scenario task category failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`updateSelectionScenarioTaskCategory: Error occurred ${error}`);
        createNotification("Updating scenario task category failed.", "error");
        return {ok: false};
    }
}

export async function setSelectionScenarioTaskStatus(scenarioTaskId: string, status: string) {

    const payload = {
        scenarioTaskId,
        status
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/set-selection-scenario-task-status`, options)
        if (res.ok) {
            console.log("Change scenario task status successfully");
            createNotification("Updated status successfully.", "success");
            return true;
        } else {
            console.error(`setSelectionScenarioTaskStatus: Bad response ${res.status}`);
            createNotification("Updating status failed.", "error");
            return false;
        }
    } catch (error) {
        console.error(`setSelectionScenarioTaskStatus: Error occurred ${error}`);
        createNotification("Updating status failed.", "error");
        return false;
    }
}

export async function deleteSelectionScenario(scenarioId: string) {

    const payload = {
        scenarioId
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/delete-selection-scenario`, options)
        if (res.ok) {
            console.log("Deleted scenario successfully");
            createNotification("Deleted scenario successfully.", "success");
            return true;
        } else {
            console.error(`deleteSelectionScenario: Bad response ${res.status}`);
            createNotification("Deleting scenario failed.", "error");
            return false;
        }
    } catch (error) {
        console.error(`deleteSelectionScenario: Error occurred ${error}`);
        createNotification("Deleting scenario failed.", "error");
        return false;
    }
}

export async function deleteSelectionScenarioTask(scenarioTaskId: string) {

    const payload = {
        scenarioTaskId
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/delete-selection-scenario-task`, options)
        if (res.ok) {
            console.log("Deleted scenario task successfully");
            createNotification("Deleted scenario task successfully.", "success");
            return true;
        } else {
            console.error(`deleteSelectionScenarioTask: Bad response ${res.status}`);
            createNotification("Deleting scenario task failed.", "error");
            return false;
        }
    } catch (error) {
        console.error(`deleteSelectionScenarioTask: Error occurred ${error}`);
        createNotification("Deleting scenario task failed.", "error");
        return false;
    }
}

export async function getSelectionCompanyDescription(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-company-description?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried selection company summary successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getSelectionCompanySummary: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionCompanySummary: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getSelectionPresentations(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-presentations?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried selection presentations successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getSelectionPresentations: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionPresentations: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getSelectionPresentation(selectionPresentationId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-presentation?selectionPresentationId=${selectionPresentationId}`, options)
        if (res.ok) {
            console.log("Queried selection presentation successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getSelectionPresentation: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionPresentation: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function createSelectionPresentation(selectionId: string, selectionSoftwareProductId: string, timeFrom: Date, timeTo: Date, title: string, participants: string[], videoconferenceLink: string, videoconferencePassword: string, agenda: string) {
    
    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
            selectionId, selectionSoftwareProductId, timeFrom, timeTo, title, participants, videoconferenceLink, videoconferencePassword, agenda
        }),
    }
    
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/create-presentation`, options)
        if (res.ok) {
            console.log("Created selection presentation successfully");
            return true;
        } else {
            console.error(`createSelectionPresentation: Bad response ${res.status}`);
            return false;
        }
    } catch (error) {
        console.error(`createSelectionPresentation: Error occurred ${error}`);
        return false;
    }
}

export async function updatePresentationParticipant(presentationId: string, userSelectionId: string, toBeRemoved: boolean) {

    const payload = {
        presentationId,
        userSelectionId,
        toBeRemoved
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/update-presentation-participant`, options)
        if (res.ok) {
            console.log("Added user presentation successfully");
            // const data = await res.json();
            // console.log(data);
            // createNotification("Added user to presentation successfully.", "success");
            return {ok: true};
        } else {
            console.error(`addPresentationParticipant: Bad response ${res.status}`);
            // createNotification("Adding user to presentation failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`addPresentationParticipant: Error occurred ${error}`);
        // createNotification("Adding user to presentation failed.", "error");
        return {ok: false};
    }
}

export async function notifyParticipantsViaEmail(presentationId: string) {

    const payload = {
        presentationId,
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection//notify-presentation-participants-email`, options)
        if (res.ok) {
            console.log("Notified presentation participants successfully");
            // const data = await res.json();
            // console.log(data);
            // createNotification("Added user to presentation successfully.", "success");
            return {ok: true};
        } else {
            console.error(`notifyParticipantsViaEmail: Bad response ${res.status}`);
            // createNotification("Adding user to presentation failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`notifyParticipantsViaEmail: Error occurred ${error}`);
        // createNotification("Adding user to presentation failed.", "error");
        return {ok: false};
    }
}

export async function updatePresentationDetails(presentationId: string, agenda: string | null, videoconferenceLink: string | null, videoconferencePassword: string | null, presentationStatus : string | null) {

    const payload = {
        presentationId, agenda, videoconferenceLink, videoconferencePassword, presentationStatus
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/update-presentation-details`, options)
        if (res.ok) {
            console.log("Added presentation agenda successfully");
            // const data = await res.json();
            // console.log(data);
            // createNotification("Added user to presentation successfully.", "success");
            return {ok: true};
        } else {
            console.error(`updatePresentationAgenda: Bad response ${res.status}`);
            // createNotification("Adding user to presentation failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`updatePresentationAgenda: Error occurred ${error}`);
        // createNotification("Adding user to presentation failed.", "error");
        return {ok: false};
    }
}

export async function getPresentationEvaluationUser(selectionPresentationId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-presentation-evaluation-user?selectionPresentationId=${selectionPresentationId}`, options)
        if (res.ok) {
            console.log("Queried selection presentation evaluation user successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getPresentationEvaluationUser: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getPresentationEvaluationUser: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getPresentationEvaluationOverall(selectionPresentationId: string, selectedUsers?:string[]) {
    const payload = {
        selectionPresentationId,
        selectedUsers
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-presentation-evaluation-overall`, options)
        if (res.ok) {
            console.log("Queried evaluations overall successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getPresentationEvaluationOverall: Bad response ${res.status}`);
            // createNotification("Adding user to presentation failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`getPresentationEvaluationOverall: Error occurred ${error}`);
        // createNotification("Adding user to presentation failed.", "error");
        return {ok: false};
    }
}

export async function generatePresentationSummary(selectionPresentationId: string) {
    const payload = {
        selectionPresentationId
    }

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload)
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/generate-presentation-summary`, options)
        if (res.ok) {
            console.log("Generated presentation summary successfully");
            const data = await res.json();
            // console.log(data);
            // createNotification("Added user to presentation successfully.", "success");
            return {ok: true, data};
        } else {
            console.error(`generatePresentationSummary: Bad response ${res.status}`);
            // createNotification("Adding user to presentation failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`generatePresentationSummary: Error occurred ${error}`);
        // createNotification("Adding user to presentation failed.", "error");
        return {ok: false};
    }
}

export async function getPresentationEvaluationUserForDownload(selectionPresentationId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-presentation-evaluation-user-download?selectionPresentationId=${selectionPresentationId}`, options)
        if (res.ok) {
            console.log("Queried selection presentation evaluation for download successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getPresentationEvaluationUserForDownload: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getPresentationEvaluationUserForDownload: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function updatePresentationEvaluationUser(selectionPresentationId:string, selectionScenarioTaskId:string, comment: string | null, rating?: number) {
    
    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
            selectionPresentationId, selectionScenarioTaskId, rating, comment
        }),
    }
    
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/update-presentation-evaluation-user`, options)
        if (res.ok) {
            console.log("Updated selection presentation evaluation successfully");
            return true;
        } else {
            console.error(`updatePresentationEvaluationUser: Bad response ${res.status}`);
            return false;
        }
    } catch (error) {
        console.error(`updatePresentationEvaluationUser: Error occurred ${error}`);
        return false;
    }
}

export async function getSelectionPhase(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-phase?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried selection phase successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getSelectionPhase: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionPhase: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getSelectionPhaseTasks(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-phase-tasks?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried selection phase tasks successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getSelectionPhaseTasks: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionPhaseTasks: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getSelectionPhaseTaskDetails(selectionSelectionPhaseTaskId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-selection-phase-task-details?selectionSelectionPhaseTaskId=${selectionSelectionPhaseTaskId}`, options)
        if (res.ok) {
            console.log("Queried selection phase task details successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getSelectionPhaseTaskDetails: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getSelectionPhaseTaskDetails: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function updateSelectionPhaseTaskDetails(selectionSelectionPhaseTaskId: string) {

    const payload = {
        selectionSelectionPhaseTaskId
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/update-selection-phase-task-details`, options)
        if (res.ok) {
            console.log("Updated selection phase task successfully");
            const data = await res.json();
            return {ok: true, ...data};
        } else {
            console.error(`updateSelectionPhaseTaskDetails: Bad response ${res.status}`);
            // createNotification("Deleting scenario task failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`updateSelectionPhaseTaskDetails: Error occurred ${error}`);
        // createNotification("Deleting scenario task failed.", "error");
        return {ok: false};
    }
}

export async function updateSelectionPhaseTaskDetailsVendor(selectionSelectionPhaseTaskId: string) {

    const payload = {
        selectionSelectionPhaseTaskId
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/update-selection-phase-task-details-vendor`, options)
        if (res.ok) {
            console.log("Updated selection phase task successfully");
            const data = await res.json();
            return {ok: true, ...data};
        } else {
            console.error(`updateSelectionPhaseTaskDetails: Bad response ${res.status}`);
            // createNotification("Deleting scenario task failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`updateSelectionPhaseTaskDetails: Error occurred ${error}`);
        // createNotification("Deleting scenario task failed.", "error");
        return {ok: false};
    }
}

export async function evaluateRequirementFulfillments(selectionId: string) {

    const payload = {
        selectionId
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/evaluate-requirement-fulfillments`, options)
        if (res.ok) {
            console.log("Evaluated requirement fulfillments successfully");
            const data = await res.json();
            return {ok: true, ...data};
        } else {
            console.error(`evaluateRequirementFulfillments: Bad response ${res.status}`);
            // createNotification("Deleting scenario task failed.", "error");
            return {ok: false};
        }
    } catch (error) {
        console.error(`evaluateRequirementFulfillments: Error occurred ${error}`);
        // createNotification("Deleting scenario task failed.", "error");
        return {ok: false};
    }
}

export async function setSelectionCompanyDescription(selectionId: string, companyDescription: string) {

    const payload = {
        selectionId,
        companyDescription
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/set-selection-company-description`, options)
        if (res.ok) {
            console.log("Changed selection company description successfully");
            createNotification("Updated description successfully.", "success");
            return true;
        } else {
            console.error(`setSelectionCompanyDescription: Bad response ${res.status}`);
            createNotification("Updating description failed.", "error");
            return false;
        }
    } catch (error) {
        console.error(`setSelectionCompanyDescription: Error occurred ${error}`);
        createNotification("Updating description failed.", "error");
        return false;
    }
}

export async function setSelectionProjectPhases(selectionId: string, p1RequirementStart: Date, p1RequirementEnd: Date, p2ScenariosStart: Date, p2ScenariosEnd: Date, p3PresentationsStart: Date, p3PresentationsEnd: Date, p4ContractsStart: Date, p4ContractsEnd: Date) {

    const payload = {
        selectionId, p1RequirementStart, p1RequirementEnd, p2ScenariosStart, p2ScenariosEnd, p3PresentationsStart, p3PresentationsEnd, p4ContractsStart, p4ContractsEnd
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/set-selection-phases`, options)
        if (res.ok) {
            console.log("Changed selection project phases successfully");
            createNotification("Updated selection project phases successfully.", "success");
            return true;
        } else {
            console.error(`setSelectionProjectPhases: Bad response ${res.status}`);
            createNotification("Updating phases failed.", "error");
            return false;
        }
    } catch (error) {
        console.error(`setSelectionProjectPhases: Error occurred ${error}`);
        createNotification("Updating phases failed.", "error");
        return false;
    }
}

export async function getNumberSoftwareProductsInSelection(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-number-software-products-selection?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried number of software products in selection successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getNumberSoftwareProductsInSelection: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getNumberSoftwareProductsInSelection: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getRequirementStatusStatistics(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-requirement-status-statistics-selection?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried requirement status statistics successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getRequirementStatusStatistics: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getRequirementStatusStatistics: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getScenarioStatusStatistics(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-scenario-status-statistics-selection?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried scenario status statistics successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getScenarioStatusStatistics: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getScenarioStatusStatistics: Error occurred ${error}`);
        return {ok: false};
    }
}

export async function getPresentationStatusStatistics(selectionId: string) {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/selection/get-presentation-status-statistics-selection?selectionId=${selectionId}`, options)
        if (res.ok) {
            console.log("Queried presentation status statistics successfully");
            const data = await res.json();
            return {ok: true, data};
        } else {
            console.error(`getPresentationStatusStatistics: Bad response ${res.status}`);
            return {ok: false};
        }
    } catch (error) {
        console.error(`getPresentationStatusStatistics: Error occurred ${error}`);
        return {ok: false};
    }
}