import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../../../components/MainButton';
import { changeVendorContactEmail, getSelectionRankingFull, getSelectionVendorAccessSecret, submitScenariosToVendors, submitScenariosToVendorsAndSendEmails, updateSelectionPhaseTaskDetails } from '../../../../../../data/SelectionPortalRequests';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import TextInput from '../../../../../../components/TextInput';
import { CheckmarkIcon, CommentIcon, CrossIcon, InboxFilledIcon, ListFilledIcon, LoadingSpinner, MessageIcon, OpenIcon, RefreshIcon } from 'swap-frontend-library';
import ClipboardIcon from '../../../../../../assets/img/icons/ClipboardIcon';

interface props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScenarioResponseTracking = () => {
  const { t } = useTranslation();
  const { selectionId }  = useOutletContext<any>();
  const [ vendorRanking, setVendorRanking ] = useState<any[]>();
  const [ submitToAllConfirmed, setSubmitToAllConfirmed ] = useState(false);
  const [ submittedToAll, setSubmittedToAll ] = useState(false);
  
  const handleFetchRanking = async () => {
    const response = await getSelectionRankingFull(selectionId);
    if (response.ok) {
      const sortedSelectionSoftwareProducts = response.data.data.selectionSoftwareProducts.sort((a:any, b:any) => a.softwareProduct.productName.toLowerCase() > b.softwareProduct.productName.toLowerCase())
      setVendorRanking(sortedSelectionSoftwareProducts);
    }
  }

  const handleSubmitToVendorsAndSendEmails = async () => {
    const response = await submitScenariosToVendorsAndSendEmails(selectionId);
    setSubmittedToAll(true);
    setSubmitToAllConfirmed(false);
  }

  useEffect(() => {
    handleFetchRanking();
  },[])

  return (
    <div className='relative w-full flex flex-col justify-between overflow-y-auto px-4'>

        <div className='p-2 items-end grid grid-cols-12 mt-16 text-gray-600'>
          <div className='w-40 truncate col-span-3'>
            {t('scenarioResponseTracking.shortlist')}
          </div>
        
          <div className='mx-4 col-span-4'></div>

          <div className='flex gap-2 mx-4 col-span-3'>
            <p className='w-8 text-center -rotate-45'>{t('scenarioResponseTracking.submitted')}</p>
            <p className='w-8 text-center -rotate-45'>{t('scenarioResponseTracking.opened')}</p>
          </div>

          <div className='ml-auto col-span-2 w-full'>
            <div className='w-full mx-auto'>
              <MainButton text={t('scenarioResponseTracking.submitToAll')} func={() => {setSubmitToAllConfirmed(true)}} />
              {
                submitToAllConfirmed ? 
                  <div className='left-0 px-16 absolute z-[2] w-full flex flex-col items-center justify-center'>
                    <div className='bg-red-100/70 py-8 px-16 backdrop-blur shadow-lg border border-red-600 rounded-lg w-full flex flex-col items-center justify-center'>  
                      <p className='w-full text-red-600 rounded-lg whitespace-normal px-4 text-lg font-semibold text-center mb-4'>
                        {t('scenarioResponseTracking.attentionMessage')}
                      </p>
                      <div className='w-fit flex gap-2'>
                        <MainButton text={t('scenarioResponseTracking.confirm')} func={() => handleSubmitToVendorsAndSendEmails()} />
                        <MainButton text={t('scenarioResponseTracking.cancel')} style={"light"} func={() => setSubmitToAllConfirmed(false)} />
                      </div>
                    </div>
                  </div> : <></>
              }
            </div>
          </div>
          
        </div>
        <div className='space-y-2'>
          {
            vendorRanking?.filter(item => !item.disabled && item.included).map((item:any, index:number) => {
              return (
                <VendorRow item={item} submittedToAll={submittedToAll} />
              )
            })
          }
        </div>

    </div>
  )
}

const VendorRow = ({item, submittedToAll} : {item:any, submittedToAll:boolean}) => {
  const { t } = useTranslation();
  const { selectionId }  = useOutletContext<any>();
  const redirectLink = encodeURIComponent(`/user-area/selection-portal/${selectionId}/scenario-manager`);
  const link = `${sessionStorage.domain}/signin?redirectTo=${redirectLink}&guestType=vendor&accessCode=`;
  const [vendorContactEmail, setVendorContactEmail] = useState<string | null>(item.vendorContactEmail);
  
  const [submitted, setSubmitted] = useState<boolean>(item.scenariosSubmitted);
  const [opened, setOpened] = useState<boolean>(item.scenariosOpened);
  const [evaluationLink, setEvaluationLink] = useState<string | null>(null);
  
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);

  const handleFetchVendorAccessSecret = async () => {
    const response = await getSelectionVendorAccessSecret(item.id)
    if (response.ok) {
      setEvaluationLink(response.data.vendorAccessSecret == null || !item.scenariosSubmitted ? null : link + response.data.vendorAccessSecret);
    }
  }

  useEffect(() => {
    handleFetchVendorAccessSecret();
  }, [])
  
  const handleChangeVendorContactEmail = async () => {
      if (typeof vendorContactEmail === "undefined" || vendorContactEmail == null) return 
      if (await changeVendorContactEmail(item.id, vendorContactEmail)) {
    }
  }

  const handleSubmitToVendors = async () => {
    const response = await submitScenariosToVendors(item.id);
    if (response.success) {
      // setRequirementsEvaluationLink(response.data.vendorAccessSecret);
      setEvaluationLink(link + response.data.vendorAccessSecret);
      setSubmitted(true);
    }
  }

  const handleSubmitToVendorsAndSendEmails = async () => {
    const response = await submitScenariosToVendorsAndSendEmails(selectionId, [item.id]);
  }

  useEffect(() => {
    if (copiedToClipboard) {
      const timeoutId = setTimeout(() => {
        setCopiedToClipboard(false);
      }, 2000);
  
      return () => clearTimeout(timeoutId);
    }
  }, [copiedToClipboard]);

  return (
    <div className='bg-gray-50 rounded-lg border p-2 items-center grid grid-cols-12'>
      <div className='w-40 truncate col-span-3'>
        <div className='flex space-x-4 text-lg font-bold'>
            <p>{item.softwareProduct.productName} </p>
            {/* <p className='font-light italic'>{vendorRankingData.softwareFulfillment.note}</p> */}
        </div>
        <div>
            {item.softwareProduct.vendorDetails.company}
        </div>
      </div>
    
      <div className='mx-4 col-span-4'><TextInput onBlur={() => handleChangeVendorContactEmail()} textValue={vendorContactEmail} setTextValue={setVendorContactEmail} placeholder={t('scenarioResponseTracking.insertVendorEmail')} /></div>

      <div className='flex gap-2 mx-4 col-span-3'>
        <ProgressTracker success={submitted || submittedToAll} />
        <ProgressTracker success={opened} />
      </div>

      <div className='ml-auto col-span-2 w-full h-full'>
        {
          !submitted ?
          <MainButton text={t('scenarioResponseTracking.giveAccess')} func={handleSubmitToVendors} /> :
          <>
            {
              !evaluationLink ? <LoadingSpinner /> :
              <div className='flex flex-col gap-2'>
                <MainButton text={t('scenarioResponseTracking.sendEmail')} func={handleSubmitToVendorsAndSendEmails} height='h-8' additionalStyles='bg-primary hover:shadow-primary whitespace-nowrap' />
                <div onClick={() => {setCopiedToClipboard(true); navigator.clipboard.writeText(evaluationLink)}}
                className='h-8 cursor-pointer border rounded-lg bg-white w-full text-gray-400 flex gap-2 items-center justify-center hover:bg-gray-100'>
                  {
                    copiedToClipboard ? (
                      <>
                        <div className='w-5 h-5'><CheckmarkIcon /></div>
                        <p className='text-sm'>{t('scenarioResponseTracking.copied')}</p>
                      </>
                    ) : (
                      <>
                        <div className='w-5 h-5'><ClipboardIcon /></div>
                        <p className='text-sm'>{t('scenarioResponseTracking.copyCode')}</p>
                      </>
                    )
                  }
                </div>
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}

const ProgressTracker = ({success} : {success:boolean}) => {
  if (!success) {
    return (
      <div className='w-8 h-8 text-amber-400 p-1 rounded-full bg-transparent border border-amber-400'><CrossIcon /></div>
    )
  }
  return (
    <div className='w-8 h-8 text-white p-1 rounded-full bg-green-600 border border-green-600'><CheckmarkIcon /></div>
  )
}

export default ScenarioResponseTracking