import React, { useEffect, useState } from 'react'
import { getSearchResultsFlask } from '../../../../../../../data/CustomerSearchRequests';

import { socketMatchingEngine as socket } from '../../../../../../../_socket/socket';
import ResultRow from './components/ResultRow';
import { FormModal, InfoIcon, LoadingSpinner } from 'swap-frontend-library';
import { useSearchParams } from 'react-router-dom';
import MainButton from '../../../../../../../components/MainButton';
import StartSelectionForm from '../../../selection-cockpit/components/StartSelectionForm';
import { RootState } from '../../../../../../../redux/store';
import { useSelector } from 'react-redux';

const Results = ({queryUrl} : {queryUrl: string}) => {
  const isOrgAdmin = useSelector((state: RootState) => state.user.isOrgAdmin);
  const [numResults, setNumResults] = useState(0);
  const [customerSearch, setCustomerSearch] = useState<any>();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [customerSearchCustomerDetailsId, setCustomerSearchCustomerDetailsId] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<string>("fit_score");

  const [searchParams, setSearchParams] = useSearchParams();

  const [ topFeatures, setTopFeatures ] = useState<Array<string>>([]);

  const [ numberOfOverallResults, setNumberOfOverallResults ] = useState<number>();
  
  const handleGetSearchResults = async (url : string) => {
    const code = searchParams.get("code");

    const data = await getSearchResultsFlask(url, code);
    console.log(data);
    setCustomerSearch(data.customerSearch);
    setIsUnlocked(data.isUnlocked);
    setNumResults(data.scores.length);
    setSearchResults(data.scores);
    setCustomerSearchCustomerDetailsId(data.customerSearchCustomerDetailsId);

    setTopFeatures(data.metrics.top_categories.map((item:any) => item[0]));
  }

  useEffect(() => {
    if (queryUrl == "") return
    handleGetSearchResults(queryUrl);
  }, [queryUrl])

  // useEffect(() => {
  //     if (typeof searchResults === "undefined" || searchResults.length == 0) return
  //     setTopFeatures(Object.keys(searchResults[0].category_scores));
  //     console.log("Top features: ", Object.keys(searchResults[0].category_scores));
  // }, [searchResults])
  
  // const handleShowMoreResults = () => {
  //     setShowNumResults(prev => {
  //       if (prev < searchResults.length) return prev + 10; 
  //       return 10; 
  //     })
  // }

  if (searchResults.length == 0) return <div className='pt-44 my-8'><LoadingSpinner /></div>
  
  return (
    <div className='relative h-full overflow-y-auto px-8 flex flex-col mx-auto gap-8 pt-44 pb-56'>
      <div className="w-full relative mt-16">
        <div className='w-full grid grid-cols-12 gap-4 text-sm font-light pr-4 items-end'>
            <div className='max-md:col-span-full col-span-5 grid grid-cols-8 gap-1'>
                <div className='col-span-4 flex justify-start'><p>Matched software</p><div className='h-4 w-4'><InfoIcon /></div></div>
                <div className={`col-span-2 cursor-pointer flex justify-center rounded-full ${sortBy == "fit_score" ? "bg-primary text-white" : ""}`} onClick={() => {setSortBy("fit_score")}}><p>Fit score</p><div className='h-4 w-4'><InfoIcon /></div></div>
                <div className={`col-span-2 cursor-pointer flex justify-center rounded-full ${sortBy == "reference_score" ? "bg-primary text-white" : ""}`} onClick={() => {setSortBy("reference_score")}}><p>Similar users</p><div className='h-4 w-4'><InfoIcon /></div></div>
            </div>
            <div className='max-md:hidden col-span-7 grid grid-cols-5 gap-2'>
                {
                    typeof topFeatures === "undefined" ? <></> : topFeatures.slice(0,5).map((topFeature:string, index:number) => (
                        <div className='w-full mx-2'><p className='w-full truncate text-center'>{topFeature}</p></div>
                    ))
                }
            </div>
        </div>

        <div className='flex flex-col gap-4 mt-4'>
        {/* slice(0,showNumResults) */}
            {
            searchResults.sort(
              (a:any, b:any) => {
                if (sortBy == "fit_score") {
                  return b.score - a.score;
                } else if (sortBy == "reference_score") {
                  if (b.numberOfReferences == 0 || a.numberOfReferences == 0) {
                    return b.numberOfReferences - a.numberOfReferences;
                  }
                  return (b.referenceAvg*0.7 + b.numberOfReferences*0.3) - (a.referenceAvg*0.7 + a.numberOfReferences*0.3);
                  // return b.numberOfReferences - a.numberOfReferences;
                }
                return 0;
              }
            ).slice(0,20).map((result: any, index: number) => {
                return (<ResultRow 
                  idx={index} 
                  id={result.softwareProduct?.id} 
                  productName={result.softwareProduct?.productName} 
                  productDescription={result.softwareProduct?.productDescription} 
                  productUrl={result.softwareProduct?.productUrl} 
                  productDetails={result.softwareProduct?.productDetails}
                  references={result.references} 
                  referenceAvg={result.referenceAvg} 
                  softwareFulfillmentNote={result.softwareFulfillment?.note} 
                  companyName={result.softwareProduct?.vendorDetails.company} 
                  score={result.score} 
                  customerSearchSoftwareProductId={result.id} 
                  numberOfReferences={result.numberOfReferences} 
                  topFeatures={topFeatures} 
                  topFeaturesScores={result.category_scores} 
                />);
            })
            }

            {/* <div onClick={handleShowMoreResults} className={`cursor-pointer flex items-center justify-center border shadow bg-white hover:bg-gray-50 rounded-[80px] pr-4 h-[70px]`}>
                <p>{(showNumResults < searchResults.length) ? "Show more" : "Show less"}</p>
                <div className={`w-6 h-6 flex items-center ${(showNumResults < searchResults.length) ? "" : "rotate-180"}`}><ArrowDownIcon /></div>
            </div> */}
        </div>


        {/* {
            (isUnlocked) ? (<></>) : (
              <div className="absolute flex items-center justify-center rounded-lg h-[90%] w-[70%] top-[5%] left-10 p-10 mx-4 text-secondary1 max-sm:px-4 max-sm:py-6 max-sm:m-0" >
              <StoreProvider>
              <PremiumOverlay handleGetSearchResults={handleGetSearchResults} />
              </StoreProvider>
              </div>
              )
              } */}
      </div>

      {
        customerSearchCustomerDetailsId == null || !isOrgAdmin ? <></> : <StartSelectionFromMatching customerSearchCustomerDetailsId={customerSearchCustomerDetailsId} />
      }
    </div>
  )
}

const StartSelectionFromMatching = ({customerSearchCustomerDetailsId} : {customerSearchCustomerDetailsId: string}) => {
  const [startSelectionFormOpen, setStartSelectionFormOpen] = useState(false);

  return (
    <>
      {(startSelectionFormOpen) ? (<FormModal isOpen={startSelectionFormOpen} setOpen={setStartSelectionFormOpen} size="regular"><StartSelectionForm customerSearchCustomerDetailsId={customerSearchCustomerDetailsId}/></FormModal>) : (<div className="hidden"></div>)}
      <div className='fixed z-[2] bottom-4 right-4 w-96 h-48 mx-auto border rounded-full shadow-lg bg-white/50 backdrop-blur-md md:p-8 flex items-center justify-center p-4'>
        <div className='flex flex-col items-center gap-4'>
            <div className='w-full flex items-center justify-center gap-4 text-xl font-semibold'>
              Start selection project
            </div>
            <div className='w-fit'>
              <MainButton text={'Start'} func={() => setStartSelectionFormOpen(true)} />
            </div>
        </div>
      </div>
    </>
  )
}

export default Results