import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BigRingChartFilter from '../components/charts/BigRingChartFilter';
import BigRingChartFilterWorking from '../components/charts/BigRingChartFilterWorking';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import { getRequirementStatusStatistics } from '../../../../../../data/SelectionPortalRequests';

interface props {
    selectionId: string
}

const RequirementsDonut = ({selectionId} : props) => {
    const { t } = useTranslation();
    const [labels, setLabels] = useState<string[]>();
    const [data, setData] = useState<number[]>();
    // green, yellow, dark green, gray
    const colors = ['rgb(134, 239, 172)', 'rgb(251, 191, 36)', 'rgb(22, 163, 74)', 'rgb(163, 163, 163)'];

    const handleFetchData = async () => {
        const response = await getRequirementStatusStatistics(selectionId);
        if (response.ok) {
            // console.log(response.data.data.requirementStatusCounts)
            setLabels(Object.keys(response.data.data.requirementStatusCounts).map((key) => t("donut."+key)))
            setData(Object.values(response.data.data.requirementStatusCounts))
        }
    }
    useEffect(() => {handleFetchData()}, [])
    
  return (
    <div className='p-4 h-full bg-white rounded-lg shadow sm:p-6'>
        {
            (typeof labels === "undefined" || typeof data === "undefined") ? (<LoadingSpinner />) : (
                <BigRingChartFilterWorking 
                    subTitle={t('requirementsDonut.subTitle')} 
                    title={t('requirementsDonut.title', { count: data.reduce((partialSum, a) => partialSum + a, 0) })} 
                    labels={labels} 
                    data={data} 
                    colors={colors} 
                />
            )
        }
    </div>
  )
}

export default RequirementsDonut;