import { useState } from "react";
import TextInput from "./TextInput";

const FormField = ({existingText, label, placeholder, setFormData, formDataKey, editing, rows, required, type} : {existingText:string, label?:string, placeholder?:string, setFormData:React.Dispatch<React.SetStateAction<any>>, formDataKey:string, editing: boolean, rows?: number, required?: boolean, type?: string}) => {
    const [inputText, setInputText] = useState(existingText);

    const handleSetFormData = () => {
        setFormData((prev:any) => ({...prev, [formDataKey]: inputText}));
    }
    return (
        <TextInput label={label} placeholder={placeholder} textValue={inputText} setTextValue={setInputText} disabled={!editing} onBlur={handleSetFormData} rows={rows} required={required} type={type} />
    )
}

export default FormField