import SearchBar from '../../../../../../components/SearchBar';
import { ArrowDownIcon, ArrowRightIcon, BarsTwoIcon, BurgerIcon, CheckmarkIcon, CrossIcon, ListFilledIcon, LoadingSpinner, PlusIcon, RefreshIcon, SearchIcon, SettingsFilledIcon, WarningIcon } from 'swap-frontend-library';
import { useTranslation } from 'react-i18next';
import TableHeader from '../../../../../../components/TableHeader';
import SearchBarFuzzy from '../../../../../../components/SearchBarFuzzy';
import { useEffect, useState } from 'react';
import TableRow from '../../../../../../components/TableRow';
import NavPagination from '../../../../../../components/NavPagination';
import GearIcon from '../../../../../../assets/img/icons/GearIcon';
import MainButton from '../../../../../../components/MainButton';
import SelectorDropdown from '../../../../../../components/SelectorDropdown';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { getSelections } from '../../../../../../data/SelectionPortalRequests';

const SelectionBrowserTable = () => {
    const {t} = useTranslation();
    const email = useSelector((state: RootState) => state.user.email);

    // const [sortBy, setSortBy] = useState<any>({});
    const [sortBy, setSortBy] = useState<any>({key:"createdDate", asc:true});
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterByIndustry, setFilterByIndustry] = useState<string>("All");
    const [filterBySelectedTags, setFilterBySelectedTags] = useState<any[]>([]);
    const [filterByStatus, setFilterByStatus] = useState<string>("All");
    const [filterBySoftwareCategory, setFilterBySoftwareCategory] = useState<string>("All");
    
    const [selections, setSelections] = useState<any[]>([]);
    const [selectionsFiltered, setSelectionsFiltered] = useState<any[]>([]);
    const [visibleRows, setVisibleRows] = useState([1,1]);

    const [softwareCategories, setSoftwareCategories] = useState<any[]>([]);
    const [industries, setIndustries] = useState<any[]>([]);
    const [tags, setTags] = useState<any[]>([]);
    
    const handleFetchSelections = async () => {
        const response = await getSelections(email);
        const fetchedSelections = [
            ...(response.data.data.personalActiveSelections || []), 
            ...(response.data.data.customerDetailsSelections || [])
        ];
        setSelections(fetchedSelections);
        setSelectionsFiltered(fetchedSelections);

        setTags(
            fetchedSelections
            .filter((item:any) => item.selectionTags != null && item.selectionTags != "") // Only selection where tags exist
            .reduce((r:any, a:any) => ([...r, ...a.selectionTags]), []) // Combine arrays of tags
            .filter((item:any, index:any, array:any[]) => array.indexOf(item) === index) // Only unique
        )

        setIndustries(
            fetchedSelections.filter((item:any) => item.selectionIndustry != null && item.selectionIndustry != "") // Only selection where tags exist
            .filter((item:any, index:any, array:any[]) => array.findIndex(i => i.selectionIndustry === item.selectionIndustry) === index) // Only unique
            .map((item:any) => ({label:item.selectionIndustry, value:item.selectionIndustry})) // Convert to format
        );
        
        setSoftwareCategories(
            fetchedSelections.filter((item:any) => item.selectionSoftwareCategory != null && item.selectionSoftwareCategory != "") // Only selection where tags exist
            .filter((item:any, index:any, array:any[]) => array.findIndex(i => i.selectionSoftwareCategory === item.selectionSoftwareCategory) === index) // Only unique
            .map((item:any) => ({label:item.selectionSoftwareCategory, value:item.selectionSoftwareCategory})) // Convert to format
        );
    }

    useEffect(() => {handleFetchSelections();}, [])

    const handleSort = (currentSelections:any) => {
        return currentSelections.sort((a:any, b:any) =>  {
            const asc = 2*Number(sortBy.asc)-1;
            let aVal = a[sortBy.key];
            let bVal = b[sortBy.key];

            if (sortBy.key == "createdDate") {
                aVal = Date.parse(aVal);
                bVal = Date.parse(bVal);
            }
            if (bVal > aVal) return -asc;
            if (bVal < aVal) return asc;
            return 0;
        });
    }
    
    const handleFilter = (currentSelections:any) => {
        let filteredSelections = currentSelections;
        if (filterBySoftwareCategory != "All") filteredSelections = filteredSelections.filter((selection:any) => selection.selectionSoftwareCategory == filterBySoftwareCategory);
        if (filterByIndustry != "All") filteredSelections = filteredSelections.filter((selection:any) => selection.selectionIndustry == filterByIndustry);
        if (filterByStatus != "All") filteredSelections = filteredSelections.filter((selection:any) => selection.selectionStatus == filterByStatus);
        if (filterBySelectedTags.length > 0) filteredSelections = filteredSelections.filter((selection:any) => selection.selectionTags?.filter((tag:any) => filterBySelectedTags.includes(tag)).length > 0);
        // console.log(filterByIndustry, filteredSelections)
        return filteredSelections
    }
    
    useEffect(() => {
        const filteredSelections = handleFilter(selections)
        const sortedAndFilteredSelections = handleSort(filteredSelections);
        setSelectionsFiltered([...sortedAndFilteredSelections]);
    }, [sortBy, filterByIndustry, filterBySelectedTags, filterByStatus, filterBySoftwareCategory])
        
    return (
    <div className='w-full h-full flex flex-col'>

        <div className="space-y-4">
            <div className='px-4 mt-4 space-y-2'>
                <div className="w-full mb-1">
                    <div className="items-center justify-start block flex max-sm:flex-col">
                        <div className='max-sm:w-full xl:w-96 flex'>
                            <SearchBarFuzzy translatedPlaceholderItem={t('searchSelectionBrowser.placeholder')} originalData={selections} setFilteredData={setSelectionsFiltered} filterKeys={["selectionTitle", "selectionDescription", "selectionIndustry", "selectionTags"]} />

                        </div>
                        <div onClick={() => setFilterOpen(prev => !prev)} className={`cursor-pointer w-11 h-11 rounded-lg border bg-gray-50 border border-gray-300 text-gray-600 p-1 transition-all`}><SettingsFilledIcon /></div>
                    </div>
                </div>
            </div>
            {
                filterOpen ? 
                <div className='p-4 border-y border-gray-300 space-y-4'>
                    <div className='flex gap-4 justify-between'>
                        <div className='w-full'>
                            <SelectorDropdown label={t("filter.softwareCategory")} items={[{value: "All", label: t("all")}, ...softwareCategories]} itemValueKey={'value'} itemLabelKey={'label'} selectedItem={filterBySoftwareCategory} setSelectedItem={setFilterBySoftwareCategory} />
                        </div>

                        <div className='w-full'>
                            <SelectorDropdown label={t("filter.industry")} items={[{value: "All", label: t("all")}, ...industries]} itemValueKey={'value'} itemLabelKey={'label'} selectedItem={filterByIndustry} setSelectedItem={setFilterByIndustry} />
                        </div>
                        
                        <div className='w-full'>
                            <SelectorDropdown label={t("filter.status")} items={[{value: "All", label: t("all")}, {value: "ongoing", label: t("status.ongoing")}, {value: "paused", label: t("status.paused")}, {value: "canceled", label: t("status.canceled")}, {value: "completed", label: t("status.completed")}]} itemValueKey={'value'} itemLabelKey={'label'} selectedItem={filterByStatus} setSelectedItem={setFilterByStatus} />
                        </div>
                    </div>
                    
                    <div>
                        <p className="block text-sm font-medium text-gray-900">{t("filter.tag")}</p>
                        <div className='gap-2 flex flex-wrap'>
                            {
                                tags.map((tag:any) => (<div onClick={() => {if (filterBySelectedTags.includes(tag)) {setFilterBySelectedTags(prev => prev.filter((item:any) => item != tag))} else {setFilterBySelectedTags(prev => [...prev, tag])}}} className={`cursor-pointer px-2 rounded h-fit ${filterBySelectedTags.includes(tag) ? "bg-primary" : "bg-gray-200"} transition-all`}>{tag}</div>))
                            }
                        </div>
                    </div>
                </div> :
                <></>
            }


            <div className='grid grid-cols-12 px-6 py-2'>
                <div onClick={() => setSortBy((prev:any) => ({key:"createdDate",asc:prev.key=="createdDate" ? !prev.asc : true}))} className='cursor-pointer flex items-center gap-1 col-span-1 text-sm'>{t("table.createdOn")} <div className={`h-4 w-4 ${sortBy.key == "createdDate" ? "bg-gray-500 rounded-full text-white" : ""} ${sortBy.key == "createdDate" && !sortBy.asc ? "rotate-180" : ""} transition-all duration-500`}><ArrowDownIcon /></div></div>
                <div onClick={() => setSortBy((prev:any) => ({key:"selectionTitle",asc:prev.key=="selectionTitle" ? !prev.asc : true}))} className='cursor-pointer flex items-center gap-1 col-span-3 text-sm'>{t("table.title")} <div className={`h-4 w-4 ${sortBy.key == "selectionTitle" ? "bg-gray-500 rounded-full text-white" : ""} ${sortBy.key == "selectionTitle" && !sortBy.asc ? "rotate-180" : ""} transition-all duration-500`}><ArrowDownIcon /></div></div>
                <p className='col-span-6 text-sm'>{t("table.tags")}</p>
                <div onClick={() => setSortBy((prev:any) => ({key:"selectionStatus",asc:prev.key=="selectionStatus" ? !prev.asc : true}))} className='cursor-pointer flex items-center justify-center gap-1 col-span-1 text-sm text-center'>{t("table.status")} <div className={`h-4 w-4 ${sortBy.key == "selectionStatus" ? "bg-gray-500 rounded-full text-white" : ""} ${sortBy.key == "selectionStatus" && !sortBy.asc ? "rotate-180" : ""} transition-all duration-500`}><ArrowDownIcon /></div></div>
            </div>
        </div>

        {(!selections) ? (<LoadingSpinner />) : (
            <>
            <div className="w-full grow overflow-x-auto text-sm text-left text-gray-500 space-y-3 p-2">
                {
                    (
                        selectionsFiltered.slice(visibleRows[0]-1,visibleRows[1]).map((selection : any, index : number) => {
                            return (
                                <SelectionRow selection={selection} />
                            )
                        })
                    )
                }
            </div>

            <NavPagination totalItems={selectionsFiltered.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} />
            </>
            )
        }
    </div>
  )
}

const SelectionRow = ({selection} : {selection: any}) => {
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);
    const date : Date = new Date(Date.parse(selection.createdDate));
    const dateString : string = `${date.getDate().toString().padStart(2,"0")}.${(date.getMonth()+1).toString().padStart(2,"0")}.${date.getFullYear()}`;

    
    return (
        <div className='grid grid-cols-12 bg-gray-50 items-center bg-white rounded-lg p-4 shadow h-24 gap-2'>
            <p className='col-span-1 truncate'>{dateString}</p>
            <div className='col-span-3'>
                <p className='text-xs font-light'>{selection.selectionSoftwareCategory} {selection.selectionIndustry ? "- "+selection.selectionIndustry : ""}</p>
                <p className='text-md font-semibold truncate'>{selection.selectionTitle}</p>
            </div>
            <div className='col-span-6 h-full overflow-y-auto flex flex-wrap gap-1 bg-gray-50 rounded-lg border p-2'>
                {
                    selection.selectionTags?.map((tag:any) => (<div className='px-2 bg-gray-200 rounded h-fit'>{tag}</div>))
                }
            </div>
            <div className='col-span-1 flex justify-center'>
                {
                    selection.selectionStatus == "ongoing" ? <div className='w-6 h-6 rounded-full p-1 bg-primary text-white' title={t("status.ongoing")}><GearIcon /></div> :
                    selection.selectionStatus == "paused" ? <div className='w-6 h-6 rounded-full p-1 bg-gray-400 text-white rotate-90' title={t("status.paused")}><BarsTwoIcon /></div> :
                    selection.selectionStatus == "completed" ? <div className='w-6 h-6 rounded-full p-1 bg-green-600 text-white' title={t("status.completed")}><CheckmarkIcon /></div> :
                    selection.selectionStatus == "canceled" ? <div className='w-6 h-6 rounded-full p-1 bg-red-600 text-white' title={t("status.canceled")}><CrossIcon /></div> :
                    selection.selectionStatus == "archived" ? <div className='w-6 h-6 rounded-full p-1 bg-gray-400 text-white' title={t("status.archived")}><ListFilledIcon /></div> :
                    <></>
                }
            </div>
            <div className='col-span-1'>
                {
                    selection.access ? 
                    <div className='h-fit'><MainButton text={t('button.view')} link={`/user-area/selection-portal/${selection.id}`} /></div> :
                    <div><MainButton text={t('button.noAccess')} link={`#`} disabled={true} /></div>
                }
            </div>
        </div>
    )
}

export default SelectionBrowserTable