import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Position, PrimaryButton, Tooltip, Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { ZoomInIcon, ZoomOutIcon } from '@react-pdf-viewer/zoom';
import { selectionModePlugin, SelectionMode, RenderSwitchSelectionModeProps } from '@react-pdf-viewer/selection-mode';
import { HandToolIcon, TextSelectionIcon } from '@react-pdf-viewer/selection-mode';
import { getFilePlugin, RenderDownloadProps, DownloadIcon } from '@react-pdf-viewer/get-file';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { HighlightArea, RenderHighlightContentProps, RenderHighlightTargetProps, RenderHighlightsProps, highlightPlugin } from '@react-pdf-viewer/highlight';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/selection-mode/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import './DocumentReader.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';

import { addDocumentComment, getDocument, getDocumentComments } from '../../../../../../data/SelectionPortalRequests';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import UploadIcon from '../../../../../../assets/img/icons/UploadIcon';
import ArrowLeftIcon from '../../../../../../assets/img/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';
import SidebarDocumentInfo from './features/SidebarDocumentInfo';
import InfoIcon from '../../../../../../assets/img/icons/InfoIcon';
import AnalysisIcon from '../../../../../../assets/img/icons/AnalysisIcon';
import MessageIcon from '../../../../../../assets/img/icons/MessageIcon';
import CommentIcon from '../../../../../../assets/img/icons/CommentIcon';
import SidebarChat from './features/SidebarChat';
import SidebarComments from './features/SidebarComments';
import SidebarAnalysis from './features/SidebarAnalysis';
import MainButton from '../../../../../../components/MainButton';
import { getImage } from '../../../../../../data/ProductRequests';
import SpeedDial from '../../components/SpeedDial';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';

interface Comment {
    id: string;
    comment: string;
    highlightAreas: HighlightArea[];
    checklistItem: string;
    quote: string;
}

const DocumentReader = () => {
    const { t } = useTranslation();
    const { selectionId } = useOutletContext<any>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [documentId, setDocumentId] = useState<string>();
    const [documentData, setDocumentData] = useState<any>();
    const [numPages, setNumPages] = useState<number>();
    const [zoom, setZoom] = useState<number>(100);
    const [dragSelection, setDragSelection] = useState(false);
    const [sidebarContent, setSidebarContent] = useState("");

    const projectRole = useSelector((state: RootState) => state.selection.projectRole);

    const [comments, setComments] = useState<Comment[]>([]);
    const [checklistItemTagging, setChecklistItemTagging] = useState<any>({id:undefined, title:undefined});
    
    const ref = useRef<HTMLDivElement>(null);
    const [pdfWidth, setPdfWidth] = useState<number>();

    const navigate = useNavigate();

    const handleFetchDocument = async (id : string) => {
        const response = await getDocument(id);
        if (response.ok) {
            setDocumentData(response.data.data.results);
            handleFetchImage(response.data.data.results.selectionSoftwareProduct.softwareProduct.id);
        }
    }

    const handleFetchDocumentComments = async (id : string) => {
        const response = await getDocumentComments(id);
        if (response.ok) {
            setComments(response.data.data.results);
        }
        
    }
    
    useEffect(() => {
      const queryDocumentId = searchParams.get("documentId");

      if (queryDocumentId) {
        setDocumentId(queryDocumentId);
        handleFetchDocument(queryDocumentId);
        handleFetchDocumentComments(queryDocumentId);
      }
    }, [])

    const [imageSource, setImageSource] = useState("");
    
    const handleFetchImage = async (id: string) => {
        getImage(id, "/api/product/get-logo?id=", '').then(frontendImageLocation => {
            setImageSource(frontendImageLocation);
        });
    }

    const thumbnailPluginInstance = thumbnailPlugin();
    const { Thumbnails } = thumbnailPluginInstance;

    const zoomPluginInstance = zoomPlugin();
    const { zoomTo } = zoomPluginInstance;
    
    const selectionModePluginInstance = selectionModePlugin();
    const { SwitchSelectionMode } = selectionModePluginInstance;

    const getFilePluginInstance = getFilePlugin();
    const {Download} = getFilePluginInstance;

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToNextPage, jumpToPreviousPage, CurrentPageInput, NumberOfPages } = pageNavigationPluginInstance;

    const renderHighlightTarget = (props: RenderHighlightTargetProps) => { 
        setClickedHighlightComment(undefined);

        if (projectRole != "selection_admin") return <></>;
        return (
            <div
                style={{
                    left: `${props.selectionRegion.left}%`,
                    top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
                }}
                className={`flex h-10 w-10 p-2 cursor-pointer absolute rounded-lg shadow ${typeof checklistItemTagging.id !== "undefined" ? "bg-primary" : "bg-gray-400"} z-[2] translate-x-[0px] translate-y-[5px]`}
            >
                <div className="text-white" onClick={props.toggle}>
                    {
                        typeof checklistItemTagging.id !== "undefined" ? <AnalysisIcon /> : <CommentIcon />
                    }
                </div>
            </div>
    )};

    const [message, setMessage] = React.useState('');

    const renderHighlightContent = (props: RenderHighlightContentProps) => {

        const addNote = async () => {
            if (message !== '' || typeof checklistItemTagging.id !== "undefined") {
                if (typeof documentId !== "undefined") {
                    await addDocumentComment(documentId, message, props.selectedText, props.highlightAreas, (typeof checklistItemTagging.id !== "undefined" ? checklistItemTagging.id : ""));
                    await handleFetchDocumentComments(documentId);
                };
                setChecklistItemTagging({id:undefined, title:undefined});
                setMessage("");
                props.cancel();
            }
        };

        return (
            <div
                style={{
                    left: `${props.selectionRegion.left}%`,
                    top: `${((props.selectionRegion.top + props.selectionRegion.height) < 80) ? String(props.selectionRegion.top + props.selectionRegion.height)+"%" : ("")}`,
                    bottom: `${((props.selectionRegion.top + props.selectionRegion.height) >= 80) ? String(100-(props.selectionRegion.top))+"%" : ("")}`,
                    zIndex: 5000
                }}
                className='fixed z-1000 border border-gray-300 bg-white rounded-lg p-2'
            >
                {
                    typeof checklistItemTagging.id !== "undefined" ? (
                        <div className='text-sm mb-2 font-light'>
                            {t('documentReader.addTagForItem', { title: checklistItemTagging.title })}
                        </div>
                    ) : <></>
                }
                <div>
                    <textarea
                        rows={3}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: '8px',
                    }}
                >
                    <div className="mr-2 w-fit">
                        <MainButton text={t('documentReader.add')} link={''} func={addNote} height='h-8' />
                    </div>
                    <div className="mr-2 w-fit">
                        <MainButton text={t('documentReader.cancel')} link={''} func={props.cancel} style="light" height='h-8' />
                    </div>
                </div>
            </div>
        );
    };

    const [clickedHighlightComment, setClickedHighlightComment] = useState<Comment>();
    const jumpToNote = (comment: Comment) => { 
        // TODO check if comment is in analysis or comments sidebar
        setClickedHighlightComment(comment); 
        if (comment.checklistItem == null) {
            setSidebarContent("comments"); 
        } else {
            setSidebarContent("analysis");
        }
    };
    const jumpToHighlight = (comment : any) => { jumpToHighlightArea(comment.highlightAreas[0]); setClickedHighlightComment(comment) };

    const renderHighlights = (props: RenderHighlightsProps) => (
        <div>
            {comments.map((note) => (
                <React.Fragment key={note.id}>
                    {note.highlightAreas
                        .filter((area) => area.pageIndex === props.pageIndex)
                        .map((area, idx) => (
                            <div
                                key={idx} style={Object.assign({}, props.getCssProperties(area, props.rotation))}
                                className={`cursor-pointer z-[1] opacity-[0.4] ${(typeof clickedHighlightComment !== "undefined" && note.id == clickedHighlightComment.id) ? "animate-bounce bg-red-300" : (note.checklistItem == null) ? "bg-amber-300" : "bg-blue-300"}`}
                                onClick={() => jumpToNote(note)}
                            />
                        ))}
                </React.Fragment>
            ))}
        </div>
    );

    const highlightPluginInstance = highlightPlugin({
        renderHighlightTarget, 
        renderHighlightContent,
        renderHighlights
    });

    const { jumpToHighlightArea } = highlightPluginInstance;

    useEffect(() => {
        zoomTo(zoom/100);
    }, [zoom, zoomPluginInstance])
    
    if (typeof documentData === "undefined") return <LoadingSpinner />

    const createdDateParsed : Date = new Date(Date.parse(documentData.createdDate));
    const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;
    return (
        <div className='h-full flex flex-col'>
            <div className="w-full h-16 flex content-center justify-between w-full py-2.5 pr-5 pl-5 text-sm font-medium leading-5 text-gray-900 bg-white border-b border-solid max-md:pr-5 max-md:max-w-full">
                <div onClick={() => navigate(`../?type=${documentData.type}`)}
                className='flex items-center text-gray-900 cursor-pointer bg-gray-50 hover:bg-gray-100 rounded-lg p-2 space-x-2'>
                    <ArrowLeftIcon iconColor='currentColor'/>
                    <p>{t('documentReader.allDocuments', { type: documentData.type })}</p>
                </div>

                <div className='flex h-full items-center justify-center mx-auto space-x-4'>
                    <div className=''>
                        <p className='text-md font-medium'>{documentData.name}</p>
                        <p className='text-xs font-light'>
                            {t('documentReader.createdDate', {
                                day: createdDateParsed.getDate(),
                                month: createdDateParsed.getMonth() + 1,
                                year: createdDateParsed.getFullYear()
                            })}
                        </p>
                    </div>
                    <div className='h-full w-fit max-sm:hidden'>
                        {
                            (imageSource == "") ? (
                                <div className='h-full w-full p-4 flex items-center text-center justify-center font-bold text-white bg-gray-300 rounded'>
                                    {documentData.selectionSoftwareProduct.softwareProduct.vendorDetails.company}
                                </div>
                            ) : (
                                <img className="h-full" src={imageSource} />
                            )
                        }
                    </div>
                    <div className=''>
                        <p className='text-md font-medium'>{documentData.selectionSoftwareProduct.softwareProduct.productName}</p>
                        <p className='text-xs font-light'>{documentData.selectionSoftwareProduct.softwareProduct.vendorDetails.company}</p>
                    </div>
                </div>
                <div className='flex items-center space-x-2'>
                    <div onClick={() => {setSidebarContent(prev => (prev == "analysis") ? ("") : ("analysis")); setClickedHighlightComment(undefined);}}
                    className={`cursor-pointer flex items-center border border-primary justify-center text-primary h-10 w-10 p-1.5 rounded-full ${sidebarContent == "analysis" ? "bg-primary" : "hover:bg-gray-100"}`}>
                        <AnalysisIcon iconColor={sidebarContent == "analysis" ? "white" : "currentColor"}  />
                    </div>
                    <div onClick={() => {setSidebarContent(prev => (prev == "comments") ? ("") : ("comments")); setClickedHighlightComment(undefined);}}
                    className={`cursor-pointer flex items-center text-gray-400 border border-transparent justify-center h-10 w-10 p-1 rounded-full ${sidebarContent == "comments" ? "bg-primary" : "hover:bg-gray-100"}`}>
                        <CommentIcon iconColor={sidebarContent == "comments" ? "white" : "currentColor"} />
                    </div>
                    {/* <div onClick={() => {setSidebarContent(prev => (prev == "chat") ? ("") : ("chat")); setClickedHighlightComment(undefined);}}
                    className={`cursor-pointer text-gray-400 p-1 flex items-center border border-transparent justify-center h-10 w-10 rounded-full ${sidebarContent == "chat" ? "bg-primary" : "hover:bg-gray-100"}`}>
                        <MessageIcon iconColor={sidebarContent == "chat" ? "white" : "currentColor"} />
                    </div> */}
                    <div onClick={() => {setSidebarContent(prev => (prev == "details") ? ("") : ("details")); setClickedHighlightComment(undefined);}}
                    className={`cursor-pointer flex items-center border border-transparent justify-center h-10 w-10 rounded-full ${sidebarContent == "details" ? "bg-primary" : "hover:bg-gray-100"}`}>
                        <InfoIcon iconColor={sidebarContent == "details" ? "white" : ""} />
                    </div>
                </div>
            </div>

            
            {
                (typeof documentData === "undefined") ? (<LoadingSpinner />) : (documentData == null) ? (<div>Error</div>) : (
                    <div className='h-full grow flex overflow-y-hidden'>
                        <div className='w-[10%] bg-white border-r'>
                            <Thumbnails />
                        </div>
                        <div className='grow'>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                <Viewer fileUrl={documentData.location} plugins={[
                                    thumbnailPluginInstance,
                                    zoomPluginInstance,
                                    selectionModePluginInstance,
                                    getFilePluginInstance,
                                    pageNavigationPluginInstance,
                                    highlightPluginInstance
                                ]} />
                            </Worker>
                        </div>
                        {
                            sidebarContent == "" ? (<></>) : (
                                <div className='h-full flex flex-col w-96 bg-white border-l'>
                                    <div className='text-2xl p-4 font-semibold'>{sidebarContent.slice(0,1).toUpperCase()+sidebarContent.slice(1)}</div>
                                    {
                                        (sidebarContent == "analysis") ? (<SidebarAnalysis comments={comments} jumpToHighlight={jumpToHighlight} clickedHighlightComment={clickedHighlightComment} checklistItemTagging={checklistItemTagging} setChecklistItemTagging={setChecklistItemTagging} />) :
                                        (sidebarContent == "comments") ? (<SidebarComments comments={comments} jumpToHighlight={jumpToHighlight} clickedHighlightComment={clickedHighlightComment} />) :
                                        // (sidebarContent == "chat") ? (<SidebarChat />) : 
                                        (sidebarContent == "details") ? (<SidebarDocumentInfo documentData={documentData} DownloadElement={Download} />) : (<></>)

                                    }
                                </div>
                            )
                        }
                    </div>
                )
            }

            <div className="w-full h-16 flex content-center justify-between w-full py-2.5 text-sm font-medium leading-5 text-gray-900 bg-white border-t border-solid max-md:pr-5 max-md:max-w-full">
                <div>
                    <div className='flex items-center'>
                        <div onClick={jumpToPreviousPage}
                        className='flex items-center justify-center cursor-pointer w-6 h-6'>
                            <ArrowLeftIcon iconColor='currentColor' />
                        </div>

                        <CurrentPageInput />
                        
                        <p className='pr-1'>/</p>

                        <NumberOfPages />
                        <div onClick={jumpToNextPage}
                        className='flex items-center justify-center cursor-pointer w-6 h-6'>
                            <ArrowRightIcon iconColor='currentColor' />
                        </div>
                    </div>
                </div>
                
                <div className='flex'> 
                    <SwitchSelectionMode mode={!dragSelection ? SelectionMode.Hand : SelectionMode.Text}>
                        {(props: RenderSwitchSelectionModeProps) => (
                            <button onClick={() => {props.onClick(); setDragSelection(prev => !prev);}}>
                                {
                                    dragSelection ? <HandToolIcon /> : <TextSelectionIcon />
                                }
                            </button>
                        )}
                    </SwitchSelectionMode>
                </div>

                <div className='flex space-x-2 items-center'>
                    <div className='flex space-x-2 items-center'>
                        <div onClick={() => setZoom(prev => prev - 10)}
                        className='cursor-pointer h-4 w-4'>
                            <ZoomOutIcon />
                        </div>
                        
                        <input id="default-range" type="range" value={zoom} min={0} max={200} step={5} onChange={(e) => setZoom(Number(e.currentTarget.value))} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer" />
                        
                        <div onClick={() => setZoom(prev => prev + 10)}
                        className='cursor-pointer h-4 w-4'>
                            <ZoomInIcon />
                        </div>
                    </div>

                    <p className='w-16 text-center'>{zoom} %</p>
                </div>

            </div>

            <SpeedDial speedDials={[
                // {tooltip: "Add contracts", onClick: () => console.log("Not implemented")},
            ]} defaultDials={true} currentContext='contracts' offsetY='bottom-20' selectionId={selectionId} />
        </div>
    )
}

export default DocumentReader