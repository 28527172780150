import React, { useEffect, useState } from 'react'
import SearchTable from './features/SearchTable';
import FormModal from '../../../../../../components/FormModal';
import StartSelectionForm from '../../selection-cockpit/components/StartSelectionForm';
import QueryFieldSP from '../../../../../../components/QueryFieldSP';
import { RootState } from '../../../../../../redux/store';
import { useSelector } from 'react-redux';

const MatchingOverview = () => {
    const [selectedSearch, setSelectedSearch] = useState<string | null>(null);
    const [startSelectionFormOpen, setStartSelectionFormOpen] = useState(false);

    const isOrgAdmin = useSelector((state: RootState) => state.user.isOrgAdmin);

    return (
    <div className='h-full pt-16 relative'>
        {(startSelectionFormOpen) ? (<FormModal isOpen={startSelectionFormOpen} setOpen={setStartSelectionFormOpen} size="regular"><StartSelectionForm customerSearchCustomerDetailsId={selectedSearch}/></FormModal>) : (<div className="hidden"></div>)}
        <div className="p-4 absolute mb-4 col-span-full xl:mb-2 h-10 top-4">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                Matching Area
            </h1>
        </div>

        <div className='h-full pb-96 overflow-y-auto p-8'>
            <SearchTable setSelectedSearch={setSelectedSearch} setStartSelectionFormOpen={setStartSelectionFormOpen} />
        </div>

        {
            isOrgAdmin ?
            <div className='absolute bottom-0 left-0 h-96 w-full px-4'>
                <div className='bg-white/50 backdrop-blur-md border rounded-t-[70px] py-8 w-full h-full flex flex-col items-center justify-center'>
                    <p className='text-2xl font-semibold mb-4'>Start new matching</p>
                    <div className='w-1/2 max-sm:w-full max-sm:px-2 mt-8'><QueryFieldSP /></div>
                </div>
            </div> :
            <></>
        }

    </div>
  )
}

export default MatchingOverview