import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchCustomerDetails, upsertAccountDetails, upsertCustomerDetails } from '../../../../data/UserCustomerRequests';
import { LoadingSpinner } from 'swap-frontend-library';
import TextInput from '../../../../components/TextInput';
import MainButton from '../../../../components/MainButton';
import FormField from '../../../../components/FormField';
import TableHeader from '../../../../components/TableHeader';
import TableRow from '../../../../components/TableRow';
import { updateUserPassword } from '../../../../data/AuthRequests';
import { getSelectionPortalPlanLimit } from '../../../../data/PaymentRequests';

const ProfileSettings = () => {
    const {t} = useTranslation();

    const [customerData, setCustomerData] = useState<any>();
    const [orgUsers, setOrgUsers] = useState<any>();
    const [accountFormData, setAccountFormData] = useState<{
        firstname?: string,
        lastname?: string,
    }>();
    
    const [orgFormData, setOrgFormData] = useState<{
        company?: string,
        address_street?: string,
        address_city?: string,
        address_zip?: string,
        address_country?: string,
        contact_phone?: string,
        contact_email?: string,
    }>();

    const [existingPassword, setExistingPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const [editingAccount, setEditingAccount] = useState(false);
    const [editingOrg, setEditingOrg] = useState(false);
    const [editingPassword, setEditingPassword] = useState(false);

    const handleFetchCustomerData = () => {
        fetchCustomerDetails().then(data => {setCustomerData(data.data.customer); setOrgUsers(data.data.orgUsers)})
    }
    
    const handleUpdateCustomerData = async () => {
        if (!orgFormData || Object.keys(orgFormData).length === 0) {
            setEditingOrg(false);
            return;
        }
        if ((await upsertCustomerDetails(orgFormData)).success) {
            setEditingOrg(false);
            setOrgFormData(undefined);
            handleFetchCustomerData();
        }
    }
    
    const handleUpdateAccountData = async () => {
        if (!accountFormData || Object.keys(accountFormData).length === 0) {
            setEditingAccount(false);
            return;
        }
        if ((await upsertAccountDetails(accountFormData)).success) {
            setEditingAccount(false);
            setAccountFormData(undefined);
            handleFetchCustomerData();
        }
    }
    
    const handleUpdatePassword = async () => {
        if (!existingPassword || !newPassword) {
            setEditingPassword(false);
            return;
        }
        if (await updateUserPassword(existingPassword, newPassword)) {
            setEditingPassword(false);
            setExistingPassword("");
            setNewPassword("");
            handleFetchCustomerData();
        }
    }

    const [currentNumSelections, setCurrentNumSelections] = useState(0);
    const [selectionsRateLimit, setSelectionsRateLimit] = useState(0);
    const [currentNumMatchings, setCurrentNumMatchings] = useState(0);
    const [matchingsRateLimit, setMatchingsRateLimit] = useState(0);
        const handleFetchRateLimit = async () => {
        const response = await getSelectionPortalPlanLimit();
        if (response.success) {
            // console.log(response)
            setSelectionsRateLimit(response.data.maxNumSelections)
            setCurrentNumSelections(response.data.currNumSelections)
            
            setMatchingsRateLimit(response.data.maxNumMatchings)
            setCurrentNumMatchings(response.data.numMatchingsThisMonth)
        }
    }

    useEffect(() =>{handleFetchCustomerData(); handleFetchRateLimit()}, [])

    if (typeof customerData === "undefined") return <LoadingSpinner />
    return (
    <div className='p-4 space-y-4'>
        <div className='grid grid-cols-12 gap-4'>

            <div className='xl:col-span-6 col-span-12 grid gap-4'>

                <div className='bg-white rounded-lg shadow p-4 h-full'>
                    <p className='text-2xl font-semibold mb-4'>{t('userProfileSettings.yourAccount')}</p>

                    <div className='space-y-2'>
                        <FormField existingText={customerData.firstname} label={t("firstname")} setFormData={setAccountFormData} formDataKey='firstname' editing={editingAccount} />
                        <FormField existingText={customerData.lastname} label={t("lastname")} setFormData={setAccountFormData} formDataKey='lastname' editing={editingAccount} />
                        {/* <FormField existingText={customerData.email} label={t("Email")} setFormData={setAccountFormData} formDataKey='email' editing={editingAccount} /> */}
                    </div>

                    {
                        !editingAccount ? 
                        <div className='w-fit ml-auto mt-4'><MainButton text={t('userProfileSettings.edit')} func={() => setEditingAccount(true)} style='light' /></div> :
                        <div className='w-fit ml-auto mt-4'><MainButton text={t('userProfileSettings.save')} func={() => {handleUpdateAccountData()}} /></div>
                    }


                </div>

                <div className='bg-white rounded-lg shadow p-4 h-full'>
                    <p className='text-2xl font-semibold mb-4'>{t('userProfileSettings.resetPassword')}</p>

                    <div className='space-y-2'>
                        <TextInput textValue={existingPassword} setTextValue={setExistingPassword} label={t("userProfileSettings.existingPassword")} disabled={!editingPassword}/>
                        <TextInput textValue={newPassword} setTextValue={setNewPassword} label={t("userProfileSettings.newPassword")} disabled={!editingPassword}/>
                    </div>                    

                    {
                        !editingPassword ? 
                        <div className='w-fit ml-auto mt-4'><MainButton text={t('userProfileSettings.edit')} func={() => setEditingPassword(true)} style='light' /></div> :
                        <div className='w-fit ml-auto mt-4'><MainButton text={t('userProfileSettings.save')} func={() => {handleUpdatePassword()}} /></div>
                    }
                </div>

            </div>
            
            <div className='xl:col-span-6 col-span-12 h-full gap-4 grid'>
                <div className='bg-white rounded-lg shadow p-4'>
                    <div className='relative flex items-center'>
                        <p className='text-2xl font-semibold mb-4'>{t('userProfileSettings.yourOrganization')}</p>
                        {
                            !customerData.isOrgAdmin ? <></> :
                            customerData.customerDetails.payment_selectionPortalPlan ?
                            <div className='absolute cursor-pointer right-0 text-sm group font-semibold w-fit h-fit group rounded-lg text-white bg-primary p-1'>
                                <p className='uppercase'>{customerData.customerDetails.payment_selectionPortalPlan.name}</p>
                                <div className='absolute w-64 group-hover:block hidden bg-white/30 backdrop-blur-lg text-primary border mt-2 z-[2] shadow right-0 rounded text-center space-y-2 py-2'>
                                    <p>{currentNumMatchings} / {matchingsRateLimit} {t("userProfileSettings.matchingsUsed")}</p>
                                    <p>{currentNumSelections} / {selectionsRateLimit} {t("userProfileSettings.selectionsUsed")}</p>
                                </div>
                            </div> :
                            <p className='absolute right-0 text-sm font-semibold w-fit h-fit uppercase group rounded-lg text-white bg-primary p-1'>{t('userProfileSettings.freeTrial')}</p>
                        }
                        {/* <div className='absolute text-center right-0 top-0 flex items-center justify-center text-sm font-semibold w-fit h-fit cursor-pointer uppercase group rounded-lg text-white bg-primary p-1 hover:text-primary hover:bg-white border border-primary transition-all duration-500'>
                            <p className='group-hover:invisible visible'>{customerData.customerDetails.payment_selectionPortalPlan}</p>
                            <p className='absolute group-hover:block hidden'>Upgrade</p>
                        </div> */}
                    </div>

                    <div className='space-y-2'>
                        <FormField existingText={customerData.customerDetails.company} label={t("companyName")} setFormData={setOrgFormData} formDataKey='company' editing={editingOrg} />

                        <div>
                            <p className='block text-sm font-medium text-gray-900'>{t('userProfileSettings.address')}</p>
                            <div className='space-y-2'>
                                <FormField existingText={customerData.customerDetails.address_street} placeholder={t('userProfileSettings.street')} setFormData={setOrgFormData} formDataKey='address_street' editing={editingOrg} />
                                <FormField existingText={customerData.customerDetails.address_city} placeholder={t('userProfileSettings.city')} setFormData={setOrgFormData} formDataKey='address_city' editing={editingOrg} />
                                <FormField existingText={customerData.customerDetails.address_zip} placeholder={t('userProfileSettings.zip')} setFormData={setOrgFormData} formDataKey='address_zip' editing={editingOrg} />
                                <FormField existingText={customerData.customerDetails.address_country} placeholder={t('userProfileSettings.country')} setFormData={setOrgFormData} formDataKey='address_country' editing={editingOrg} />
                            </div>
                        </div>
                        
                        <div>
                            <p className='block text-sm font-medium text-gray-900'>{t('userProfileSettings.contact')}</p>
                            <div className='space-y-2'>
                                <FormField existingText={customerData.customerDetails.contact_phone} placeholder={t('userProfileSettings.phone')} setFormData={setOrgFormData} formDataKey='contact_phone' editing={editingOrg} />
                                <FormField existingText={customerData.customerDetails.contact_email} placeholder={t('userProfileSettings.email')} setFormData={setOrgFormData} formDataKey='contact_email' editing={editingOrg} />
                            </div>
                        </div>

                        {
                            customerData.isOrgAdmin ? 
                            <div>
                                {
                                    !editingOrg ? 
                                    <div className='w-fit ml-auto mt-4'><MainButton text={t('userProfileSettings.edit')} func={() => setEditingOrg(true)} style='light' /></div> :
                                    <div className='w-fit ml-auto mt-4'><MainButton text={t('userProfileSettings.save')} func={() => handleUpdateCustomerData()} /></div>
                                }
                            </div> :
                            <></>
                        }

                    </div>
                </div>
                
                {
                    customerData.isOrgAdmin ? 
                    <div className='bg-white rounded-lg shadow p-4 overflow-x-hidden'>
                        <div className="text-2xl font-semibold mb-4 py-2">
                            {t('userProfileSettings.orgAccounts')} ({orgUsers.length})
                        </div>
                        <div className="relative overflow-x-auto">
                            <table className="w-full text-sm text-left text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <TableHeader header={[`${t('userProfileSettings.email')}`, `${t('firstname')}`, `${t('lastname')}`, `${t('userProfileSettings.selections')}`, `${t('userProfileSettings.orgAdmin')}`]} />
                                </thead>
                                {
                                    orgUsers.map(({id, email, firstname, lastname, isOrgAdmin, userSelection} : any, index : number) => {
                                        return (
                                            <tbody className="cursor-pointer">
                                                <TableRow
                                                    rowItems={[`${index}`, email, firstname, lastname, userSelection.length, <input checked={isOrgAdmin} type='checkbox' />]}
                                                />
                                            </tbody>
                                        )
                                    })
                                }
                            </table>
                        </div>
                    </div> :
                    <></>
                }
            </div>

        </div>
        
    </div>
  )
}

export default ProfileSettings