import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { useOutletContext } from 'react-router-dom';
import { addUserSelection, changeSelectionCustomGroup, changeSelectionEditingRights, changeSelectionParticipantGroup, getUsersSelection } from '../../../../../../data/SelectionPortalRequests';
import { LoadingSpinner, PlusIcon, RefreshIcon, TableHeader, TableRow } from 'swap-frontend-library';
import MainButton from '../../../../../../components/MainButton';
import SearchBarFuzzy from '../../../../../../components/SearchBarFuzzy';
import { fetchCustomerDetails } from '../../../../../../data/UserCustomerRequests';
import TextInput from '../../../../../../components/TextInput';


const SelectionUsersTable = () => {
  const {t} = useTranslation();
  const projectRole = useSelector((state: RootState) => state.selection.projectRole);
  const { selectionId } = useOutletContext<any>();
  const [userSelection, setUsersSelection] = useState<any[]>([]);
  const [filteredUserSelection, setFilteredUsersSelection] = useState<any[]>([]);
  
  const [orgUsers, setOrgUsers] = useState<any[]>([]);
  const [orgUserEmails, setOrgUserEmails] = useState<any[]>([]);
  
  const redirectLink = encodeURIComponent(`/user-area/selection-portal/${selectionId}/`);
  const link = `${sessionStorage.domain}/signin?redirectTo=${redirectLink}&guestType=seeker&accessCode=`;

  const handleFetchOrgUsers = async () => {
    const data = await fetchCustomerDetails(); 
    setOrgUsers(data.data.orgUsers);
    setOrgUserEmails(data.data.orgUsers.map((item:any) => item.email))
  }

  const handleFetchUsersSelection = async () => {
    const data = await getUsersSelection(selectionId, true);
    // console.log(data.data.data);
    setUsersSelection(data.data.data);
    setFilteredUsersSelection(data.data.data);
  }
  
  const handleChangeEditingRights = async (userSelectionId : string, newRole : string) => {
    const response = await changeSelectionEditingRights(selectionId, userSelectionId, newRole)
    if (response.ok) {
      handleFetchUsersSelection();
    }
  }
  
  const handleChangeParticipantGroup = async (userSelectionId : string, newGroup : string) => {
    const response = await changeSelectionParticipantGroup(selectionId, userSelectionId, newGroup)
    if (response.ok) {
      handleFetchUsersSelection();
    }
  }

  useEffect(() => {
    handleFetchUsersSelection();
    handleFetchOrgUsers();
  }, [])

  return (
    <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
          {/* {deleteModalOpen ? <DeleteModal isOpen={deleteModalOpen} setOpen={setDeleteModalOpen} deleteHandler={() => handleDeleteVendorAccount(deleteItem.id)} content={
            <div>
              Soll der Account mit der E-Mail Adresse {deleteItem.email} wirklich gelöscht werden?
            </div>
          } /> : <></>} */}
          {
            (typeof userSelection !== "undefined") ? (
              <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
                {t('selectionUsersTable.title')}
                <p className="mt-1 text-sm font-normal text-gray-500">{t('selectionUsersTable.subtitle', { count: userSelection.length })}</p>
              </div>
            ) : <></>
          }

          <div
              className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200"
          >
          <div className="w-full mb-1">
              <div className="items-center justify-between block sm:flex">
                <div className='sm:w-64 xl:w-96'>
                  <SearchBarFuzzy translatedPlaceholderItem={t('selectionUsersTable.searchPlaceholder')} originalData={userSelection} setFilteredData={setFilteredUsersSelection} filterKeys={["user.email"]} />
                </div>
              
              <div className="flex items-center ml-auto space-x-2 sm:space-x-3">
                  <button
                    onClick={() => handleFetchUsersSelection()}
                    type="button"
                    data-refresh
                    className="inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                  >
                    <div className='text-white w-4 h-4'><RefreshIcon /></div>
                    {t('selectionUsersTable.refresh')}
                  </button>
                  
                  {
                    projectRole == "selection_admin" ? (
                      <AddUserToSelectionButton selectionId={selectionId} orgUsers={orgUsers} handleFetchUsersSelection={handleFetchUsersSelection} />
                    ) : <></>
                  }

              </div>
              </div>
          </div>
        </div>

          {
            (typeof userSelection === "undefined") ? (<LoadingSpinner />) : (
              <table className="w-full text-sm text-left text-gray-500">
                  <TableHeader header={[t('selectionUsersTable.email'), t('selectionUsersTable.editingRights'), t('selectionUsersTable.participantRole'), t('selectionUsersTable.customGroup'), ""]} />
                  {
                      filteredUserSelection.map((user: any, index: number) => {

                          return (
                              <tbody className="cursor-pointer">
                                  <TableRow rowItems={["", 
                                  <div>
                                    {orgUserEmails.includes(user.user?.email) ? <></> : <p className='font-light text-xs'>{t('selectionUsersTable.guest')} {user.guestEmail ? <span onClick={() => {navigator.clipboard.writeText(link + user.accessSecret)}} className='cursor-pointer bg-gray-100 ml-2 rounded px-2 py-.5 border active:bg-primary active:text-white transition-all'>{t('selectionUsersTable.copyAccessCode')}</span> : <></>}</p>}
                                    {user.user?.email || user.guestEmail}
                                  </div>, 
                                  <div className='font-bold'>
                                    {
                                      user.guestEmail ? <></> : 
                                      <select 
                                        value={user.selectionRole}
                                        onChange={(event) => handleChangeEditingRights(user.id, event.target.value)}
                                        id="countries" className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${user.selectionRole == "selection_admin" ? "bg-red-100 border-red-300" : "bg-gray-50 border-gray-300"}`} style={{WebkitAppearance: "none"}}>
                                        <option value={"selection_admin"}>{t('selectionUsersTable.admin')}</option>
                                        <option value={"selection_user"}>{t('selectionUsersTable.read')}</option>
                                      </select>
                                    }
                                  </div>,
                                  <div className='font-bold'>
                                    <select 
                                      value={user.selectionParticipantGroup}
                                      onChange={(event) => handleChangeParticipantGroup(user.id, event.target.value)}
                                      id="countries" className={`border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${user.selectionParticipantGroup == "consultant" ? "bg-blue-100 border-blue-300" : user.selectionParticipantGroup == "vendor" ? "bg-amber-100 border-amber-300" : "bg-gray-50 border-gray-300"}`} style={{WebkitAppearance: "none"}}>
                                      <option value={"seeker"}>{t('selectionUsersTable.seeker')}</option>
                                      <option value={"consultant"}>{t('selectionUsersTable.consultant')}</option>
                                      <option value={"vendor"}>{t('selectionUsersTable.vendor')}</option>
                                    </select>
                                  </div>,
                                  <CustomRoleTextInput user={user} selectionId={selectionId} handleFetchUsersSelection={handleFetchUsersSelection} />,
                                  <div><MainButton text={t('selectionUsersTable.remove')} style='light' func={() => handleChangeEditingRights(user.id, "delete")} /></div>
                                  ]} />
                              </tbody>
                          );
                      })
                  }
                </table>
            )
          }

    </div>
  )
}

const CustomRoleTextInput = ({user, selectionId, handleFetchUsersSelection} : {user:any, selectionId:string, handleFetchUsersSelection:any}) => {
  const [customGroup, setCustomGroup] = useState("");

  useEffect(() => {
    setCustomGroup(user.selectionCustomGroup)
  }, [user.selectionCustomGroup])
  
  const handleChangeCustomGroup = async () => {
    const response = await changeSelectionCustomGroup(selectionId, user.id, customGroup)
    if (response.ok) {
      handleFetchUsersSelection();
    }
  }

  return (
    <div className='font-bold'>
      <TextInput textValue={customGroup} setTextValue={setCustomGroup} onBlur={() => handleChangeCustomGroup()} />
    </div>
  )
}

const AddUserToSelectionButton = ({selectionId, orgUsers, handleFetchUsersSelection} : {selectionId: string, orgUsers: any[], handleFetchUsersSelection: () => void}) => {
  const {t} = useTranslation();
  const [openAdd, setOpenAdd] = useState(false);
  const [filteredOrgUsers, setFilteredOrgUsers] = useState<any[]>(orgUsers);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserEmailError, setNewUserEmailError] = useState(false);

  useEffect(() => {setFilteredOrgUsers(orgUsers)}, [orgUsers])

  const handleOpenAddUser = () => {
    setNewUserEmail("");
    setNewUserEmailError(false);
    setOpenAdd(prev => !prev);
  }

  const handleSendInvitation = async (email?: string) => {
    if (newUserEmail == "" && email == "") { return; }
    const response = await addUserSelection(selectionId, email || newUserEmail)
    console.log(response)
    if (response.ok) {
      handleOpenAddUser();
      handleFetchUsersSelection();
    } else {
      setNewUserEmailError(true);
    }
  }

  return (
    <>
      {
        (openAdd) ? (
          <div className='relative'>
            <label htmlFor="search" className="sr-only">{t('selectionUsersTable.addUser')}</label>
            <div className="relative h-full w-64">
                {/* <input
                onChange={e => {setNewUserEmailError(false); setNewUserEmail(e.target.value);}}
                value={newUserEmail}
                type="text"
                id="search"
                className={`bg-gray-50 border ${newUserEmailError ? "border-red-300" : "border-gray-300"} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5`}
                placeholder={`E-Mail`}
                /> */}
                <div className='w-full'>
                  <SearchBarFuzzy translatedPlaceholderItem={''} originalData={orgUsers} setFilteredData={setFilteredOrgUsers} filterKeys={["email"]} setOuterSearchText={setNewUserEmail} />
                </div>
            </div>
            <button onClick={() => handleSendInvitation()}
            className="text-white absolute end-4 bottom-0.5 bg-primary border hover:bg-primary2 hover:text-primary hover:border-primary font-medium rounded-lg text-sm px-4 py-2">{t('selectionUsersTable.send')}</button>

            {
              filteredOrgUsers.length == 0 ? <></> :
              <div className='absolute z-[3] right-0 mt-2 bg-gray-50 p-2 border rounded-lg shadow-lg space-y-2 h-32 overflow-y-auto'>
                {
                  filteredOrgUsers.map((orgUser:any) => {
                    return (
                      <div 
                      onClick={() => {handleSendInvitation(orgUser.email)}}
                      className='cursor-pointer bg-white hover:shadow rounded-lg p-1 px-2 border w-96 truncate'>{orgUser.email}</div> 
                    )
                  })
                }
              </div>
            }
          </div>
        ) : (<></>)
      }
      <button
        onClick={handleOpenAddUser}
        id="createProductButton"
        className="inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
        type="button"
        data-drawer-target="drawer-create-product-default"
        data-drawer-show="drawer-create-product-default"
        aria-controls="drawer-create-product-default"
        data-drawer-placement="right"
      >
        {openAdd ? t('selectionUsersTable.cancel') : <><div className='text-white w-4 h-4'><PlusIcon /></div>{t('selectionUsersTable.add')}</>}
      </button>
    </>
  )
}

export default SelectionUsersTable