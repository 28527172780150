import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../../components/MainButton';
import BellFilledIcon from '../../../../../assets/img/icons/BellFilledIcon';
import ActivityWindow from './components/ActivityWindow';
import ChatWindow from './components/ChatWindow';
import { getAllChats } from '../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

const Index = () => {
  const { t } = useTranslation();
  const [selectedChat, setSelectedChat] = useState<string>();
  const [selectedChatEmail, setSelectedChatEmail] = useState<string>();
  const [chats, setChats] = useState<any[]>();
  const { selectionId, setSelectedSelectionPhase } = useOutletContext<any>();
  const email = useSelector((state: RootState) => state.user.email);

  useEffect(() => { setSelectedSelectionPhase(""); }, []);

  const handleFetchChats = async () => {
    const response = await getAllChats(selectionId);
    setChats(response.data.data);
    return response.data.data;
  };

  const handleSwitchChatsToRecipient = async (recipientId: string) => {
    const newChats = await handleFetchChats();
    if (typeof chats !== "undefined") {
      const matchingChats = newChats.filter((chat: any) => chat.userChat.map((userChat: any) => userChat.user.id).includes(recipientId));
      if (matchingChats.length > 0) {
        setSelectedChat(matchingChats[0].id);
      }
    }
  };

  useEffect(() => { handleFetchChats(); }, []);

  return (
    <div className='h-full'>
      <div className='flex h-full w-full'>
        <div className="relative h-full w-64 flex flex-col font-medium whitespace-nowrap bg-white border-r border-solid basis-0 leading-[150%]">
          <div className='absolute border-b w-full px-3 py-2 h-16 disabled/top-16 bg-white'>
            <div onClick={() => setSelectedChat(undefined)}
              className={`relative flex items-center ${(typeof selectedChat === "undefined") ? ("bg-primary text-white") : ("hover:bg-gray-200")} rounded-md h-full px-2 cursor-pointer`}>
              <div className='absolute h-10 w-10 rounded-full flex justify-center items-center'>
                <BellFilledIcon iconColor='currentColor' />
              </div>
              <div className='w-full pl-12'>
                <p className='truncate font-semibold'>{t('inbox.activities')}</p>
              </div>
            </div>
          </div>

          <div className="h-full w-64 disabled/mt-32 mt-16 mb-20 flex flex-col px-3 py-1.5 overflow-y-auto text-base text-gray-900">
            <div id='chats' className='h-full flex flex-col py-2 overflow-y-auto space-y-2'>
              {
                (typeof chats === "undefined") ? (<LoadingSpinner />) : (chats.length == 0) ? (<></>) : (
                  <>
                    {
                      chats.map((chat: any, index: number) => {
                        let recipients = chat.userChat.filter((item: any) => item.user.email != email);

                        return (
                          <div onClick={() => { setSelectedChat(chat.id); setSelectedChatEmail(recipients[0].user.email); }}
                            className={`relative py-2 flex items-center ${(selectedChat === chat.id) ? ("bg-primary text-white") : ("hover:bg-gray-200")} rounded-md px-2 cursor-pointer`}>
                            <div className='absolute h-10 w-10 bg-white rounded-full flex justify-center items-center'>
                              <div className='text-primary font-semibold'>{String(recipients[0].user.email[0]).toUpperCase()}</div>
                            </div>
                            <div className='w-full pl-12'>
                              <p className='truncate font-semibold'>{recipients[0].user.email}</p>
                              <p className='text-secondary italic truncate'>{t('inbox.messagePlaceholder')}</p>
                            </div>
                          </div>
                        );
                      })
                    }
                  </>
                )
              }
            </div>
          </div>

          <div className='absolute border-t w-full p-4 h-20 bottom-0 bg-white'>
            <MainButton text={t('inbox.newChat')} link={''} style='light' func={() => setSelectedChat("")} />
          </div>
        </div>

        {
          (typeof selectedChat !== "undefined") ?
            (<ChatWindow chatId={selectedChat} setChatId={setSelectedChat} selectedChatEmail={selectedChatEmail} handleSwitchChatsToRecipient={handleSwitchChatsToRecipient} />) :
            (<ActivityWindow />)
        }
      </div>
    </div>
  );
};

export default Index;