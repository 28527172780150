// export async function getCustomerSearchCredits() {
//     const options : RequestInit = {
//         method: "GET",
//         credentials: "include",
//     }
//     try {
//         const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/payment/get-customer-search-credits`, options);
//         if (res.ok) {
//             const data = await res.json();
//             return data.data;
//         } else {
//             console.error(`getCustomerSearchCredits: Bad response ${res.status}`);
//         }

//     } catch(error) {
//         console.error(`getCustomerSearchCredits: Error occurred ${error}`);
//     }
// }

export async function unlockCustomerSearch() {

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({}),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/payment/unlock-customer-search`, options);
        if (res.ok) {
            return true;
        } else {
            console.error(`unlockCustomerSearch: Bad response ${res.status}`);
            return false;
        }
        
    } catch(error) {
        console.error(`unlockCustomerSearch: Error occurred ${error}`);
        return false;
    }
}

export async function configStripe() {
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/payment/config-stripe`);
        if (res.ok) {
            const { publishableKey } = await res.json();
            return publishableKey
        } else {
            console.error(`configStripe: Bad response ${res.status}`);
            return undefined;
        }
    } catch(error) {
        console.error(`configStripe: Error occurred ${error}`);
        return undefined;
    }
}

export async function createPaymentIntentQuery() {

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({})
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/payment/create-payment-intent-query`, options);
        if (res.ok) {
            const { clientSecret } = await res.json();
            return clientSecret;
        } else {
            console.error(`createPaymentIntentQuery: Bad response ${res.status}`);
            return undefined;
        }
    } catch(error) {
        console.error(`createPaymentIntentQuery: Error occurred ${error}`);
        return undefined;
    }
}

export async function createPaymentIntentLead(leadId : string) {

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
            leadId: leadId
        })
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/payment/create-payment-intent-lead`, options);
        if (res.ok) {
            const { clientSecret } = await res.json();
            return clientSecret;
        } else {
            console.error(`createPaymentIntentLead: Bad response ${res.status}`);
            return undefined;
        }
    } catch(error) {
        console.error(`createPaymentIntentLead: Error occurred ${error}`);
        return undefined;
    }
}

export async function fetchTransactionHistory() {
    const options : RequestInit = {
        method: "GET",
        credentials: "include"
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/payment/get-transactions`, options);
        if (res.ok) {
            const { data } = await res.json();
            return data;
        } else {
            console.error(`fetchTransactionHistory: Bad response ${res.status}`);
            return undefined;
        }
    } catch(error) {
        console.error(`fetchTransactionHistory: Error occurred ${error}`);
        return undefined;
    }
}

export async function changeSelectionPortalPlan(customerDetailsId: string, selectionPortalPlanProductCode: string) {

    const payload = {
        customerDetailsId,
        selectionPortalPlanProductCode
    }
    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/payment/change-selection-portal-plan`, options);
        if (res.ok) {
            return true;
        } else {
            console.error(`changeSelectionPortalPlan: Bad response ${res.status}`);
            return false;
        }
        
    } catch(error) {
        console.error(`changeSelectionPortalPlan: Error occurred ${error}`);
        return false;
    }
}

export async function getSelectionPortalPlanLimit() {
    
    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }
    
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/payment/get-selection-portal-plan-rate-limits`, options);
        if (res.ok) {
            const data = await res.json();
            return {success: true, data: data.data};
        } else {
            console.error(`getSelectionPortalPlanLimit: Bad response ${res.status}`);
            return {success: false};
        }
    } catch(error) {
        console.error(`getSelectionPortalPlanLimit: Error occurred ${error}`);
        return {success: false};
    }
}

export async function claimExpertMatching(queryUrl: string) {

    const payload = {
        queryUrl
    }
    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/payment/claim-expert-matching`, options);
        if (res.ok) {
            const data = res.json();
            return data;
        } else {
            console.error(`claimExpertMatching: Bad response ${res.status}`);
            return null;
        }
        
    } catch(error) {
        console.error(`claimExpertMatching: Error occurred ${error}`);
        return null;
    }
}