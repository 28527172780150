import React, { useState } from 'react'
import { MainButton, TextInput } from 'swap-frontend-library'

const PlatformApiKeySettings = () => {
    const [openAI, setOpenAI] = useState<string>('')
    const [webrisk, setWebrisk] = useState<string>('')
    const [stripePublic, setStripePublic] = useState<string>('')
    const [stripePrivate, setStripePrivate] = useState<string>('')
  
    return (
      <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            API Keys
            <p className="mt-1 text-sm font-normal text-gray-500">Set API keys</p>
        </div>
  
        <div className='p-4 bg-white space-y-4'>
          <TextInput textValue={openAI} setTextValue={setOpenAI} label='OpenAI API key' />
          <TextInput textValue={webrisk} setTextValue={setWebrisk} label='Google Webrisk API key' />
          <TextInput textValue={stripePublic} setTextValue={setStripePublic} label='Stripe Public API key' />
          <TextInput textValue={stripePrivate} setTextValue={setStripePrivate} label='Stripe Private API key' />
          
          <div className='ml-auto w-32'>
            <MainButton text='Save' func={() => {}} />
          </div>
        </div>
      </div>
    )
}

export default PlatformApiKeySettings