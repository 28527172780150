import React, { useState } from 'react';
import MainButton from '../../../../../../components/MainButton';
import { ArrowLeftIcon } from 'swap-frontend-library';
import ComparisonRequirements from './features/ComparisonRequirements';
import ComparisonPresentations from './features/ComparisonPresentations';
import ComparisonCosts from './features/ComparisonCosts';
import { useTranslation } from 'react-i18next';

const Comparison = () => {
    const { t } = useTranslation();
    const [comparePhase, setComparePhase] = useState("requirements");

    return (
        <div className='flex flex-col h-full w-full relative z-[1]'>
            {/* Heading */}
            <div className="absolute top-0 h-16 py-4 justify-between z-[1] w-full flex items-center py-2.5 pr-5 pl-5 text-sm font-medium leading-5 text-gray-900 bg-white border-b border-solid max-md:pr-5 max-md:max-w-full">
                <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                    {t('comparison.heading')}
                </h1>
                <div className='absolute right-2 z-[1] flex items-center space-x-2'>
                    <MainButton text={t('comparison.ranking')} link='..' icon={<div className='w-6 h-6'><ArrowLeftIcon /></div>} text_location='right' style='light' />
                </div>
            </div>

            {/* Phase tabs */}
            <ul className="absolute overflow-x-auto top-16 pt-4 h-16 z-[1] w-full flex items-end pr-5 pl-5 text-sm font-medium leading-5 text-gray-900 bg-white border-b border-solid max-md:pr-5 max-md:max-w-full">
                <li 
                    onClick={() => {setComparePhase("requirements");}}
                    className={`w-full flex items-end justify-between mr-6 p-2 border-b-4 ${(comparePhase == "requirements") ? ("text-primary border-primary") : ("border-gray-200 text-gray-400 hover:border-gray-300")} cursor-pointer`}>
                    <p className={`inline-block align-bottom h-full truncate w-64 rounded-t-lg`}>
                        {t('comparison.requirements')}
                    </p>
                </li>
                
                <li 
                    onClick={() => {setComparePhase("presentations");}}
                    className={`w-full flex items-end justify-between mr-6 p-2 border-b-4 ${(comparePhase == "presentations") ? ("text-primary border-primary") : ("border-gray-200 text-gray-400 hover:border-gray-300")} cursor-pointer`}>
                    <p className={`inline-block align-bottom h-full truncate w-64 rounded-t-lg`}>
                        {t('comparison.presentations')}
                    </p>
                </li>
                
                <li 
                    onClick={() => {setComparePhase("costs");}}
                    className={`w-full flex items-end justify-between mr-6 p-2 border-b-4 ${(comparePhase == "costs") ? ("text-primary border-primary") : ("border-gray-200 text-gray-400 hover:border-gray-300")} cursor-pointer`}>
                    <p className={`inline-block align-bottom h-full truncate w-64 rounded-t-lg`}>
                        {t('comparison.costs')}
                    </p>
                </li>
            </ul>

            <div className='mt-32 h-full overflow-y-auto'>
                {
                    comparePhase == "requirements" ? <ComparisonRequirements /> : <></>
                }
                {
                    comparePhase == "presentations" ? <ComparisonPresentations /> : <></>
                }
                {
                    comparePhase == "costs" ? <ComparisonCosts /> : <></>
                }
            </div>
        </div>
    );
};

export default Comparison;