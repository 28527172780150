import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import BigRingChartFilter from '../components/charts/BigRingChartFilter'
import BigRingChartFilterWorking from '../components/charts/BigRingChartFilterWorking'
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import { getScenarioStatusStatistics } from '../../../../../../data/SelectionPortalRequests';

interface props {
    selectionId: string
}

const ScenariosDonut = ({selectionId} : props) => {
    const { t } = useTranslation();
    const [labels, setLabels] = useState<string[]>();
    const [data, setData] = useState<number[]>();
    const [numScenarios, setNumScenarios] = useState<number>();

    // green, dark green, gray, yellow
    const colors = ['rgb(134, 239, 172)', 'rgb(22, 163, 74)', 'rgb(163, 163, 163)', 'rgb(251, 191, 36)'];

    const handleFetchData = async () => {
        const response = await getScenarioStatusStatistics(selectionId);
        if (response.ok) {
            // console.log(Object.keys(response.data.data.scenarioStatusCounts));
            setLabels(Object.keys(response.data.data.scenarioStatusCounts).map((key) => t("donut."+key)));
            setData(Object.values(response.data.data.scenarioStatusCounts));
            setNumScenarios(response.data.data.numScenarios);
        }
    }
    useEffect(() => {handleFetchData()}, [])
    
  return (
    <div className='p-4 h-full bg-white rounded-lg shadow sm:p-6'>
        {
            (typeof labels === "undefined" || typeof data === "undefined") ? (
                <LoadingSpinner />
            ) : (
                <BigRingChartFilterWorking 
                    subTitle={t('scenariosDonut.subTitle')} 
                    title={t('scenariosDonut.title', { totalTasks: data.reduce((partialSum, a) => partialSum + a, 0), numScenarios })} 
                    labels={labels} 
                    data={data} 
                    colors={colors} 
                />
            )
        }
    </div>
  )
}

export default ScenariosDonut