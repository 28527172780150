import React, { useEffect, useState } from 'react'
import { LoadingSpinner } from 'swap-frontend-library'
import { getPlatformSetting, sendTestEmail, setPlatformSetting } from '../../../../../../data/PlatformRequests'
import TextInput from '../../../../../../components/TextInput';
import MainButton from '../../../../../../components/MainButton';
import { upsertCustomerDetails } from '../../../../../../data/UserCustomerRequests';

const PlatformMessageSettings = () => {
    const [platformMessage, setPlatformMessage] = useState<string>();

    const handleGetSettings = async () => {

      const response = await getPlatformSetting('platformMessage');
      if (response != null) {
        setPlatformMessage(response.settingValue.platformMessage);
      } else {
        setPlatformMessage('');
      }
    }

    useEffect(() => {
      handleGetSettings();
    }, [])

    const handleUpdateSettings = async () => {
      await setPlatformSetting('platformMessage', {platformMessage});
    }
  
    return (
      <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            Platform message
            <p className="mt-1 text-sm font-normal text-gray-500">Set platform message to users</p>
        </div>

        {
          (typeof platformMessage === 'undefined') ? <div className='h-64 flex items-center justify-center bg-white'><LoadingSpinner /></div> : (
            <div className='p-4 bg-white space-y-4'>
              <TextInput textValue={platformMessage} setTextValue={setPlatformMessage} label='Message' rows={4} />

              <div className='ml-auto w-fit space-x-4'>
                <div className='flex items-center gap-4 w-32'>
                  <MainButton text='Save' func={() => handleUpdateSettings()} />
                </div>
              </div>
            </div>
          )
        }
  
  
      </div>
    )
}

export default PlatformMessageSettings