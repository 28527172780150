import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { login, setEmail, setGuestSelectionId, setRole } from '../../../../redux/userSlice';
import { loginGuest, loginUser } from '../../../../data/AuthRequests';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../components/MainButton';
import EyeOpenIcon from '../../../../assets/img/icons/EyeOpenIcon';
import EyeClosedIcon from '../../../../assets/img/icons/EyeClosedIcon';
import ArrowRightIcon from '../../../../assets/img/icons/ArrowRightIcon';

const Index = () => {
    const [signInWithAccount, setSignInWithAccount] = useState<boolean>(true);
    const [accessCode, setAccessCode] = useState<string>();
    
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const accessCodeParam = searchParams.get("accessCode");
        if (accessCodeParam) {
            setSignInWithAccount(false);
            setAccessCode(accessCodeParam);
        } 
    } , [])
    return (
        <div className='w-full h-[100dvh] flex items-center justify-center sm:pt-20'>
            <div className="sm:shadow-card rounded-lg text-secondary1 px-8 py-16 w-[500px] max-md:py-16">
                {
                    signInWithAccount ? 
                    <SignInAccount setSignInWithAccount={setSignInWithAccount} /> :
                    <SignInGuest setSignInWithAccount={setSignInWithAccount} accessCode={accessCode} />
                }
            </div>
        </div>
  )
}

const SignInAccount = ({setSignInWithAccount} : {setSignInWithAccount : React.Dispatch<React.SetStateAction<boolean>>}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputEmail, setInputEmail] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const [loginError, setLoginError] = useState(false);
    const [notVerifiedError, setNotVerifiedError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const location = useLocation();

    const submitHandler = async () => {
        setLoginError(false);
        setNotVerifiedError(false);

        const res = await loginUser(inputEmail, inputPassword);

        if (res.success) {
            
            dispatch(login());
            // console.log(res);
            dispatch(setRole(res.data.role));
            dispatch(setEmail(res.data.email));

            
            const { redirectTo } = queryString.parse(location.search);
            console.log(redirectTo)
            if (typeof redirectTo === "undefined" || redirectTo == null || redirectTo == "") {
                navigate("/user-area")
            } else {
                window.location.href = `${process.env.PUBLIC_URL}${redirectTo}`;
                // navigate(`${redirectTo}`);
            }
            // history.push(redirectTo == null ? "/apps" : redirectTo);

        } else {
            if (res.status == 400) { setLoginError(true); }
            if (res.status == 401) { setLoginError(true); }
            if (res.status == 403) { setNotVerifiedError(true); }
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <h2 className="text-2xl font-semibold text-center">{`${t("welcomeBack")}`}</h2>
            <p className="text-base text-center mt-4 mb-10">{`${t("useEmailSignIn")}`}</p>
            {/* <div className="border-b mb-6">
                <button className="border rounded-md mb-6 outline-none border-secondary6 flex items-center justify-center gap-4 w-full py-3 text-sm hover:bg-primary2 transition-all ease-in-out duration-300"> 
                    <img src="img/icons/google-icon.svg" alt="" className="w-5"></img>
                    {t("signInGoogle")}
                </button>
            </div> */}

            <form action="" className="flex flex-col gap-6">
                <div className="">
                    <input onChange={(event) => {setInputEmail(event.target.value)}}
                    type="text" placeholder="E-Mail" className="w-full text-base outline-none border border-secondary6 bg-transparent text-secondary1 rounded-md placeholder:text-sm focus:border-primary focus-within:border px-3 py-2"></input>
                </div>

                <div className="relative flex">
                    <input onChange={(event) => {setInputPassword(event.target.value)}}
                    type={showPassword ? "text" : "password"} placeholder={`${t("password")}`} className={`w-10/12 text-base outline-none border bg-transparent text-secondary1 rounded-md placeholder:text-sm focus:border-primary focus-within:border px-3 py-2`}></input>
                    <button
                        type="button"
                        className="absolute w-2/12 top-1/2 right-0 transform -translate-y-1/2 text-secondary4"
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ? (
                            <div className='w-[30px] mx-auto'><EyeOpenIcon iconColor='currentColor' /></div>
                            ) : (
                            <div className='w-[30px] mx-auto'><EyeClosedIcon iconColor='currentColor' /></div>
                            
                        )}
                    </button>
                </div>
                <div className='w-full'>
                    <MainButton text={t("signIn")} icon={<ArrowRightIcon />} link="" func={submitHandler} />
                </div>
            {loginError ? (<div className='mx-auto w-full whitespace-normal'><p className='text-xs italic text-rose-500 text-center'>{t("correctEmailPassword")}</p></div>) : (<p className='hidden'></p>)}
            {notVerifiedError ? (<div className='w-full'><p className='text-xs italic text-rose-500 text-center'>{t("emailNotVerified")}</p></div>) : (<p className='hidden'></p>)}
            </form>
            <div className="text-base mt-8 text-secondary4">{t("guestInvite?")} <span className="text-primary cursor-pointer underline underline-offset-1 hover:underline-offset-2 transition-all " onClick={() => setSignInWithAccount(false)}>{t("clickHere")}</span></div>
            {/* <div className="text-base mt-8 text-secondary4">{t("forgotEmailPassword")} <Link className="text-primary" to="#">{t("clickHere")}</Link></div> */}
            {/* <div className="text-base mt-2 text-secondary4">{t("noAccount")} <a href={process.env.REACT_APP_LANDING_PAGE + "/signin"} className="text-primary">{t("clickHere")}</a></div> */}
        </>
    )
}

const SignInGuest = ({setSignInWithAccount, accessCode} : {setSignInWithAccount : React.Dispatch<React.SetStateAction<boolean>>, accessCode: string | undefined}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [inputAccessCode, setInputAccessCode] = useState(typeof accessCode !== "undefined" ? accessCode : "");
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (typeof accessCode !== "undefined") submitHandler();
    }, [accessCode])
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const submitHandler = async () => {
        setLoginError(false);
        const guestType = searchParams.get("guestType")
        console.log(guestType)
        const res = await loginGuest(inputAccessCode, guestType);
        if (res.success) {
            
            dispatch(login());
            dispatch(setRole(res.data.role));
            dispatch(setEmail("Guest"));
            dispatch(setGuestSelectionId(res.data.selectionId));
            
            let link : string = `/user-area/selection-portal/${res.data.selectionId}`;
            const redirectUrl = searchParams.get("redirectTo")
            if (redirectUrl != null) link = redirectUrl;

            window.location.href = `${process.env.PUBLIC_URL}${link}`;

        } else {
            if (res.status == 400) { setLoginError(true); }
            if (res.status == 401) { setLoginError(true); }
        }
    }
    return (
        <>
            <h2 className="text-2xl font-semibold text-center">{`${t("welcome")}`}</h2>
            <p className="text-base text-center mt-4 mb-10">{`${t("accesCodeSignIn")}`}</p>

            <div className="flex flex-col gap-6">
                <div className="relative flex">
                    <input onChange={(event) => {setInputAccessCode(event.target.value)}} value={inputAccessCode}
                    type={showPassword ? "text" : "password"} placeholder={`${t("Access code")}`} className={`w-10/12 text-base outline-none border bg-transparent text-secondary1 rounded-md placeholder:text-sm focus:border-primary focus-within:border px-3 py-2`}></input>
                    <button
                        type="button"
                        className="absolute w-2/12 top-1/2 right-0 transform -translate-y-1/2 text-secondary4"
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ? (
                            <div className='w-[30px] mx-auto'><EyeOpenIcon iconColor='currentColor' /></div>
                            ) : (
                            <div className='w-[30px] mx-auto'><EyeClosedIcon iconColor='currentColor' /></div>
                            
                        )}
                    </button>
                </div>
                <div className='w-full'>
                    <MainButton text={t("signIn")} icon={<ArrowRightIcon />} link="" func={submitHandler} />
                </div>
                {loginError ? (<div className='w-full'><p className='text-xs italic text-rose-500 text-center'>{t("Please enter the correct access code and try again.")}</p></div>) : (<p className='hidden'></p>)}
            </div>
            <div className="text-base mt-8 text-secondary4">{t("haveAccount?")} <span className="text-primary cursor-pointer underline underline-offset-1 hover:underline-offset-2 transition-all" onClick={() => setSignInWithAccount(true)}>{t("clickHere")}</span></div>
        </>
    )
}

export default Index;