import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PercentageCircle from '../../../../../../components/PercentageCircle';
import VendorRankingRow from '../components/VendorRankingRow';
// @ts-ignore
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ArrowDownIcon from '../../../../../../assets/img/icons/ArrowDownIcon';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import { changeRankingInclusion, changeRankingInclusionMultiple, getSelectionRanking } from '../../../../../../data/SelectionPortalRequests';
import { useNavigate, useOutletContext } from 'react-router';
import TopVendorsPodium from '../components/TopVendorsPodium';
import SidebarRankingInfo from '../components/SidebarRankingInfo';
import { useSearchParams } from 'react-router-dom';
import { AnalysisIcon, EyeOpenIcon, MainButton, SettingsFilledIcon } from 'swap-frontend-library';
import SpeedDial from '../../components/SpeedDial';
import SelectionGuideInjector from '../../components/SelectionGuideInjector';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import DropdownMenu from '../../../../../../components/DropdownMenu';

const Ranking = () => {
    const { t } = useTranslation();
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const { selectionId }  = useOutletContext<any>();
    const [vendorRanking, setVendorRanking] = useState<any[]>();
    const [sortedVendorRanking, setSortedVendorRanking] = useState<any[]>();
    const [currentAvailableScore, setCurrentAvailableScore] = useState<string>();
    const [sortBy, setSortBy] = useState<string>();
    const [excludedVisible, setExcludedVisible] = useState(false);
    const [numberIncluded, setNumberIncluded] = useState(0);
    const [exclusionError, setExclusionError] = useState(false);
    const [includeDragActive, setIncludeDragActive] = useState(false);
    const [excludeDragActive, setExcludeDragActive] = useState(false);
    const [sidebarOpenWithRankingData, setSidebarOpenWithRankingData] = useState<any>();
    const [endPhase, setEndPhase] = useState<string | null>(null);

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false);

    const handleExclude = (e : any) => {
        setLoading(true);
        handleDrop(e, false);
        // if (numberIncluded > 1) {
        // } else {
        //     setExclusionError(true);
        //     setLoading(false);
        // }
        setExcludeDragActive(false);
    }
    
    const handleInclude = (e : any) => {
        setLoading(true);
        handleDrop(e, true);
        setIncludeDragActive(false);
    }

    const handleDrop = async (e : any, include : boolean) => {
        const id = e.dataTransfer.getData("id");
        
        if (await changeRankingInclusion(id, include)) {
            setExcludeDragActive(false);
            setIncludeDragActive(false);

            await handleFetchRanking();
        }

        // const newVendorRanking = vendorRanking?.map((item:any) =>{
        //     if (id == item.id) {
        //         item.included = include;
        //     }
        //     return item;
        // })
        // setVendorRanking(newVendorRanking);
    }

    const enterDrop = (e : any, include : boolean) => {
        e.preventDefault();
        if (include) { setIncludeDragActive(true); } else { setExcludeDragActive(true); }
    }
    
    const leaveDrop = (e : any, include : boolean) => {
        e.preventDefault();
        if (include) { setIncludeDragActive(false); } else { setExcludeDragActive(false); }
    }
    
    const dragStart = (e : any, id : string) => {
        setExclusionError(false);
        e.dataTransfer.setData("id", id);
    }

    const toggleExcluded = () => {
        setExcludedVisible(prev => !prev);
        setExclusionError(false);
    }

    const handleExcludeMultiple = async (maxToBeExcluded: number) => {
        setLoading(true);
        await changeRankingInclusionMultiple(selectionId, maxToBeExcluded);
        await handleFetchRanking();
        setLoading(false);
    }

    const handleFetchRanking = async () => {
        const response = await getSelectionRanking(selectionId, true);
        // console.log(response)
        if (response.ok) {
            setVendorRanking(response.data.data.results);
            setCurrentAvailableScore(response.data.data.sortKey);
            setSortBy(response.data.data.sortKey);
        }
        setLoading(false);
    }

    const [ searchParams, setSearchParams ] = useSearchParams();
    useEffect(() => {
        const endPhaseSearchParam = searchParams.get("endPhase");
        setEndPhase(endPhaseSearchParam)
        handleFetchRanking();
    }, [])
    
    // useEffect(() => {
    //     const endPhaseSearchParam = searchParams.get("endPhase");
    //     setEndPhase(endPhaseSearchParam)
    // }, [searchParams])

    const sortRankingByKey = ((a:any, b:any) => {
        if (typeof sortBy === "undefined") return 0;

        if (b[sortBy] < a[sortBy]) {
            return -1;
        }
        if (b[sortBy] > a[sortBy]) {
            return 1;
        }
        return 0;
    })

    useEffect(() => {
        const numberIncludedOrUndefined = vendorRanking?.filter((item:any) => item.included).length;
        if (typeof numberIncludedOrUndefined !== "undefined") {
            setNumberIncluded(numberIncludedOrUndefined);
        }

    }, [vendorRanking])
    
    // useEffect(() => {
    //     if (typeof vendorRanking === "undefined") return
    //     const newSortedRanking = vendorRanking.sort(sortRankingByKey);
    //     console.log(newSortedRanking)
    //     setSortedVendorRanking(newSortedRanking);
    // }, [sortBy])
    
    return (
    <div className='flex flex-col h-full w-full relative z-[2]'>
        <div className="absolute top-0 py-4 h-16 justify-between z-[1000] w-full flex items-center py-2.5 pr-5 pl-5 text-sm font-medium leading-5 text-gray-900 bg-white border-b border-solid max-md:pr-5 max-md:max-w-full">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                {t('ranking.title')}
            </h1>

            <div className='absolute right-2 z-[1000] flex space-x-2'>
                {
                    projectRole == "selection_admin" ? 
                    <>
                        <MainButton text={t('ranking.compareSystems')} icon={<div className='text-secondary w-6 h-6'><AnalysisIcon /></div>} link="compare" />
                        <MainButton text={excludedVisible ? t('ranking.saveShortlist') : t('ranking.updateShortlist')} func={() => setExcludedVisible(prev => !prev)} style='light' icon={<div className='text-secondary w-6 h-6'><SettingsFilledIcon /></div>} />
                    </> : <></>
                }
                {
                    endPhase != null ? (
                        <SelectionGuideInjector selectionId={selectionId} acceptedTaskCodes={["FINISH_PHASE"]} reloadTaskTrigger={vendorRanking} />
                    ) : (<></>)
                }
            </div>
        </div>

        <div className='absolute top-16 h-10 w-full border-b flex items-end justify-between bg-white px-8'>
            <div className='flex items-end justify-start'>
                <div onClick={() => setSortBy("hide")} className={`w-fit cursor-pointer border-b-4 ${sortBy == "hide" ? "border-primary": "border-transparent"} flex items-center gap-2`}>
                    {t('ranking.hideOrder')}
                </div>
            </div>

            <div className='flex items-end justify-end'>
                <div className='flex text-center'>
                    <div onClick={() => setSortBy("score_preselection")} className={`w-32 cursor-pointer border-b-4 ${sortBy == "score_preselection" ? "border-primary": "border-transparent"}`}>
                        {t('ranking.preselection')}
                    </div>
                    <div onClick={() => setSortBy("score_requirements")} className={`w-32 cursor-pointer border-b-4 ${sortBy == "score_requirements" ? "border-primary": "border-transparent"}`}>
                        {t('ranking.requirements')}
                    </div>
                    <div onClick={() => setSortBy("score_presentations")} className={`w-32 cursor-pointer border-b-4 ${sortBy == "score_presentations" ? "border-primary": "border-transparent"}`}>
                        {t('ranking.presentations')}
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="mb-4 col-span-full xl:mb-2">
        </div> */}

        {
            loading ? (
                <div className='absolute z-[30] h-full w-full bg-white/30 backdrop-blur flex items-center justify-center'>
                    <LoadingSpinner />
                </div>
            ) : <></>
        }

        <div className='relative overflow-x-hidden h-full mt-28'>
            <div className='h-full w-full'>
                {
                    (typeof vendorRanking === "undefined") ? (<LoadingSpinner />) : (
                        <>
                        <div className='relative h-full w-full flex flex-col justify-between'>

                            { vendorRanking.filter((item:any) => item.included).length == 0 ? (
                                <div 
                                className={`flex items-center justify-center cursor-pointer shadow h-32 py-8 mt-2 bg-white transitions-all duration-500 rounded-lg`}>
                                    {t('ranking.noSystemsIncluded')}
                                </div>
                            ) : (<></>)}

                            <div className={`relative overflow-y-auto pt-3  ${excludedVisible ? "h-[60%]" : "mb-16"}`}
                            onDrop={handleInclude} onDragOver={(e) => enterDrop(e, true)} onDragLeave={(e) => leaveDrop(e, true)}>

                                {/* <div className='max-lg:hidden pt-4'>
                                    <TopVendorsPodium vendorData={vendorRanking.filter((item:any) => item.included).slice(0,3)} dragStart={dragStart} draggable={excludedVisible} setSidebarOpenWithRankingData={setSidebarOpenWithRankingData} />
                                </div> */}
                
                                <div
                                className={`grid ${sortBy == "hide" ? "lg:grid-cols-4 grid-cols-2" : "grid-cols-1"} gap-2 px-4 pb-4`}>
                                    {
                                        vendorRanking.filter((item:any) => item.included).sort(sortRankingByKey).map((item:any, index:number) => {
                                            if (item.included) {
                                                return (
                                                    <div draggable={excludedVisible && projectRole == "selection_admin"} onDragStart={(e) => dragStart(e, item.id)} className={`relative group ${(index < 3 && false) ? ("lg:hidden") : ("")}`}>
                                                        {
                                                            excludedVisible && projectRole == "selection_admin" ? <div onClick={() => handleExcludeMultiple(item.rank)} className='absolute cursor-pointer rounded shadow right-5 -top-5 px-4 flex items-center justify-center font-semibold text-red-400 h-8 bg-amber-200 border border-2 border-dashed border-red-400 group-hover:block hidden hover:scale-[1.05] transition-all'><p>{t('ranking.excludeAllBelow')}</p></div> : <></>
                                                        }
                                                        <VendorRankingRow vendorRankingData={item} index={index} currentAvailableScore={sortBy} setSidebarOpenWithRankingData={setSidebarOpenWithRankingData} hideOrder={sortBy == "hide"} />
                                                    </div>
                                                )
                                            }
                                        })
                                    }

                                    {/* { exclusionError ? (
                                        <div className='text-red-700 font-semibold w-full text-center'>
                                            There must be at least one system in the current selection.
                                        </div>
                                    ) : (<></>)} */}

                                        {/* <div className={`flex items-center relative cursor-pointer my-4`}>
                                            <div className='grow my-2 border-t border-gray-300 border-2'>
                                            </div>
                            
                                            <div className='mx-4 flex flex-col items-center text-gray-400'>
                                                +
                                            </div>
                                            
                                            <div className='grow my-2 border-t border-gray-300 border-2'>
                                            </div>
                                        </div> */}
                                </div>
                            </div>

                            { includeDragActive ? (
                                <div onDrop={handleInclude} onDragOver={(e) => enterDrop(e, true)} onDragLeave={(e) => leaveDrop(e, true)} className='absolute top-0 left-0 w-full h-[60%] bg-gray-300 z-[2] opacity-50 border-8 border-dashed rounded-lg'>
                                </div>
                                ) : (<></>)
                            }
                
                            <div onClick={toggleExcluded}
                            className={`absolute flex flex-col w-full bg-gray-50 z-20 ${excludedVisible ? "top-[60%] h-[40%]" : "bottom-0"}`}>
                                <div className='flex items-center cursor-pointer z-20 h-16' style={{boxShadow: "0px -24px 23px -24px rgba(0,0,0,0.32), 0px 31px 23px -24px rgba(0,0,0,0.32)"}}>
                                    <div className='grow my-2 border-t border-gray-400 border-4'>
                                    </div>
                    
                                    <div className='mx-4 flex flex-col items-center text-gray-400'>
                                        {
                                            (excludedVisible) ? (t('ranking.hideExcluded')) : (t('ranking.seeExcludedSystems'))
                                        }
                                    </div>
                                    
                                    <div className='grow my-2 border-t border-gray-400 border-4'>
                                    </div>
                                </div>

                                {
                                    (excludedVisible) ? (
                                        <div onDrop={handleExclude} onDragOver={(e) => enterDrop(e, false)} onDragLeave={(e) => leaveDrop(e, false)}
                                        className='relative z-0 h-full overflow-y-auto w-full space-y-2 pb-4 px-4'>
                                            {   
                                                vendorRanking.filter((item:any) => !item.included).map((item:any, index:number) => {
                                                    return (
                                                        <div draggable={projectRole == "selection_admin"} onDragStart={(e) => dragStart(e, item.id)}>
                                                            <VendorRankingRow vendorRankingData={item} index={numberIncluded+index} currentAvailableScore={sortBy} setSidebarOpenWithRankingData={setSidebarOpenWithRankingData} />
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    ) : (<></>)
                                }
                            </div>

                            { excludeDragActive ? (
                                <div onDrop={handleExclude} onDragOver={(e) => enterDrop(e, false)} onDragLeave={(e) => leaveDrop(e, false)}
                                className='absolute top-[60%] z-[4000] mt-16 w-full h-[40%] pb-20 bg-gray-300 z-[2] opacity-50 border-8 border-dashed rounded-lg'>
                                </div>
                                ) : (<></>)
                            }
                        
                        </div>
                
                        </>
                    )
                }
            </div>
            
            <SidebarRankingInfo vendorRankingData={sidebarOpenWithRankingData} setSidebarOpenWithRankingData={setSidebarOpenWithRankingData} />
        </div>

        {
            endPhase == "finish" ? (
                <SelectionGuideInjector selectionId={selectionId} acceptedTaskCodes={["FINISH_PHASE"]} />
            ) : (<></>)
        }
        
        {
            (endPhase != null && endPhase != "finish") ? (
                <SpeedDial offsetY={excludedVisible ? undefined : "bottom-16"} speedDials={[
                    {tooltip: t('ranking.updateShortlistTooltip'), onClick:() => setExcludedVisible(prev => !prev), icon:<div className='text-primary w-6 h-6'><SettingsFilledIcon /></div>},
                ]} defaultDials={true} currentContext={endPhase} selectionId={selectionId} reloadTaskTrigger={vendorRanking} />
            ) : (<></>)
        }
        
        
    </div>
  )
}

export default Ranking