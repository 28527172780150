import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, LoadingSpinner, QuestionmarkIcon, SettingsFilledIcon, WarningIcon } from 'swap-frontend-library';
import CostSettingsModal from './components/CostSettingsModal';
import TotalCostCell from './components/TotalCostCell';
import { useOutletContext } from 'react-router-dom';
import { updateSelectionData } from '../../../../../../../../data/SelectionPortalRequests';

interface props {
    categoriesWithCosts: any,
    vendorRanking: any[],
    softwareProductFinancials: any,
    selectionRequirements: any,
    aggregateCosts: any,
    setCustomizingSettingsElement: React.Dispatch<React.SetStateAction<any>>,
    selectionDetails: any
}

const ComparisonCostsCustomization = ({categoriesWithCosts, vendorRanking, softwareProductFinancials, selectionRequirements, aggregateCosts, setCustomizingSettingsElement, selectionDetails} : props) => {
    const { t } = useTranslation();
    const [customizationCostsOpen, setCustomizationCostsOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);

    const [projectManagementFactor, setProjectManagementFactor] = useState<number>(0);
    const [consultingFactor, setConsultingFactor] = useState<number>(0);

    useEffect(() => {
        if (settingsOpen) {
            setCustomizingSettingsElement(<div className={`flex justify-center`}><CostSettingsModal setOpen={setSettingsOpen}><SettingsCustomizingCosts projectManagementFactor={projectManagementFactor} setProjectManagementFactor={setProjectManagementFactor} consultingFactor={consultingFactor} setConsultingFactor={setConsultingFactor} /></CostSettingsModal></div>)
        } else {
            setCustomizingSettingsElement(undefined);
        }
    }, [settingsOpen])
    
    useEffect(() => {
        setProjectManagementFactor(selectionDetails.projectManagementFactor/100);
        setConsultingFactor(selectionDetails.consultingFactor/100);
    }, [])

    return (
    <>
        <tr onClick={() => setCustomizationCostsOpen(prev => !prev)}
            className='sticky top-56 mt-4 z-[3] cursor-pointer'>
            <td className='sticky left-0 z-[3] py-2'>
                <div className={`h-24 sm:w-[400px] md:w-[600px] pl-2 flex items-center gap-4 bg-white/70 backdrop-blur-lg ml-4 border-l border-y border-primary rounded-l-lg text-xl font-bold text-primary`}>
                    <div className={`w-4 h-4 ${customizationCostsOpen ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div>
                    {t('customizationCosts.total')}
                    <div onClick={(e) => {e.stopPropagation(); setSettingsOpen(prev => !prev)}} className={`relative ml-auto mr-4 w-10 h-10 rounded-full p-1.5 border border-primary group hover:bg-white hover:text-primary transition-all flex justify-center ${settingsOpen ? "bg-white text-primary" : "bg-primary text-white"}`}>
                        <SettingsFilledIcon />
                    </div>
                </div>
            </td>
            {
                vendorRanking.map((vendorData:any, index:number) => {
                    const lastVendor = index+1 == vendorRanking.length;
                    return (
                        <TotalCostCell vendorData={vendorData} aggregateCosts={aggregateCosts} softwareProductFinancials={softwareProductFinancials} isLastItem={lastVendor} projectManagementFactor={projectManagementFactor} consultingFactor={consultingFactor} warningElement={
                            <>
                              {
                                !softwareProductFinancials[vendorData.id].dailyRateDev ? 
                                <div title="Please check whether the relevant financial data has been filled in." className='absolute top-2 right-2 w-5 h-5 text-amber-600'><WarningIcon /></div> : 
                                <></>
                              }
                            </>
                          } />
                    )
                })
            }
        </tr>
        {
            customizationCostsOpen ? 
            <>
                {
                    Object.keys(categoriesWithCosts).map((category_0: any, index: number) => {
                        const subCategoriesWithScores = categoriesWithCosts[category_0].subCategories;
                        const scores = categoriesWithCosts[category_0].costs;
                        return (
                            <>
                            <CategoryRow category_0={category_0} vendorRanking={vendorRanking} categoriesWithCosts={categoriesWithCosts} softwareProductFinancials={softwareProductFinancials} projectManagementFactor={projectManagementFactor} consultingFactor={consultingFactor}>
                                <>
                                {
                                    Object.keys(subCategoriesWithScores).map((category_1: any, index: number) => {
        
                                        const lastCategory = index+1 == Object.keys(subCategoriesWithScores).length;
        
                                        const requirements = selectionRequirements.filter((selectionRequirement : any) => (selectionRequirement.category[0] == category_0 && selectionRequirement.category[1] == category_1))
                                        return (
                                            <>
                                                <SubCategoryRow category_0={category_0} category_1={category_1} vendorRanking={vendorRanking} categoriesWithCosts={categoriesWithCosts} isLast={lastCategory} softwareProductFinancials={softwareProductFinancials} projectManagementFactor={projectManagementFactor} consultingFactor={consultingFactor}>
        
                                                    {
                                                        requirements.map((selectionRequirement: any, index: number) => {
                                                            const lastRequirement = index+1 == requirements.length;
                                                            return (
                                                                <RequirementComparisonRow selectionRequirement={selectionRequirement} vendorRanking={vendorRanking} isLast={lastCategory && lastRequirement} softwareProductFinancials={softwareProductFinancials} projectManagementFactor={projectManagementFactor} consultingFactor={consultingFactor} />
                                                            );
                                                        })
                                                    }
                                                </SubCategoryRow>
        
                                            </>
                                        )
                                    })
                                }
                                </>
                            </CategoryRow>
                            </>
                        );
                    })
                }
            </> :
            <></>
        }

        {/* <div className={`${settingsOpen ? "flex" : "hidden"} justify-center fixed right-5 bottom-5 z-[1000]`}><CostSettingsModal setOpen={setSettingsOpen}><SettingsCustomizingCosts /></CostSettingsModal></div> */}
    </>
  )
}

const CategoryRow = ({children, category_0, vendorRanking, categoriesWithCosts, softwareProductFinancials, projectManagementFactor, consultingFactor} : {children:any, category_0:string, vendorRanking:any[] | undefined, categoriesWithCosts:any, softwareProductFinancials:any, projectManagementFactor: number, consultingFactor: number}) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <tr onClick={() => setOpen(prev => !prev)} className='cursor-pointer'>
                <td className='sticky left-0 z-[2] bg-gray-50'>
                    <div className={`bg-white mt-4 ml-4 h-20 sm:w-[400px] md:w-[600px] py-2 pl-2 pr-4 border-l border-t rounded-tl-lg ${open ? "" : "border-b rounded-bl-lg"} text-xl font-semibold flex items-center gap-2`}>
                        <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div>
                        <p>{category_0}</p>
                    </div>
                </td>

                {
                    vendorRanking?.map((currentVendorRanking:any, index:number) => {
                        let factoredCosts = -1;
                        if (categoriesWithCosts[category_0].costs[currentVendorRanking.id] != -1) {
                            const rawCosts = categoriesWithCosts[category_0].costs[currentVendorRanking.id];
                            factoredCosts = rawCosts + (rawCosts * projectManagementFactor) + (rawCosts * consultingFactor);
                        }
                        // categoriesWithCosts[category_0].costs[currentVendorRanking.id]
                        return (
                            <td key={index} className=''>
                                <div className={`bg-white mt-4 h-20 w-40 py-2 border-t ${open ? "" : "border-b"} ${index+1 == vendorRanking.length ? `border-r ${open ? "rounded-tr-lg" : "rounded-r-lg"}` : ""} text-xl font-semibold flex items-center justify-center`}>
                                    {
                                        factoredCosts == -1 ? 
                                        <div className='w-6 h-6'><QuestionmarkIcon /></div> : 
                                        factoredCosts.toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[currentVendorRanking.id].preferredCurrency })
                                    }
                                </div>
                            </td>
                        )
                    })
                }
            </tr>
            {
                open ? 
                children :
                <></>
            }
        </>
    )

}

const SubCategoryRow = ({children, category_0, category_1, vendorRanking, categoriesWithCosts, isLast, softwareProductFinancials, projectManagementFactor, consultingFactor} : {children:any, category_0:string, category_1:string, vendorRanking:any[] | undefined, categoriesWithCosts:any, isLast: boolean, softwareProductFinancials: any, projectManagementFactor: number, consultingFactor: number}) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <tr onClick={() => setOpen(prev => !prev)} className='cursor-pointer'>
                <td className='sticky left-0 z-[2] bg-gray-50'>
                    <div className={`bg-white sm:w-[400px] md:w-[600px] ml-4 py-2 pl-2 pr-4 h-16 border-l text-md font-semibold flex items-center gap-2 ${isLast && !open ? `border-b ${open ? "" : "rounded-bl-lg"}` : ""} `}>
                        <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div>
                        <p>{category_1}</p>
                    </div>
                </td>

                {
                    vendorRanking?.map((currentVendorRanking:any, index: number) => {
                        let factoredCosts = -1;
                        if (categoriesWithCosts[category_0].subCategories[category_1].costs[currentVendorRanking.id] != -1) {
                            const rawCosts = categoriesWithCosts[category_0].subCategories[category_1].costs[currentVendorRanking.id];
                            factoredCosts = rawCosts + (rawCosts * projectManagementFactor) + (rawCosts * consultingFactor);
                        }
                        return (
                            <td key={index}>
                                <div className={`bg-white py-2 h-16 w-40 text-md font-semibold flex items-center justify-center ${isLast && !open ? `border-b` : ""} ${index+1 == vendorRanking.length ? `border-r ${isLast && !open ? "rounded-br-lg" : ""}` : ""}`}>
                                    <div className='scale-[0.8] opacity-70'>
                                        {
                                            factoredCosts == -1 ? 
                                            <div className='w-6 h-6'><QuestionmarkIcon /></div> : 
                                            factoredCosts.toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[currentVendorRanking.id].preferredCurrency })
                                        }
                                    </div>
                                </div>
                            </td>
                        )
                    })
                }
            </tr>
            {
                open ? 
                children :
                <></>
            }
        </>
    )

}

const RequirementComparisonRow = ({selectionRequirement, vendorRanking, isLast, softwareProductFinancials, projectManagementFactor, consultingFactor} : {selectionRequirement:any, vendorRanking:any, isLast:boolean, softwareProductFinancials:any, projectManagementFactor: number, consultingFactor: number}) => {
const [softwareFulfillments, setSoftwareFulfillments] = useState<any[]>();

    const reformatFulfillments = async () => {
        setSoftwareFulfillments(selectionRequirement.selectionSoftwareProductRequirementFulfillments.reduce((r:any, a:any) => {
            const selectionSoftwareProductId = a.selectionSoftwareProduct.id;
            r[selectionSoftwareProductId] = r[selectionSoftwareProductId] || [];
            r[selectionSoftwareProductId] = {comment:a.comment, score:a.score, customizingEffort:a.customizingEffort};
            return r;
        }, Object.create(null)));
    }

    useEffect(() => {reformatFulfillments()},[])

    if (typeof softwareFulfillments == "undefined") return <LoadingSpinner />
    const importance = selectionRequirement.importance;

    const allCustomizingEffortsZero = vendorRanking?.every((currentVendorRanking: any) => {
        const relevantFulfillment = softwareFulfillments[currentVendorRanking.id];
        return relevantFulfillment && !relevantFulfillment.customizingEffort;
    });

    if (allCustomizingEffortsZero) return <></>
    return (
        <tr className=''>
            <td className='sticky left-0 z-[2] bg-gray-50 h-24'>
                <div className={`h-full sm:w-[400px] md:w-[600px] flex items-center justify-between gap-4 bg-white px-8 ml-4 border-l ${isLast ? "rounded-bl-lg border-b" : ""}`}>
                    <p className='w-full text-wrap line-clamp-2'>{selectionRequirement.requirement}</p>
                    <div className='flex space-x-1 items-center group'>
                        <div
                        className={`w-6 h-6 ${(33 <= Math.ceil(importance)) ? ("bg-primary text-white"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full flex items-center justify-center font-bold`}>C</div>
                        <div
                        className={`w-6 h-6 ${(66 <= Math.ceil(importance)) ? ("bg-primary text-white"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full flex items-center justify-center font-bold`}>B</div>
                        <div
                        className={`w-6 h-6 ${(100 <= Math.ceil(importance)) ? ("bg-primary text-white"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full flex items-center justify-center font-bold`}>A</div>
                    </div>
                </div>
            </td>

            {
                vendorRanking?.map((currentVendorRanking:any, index:number) => {
                    const relevantFulfillment = softwareFulfillments[currentVendorRanking.id];
                    return (
                        <VendorFulfillmentCell relevantFulfillment={relevantFulfillment} isLastRow={isLast} isLastCell={index+1 == vendorRanking.length} softwareProductFinancials={softwareProductFinancials[currentVendorRanking.id]} projectManagementFactor={projectManagementFactor} consultingFactor={consultingFactor} /> 
                    )
                })
            }
        </tr>
    )
}

const VendorFulfillmentCell = ({relevantFulfillment, isLastRow, isLastCell, softwareProductFinancials, projectManagementFactor, consultingFactor} : {relevantFulfillment:any, isLastRow:boolean, isLastCell:boolean, softwareProductFinancials:any, projectManagementFactor: number, consultingFactor: number}) => {
    
    const [factoredCosts, setFactoredCosts] = useState(0);
    
    const handleCalcCosts = () => {
        if (typeof relevantFulfillment !== "undefined" && relevantFulfillment.score == 1) {
            const rawCosts = relevantFulfillment.customizingEffort * softwareProductFinancials.dailyRateDev;
            setFactoredCosts(rawCosts + (rawCosts * projectManagementFactor) + (rawCosts * consultingFactor));
        }
    }

    useEffect(() => handleCalcCosts(), [projectManagementFactor, consultingFactor, relevantFulfillment])

    return (
        <td className={`h-24 transition-all relative`}>
            <div className={`h-full bg-white flex items-center w-40 justify-center ${isLastRow ? "border-b" : ""} ${isLastCell ? "border-r" : ""} ${isLastRow && isLastCell? "rounded-br-lg" : ""}`}>
                {
                    typeof relevantFulfillment === "undefined" ? 
                    <div title="Not evaluated" className={`w-8 h-8 border rounded-full p-1 text-white bg-gray-400`}><QuestionmarkIcon /></div> :
                    factoredCosts.toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials.preferredCurrency })
                }
            </div>
        </td>
    )
}

const SettingsCustomizingCosts = ({projectManagementFactor, setProjectManagementFactor, consultingFactor, setConsultingFactor} : {projectManagementFactor: number, setProjectManagementFactor: React.Dispatch<React.SetStateAction<number>>, consultingFactor: number, setConsultingFactor: React.Dispatch<React.SetStateAction<number>>}) => {
    const { t } = useTranslation();
    const {selectionId} = useOutletContext<any>();
    const [initialProjectManagementFactor, setInitialProjectManagementFactor] = useState(projectManagementFactor);
    const [initialConsultingFactor, setInitialConsultingFactor] = useState(consultingFactor);

    const [internalProjectManagementFactor, setInternalProjectManagementFactor] = useState(projectManagementFactor);
    const [internalConsultingFactor, setInternalConsultingFactor] = useState(consultingFactor);
    
    useEffect(() => {
        setProjectManagementFactor(internalProjectManagementFactor);
    }, [internalProjectManagementFactor])
    
    useEffect(() => {
        setConsultingFactor(internalConsultingFactor);
    }, [internalConsultingFactor])

    const handleSaveConsultingFactor = () => {
        if (initialConsultingFactor == internalConsultingFactor) return;
        updateSelectionData(selectionId, {consultingFactor: internalConsultingFactor*100})
        setInitialConsultingFactor(internalConsultingFactor);
    }
    
    const handleSaveProjectManagementFactor = () => {
        if (initialProjectManagementFactor == internalProjectManagementFactor) return;
        updateSelectionData(selectionId, {projectManagementFactor: internalProjectManagementFactor*100})
        setInitialProjectManagementFactor(internalProjectManagementFactor);
    }

    // console.log(internalProjectManagementFactor, internalConsultingFactor)

    return (
        <div className='flex flex-col p-4 w-[33vw]'>
            <p className='text-xl'>{t('customizationCosts.settingsTitle')}</p>

            <div className='flex flex-col gap-4 mt-4'>
                <div>
                    <div className='flex'>
                        <p className='text-md font-semibold mb-2'>{t('customizationCosts.projectManagementFactor')}</p>
                        {
                            initialProjectManagementFactor == internalProjectManagementFactor ? <></> : 
                            <div onClick={() => handleSaveProjectManagementFactor()} className='mx-4 rounded-full bg-primary font-semibold text-white h-fit px-2 cursor-pointer'>{t('common.save')}</div>
                        }
                    </div>
                    <div className='grid grid-cols-4 gap-4'>
                        <Slider factor={internalProjectManagementFactor} setFactor={setInternalProjectManagementFactor} />
                        <input type="text" value={internalProjectManagementFactor} onChange={(e) => setInternalProjectManagementFactor(Number(e.target.value))} min={0} className='border border-gray-300 rounded-lg bg-gray-50 font-normal p-1' />
                    </div>
                </div>
                
                <div>
                    <div className='flex'>
                        <p className='text-md font-semibold mb-2'>{t('customizationCosts.consultingFactor')}</p>
                        {
                            initialConsultingFactor == internalConsultingFactor ? <></> : 
                            <div onClick={() => handleSaveConsultingFactor()} className='mx-4 rounded-full bg-primary font-semibold text-white h-fit px-2 cursor-pointer'>{t('common.save')}</div>
                        }
                    </div>
                    <div className='grid grid-cols-4 gap-4'>
                        <Slider factor={internalConsultingFactor} setFactor={setInternalConsultingFactor} />
                        <input type="text" value={internalConsultingFactor} onChange={(e) => setInternalConsultingFactor(Number(e.target.value))} min={0} className='border border-gray-300 rounded-lg bg-gray-50 font-normal p-1' />
                    </div>
                </div>
            </div>
        </div>
    )
}

const Slider = ({factor, setFactor} : {factor: number, setFactor: React.Dispatch<React.SetStateAction<number>>}) => {
  const [maxValue, setMaxValue] = useState(2);

  useEffect(() => {
    if (typeof factor === "undefined" || factor <= maxValue) return;
    setMaxValue(factor)

  }, [factor])

  return (
    <div className="relative mb-6 col-span-3">
      <input  type="range" value={factor} onChange={(e) => setFactor(Number(e.target.value))} min={0} max={maxValue} step={0.1} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer" />
      <span className="text-sm text-gray-500 absolute start-0 -bottom-6">0</span>
      <span className="text-sm text-gray-500 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">{maxValue/2}</span>
      <span className="text-sm text-gray-500 absolute end-0 -bottom-6">{maxValue}</span>
    </div>
  )
}

export default ComparisonCostsCustomization