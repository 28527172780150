import React, { useEffect, useState } from 'react'
import TableRow from '../../../../../../components/TableRow'
import WarningIcon from '../../../../../../assets/img/icons/WarningIcon'
import QuickMenu from '../../../../../../components/QuickMenu'
import TrashIcon from '../../../../../../assets/img/icons/TrashIcon'
import { UpdateSelectionRequirementKeywords, updateSelectionRequirement, vendorEvaluateRequirement } from '../../../../../../data/SelectionPortalRequests'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store'
import { CheckmarkIcon, CrossIcon, InfoIcon } from 'swap-frontend-library'
import GearIcon from '../../../../../../assets/img/icons/GearIcon'
import TextInput from '../../../../../../components/TextInput'
import { useTranslation } from 'react-i18next'

interface props {
    index : number,
    selectionRequirement : any,
    vendorFulfillment : any,
    openMenuRequirement : string | undefined,
    setOpenMenuRequirement : React.Dispatch<React.SetStateAction<string | undefined>>,
    handleOpenRequirement : (a:string, b:string) => void,
    handleSetRequirementStatus : (a:string, b:string) => void,
    handleDeleteRequirement : (a:string) => void,
    handleFetchRequirements: () => void,
}

const RequirementTableRow = ({index, selectionRequirement, vendorFulfillment, openMenuRequirement, setOpenMenuRequirement, handleOpenRequirement, handleSetRequirementStatus, handleDeleteRequirement, handleFetchRequirements} : props) => {
    const role = useSelector((state: RootState) => state.user.role);
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const { t } = useTranslation();
    
    const [importance, setImportance] = useState(0);
    const [isTemplateScore, setIsTemplateScore] = useState(false);
    
    const [prevFulfillment, setPrevFulfillment] = useState<number | null>(null);
    const [fulfillment, setFulfillment] = useState<number | null>(null);
    const [comment, setComment] = useState<string | null>(null);
    const [customizingEffort, setCustomizingEffort] = useState<number | null>(null);
    
    useEffect(() => {
        setImportance(selectionRequirement.selectionRequirements_importance);
        setIsTemplateScore(vendorFulfillment.fulfillment?.templateFulfillmentPlatformLayer != null || vendorFulfillment.fulfillment?.templateFulfillmentCustomerLayer != null);
        
        const initialScore = typeof vendorFulfillment.fulfillment?.score !== "undefined" ? vendorFulfillment.fulfillment?.score : null;
        setPrevFulfillment(initialScore);
        setFulfillment(initialScore);
        setCustomizingEffort(vendorFulfillment.fulfillment?.customizingEffort);
        setComment(vendorFulfillment.fulfillment?.comment);
    } , [selectionRequirement])

    const handleSetImportance = async ({title, description, category, importance} :  UpdateSelectionRequirementKeywords) => {
        const response = await updateSelectionRequirement(selectionRequirement.selectionRequirements_id, title, description, category, importance)
        if (response.ok) {
            // setImportance(response.data.data.importance);
            handleFetchRequirements();
        }
    }
    
    const handleSetVendorFulfillment = async () => {
        const response = await vendorEvaluateRequirement(selectionRequirement.selectionRequirements_id, fulfillment, customizingEffort, comment)
        if (response.ok) {
            handleFetchRequirements();
        }
    }

    useEffect(() => {
        if (fulfillment != prevFulfillment) {
            handleSetVendorFulfillment()
            setPrevFulfillment(fulfillment);
            setIsTemplateScore(false);
        };
    }, [fulfillment])

    const basicRowItems = [index, selectionRequirement.selectionRequirements_requirement];

    let totalRowItems : any[] = [];
    let maxRowWidths : any[] = ["","max-w-[500px]","",""];
    if (role == "guest_vendor") {
        maxRowWidths[3] = "w-full"
        totalRowItems = [...basicRowItems, 
        <div className='flex items-center gap-2'>
            <div className='relative flex items-center'>
                <div className='absolute z-[2] flex gap-4 relative items-center'>
                    <div onClick={(e) => {e.stopPropagation(); if (!vendorFulfillment.finalized) setFulfillment(prev => prev == 0 ? null : 0)}} title={t('requirementTableRow.notFulfilled')} className={`w-8 h-8 border rounded-full p-1 ${fulfillment == 0 ? "text-white bg-red-200" : "bg-white border-red-200/50 text-red-200/50"}`}><CrossIcon /></div>
                    <div onClick={(e) => {e.stopPropagation(); if (!vendorFulfillment.finalized) setFulfillment(prev => prev == 1 ? null : 1)}} title={t('requirementTableRow.fulfilledWithCustomizing')} className={`w-8 h-8 border rounded-full p-1 ${fulfillment == 1 ? "text-white bg-amber-300" : "bg-white border-amber-300/50 text-amber-300/50"}`}><GearIcon /></div>
                    <div onClick={(e) => {e.stopPropagation(); if (!vendorFulfillment.finalized) setFulfillment(prev => prev == 3 ? null : 3)}} title={t('requirementTableRow.fullyFulfilled')} className={`w-8 h-8 border rounded-full p-1 ${fulfillment == 3 ? "text-white bg-lime-400" : "bg-white border-lime-400/50 text-lime-400/50"}`}><CheckmarkIcon /></div>
                </div>

            </div>
            <div title={t('requirementTableRow.templateScoreInfo')} className='w-5 h-5 text-gray-400'>
                {
                    isTemplateScore ? 
                    <InfoIcon /> :
                    <></>
                }
            </div>
        </div>,
        <div>
            {
                fulfillment == 1 ?
                <div className='w-32 p-1 px-2 flex items-center gap-2 border border-gray-300 rounded-lg' onClick={(e) => e.stopPropagation()}>
                    <input className='bg-gray-50 text-gray-900 text-sm p-1 block w-full text-center' type='number' value={customizingEffort || 0} min={0} onChange={(e) => setCustomizingEffort(Number(e.target.value))} onBlur={() => handleSetVendorFulfillment()} />
                    <p className=''>{t('requirementTableRow.days')}</p>
                </div> :
                <></>
            }
        </div>,
        <div onClick={(e) => e.stopPropagation()} className='w-full p-1'>{(vendorFulfillment.finalized) ? <div className='w-full text-gray-500'>{comment}</div> : <TextInput placeholder={t('requirementTableRow.commentPlaceholder')} textValue={comment} setTextValue={setComment} onBlur={() => handleSetVendorFulfillment()} />}</div>,
        <div></div>
    ]
    } else if (role == "customer" || role == "platform_manager" || role == "guest_seeker") {
        totalRowItems = [...basicRowItems, 
        <div className='flex space-x-1 items-center group'>
            <div onClick={(e) => {e.stopPropagation(); if (projectRole == "selection_admin") {handleSetImportance({importance: 33});}}}
            className={`w-6 h-6 ${(33 <= Math.ceil(importance)) ? ("bg-primary text-white"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full flex items-center justify-center font-bold`}>C</div>
            <div onClick={(e) => {e.stopPropagation(); if (projectRole == "selection_admin") {handleSetImportance({importance: 66});}}}
            className={`w-6 h-6 ${(66 <= Math.ceil(importance)) ? ("bg-primary text-white"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full flex items-center justify-center font-bold`}>B</div>
            <div onClick={(e) => {e.stopPropagation(); if (projectRole == "selection_admin") {handleSetImportance({importance: 100});}}}
            className={`w-6 h-6 ${(100 <= Math.ceil(importance)) ? ("bg-primary text-white"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full flex items-center justify-center font-bold`}>A</div>
            {/* {
                importance >= 33 ?
                <div className='font-normal text-gray-900 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                    {
                        importance >= 100 ? "A-Priority" :
                        importance >= 66 ? "B-Priority" :
                        importance >= 33 ? "C-Priority" :
                        ""
                    }
                </div> :
                <></>
            } */}
        </div>, <div className='w-full flex justify-center'>
        {
            (selectionRequirement.selectionRequirements_requirementStatus == "warning") ? (
                <div className='group w-full h-6 text-amber-400 flex items-center justify-center'>
                    <WarningIcon iconColor='currentColor' />
                    <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                        {t('requirementTableRow.warningMessage')}
                    </div>
                </div>
            ) : (selectionRequirement.selectionRequirements_requirementStatus == "auto_completed") ? (
                <div className='group w-full h-6 text-green-400 font-bold flex items-center justify-center'>
                    <span className='w-6 h-6 flex'>(<CheckmarkIcon />)</span>
                    <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                        {t('requirementTableRow.autoCompletedMessage')}
                    </div>
                </div>
            ) : (selectionRequirement.selectionRequirements_requirementStatus == "completed") ? (
                <div className='group w-full h-6 text-green-400 flex items-center justify-center'>
                    <div className='w-6 h-6'><CheckmarkIcon /></div>
                    <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                        {t('requirementTableRow.completedMessage')}
                    </div>
                </div>
            ) : (selectionRequirement.selectionRequirements_requirementStatus == "archived") ? (
                <div className='group w-full h-6 text-red-400 flex items-center justify-center'>
                    <div className='h-8 w-8'><CrossIcon /></div>
                    <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                        {t('requirementTableRow.archivedMessage')}
                    </div>
                </div>
            ) : (<></>)
        } </div>, 
        
        projectRole == "selection_admin" ? <QuickMenu id={selectionRequirement.selectionRequirements_id} openMenuForId={openMenuRequirement} setOpenMenuForId={setOpenMenuRequirement} menuItems={[
            {label: t("requirement.completed"), value: "completed", icon: <div className='text-green-400 w-6 h-6 flex'><CheckmarkIcon /></div>, function: handleSetRequirementStatus },
            {label: t("requirement.warning"), value: "warning", icon: <div className='text-amber-400'><WarningIcon iconColor='currentColor' /></div>, function: handleSetRequirementStatus },
            {label: t("requirement.archived"), value: "archived", icon: <div className='text-red-400 w-6 h-6'><CrossIcon /></div>, function: handleSetRequirementStatus },
            {label: t("delete"), value: "delete", icon: <div className='text-red-400'><TrashIcon /></div>, function: handleDeleteRequirement }
        ]} /> : <></>]
    }

  return (
    <tbody
    id={selectionRequirement.selectionRequirements_id} className={`cursor-pointer ${selectionRequirement.selectionRequirements_requirementStatus == "archived" ? "opacity-70 hover:opacity-100 transitions-all duration-100" : ""}`} onClick={() => handleOpenRequirement(selectionRequirement.selectionRequirements_id, selectionRequirement.selectionRequirements_requirement)}>
        <TableRow maxRowWidths={maxRowWidths}
        rowItems={totalRowItems} />
    </tbody>
  )
}

export default RequirementTableRow