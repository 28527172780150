import React, { useEffect, useRef } from 'react'
import CrossIcon from '../../../../../../../../assets/img/icons/CrossIcon'
import { HighlightArea } from '@react-pdf-viewer/highlight'
import { useTranslation } from 'react-i18next' // Added import

interface props {
    comment: any,
    jumpToHighlight: (comment: any) => void,
    clickedHighlightComment: any,
}

const DocumentComment = ({comment, jumpToHighlight, clickedHighlightComment}: props) => {
    const { t } = useTranslation(); // Added useTranslation hook
    const ref = useRef<any>();
    useEffect(() => {
        if (typeof clickedHighlightComment !== "undefined" && clickedHighlightComment.id == comment.id && typeof ref !== "undefined") {
            ref.current.scrollIntoView({behaviour: "smooth"});
        }
    }, [clickedHighlightComment])

    const dateParsed : Date = new Date(comment.createdDate);
    const dateString : string = `${dateParsed.getDate().toString().padStart(2, '0')}.${(dateParsed.getMonth()+1).toString().padStart(2, '0')}.${dateParsed.getFullYear()} ${dateParsed.getHours().toString().padStart(2, '0')}:${dateParsed.getMinutes().toString().padStart(2, '0')}`;

    return (
        <div ref={ref}
        onClick={() => {if (comment.highlightAreas.length > 0) jumpToHighlight(comment)}}
        className={`rounded-lg z-[20] cursor-pointer shadow border-2 hover:bg-gray-200 w-full bg-gray-300 mb-2 p-2 ${(typeof clickedHighlightComment !== "undefined" && clickedHighlightComment.id == comment.id) ? "border-primary" : "border-gray-400"}`}>
            <div className='flex text-gray-600 text-sm p-1 bg-gray-100 border border-gray-200 rounded-lg italic justify-between'>
                <div className='line-clamp-4'>
                    {comment.quote}
                </div>
            </div>

            {
                (typeof comment !== "undefined") ? (
                    <div className='mt-2'>
                        <input 
                        // onChange={(event) => {setProductName(event.target.value)}}
                        placeholder={t('documentComment.placeholderComment')} value={comment.comment} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  required></input>
                    </div>
                ) : (<></>)
            }

            {(typeof clickedHighlightComment !== "undefined" && clickedHighlightComment.id == comment.id) ? (
                <div className='mt-2 flex items-center justify-between'>
                    <div>
                        <div>
                            {comment.user.email}
                        </div>
                        <div>
                            {dateString}
                        </div>
                    </div>
                    {/* <div className='w-8 h-8 p-1 rounded-full cursor-pointer text-red-500 hover:bg-gray-300'>
                        <CrossIcon iconColor='currentColor'/>
                    </div> */}
                </div>
            ) : (<></>)}

        </div>
  )
}

export default DocumentComment