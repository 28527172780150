import React, { useEffect } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

const Index = () => {
  const outletContext = useOutletContext<any>();
  const { selectionId, setSelectedSelectionPhase } = useOutletContext<any>();
  useEffect(() => {
    setSelectedSelectionPhase("");
  }, [])
  return (
    <Outlet context={outletContext}/>
  )
}

export default Index