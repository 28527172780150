import React from 'react'
import { CheckmarkIcon, WarningIcon } from 'swap-frontend-library'
import { useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';

const PlatformMessage = ({ platformMessage }: { platformMessage?: string }) => {
    const { t } = useTranslation();
    const [acknowledged, setAcknowledged] = useState(false)
    const cookies = new Cookies();

    useEffect(() => {
        const isAcknowledged = cookies.get('platformMessageAcknowledged') === 'true'
        if (isAcknowledged) {
            setAcknowledged(true)
        }
    }, [])

    const handleAcknowledge = () => {
        // Expires in one day
        cookies.set('platformMessageAcknowledged', true, { path: '/', expires: new Date(Date.now()+(1*86400000)) });
        setAcknowledged(true)
    }

    if (acknowledged || typeof platformMessage === "undefined") return <></>

    return (
        <div className='absolute top-5 right-5 bg-amber-400/50 backdrop-blur-lg rounded-lg xl:rounded-full border-2 border-amber-400 shadow-lg w-1/2 p-8 z-[300] flex items-center gap-8'>
            <div className='w-8 h-8 text-amber-700'><WarningIcon /></div>
            <div className='w-full'>
                <p className='font-light text-sm text-gray-800'>{platformMessage}</p>
                
                <div className='cursor-pointer space-x-2 mt-4 flex w-fit group' onClick={handleAcknowledge}>
                    <div className='w-6 h-6 rounded-full bg-amber-400/50 border border-amber-700 p-1 text-amber-700 group-hover:bg-green-400/50 group-hover:text-green-700 group-hover:border-green-700 transition-all'>
                        <CheckmarkIcon />
                    </div>
                    <p className='font-semibold text-amber-700'>{t('platformMessage.acknowledge')}</p>
                </div>
            </div>
        </div>
    )
}

export default PlatformMessage