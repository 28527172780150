import React, { useState } from 'react'
import TopNRequirementRow from '../components/TopNRequirementRow';
import { ArrowDownIcon, ArrowRightIcon, CheckoutIcon } from 'swap-frontend-library';
import FormModal from '../../../../../../../../components/FormModal';
import PercentageCircle from '../../../../../../../../components/PercentageCircle';

interface props {
}

const FunctionalAnalysis = ({ requirementOverview, requirementsRaw } : {requirementOverview : any, requirementsRaw?: any[]}) => {
    const [requirementOverviewVisible, setRequirementOverviewVisible] = useState(true);
    const [selectedRequirementCategoryIndex, setSelectedRequirementCategoryIndex] = useState(0);
    const [expertViewOpen, setExpertViewOpen] = useState(false);

    return (
    <div 
    // onClick={() => {!requirementOverviewVisible ? setRequirementOverviewVisible(true) : null}} 
    className={`relative w-full p-10 max-sm:p-4 shadow border rounded-lg bg-white expand-down-large ${requirementOverviewVisible ? "" : "cursor-pointer"}`}>
        <div 
        onClick={() => setExpertViewOpen(true)}
        className='cursor-pointer absolute right-5 -top-3 rounded-full border-blue-700/90 text-blue-700 hover:to-blue-400 hover:from-[#57e3ff] bg-gradient-to-br from-blue-400 to-[#57e3ff] border-2 shadow shadow-primary/50 font-semibold px-2 transition-all'>Expert insights</div>
        <div className='relative flex items-center justify-between max-md:mt-8'>
            <p className='font-semibold text-xl'>Functional requirements</p>
            <div onClick={() => setRequirementOverviewVisible(prev => !prev)} className={`cursor-pointer w-8 h-8 text-gray-300 ${requirementOverviewVisible ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
        </div>

        {
            !requirementOverviewVisible ? <></> : (
            <div className='mt-8 max-md:my-8'>
                <div className='w-full md:p-4 rounded-b'>
                    <div className='w-full md:p-10 mb-4 shadow rounded-2xl bg-white'>
                        <p className='font-semibold text-lg'>Important categories</p>
                        <div className='flex max-md:flex-col gap-4'>
                            <div className="w-full p-10 max-sm:p-4">
                                <div className='space-y-2'>
                                    {
                                        requirementOverview.top_categories.map((item : any, index : number) => (
                                            <div key={index} onClick={() => setSelectedRequirementCategoryIndex(index)} className={`relative w-full flex flex-col justify-center items-center h-16 cursor-pointer`}>
                                                <div className={`${requirementOverview.top_categories[selectedRequirementCategoryIndex][0] == item[0] ? "bg-primary border-transparent" : "bg-primary/30 border-primary"} h-full border rounded`} style={{width: `${item[1]}%`}}></div>
                                                <div className={`absolute text-lg font-semibold text-gray-900 bg-white/30 backdrop-blur p-1 rounded border text-center`}>{item[0]}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className='relative flex items-center justify-center grow-y max-md:py-4 py-16'>
                                <div className='w-[2px] h-full bg-primary/50'></div>
                                <div className='absolute w-8 h-16 bg-white text-primary py-4 max-md:rotate-90'><ArrowRightIcon /></div>
                            </div>
                            <div className="w-full p-10 max-sm:p-4">
                                <div className='space-y-4'>
                                    {
                                        requirementOverview.top_categories_2.filter((item :any) => item[0] == requirementOverview.top_categories[selectedRequirementCategoryIndex][0]).map((item : any, index : number) => (
                                            <div key={index} className='border border-primary rounded p-4 text-lg font-semibold text-gray-900 '>
                                                <p className='text-3xl text-primary font-bold italic'>{item[2]} %</p>
                                                <p className='truncate'>{item[1]}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full p-10 max-md:p-4 max-md:py-8 shadow rounded-2xl bg-white">
                        <p className='font-semibold text-lg mb-8'>Top-5 requirements</p>
                        <div className='divide-y'>
                            {
                                requirementOverview.top_n_requirements.map((item : any, index : number) => (
                                    <div key={index}>
                                        <TopNRequirementRow item={item} />
                                    </div>
                                ))
                            }
                        </div>
                        <div className='relative border-t pt-4'>
                            <div className='cursor-pointer group absolute w-full h-full flex items-center justify-center scale-[105%] backdrop-blur bg-white/50 hover:bg-primary text-primary3 hover:text-white transition-all border rounded'>
                                <div className='text-2xl flex items-center gap-4 font-semibold'>
                                    <p className='text-center'>View all</p>
                                </div>                                                        
                            </div>

                            <div className='py-6 h-16 flex'>
                                <p className='text-3xl text-nowrap text-primary font-bold italic'>69 %</p>
                                <div className='ml-5 text-lg font-semibold text-gray-900 line-clamp-2'>
                                    Please purchase the full report to see this complete list of your requirements.
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            )
        }

        {
            expertViewOpen && typeof requirementsRaw !== "undefined" ?
            <FormModal isOpen={expertViewOpen} setOpen={setExpertViewOpen} size='large' additionalStyles='py-8 px-4 bg-gradient-to-br from-white/30 to-[#57e3ff]/10 border-2 border-primary'>
                <>
                    <div 
                    className='cursor-pointer absolute left-5 -top-3 rounded-full border-blue-700 text-blue-700 hover:to-blue-700 hover:from-[#57e3ff] bg-gradient-to-br from-blue-400 to-[#57e3ff] border-2 shadow shadow-primary/50 font-semibold px-2 transition-all'>Expert insights</div>
                    <div className='grid grid-cols-1 gap-4 overflow-y-hidden'>
                        {
                            requirementsRaw.sort((a:any, b:any) => b.confidence - a.confidence).map((item:any, index:number) => {
                                return (
                                    <div className='border rounded p-3 '>
                                        <div className='flex gap-2 items-center'>
                                            <p className='text-lg font-light truncate'>{item.category_1}</p>
                                            <div className='w-3 h-3'><ArrowRightIcon /></div>
                                            <p className='text-lg font-light truncate'>{item.category_2}</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <div className='w-full truncate'>
                                                <p className='text-lg font-semibold truncate w-full'>{item.requirement}</p>
                                            </div>
                                            <div className='w-20'>
                                                <PercentageCircle rating={item.confidence < 1 ? Math.round(item.confidence*100) : item.confidence} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            </FormModal> :
            <></>
        }
    </div>
  )
}

export default FunctionalAnalysis