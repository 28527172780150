import React, { useState } from 'react'
import TypingText from '../components/TypingText'
import { ArrowDownIcon } from 'swap-frontend-library';

const CompanyFactAnalysis = ({ companyFacts, companyLogos, queryUrl, open, typing, plainStyle } : { companyFacts: any, companyLogos: string[] | undefined, queryUrl: string, open? : boolean | null, typing?: boolean, plainStyle?: boolean }) => {
    const [visible, setVisible] = useState(typeof open !== "undefined" && open != null ? open : true);

    return (
        <div 
        // onClick={(e) => {e.stopPropagation(); !visible && open != null ? setVisible(true) : null}} 
        className={`w-full h-fit p-10 max-md:px-8 max-md:py-10 ${ plainStyle ? "" : "shadow border bg-white rounded-lg" } expand-down ${visible ? "" : "cursor-pointer"}`}>
            <div className='relative flex items-center justify-between'>
                <p className='font-semibold text-xl'>Your company facts</p>
                { 
                    (open != null || typeof open === "undefined") ? (
                        <div onClick={() => setVisible(prev => !prev)} className={`cursor-pointer w-8 h-8 text-gray-300 ${visible ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
                    ) : (
                        <></>
                    ) 
                }
            </div>

            {
                !visible ? <></> : (
                    <div className='w-full h-full expand-down mt-4'>
                        { typeof companyLogos === "undefined"  || companyLogos == null || companyLogos.length == 0 ? <></> : (
                            <div className='mb-8 w-full flex items-center justify-start bg-primary p-4 bg-opacity-80 rounded-[80px]'><img className='max-h-32' src={"https://"+companyLogos[0]} /></div>
                        )}
                        { companyFacts.companyName == "NA" || companyFacts.companyName == null ? <></> : (
                            // Company name
                            <div>
                                <p className='mb-6 font-semibold text-2xl'><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyFacts.companyName) ? companyFacts.companyName.join(", ") : companyFacts.companyName} /></p>
                            </div>
                        )}
                        <div className='grid-cols-2 gap-2 grid'>
                            { companyFacts.phoneNumber == "NA" || companyFacts.phoneNumber == null ? <></> : (
                                // Tel
                                <div className=''>
                                    <p className='font-semibold'>Phone</p>
                                    <p className=''><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyFacts.phoneNumber) ? companyFacts.phoneNumber.join(", ") : companyFacts.phoneNumber} /></p>
                                </div>
                            )}
                            { companyFacts.emailAddress == "NA"  || companyFacts.emailAddress == null || companyFacts.emailAddress == "[email protected]" || companyFacts.emailAddress == "[email protected]"  ? <></> : (
                                // Mail
                                <div className=''>
                                    <p className='font-semibold'>E-Mail</p>
                                    <a href={`mailto:${Array.isArray(companyFacts.emailAddress) ? companyFacts.emailAddress[0] : companyFacts.emailAddress}`} className='underline underline-offset-2'><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyFacts.emailAddress) ? companyFacts.emailAddress[0] : companyFacts.emailAddress} /></a>
                                </div>
                            )}
                            <div className=''>
                                <p className='font-semibold'>Website</p>
                                <a href={queryUrl} target='_blank' className='underline underline-offset-2 w-16 overflow-hidden truncate'><TypingText style='truncate' disabled={!typing} typingSpeed={30} text={queryUrl} /></a>
                            </div>
                            { companyFacts.companyAddress == "NA" || companyFacts.companyAddress == null ? <></> : (
                                // Adress
                                <div className=''>
                                    <p className='font-semibold'>Address</p>
                                    <p className=''><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyFacts.companyAddress) ? companyFacts.companyAddress.join(", ") : companyFacts.companyAddress} /></p>
                                </div>
                            )}
                            { companyFacts.managementBoard == "NA" || companyFacts.managementBoard == null ? <></> : (
                                // CEO
                                <div className=''>
                                    <p className='font-semibold'>Management</p>
                                    <p className=''><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyFacts.managementBoard) ? companyFacts.managementBoard.join(", ") : companyFacts.managementBoard} /></p>
                                </div>
                            )}
                            { companyFacts.registryId == "NA" || companyFacts.registryId == null ? <></> : (
                                // HRB
                                <div className=''>
                                    <p className='font-semibold'>Registration</p>
                                    <p className=''><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyFacts.registryId) ? companyFacts.registryId.join(", ") : companyFacts.registryId} /></p>
                                </div>
                            )}
                            { companyFacts.districCourt == "NA" || companyFacts.districtCourt == null ? <></> : (
                                // 
                                <div className=''>
                                    <p className='font-semibold'>Local court</p>
                                    <p className=''><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyFacts.districCourt) ? companyFacts.districCourt.join(", ") : companyFacts.districCourt} /></p>
                                </div>
                            )}
                            { companyFacts.taxId == "NA" || companyFacts.taxId == null ? <></> : (
                                // 
                                <div className=''>
                                    <p className='font-semibold'>Tax number</p>
                                    <p className=''><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyFacts.taxId) ? companyFacts.taxId.join(", ") : companyFacts.taxId} /></p>
                                </div>
                            )}
                        </div>
                    </div>
                ) 
            }

        </div>
  )
}

export default CompanyFactAnalysis