import React from 'react';
import './index.css';
//<link rel="stylesheet" href="assets/css/output.css">
import './assets/fonts/general-sans/font-styles.css';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import {
  RouterProvider,
} from "react-router-dom";

import router from './Routes';
import "./18n";
import SocketInjector from './_socket/SocketInjector';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <SocketInjector />
      <RouterProvider router={router} />
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
