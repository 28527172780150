import React, { useEffect, useState } from 'react'
import TextInput from '../../../../../../../components/TextInput'
import MainButton from '../../../../../../../components/MainButton'
import { getRequirementTemplates, getSelectionRequirements } from '../../../../../../../data/SelectionPortalRequests';
import ArrowRightIcon from '../../../../../../../assets/img/icons/ArrowRightIcon';
import Fuse from 'fuse.js';
import PageLogo from '../../../../../../../components/PageLogo';
import RequirementInputWizardRow from './RequirementInputWizardRow';
import RequirementInputWizardModal from './RequirementInputWizardModal';
import { useTranslation } from 'react-i18next'; // Import useTranslation

interface props {
    selectionId: string
    newRequirementTitle: string | null,
    setNewDescription: React.Dispatch<React.SetStateAction<string | null>>;
    setNewCategory1: React.Dispatch<React.SetStateAction<string>>;
    setNewCategory2: React.Dispatch<React.SetStateAction<string>>;
    setNewCategory3: React.Dispatch<React.SetStateAction<string>>;
    setNewRequirementTitle: React.Dispatch<React.SetStateAction<string | null>>;
    setNewRequirementStaticRequirementId: React.Dispatch<React.SetStateAction<string | null>>;
    setExistingSelectinRequirementId: React.Dispatch<React.SetStateAction<string | null>>;
}

const RequirementInputWizard = ({selectionId, newRequirementTitle, setNewRequirementTitle, setNewDescription, setNewRequirementStaticRequirementId, setExistingSelectinRequirementId, setNewCategory1, setNewCategory2, setNewCategory3} : props) => {
    const { t } = useTranslation(); // Initialize t function
    const [requirementTemplatesWizardOpen, setRequirementTemplatesWizardOpen] = useState(false);
    const [requirementTemplatesWizardModelOpen, setRequirementTemplatesWizardModelOpen] = useState(false);
    const [requirementTemplates, setRequirementTemplates] = useState<any[]>([]);
    const [selectionRequirements, setSelectionRequirements] = useState<any[]>([]);

    const [uniqueRequirementCategories, setUniqueRequirementCategories] = useState<any>({});
    const [selectedRequirementTemplate, setSelectedRequirementTemplate] = useState<any>();

    useEffect(() => {
        handleFetchRequirementTemplates();
        handleFetchSelectionRequirements();
        handleConfigureFuseRequirementTemplates(); 
        handleConfigureFuseSelectionRequirements();
    }, [])
    
    useEffect(() => {
        if (typeof selectedRequirementTemplate === "undefined") return;
        setNewRequirementTitle(selectedRequirementTemplate.requirement);
        setNewDescription(selectedRequirementTemplate.description);
        setNewCategory1(selectedRequirementTemplate.category[0]);
        setNewCategory2(selectedRequirementTemplate.category[1]);
        setNewCategory3(selectedRequirementTemplate.category[2]);
        setNewRequirementStaticRequirementId(selectedRequirementTemplate.staticRequirementId);

        if (selectedRequirementTemplate.staticRequirementId == null) {
            setExistingSelectinRequirementId(selectedRequirementTemplate.existingSelectionRequirementId);
        }
    }, [selectedRequirementTemplate])

    const handleFetchSelectionRequirements = async () => {
        // console.log(selectionId)
        const data = await getSelectionRequirements(selectionId);
        const requirements = data.data.data.selectionRequirements.map((value : any) => {
            value.category = JSON.parse(value.selectionRequirements_category);
            value.requirement = value.selectionRequirements_requirement;
            value.description = value.selectionRequirements_description;
            value.id = value.selectionRequirements_id;
            value.archived = value.selectionRequirements_requirementStatus == "archived";
            return (value)
        })
        setSelectionRequirements(requirements);
    }

    const handleFetchRequirementTemplates = async () => {
        const data = await getRequirementTemplates(selectionId);
        const queriedRequirementTemplates = data.data.requirementTemplates.map((value : any) => {
            value.category = JSON.parse(value.category)
            return (value)
        })
        setRequirementTemplates(queriedRequirementTemplates);

        setUniqueRequirementCategories(data.data.uniqueCategories);
    }
    
    const handleSelectRequirementTemplate = (item:any) => {
        setSelectedRequirementTemplate({ requirement: item.requirement, description: item.description, category: item.category, staticRequirementId: item.staticRequirementId, existingSelectionRequirementId: item.existingSelectionRequirementId });
        handleInput(item.requirement);
    }

    const handleInput = (inputText: string) => {
        if (inputText != "") {setRequirementTemplatesWizardOpen(true)} else {setRequirementTemplatesWizardOpen(false)}
        handleInputRequirementTemplates(inputText);
        handleInputSelectionRequirements(inputText);
        setSearchText(inputText);

        setNewRequirementTitle(inputText);
    }

    // Fuzzy Search
    const [searchText, setSearchText] = useState("");

    // Fuzzy Search RequirementTemplates
    const [configuredFuseRequirementTemplates, setConfiguredFuseRequirementTemplates] = useState<Fuse<any>>();
    const [requirementTemplatesFiltered, setRequirementTemplatesFiltered] = useState<any[]>([]);

    const handleInputRequirementTemplates = (inputText : string) => {
        if (inputText == "") {setRequirementTemplatesFiltered(requirementTemplates); return;}
        if (typeof configuredFuseRequirementTemplates === "undefined") return;
        
        const filteredList = configuredFuseRequirementTemplates.search(inputText);
        setRequirementTemplatesFiltered(filteredList.map((item:any) => item.item));
    }

    const handleConfigureFuseRequirementTemplates = () => {
        const fuseOptions = {
          keys: ["requirement", "description"],
          threshold: .6
        }
        const fuse = new Fuse(requirementTemplates, fuseOptions);
        setConfiguredFuseRequirementTemplates(fuse);
    }

    useEffect(() => {handleConfigureFuseRequirementTemplates();}, [requirementTemplates])

    // Fuzzy Search SelectionRequirements
    const [configuredFuseSelectionRequirements, setConfiguredFuseSelectionRequirements] = useState<Fuse<any>>();
    const [selectionRequirementsFiltered, setSelectionRequirementsFiltered] = useState<any[]>([]);

    const handleInputSelectionRequirements = (inputText : string) => {
        setNewRequirementTitle(inputText);
        
        if (inputText == "") {setRequirementTemplatesFiltered(requirementTemplates); return;}
        if (typeof configuredFuseSelectionRequirements === "undefined") return;
        
        const filteredList = configuredFuseSelectionRequirements.search(inputText);
        setSelectionRequirementsFiltered(filteredList.map((item:any) => item.item));
    }
    
    const handleConfigureFuseSelectionRequirements = () => {
        const fuseOptions = {
          keys: ["requirement", "description"],
          threshold: .4
        }
        const fuse = new Fuse(selectionRequirements, fuseOptions);
        setConfiguredFuseSelectionRequirements(fuse);
    }

    useEffect(() => {handleConfigureFuseSelectionRequirements();}, [selectionRequirements])

    // console.log(requirementTemplatesFiltered);

    return (
    <div className='flex gap-4'>
        <div className='relative z-[2] grow'>
        {/* onBlur={() => setRequirementTemplatesOpen(false)} */}
            <TextInput placeholder={t('requirement.placeholder')} textValue={newRequirementTitle} setTextValue={handleInput} />
            {
                (typeof requirementTemplatesFiltered !== "undefined" && requirementTemplatesWizardOpen) ? (
                    <>
                    <div className='absolute w-full mt-2 z-[2] p-2 rounded-lg border border-primary shadow-lg bg-white'>
                        <div className='rlative max-h-[30rem] overflow-y-auto border rounded-lg bg-gray-50'>
                            {
                                requirementTemplatesFiltered.length > 0 ? (
                                    <>
                                    <div className='mb-2 sticky top-0 bg-white shadow-md border-b grow h-11 text-lg font-semibold px-2 flex items-center'>{t('requirement.similarRequirements')}</div>
                                    {
                                        selectionRequirementsFiltered.slice(0,10).map((item:any, index:number) => {
                
                                            return (
                                                <RequirementInputWizardRow item={item} handleSelectRequirementTemplate={handleSelectRequirementTemplate} isSelectionRequirement={true} />
                                            )
                                        })
                                    }
                                    <div className='my-2 sticky top-0 bg-white shadow-md border-b border-t grow h-11 text-lg font-semibold px-2 flex items-center'>{t('requirement.matchingTemplates')}</div>
                                    {
                                        requirementTemplatesFiltered.slice(0,10).map((item:any, index:number) => {
                
                                            return (
                                                <RequirementInputWizardRow item={item} handleSelectRequirementTemplate={handleSelectRequirementTemplate} isSelectionRequirement={false} />
                                            )
                                        })
                                    }
                                    </>
                                ) : (
                                    <div className='text-center font-semibold text-gray-400'>
                                        <p>{t('requirement.noTemplatesFound')}</p>
                                        <p className="underline cursor-pointer hover:text-primary transition-all" onClick={() => setRequirementTemplatesWizardOpen(false)}>{t('requirement.close')}</p>

                                        {/* <div className='p-2 hover:text-primary text-gray-900 hover:bg-primary2 rounded-lg cursor-pointer ' onClick={() => handleSelectRequirementTemplate(item)}>
                                            <p className='w-full px-2 text-md truncate'>{newRequirementTitle}</p>
                                        </div> */}
                                    </div>
                                )
                            }
                        </div>
                        {/* {requirementTemplatesFiltered.length} */}
                        <div className='flex items-center justify-end text-right text-primary px-4 border-t text-sm italic font-semibold mt-2 pt-2'>
                            {t('requirement.wizardFooter')}
                            {/* <div className='scale-[50%]'><PageLogo /></div> */}
                        </div>
                    </div>
                    </>
                ) : <></>
            }
        </div>
        { requirementTemplatesWizardOpen ? <div onClick={() => setRequirementTemplatesWizardOpen(false)} className='fixed w-screen h-[100dvh] top-0 left-0 z-[1]'></div> : <></>}

        <div className='w-64'><MainButton text={t('requirement.seeAllTemplates')} height='h-11' style='light' additionalStyles='text-primary3' func={() => setRequirementTemplatesWizardModelOpen(true)} /></div>
        { requirementTemplatesWizardModelOpen ? <RequirementInputWizardModal requirementTemplates={requirementTemplates} uniqueRequirementCategories={uniqueRequirementCategories} requirementTemplatesWizardModelOpen={requirementTemplatesWizardModelOpen} setRequirementTemplatesWizardModelOpen={setRequirementTemplatesWizardModelOpen}  setSelectedRequirementTemplate={setSelectedRequirementTemplate} /> : <></> }
    </div>
  )
}

export default RequirementInputWizard