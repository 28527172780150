import React, { useEffect, useState } from 'react'
import TextInput from '../../../../../../components/TextInput'
import SelectorDropdown from '../../../../../../components/SelectorDropdown'
import { getEmailTemplate, getEmailTemplateIds, sendTestEmail, updateEmailTemplate } from '../../../../../../data/PlatformRequests';
import MainButton from '../../../../../../components/MainButton';
import FormModal from '../../../../../../components/FormModal';

const PlatformEmailTemplateSettings = ({customerDetails} : {customerDetails?:any}) => {
    const [templateIds, setTemplateIds] = useState<string[]>([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState<string | undefined>(undefined);

    const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null);
    const [selectedTemplateContent, setSelectedTemplateContent] = useState<string | null>(null);
    const [selectedTemplateSubject, setSelectedTemplateSubject] = useState<string | null>(null);
    const [selectedTemplateNecessaryTags, setSelectedTemplateNecessaryTags] = useState<string | null>(null);

    const [updatedDate, setUpdatedDate] = useState<string | null>(null);
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [sendTestEmailTo, setSendTestEmailTo] = useState<string | null>(null);

    const handleGetEmailTemplateIds = async () => {
        const response = await getEmailTemplateIds();
        if (typeof response !== "undefined") {
            const ids = response.emailTemplateIds.map((item:any) => ({templateId:item.templateId}))
            setTemplateIds(ids);
        }
    }

    const handleGetEmailTemplate = async () => {
        if (typeof selectedTemplateId === "undefined") return;
        const response = await getEmailTemplate(selectedTemplateId, customerDetails?.id);
        if (typeof response !== "undefined" && typeof response.emailTemplate !== "undefined") {
            setSelectedTemplate(response.emailTemplate);
            setSelectedTemplateSubject(response.emailTemplate.templateSubject);
            setSelectedTemplateContent(response.emailTemplate.templateContent);
            setSelectedTemplateNecessaryTags(response.necessaryTags);
        } else {
            setSelectedTemplate(null);
            setSelectedTemplateSubject(null);
            setSelectedTemplateContent(null);
            setSelectedTemplateNecessaryTags(null);
        }
    }

    useEffect(() => {
        handleGetEmailTemplateIds();
    }, [])

    useEffect(() => {
        handleGetEmailTemplate();
    }, [selectedTemplateId])
    
    useEffect(() => {
        if (selectedTemplate === null) return;
        if (selectedTemplate.updatedDate == null) return (setUpdatedDate("New"));
        const dateParsed : Date = new Date(selectedTemplate.updatedDate);
        const dateString : string = `${dateParsed.getDate().toString().padStart(2, '0')}.${(dateParsed.getMonth()+1).toString().padStart(2, '0')}.${dateParsed.getFullYear()} - ${dateParsed.getHours().toString().padStart(2, '0')}:${dateParsed.getMinutes().toString().padStart(2, '0')}`;
        setUpdatedDate(dateString);
    }, [selectedTemplate])

    const handleUpdateEmailTemplate = async () => {
        if (typeof selectedTemplateId === "undefined" || selectedTemplateSubject == null || selectedTemplateContent == null) return;
        if (await updateEmailTemplate(selectedTemplateId, selectedTemplateSubject, selectedTemplateContent, customerDetails?.id)) {
            handleGetEmailTemplate();

        }
    }
    
    return (
        <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
            {previewOpen ? <FormModal isOpen={previewOpen} setOpen={setPreviewOpen} size='large'><div dangerouslySetInnerHTML={{__html:selectedTemplateContent==null?"":selectedTemplateContent}} /></FormModal> : <></>}
            <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
                E-Mail templates
                <p className="mt-1 text-sm font-normal text-gray-500">Set email templates</p>
            </div>

            <div className='bg-white space-y-4'>
                <div className='p-5 '>
                    <SelectorDropdown label='Choose template to edit' items={templateIds} itemValueKey={'templateId'} itemLabelKey={'templateId'} selectedItem={selectedTemplateId} setSelectedItem={setSelectedTemplateId} />
                </div>

                {
                    selectedTemplate === null ? <></> : (
                        <div>
                            <hr />
                            <div className='p-5 space-y-4'>
                                <TextInput label='Subject' textValue={selectedTemplateSubject} setTextValue={setSelectedTemplateSubject} />
                                <div className=''>
                                    <p className='block text-sm font-medium text-gray-900'>Necessary tags</p>
                                    <p className='text-gray-900 border border-gray-300 text-sm rounded-lg p-2.5'>{selectedTemplateNecessaryTags}</p>
                                </div>
                                <TextInput label='Content' textValue={selectedTemplateContent} setTextValue={setSelectedTemplateContent} rows={20} />
                                <div className='flex justify-end gap-4'>
                                    <div className='ml-auto w-fit space-x-4'>
                                        {
                                            sendTestEmailTo != null ? (
                                                <div className='flex items-center gap-4'>
                                            <TextInput textValue={sendTestEmailTo} setTextValue={setSendTestEmailTo} placeholder='Send test email to' />
                                            <MainButton text='Send' style='light' func={() => {sendTestEmail(sendTestEmailTo, selectedTemplateId); setSendTestEmailTo(null)}} />
                                            </div>
                                        ) : (
                                            <div className='flex items-start gap-4'>
                                                <p className='text-sm font-light text-gray-500 text-right'>Last updated: <br/> {updatedDate}</p>
                                                <div className='w-fit'><MainButton text={'Preview'} func={() => setPreviewOpen(true)} style='light' /></div>
                                                <MainButton text='Send test email' style='light' func={() => {handleUpdateEmailTemplate(); setSendTestEmailTo("")}} />
                                                <div className='w-fit'><MainButton text={'Save'} func={() => handleUpdateEmailTemplate()} /></div>
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    )
                }
            </div>

        </div>
    )
}

export default PlatformEmailTemplateSettings