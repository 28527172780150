import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getCustomerSearches } from '../../../../../../../data/CustomerSearchRequests';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner, SearchIcon, SettingsFilledIcon } from 'swap-frontend-library';
import QueryFieldSP from '../../../../../../../components/QueryFieldSP';
import MainButton from '../../../../../../../components/MainButton';

interface props {
    setSelectedSearch: React.Dispatch<React.SetStateAction<string | null>>;
    setStartSelectionFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchTable = ({setSelectedSearch, setStartSelectionFormOpen} : props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [customerSearches, setCustomerSearches] = useState([]);
    // const [customerSearches, setCustomerSearches] = useState(DUMMY);
    const [visibleRows, setVisibleRows] = useState([1,1]);
    
    const handleFetchCustomerSearches = async () => {
        const data = await getCustomerSearches();
        console.log(data)
        setCustomerSearches(data);
    }

    useEffect(() => {
        handleFetchCustomerSearches();
    }, [])


    const handleOpenCustomerSearch = async (customerSearchCustomerDetailsId : string, selection: any, selectionStarted: boolean) => {
        if (!selectionStarted) {
            setSelectedSearch(customerSearchCustomerDetailsId);
            setStartSelectionFormOpen(true);
        } else {
            navigate(`/user-area/selection-portal/${selection.id}`);
        }
        // navigate(`/search/result?customerSearchId=${id}`);
    }

    return (
    <div className='py-4 flex flex-col gap-4'>
        {
            typeof customerSearches === "undefined" ? <LoadingSpinner/> : customerSearches.length == 0 ? (
                <div className='w-full h-96 flex flex-col items-center justify-center'>
                    <div className='w-10 h-10 mb-4 text-gray-400'><SearchIcon /></div>
                    <p className='text-gray-600 text-center'>No software matchings conducted yet. Carry out a software matching.</p>
                </div>
            ) : (
                <>
                {/* `${t("queryUrl")}`, `${t("searchType")}`, `Selection started`, `${t("createdOn")}` */}
                {
                    customerSearches.map((item : any, index : number) => {
                        const createdDateParsed : Date = new Date(Date.parse(item.createdDate));
                        const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;


                        let premiumLevel : string | undefined = undefined;
                        let unlockedBy = "";

                        if (item.customerSearch?.unlockedCustomerSearch.length > 0) {
                            premiumLevel = item.customerSearch.unlockedCustomerSearch[0].orderType;
                        }

                        if (item.customerSearch?.unlockedCustomerSearch.length > 0) {
                            unlockedBy = item.customerSearch.unlockedCustomerSearch[0].email
                        }

                        return (
                            <div key={index} className='w-full bg-white rounded-lg border p-4 flex items-center justify-between gap-8'>
                                <div className='items-center gap-4 w-full'>
                                    <div className='gap-1 flex'>
                                        <span className='text-sm font-light'>Created by {unlockedBy}</span>
                                        <span className='text-sm font-light'>({createdDateString})</span>
                                        {/* <span className='text-sm font-light'>{premiumLevel}</span> */}
                                    </div>
                                    <div className='max-sm:text-lg text-2xl font-semibold truncate'>{item.customerSearch?.queryUrl || item.queryUrl}</div>
                                    {/* (freeSearch == "1") ? ("Free") : ("Paid") */}
                                </div>

                                <div className='flex items-center gap-4'>
                                    <div>
                                        <MainButton 
                                            text={'Matching results'} 
                                            additionalStyles='bg-primary hover:shadow-primary whitespace-nowrap' 
                                            style='light' 
                                            icon={<div className='w-6 h-6 text-primary'><SearchIcon /></div>} 
                                            linkNewTab={true}
                                            link={`../analysis?url=${item.customerSearch?.queryUrl || item.queryUrl}&code=${item.customerSearch?.unlockCode || item.unlockCode}`}
                                        />
                                        </div>
                                    {/* <div><MainButton text={'Matching results'} additionalStyles='bg-primary hover:shadow-primary whitespace-nowrap' style='light' icon={<div className='w-6 h-6 text-primary'><SearchIcon /></div>} linkNewTab={true} link={`${process.env.REACT_APP_LANDING_PAGE}/search/results?url=${customerSearch.queryUrl}&code=${customerSearch.unlockCode}${typeof premiumLevel !== "undefined" ? `&premiumLevel=${premiumLevel}` : ""}`} /></div> */}
                                    {/* {
                                        (selectionStarted == "1") ? (
                                            <></>
                                        ) : (
                                            <div><MainButton text={'Start selection'} additionalStyles='bg-primary hover:shadow-primary whitespace-nowrap' icon={<div className='w-6 h-6 text-white'><SettingsFilledIcon /></div>} func={() => {handleOpenCustomerSearch(id, selection, selectionStarted)}} /></div>
                                        )
                                    } */}
                                </div>
                            </div>
                        )
                    })
                }
                </>
            )
        }
    </div>
  )
}

const DUMMY = [
    {
        "id": "1",
        "selection": null,
        "customerSearch": {
            "queryUrl": "https://kvgmbh.com",
            "unlockedCustomerSearch": [
                {
                    "orderType": "premium",
                    "email": "koerppen@sectorlens.de"
                }
            ]
        },
        "selectionStarted": "0",
        "createdDate": "2024-11-22 13:11:10.146030"
    },
    {
        "id": "1",
        "selection": null,
        "customerSearch": {
            "queryUrl": "https://www.oke-group.com",
            "unlockedCustomerSearch": [
                {
                    "orderType": "premium",
                    "email": "koerppen@sectorlens.de"
                }
            ]
        },
        "selectionStarted": "0",
        "createdDate": "2024-11-22 13:11:10.146030"
    },
    {
        "id": "2",
        "selection": null,
        "customerSearch": {
            "queryUrl": "example.com",
            "unlockedCustomerSearch": [
                {
                    "orderType": "standard",
                    "email": "user@example.com"
                }
            ]
        },
        "selectionStarted": "0",
        "createdDate": "2024-11-23 09:25:45.789123"
    },
    {
        "id": "3",
        "selection": null,
        "customerSearch": {
            "queryUrl": "testsite.org",
            "unlockedCustomerSearch": [
                {
                    "orderType": "basic",
                    "email": "test@testsite.org"
                }
            ]
        },
        "selectionStarted": "1",
        "createdDate": "2024-11-24 17:45:30.654321"
    },
    {
        "id": "4",
        "selection": null,
        "customerSearch": {
            "queryUrl": "webservice.net",
            "unlockedCustomerSearch": [
                {
                    "orderType": "premium",
                    "email": "contact@webservice.net"
                }
            ]
        },
        "selectionStarted": "1",
        "createdDate": "2024-11-25 12:00:00.123456"
    }
]


export default SearchTable