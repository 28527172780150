import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { login, logout, setEmail, setRole } from '../../../redux/userSlice';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { checkLogin } from '../../../data/AuthRequests';
import NotificationOverlay from '../notification-overlay/NotificationOverlay';
import Navbar from './features/Navbar';
import { getPlatformLogoConfiguration } from '../../../data/PlatformRequests';
import PlatformMessage from './features/PlatformMessage';
import LanguageSelector from './features/LanguageSelector';

const Index = () => {
  const isLoggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  
  const [platformMessage, setPlatformMessage] = useState<string>();

  useEffect(() => {
    handleCheckLoggedIn();
    setLoading(false);
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      handleCheckLoggedIn();
    }
    setLoading(false);
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname])

  useEffect(() => {
    if (!isLoggedIn) return
    const interval = setInterval(() => {
      console.log("Checking if still logged in...")
      handleCheckLoggedIn();
    // }, 1000);
    }, 5 * 60 * 1000);
  
    return () => clearInterval(interval);
  }, [isLoggedIn]);

  const handleCheckLoggedIn = async () => {
    const response = await checkLogin()
    if (response.authorized) {
      if (!isLoggedIn) {
        dispatch(login());
        dispatch(setEmail(response.data.email));
        dispatch(setRole(response.data.role));
      }
    }  else {
      dispatch(logout());
    }
  }
  
  const handleChangeTheme = (colorTheme: any) => {
    document.documentElement.style.setProperty("--primary-color", colorTheme.primary);
    document.documentElement.style.setProperty("--primary2-color", colorTheme.primary2);
    document.documentElement.style.setProperty("--secondary1-color", colorTheme.secondary1);
    document.documentElement.style.setProperty("--secondary2-color", colorTheme.secondary2);
  }

  const handleGetPlatformLogoConfiguration = async () => {
    const response = await getPlatformLogoConfiguration();
    // console.log(response)

    sessionStorage.setItem("logoPath", response.logoPath);
    sessionStorage.setItem("domain", response.domain);
    sessionStorage.setItem("customerName", response.customerName);
    sessionStorage.setItem("contactEmail", response.contactEmail);
    handleChangeTheme(response.colorTheme)
    document.title = response.customerName + ' | Selection Portal';

    setPlatformMessage(response.platformMessage)

    window.dispatchEvent(new Event("sessionStorageChange"));
  }

  useEffect(() => {
    handleGetPlatformLogoConfiguration()
  }, [])

  return (
    <div className='h-[100dvh]'>
      <Navbar />
      {(!loading && typeof isLoggedIn !== "undefined") ? (
        <div className=''>
          <PlatformMessage platformMessage={platformMessage} />
          <NotificationOverlay />
          <Outlet />
        </div>
      ) : (
        <div className='mt-32'>
          <LoadingSpinner />
        </div>
      )}

      {(!loading && typeof isLoggedIn === "undefined" || !isLoggedIn) ? 
      <div className='absolute right-0 bottom-0'><LanguageSelector smallMode={true} /></div>
      : <></>}
      {/* <div className='absolute bottom-0'>{String(isLoggedIn)}</div> */}
    </div>
  )
}

export default Index