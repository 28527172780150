import React, { useEffect, useState } from 'react'
import { LoadingSpinner, MainButton, TextInput } from 'swap-frontend-library';
import { createSelectionPresentation, getSelectionPresentations, getSelectionRanking, getUsersSelection } from '../../../../../../../../data/SelectionPortalRequests';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import Calendar from '../../../../../../../../components/CalendarWidget';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';
import FormModal from '../../../../../../../../components/FormModal';

interface props {
    modalOpen: boolean,
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    selectionId: string,
    handleGetSelectionPresentations: () => {}
}

const AddPresentationModal = ({modalOpen, setModalOpen, selectionId, handleGetSelectionPresentations} : props) => {
    const {t} = useTranslation();
    const email = useSelector((state: RootState) => state.user.email);
    const [ usersSelection, setUsersSelection ] = useState<any[]>();
    const [ softwareProducts, setSoftwareProducts ] = useState<any[]>();
    const [ selectedSoftwareProduct, setSelectedSoftwareProduct ] = useState<any>();
    const [ timeFrom, setTimeFrom ] = useState<string>();
    const [ timeFromDate, setTimeFromDate ] = useState<Date>();
    const [ timeTo, setTimeTo ] = useState<string>();
    const [ timeToDate, setTimeToDate ] = useState<Date>();
    const [ timeError, setTimeError ] = useState(false);
    const [ duration, setDuration ] = useState<{hours:number, minutes:number}>();
    const [ date, setDate ] = useState<Date | null>(null);
    const [ title, setTitle ] = useState<string>(t("participationScreen.vendorPresentation"));
    const [ videoconferenceLink, setVideoconferenceLink ] = useState<string>("");
    const [ videoconferencePassword, setVideoconferencePassword ] = useState<string>("");
    const [ agenda, setAgenda ] = useState<string>("");
    const [ participants, setParticipants ] = useState<string[]>([]);

    const handleGetSelectionSoftwareProducts = async () => {
        const responsePresentations = await getSelectionPresentations(selectionId);
        if (!responsePresentations.ok) {
            return;
        }
        const selectionPresentations = responsePresentations.data.data;
        const presentationsExistingForSoftwareProductId = selectionPresentations.map((presentation:any) => presentation.selectionSoftwareProduct.id)
        
        const response = await getSelectionRanking(selectionId);
        const selectionSoftwareProducts = response.data.data.results;
        const filteredSelectionSoftwareProducts = selectionSoftwareProducts.filter((item:any) => !presentationsExistingForSoftwareProductId.includes(item.id) && item.included)
        console.log(selectionSoftwareProducts, filteredSelectionSoftwareProducts)
        setSoftwareProducts(filteredSelectionSoftwareProducts);
    }

    const handleFetchUsersSelection = async () => {
        const data = await getUsersSelection(selectionId);
        setUsersSelection(data.data.data);

        const currentUser = data.data.data.filter((item : any) => item.user.email == email)
        if (currentUser.length > 0) {
            setParticipants([currentUser[0].id])
        }
    }
    
    const handleUpdateParticipants = (userId: string) => {
        if (participants.includes(userId)) {
            if (participants.length > 1) {
                setParticipants(participants.filter(item => item != userId));
            }
        } else {
            setParticipants(prev => [...prev, userId]);
        }
    }

    const parseTime = (timeString: string) => {
        var d = new Date();
        var time = timeString.match( /(\d+)(?::(\d\d))?\s*(p?)/ );
        if (time == null) return

        d.setHours( parseInt( time[1]) + (time[3] ? 12 : 0) );
        d.setMinutes( parseInt( time[2]) || 0 );
        
        if (typeof date !== "undefined" && date != null) {
            d.setDate(date.getDate());
            d.setMonth(date.getMonth());
            d.setFullYear(date.getFullYear());
        }

        return d;
    }

    const handleSavePresentation = async () => {
        console.log(title, selectedSoftwareProduct, date, timeFromDate, timeToDate, participants)

        if (typeof date === "undefined" || date == null || typeof timeFrom === "undefined" || typeof timeTo === "undefined" || typeof selectedSoftwareProduct === "undefined") return

        const from = parseTime(timeFrom)
        const to = parseTime(timeTo)
        
        if (typeof from === "undefined" || typeof to === "undefined") return

        const success = await createSelectionPresentation(selectionId, selectedSoftwareProduct, from, to, title, participants, videoconferenceLink, videoconferencePassword, agenda);
        if (success) {
            handleGetSelectionPresentations();
            setModalOpen(false);
        }
    }

    useEffect(() => {
        setTimeError(false);
        if (typeof timeFrom !== "undefined") setTimeFromDate(parseTime(timeFrom));
        if (typeof timeTo !== "undefined") setTimeToDate(parseTime(timeTo));
    }, [timeFrom, timeTo])
    
    useEffect(() => {
        if (typeof timeToDate !== "undefined" && typeof timeFromDate !== "undefined") {
            const d = (timeToDate.getTime() - timeFromDate.getTime()) / 1000 / 60 / 60;
            const hours = Math.trunc(d);
            const minutes = Math.round(((d - hours)*60) * 100) / 100;
            setDuration({hours, minutes})
            if (d < 0) return setTimeError(true)
        }
    }, [timeFromDate, timeToDate])

    useEffect(() => {
        handleGetSelectionSoftwareProducts();
        handleFetchUsersSelection();
    }, [])

    return (
    <FormModal isOpen={modalOpen} setOpen={setModalOpen}>
        <div className='h-[70vh] overflow-y-auto p-1'>
            <h3 className="mb-4 text-xl font-medium text-gray-900">{t('addPresentationModal.title')}</h3>
            {/* selectionSoftwareProductId, timeFrom, timeTo, title, participants */}

            <div>
                <label className="block text-sm font-medium text-gray-900 mt-4">{t('addPresentationModal.titleLabel')}</label>
                <input placeholder={t('addPresentationModal.titlePlaceholder')} value={title} onChange={(event) => {setTitle(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
            </div>

            {
                (typeof softwareProducts === "undefined") ? <LoadingSpinner /> : (
                <div className='mt-4'>
                    <label htmlFor="countries" className="block text-sm font-medium text-gray-900">{t('addPresentationModal.vendorCompany')}</label>
                    <select onChange={(event) => setSelectedSoftwareProduct(event.target.value)}
                    id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" style={{WebkitAppearance: "none"}}>
                        <option value="" selected disabled hidden>{t('addPresentationModal.chooseHere')}</option>
                        {
                            softwareProducts.map((item : any, index : number) => {
                                return (<option key={index} value={item.id}>{item.softwareProduct.vendorDetails.company} {item.softwareProduct.productName}</option>);
                            })
                        }
                    </select>
                </div>
                )
            }

            <div className='grid grid-cols-2 gap-4 mt-4'>
                <div>
                    <Calendar selectedDate={date} setSelectedDate={setDate} />
                </div>
                <div className='flex flex-col'>
                    <div className='flex gap-4 mt-4'>
                        <div>
                            <label className="block text-sm font-medium text-gray-900 ">{t('addPresentationModal.presentationStart')}</label>
                            <input type="time" placeholder={t('addPresentationModal.timePlaceholder')} value={timeFrom} onChange={(event) => {setTimeFrom(event.target.value)}} name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-fit p-2.5 "  required></input>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-900 ">{t('addPresentationModal.presentationEnd')}</label>
                            <input type="time" min={timeFrom} placeholder={t('addPresentationModal.timePlaceholder')} value={timeTo} onChange={(event) => {setTimeTo(event.target.value)}} name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-fit p-2.5"  required></input>
                        </div>
                    </div>
                    <div className='mt-4 border rounded bg-gray-50 grow flex items-center justify-center'>
                        {
                            typeof duration !== "undefined" && !timeError ? (
                                <p className='font-semibold'>{duration?.hours} {t('addPresentationModal.hours')}, {duration?.minutes} {t('addPresentationModal.minutes')}</p>
                            ) : <></>
                        }

                        {
                            timeError ? (
                                <p className='text-red-400 font-semibold'>{t('addPresentationModal.timeError')}</p>
                            ) : <></>
                        }
                    </div>
                </div>
            </div>

            <div className='flex w-full gap-4'>
                <div className='w-full'>
                    <label className="block text-sm font-medium text-gray-900 mt-4">{t('addPresentationModal.videoconferenceLink')}</label>
                    <input placeholder={t('addPresentationModal.linkPlaceholder')} value={videoconferenceLink} onChange={(event) => {setVideoconferenceLink(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                </div>
                <div className='w-full'>
                    <label className="block text-sm font-medium text-gray-900 mt-4">{t('addPresentationModal.videoconferencePassword')}</label>
                    <input placeholder={t('addPresentationModal.passwordPlaceholder')} value={videoconferencePassword} onChange={(event) => {setVideoconferencePassword(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                </div>
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-900 mt-4">{t('addPresentationModal.agenda')}</label>
                <TextInput textValue={agenda} setTextValue={setAgenda} rows={5} placeholder={t('addPresentationModal.agendaPlaceholder')} />
            </div>

            {
                (typeof usersSelection === "undefined") ? <LoadingSpinner /> : (
                    <div className='mt-4'>
                        <label htmlFor="countries" className="block text-sm font-medium text-gray-900">{t('addPresentationModal.participants')}</label>
                        <div className='flex flex-wrap gap-4'>
                        {
                            usersSelection.map(selectionUser => (
                                <div onClick={() => handleUpdateParticipants(selectionUser.id)}
                                className={`cursor-pointer border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 ${participants.includes(selectionUser.id) ? "bg-primary" : "bg-gray-50"}`}>
                                    {selectionUser.user?.email || selectionUser.guestEmail}
                                </div>
                            ))
                        }
                        </div>
                    </div>
                )
            }
        <div className='mt-4'><MainButton text={t('addPresentationModal.saveButton')} func={handleSavePresentation}/></div>
        </div>
    </FormModal>
  )
}

export default AddPresentationModal