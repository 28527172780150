"use client"
// import './styles.css';
import React, { useEffect, useState } from 'react';

import { CheckmarkIcon, LinkIcon, LoadingSpinner, LoadingSpinnerIcon } from 'swap-frontend-library';
// import { useParams, useSearchParams } from 'next/navigation';
// import { useTranslation } from '@/app/i18n/client';
// import MainButton from '@/app/_components/MainButton';
import { socketMatchingEngine as socket } from '../../../../../../../_socket/socket';

import { useDispatch } from 'react-redux';
// import { changeCustomerSearch, CustomerSearchCookie } from '@/lib/navigationSlice';

import FunctionalAnalysis from './features/FunctionalAnalysis';
import SimilarCompaniesAnalysis from './features/SimilarCompaniesAnalysis';
import IndustryClassificationAnalysis from './features/IndustryClassificationAnalysis';
import CompanyFactAnalysis from './features/CompanyFactAnalysis';
import CompanyProfileAnalysis from './features/CompanyProfileAnalysis';
import CompanyDescriptionAnalysis from './features/CompanyDescriptionAnalysis';
import InformationSourcesAnalysis from './features/InformationSourcesAnalysis';
import { useSearchParams } from 'react-router-dom';
import MainButton from '../../../../../../../components/MainButton';
import { claimExpertMatching, getSelectionPortalPlanLimit } from '../../../../../../../data/PaymentRequests';
import ResultsPreview from './features/ResultsPreview';
// import { useRouter } from 'next/navigation';
// import { handleChangeMatchingPhase } from '../_utils/handleChangeMatchingPhase';

const Analysis = ({queryUrl, analysisFinished, setAnalysisFinished, jumpToResults} : {queryUrl: string, analysisFinished: boolean, setAnalysisFinished: React.Dispatch<React.SetStateAction<boolean>>, jumpToResults: () => void}) => {
    // const params = useParams();
    // const lng : string = typeof params.lng === "string" ? params.lng : ""
    // const { t } = useTranslation(lng);

    // const router = useRouter();
    const [searchParams, setSearchParams] = useSearchParams();

    const [ analysisStarted, setAnalysisStarted ] = useState(false);
    
    const [editingModeOn, setEditingModeOn] = useState(false);
    
    const [analysisProgress, setAnalysisProgress] = useState<number>(0);
    const [errorCode, setErrorCode] = useState<string>("");
    
    const [links, setLinks] = useState<string[]>();
    const [finishedLinks, setFinishedLinks] = useState<string[]>([]);
    const [failedLinks, setFailedLinks] = useState<string[]>([]);

    const [companyLogos, setCompanyLogos] = useState<string[]>();
    const [companyFacts, setCompanyFacts] = useState<any>();
    const [companyProfile, setCompanyProfile] = useState<any>();
    const [companyDescription, setCompanyDescription] = useState<string>();

    const [similarCompanies, setSimilarCompanies] = useState<any[]>();
    const [numberOfSimilarCompanies, setNumberOfSimilarCompanies] = useState<number>();

    const [requirementOverview, setRequirementOverview] = useState<any>();
    const [requirementsRaw, setRequirementsRaw] = useState<any[]>();
    const [industryClassification, setIndustryClassification] = useState<Array<{class:string, score:number}>>();
    
    const [ matchingMetrics, setMatchingMetrics ] = useState<any[]>();

    // useEffect(() => {
    //     if (queryUrl == null) return;
    //     const customerSearchCookie : CustomerSearchCookie = {queryUrl, stage:"analysis"};
    //     dispatch(changeCustomerSearch(customerSearchCookie));
    // }, [queryUrl])
    
    // const dispatch = useDispatch();

    useEffect(() => {
        console.log("QueryUrl", queryUrl)
        if (analysisFinished) return

        const handleReceiveLinks = (data : { links : string[]}) => {
            // console.log("New message received " + data.links);
            setLinks(data.links);
        }
        
        const handleReceiveFinishLink = (data : { link : string}) => {
            // console.log("New message received " + data.links);
            setFinishedLinks(prev => [...prev, data.link]);
        }
        
        const handleReceiveFailedLink = (data : { link : string}) => {
            // console.log("New message received " + data.links);
            setFailedLinks(prev => [...prev, data.link]);
        }

        const handleReceiveCompanyLogos = (data : { companyLogos : string[]}) => {
            // console.log("Logos " + data.companyLogos);

            setCompanyLogos(data.companyLogos);
        }
        
        const handleReceiveCompanyFacts = (data : { facts : any}) => {
            // console.log("Facts ", data.facts);
            setCompanyFacts(data.facts);
        }
        
        const handleReceiveCompanyProfile = (data : { profile : any}) => {
            // console.log("profile " , data.profile);
            setCompanyProfile(data.profile);
        }
        
        const handleReceiveCompanyDescription = (data : { companyDescription : string}) => {
            // console.log("Description ", data.companyDescription);
            setCompanyDescription(data.companyDescription);
        }

        const handleReceiveSimilarCompanies = (data : { numberOfSimilarCompanies : number, referenceList : any[]}) => {
            // console.log("Similar companies ", data.numberOfSimilarCompanies);
            // console.log("Similar companies ", data.referenceList);
            setNumberOfSimilarCompanies(data.numberOfSimilarCompanies);
            setSimilarCompanies(data.referenceList);
        }
        
        const handleReceiveRequirementOverview = (data : { requirementOverview : any, requirementsRaw:any[] }) => {
            // console.log("Requirement overview ", data.requirementOverview);
            console.log("Requirements raw ", data.requirementsRaw);
            setRequirementsRaw(data.requirementsRaw);
            setRequirementOverview(data.requirementOverview);
        }
        
        const handleReceiveIndustryClassification = (data : { industryClassification : Array<{class:string, score:number}>}) => {
            // console.log("Industry classification ", data.industryClassification);
            setIndustryClassification(data.industryClassification);
        }
        
        const handleAnalysisFinished = async () => {
            // console.log("Analysis finished");
            const resp = await claimExpertMatching(queryUrl);
            if (resp) {
                const unlockCode = resp.data.unlockCode;
                setSearchParams(prevParams => {
                    const newParams = new URLSearchParams(prevParams);
                    newParams.set('code', unlockCode);
                    return newParams;
                });
            }

            socket.emit("start matching", { url: queryUrl });

        }

        const handleReceiveMatchingMetrics = (data : { matchingMetrics : { top3results : any[] }}) => {
            // console.log("New message received ", data.matchingMetrics);
            setMatchingMetrics([]);
            setAnalysisFinished(true);
        }
        
        const handleError = (data : { errorCode : string}) => {
            // console.log("Error during matching: ", data.errorCode);
            setErrorCode(data.errorCode);
        }
        
        const handleReceiveAnalysisProgress = (data : { percentage : number}) => {
            // console.log("New progress " + data.percentage);
            setAnalysisProgress(prev => (prev+data.percentage > 100) ? 100 : prev+data.percentage );
        }
        
        socket.on("send analysis preresult: 1. links", handleReceiveLinks);
        socket.on("send analysis preresult: 2a. link finished", handleReceiveFinishLink);
        socket.on("send analysis preresult: 2a. link failed", handleReceiveFailedLink);
        
        socket.on("send analysis preresult: 3a. logos", handleReceiveCompanyLogos); // {logos: []}
        socket.on("send analysis preresult: 3b. facts finished", handleReceiveCompanyFacts); // {facts: {}}
        socket.on("send analysis preresult: 3b. profile finished", handleReceiveCompanyProfile); // {profile: {}}
        socket.on("send analysis preresult: 3c. description finished", handleReceiveCompanyDescription); // {description: ""}
        
        socket.on("send analysis preresult: 4a. industry classification finished", handleReceiveIndustryClassification);
        socket.on("send analysis preresult: 4b. requirement overview finished", handleReceiveRequirementOverview);
        socket.on("send analysis preresult: 4c. similar companies finished", handleReceiveSimilarCompanies);
        socket.on("analysis finished", handleAnalysisFinished);
        
        socket.on("send matching metrics", handleReceiveMatchingMetrics);

        socket.on("matching error", handleError);
        
        socket.on("send analysis progress", handleReceiveAnalysisProgress);
        
        startAnalysis(queryUrl);

        return () => {
            socket.off("send analysis preresult: 1. links", handleReceiveLinks);
            socket.off("send analysis preresult: 2a. link finished", handleReceiveFinishLink);
            socket.off("send analysis preresult: 2a. link failed", handleReceiveFailedLink);
            
            socket.off("send analysis preresult: 3a. logos", handleReceiveCompanyLogos);
            socket.off("send analysis preresult: 3b. facts finished", handleReceiveCompanyFacts);
            socket.off("send analysis preresult: 3b. profile finished", handleReceiveCompanyProfile);
            socket.off("send analysis preresult: 3c. description finished", handleReceiveCompanyDescription);

            socket.off("send analysis preresult: 4a. industry classification finished", handleReceiveIndustryClassification);
            socket.off("send analysis preresult: 4b. requirement overview finished", handleReceiveRequirementOverview);
            socket.off("send analysis preresult: 4c. similar companies finished", handleReceiveSimilarCompanies);
            socket.off("analysis finished", handleAnalysisFinished);

            socket.off("send matching metrics", handleReceiveMatchingMetrics);
            
            socket.off("matching error", handleError);
            
            socket.off("send analysis progress", handleReceiveAnalysisProgress);
        };
    }, [])

    // const startAnalysis = (url : string) => {
    //     setErrorCode("");
    //     if (!socket.connected) {
    //         socket.connect();
    //     }
    //     if (socket.connected) {
    //         console.log("Starting analysis for " + url);
    //         socket.emit("start analysis", { url });
    //     } else {
    //         setErrorCode("websocketConnection");
    //     }
    // }

    const startAnalysis = (url: string) => {
        setErrorCode("");
        if (!socket.connected) {
            socket.connect();
            socket.on("connect", () => {
                console.log("Starting analysis for " + url);
                setAnalysisStarted(true);
                socket.emit("start analysis", { url: url, mode: "expert" });
            });
        } else {
            console.log("Starting analysis for " + url);
            setAnalysisStarted(true);
            socket.emit("start analysis", { url, mode: "expert" });
        }

        // getSelectionPortalPlanLimit().then(response => {
        //     if (response.success) {
        //         const maxNumMatchings = response.data.maxNumMatchings;
        //         const numMatchingsThisMonth = response.data.numMatchingsThisMonth;

        //         if (numMatchingsThisMonth < maxNumMatchings) {
        //         }
                    
        //     }
        // })

    }

    // if (!analysisStarted) return (
    //     <div className='h-full overflow-y-auto px-4 flex flex-col mx-auto gap-8 pt-44 pb-56'>
    //         <div className='bg-white w-full h-32 rounded-lg shadow-lg flex items-center justify-center'>
    //             <p className='text-2xl font-semibold'>Start analysis</p>
    //             <MainButton text={'Start'} func={() => startAnalysis(queryUrl)} />
    //         </div>
    //     </div>
    // )

    return (
        <div className='relative h-full'>
            { 
                // (!analysisFinished || errorCode != "") ? 
                <div className='absolute z-[2] bottom-4 right-4 w-1/2 min-h-64 mx-auto border rounded-full shadow-lg bg-white/50 backdrop-blur-md md:p-16 p-4 py-8'>
                    { 
                    // (!analysisStarted) ?
                    // <div className='w-full gap-4 flex items-center justify-center'>
                    //     {/* <p className='text-2xl font-semibold'>Start analysis</p> */}
                    //     <MainButton text={'Start'} func={() => startAnalysis(queryUrl)} />
                    // </div> :
                    // !analysisStarted ? <div><MainButton text={'Start'} func={() => startAnalysis(queryUrl)} /></div> :
                    // (!analysisFinished && errorCode == "") ? 
                    (errorCode == "") ? 
                        <div className='flex flex-col items-center gap-2'>
                            {/* <p className='text-2xl font-semibold linespace-2'>Loading...</p> */}
                            <div className='w-full flex items-center justify-center gap-4'>
                                <div className='w-full rounded-full bg-gray-100 h-8 border border-primary'><div className='h-full rounded-full bg-primary transition-all duration-[2000ms]' style={{width: `${analysisProgress}%`}}></div></div>
                                {
                                    !analysisFinished ? <div className='w-8 h-8 text-primary'><LoadingSpinnerIcon /></div> : <div className='w-8 h-8 text-primary'><CheckmarkIcon /></div>
                                }
                            </div>
                        </div>
                        // : (errorCode !== "") ? (
                        :
                            <>
                                <p className='text-2xl font-semibold text-center'>Oops.</p>
                                {
                                    (errorCode == "BlacklistError") ? (
                                        <div className='text-xl font-light mt-2 text-center'>We have stopped analyzing the page you submitted. If you would still like to run an analysis, please contact our support at <a className='underline underline-offset-2 hover:underline-offset-4 transition-all' href={`mailto:${process.env.NEXT_PUBLIC_SUPPORT_EMAIL}?subject=Analysis of ${queryUrl}`}>support@find-your-software.de</a></div>
                                    ) : (errorCode == "UrlFormatError") ? (
                                        <p className='text-xl font-light mt-2 text-center'>The link you entered does not seem to be correct. Please check your input and try again.</p>
                                    ) : (errorCode == "rateLimits") ? (
                                        <p className='text-xl font-light mt-2 text-center'>You reached the rate limit for this month. Please purchase additional matchings before continuing.</p>
                                    ) : (
                                        <p className='text-xl font-light mt-2 text-center'>A problem occurred during the analysis. Please try again.</p>
                                    )
                                }
                                <div className='flex gap-8 mt-2 w-full items-center justify-center'>
                                    <div className='flex items-center gap-4'><MainButton text='Try again' 
                                    func={() => startAnalysis(queryUrl)} 
                                    />
                                    </div>
                                </div>
                            </>
                        // : <></>
                    }
                </div>
                // : <></>
            }

            <div className='h-full overflow-y-auto px-8 flex flex-col mx-auto gap-8 pt-44 pb-56'>
                {
                    (typeof matchingMetrics === "undefined" || matchingMetrics == null) ? <></> : (
                        <ResultsPreview matchingMetrics={matchingMetrics} jumpToResults={jumpToResults} />
                    )
                
                }
                {
                    (typeof requirementOverview === "undefined" || requirementOverview == null) ? <></> : (
                        <FunctionalAnalysis requirementOverview={requirementOverview} requirementsRaw={requirementsRaw} />
                    )
                }

                { 
                    (typeof numberOfSimilarCompanies === "undefined" || typeof similarCompanies === "undefined") ? (<></>) : (
                        <SimilarCompaniesAnalysis numberOfSimilarCompanies={numberOfSimilarCompanies} similarCompanies={similarCompanies} />
                    )
                }

                {
                    (typeof industryClassification === "undefined") ? <></> : (
                        <IndustryClassificationAnalysis industryClassification={industryClassification} />
                    )
                }

                {
                    ((typeof companyFacts === "undefined" || Object.keys(companyFacts).length == 0 || Object.values(companyFacts).every(value => value === null || value === '')) && (typeof companyProfile === "undefined"  || Object.keys(companyProfile).length == 0 || Object.values(companyProfile).every(value => value === null || value === ''))) ? <></> : (    
                    <div className={`grid gap-8 transition-all duration-1000 max-md:grid-cols-1 ${
                        (
                            // companyFacts is undefined/empty and companyProfile exists
                            ((typeof companyFacts === "undefined" || Object.keys(companyFacts).length == 0) && typeof companyProfile !== "undefined"  && Object.keys(companyProfile).length > 0) 
                            // companyProfile is undefined/empty and companyFacts exists
                            || ((typeof companyProfile === "undefined" || Object.keys(companyProfile).length == 0) && typeof companyFacts !== "undefined"  && Object.keys(companyFacts).length > 0)
                        ) ? "grid-cols-1" : "grid-cols-2" 
                        }`}>

                        { 
                            (typeof companyFacts === "undefined"  || Object.keys(companyFacts).length == 0 || Object.values(companyFacts).every(value => value === null || value === '')) ? (<></>) : (
                                <CompanyFactAnalysis companyFacts={companyFacts} companyLogos={companyLogos} queryUrl={queryUrl} typing={true} />
                            )
                        }
                        { 
                            (typeof companyProfile === "undefined" || Object.keys(companyProfile).length == 0 || Object.values(companyProfile).every(value => value === null || value === '') ) ? (<></>) : (
                                <CompanyProfileAnalysis companyProfile={companyProfile} typing={true} />
                            )
                        }
                    </div>
                    )
                }

                { 
                    (typeof companyDescription === "undefined" || companyDescription == null) ? (<></>) : (
                        <CompanyDescriptionAnalysis companyDescription={companyDescription} typing={true} />
                    )
                }

                { 
                    (typeof links === "undefined") ? (<></>) : (
                        <InformationSourcesAnalysis links={links} finishedLinks={finishedLinks} failedLinks={failedLinks} />
                    )
                }

            </div>

        </div>
  )
}

export default Analysis