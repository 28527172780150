import React, { useEffect, useState } from 'react'
import LoadingSpinner from './LoadingSpinner'
import { CheckmarkIcon } from 'swap-frontend-library'
import { useTranslation } from 'react-i18next'

interface props {
    textValue : string | null,
    setTextValue : (text: string) => void | React.Dispatch<React.SetStateAction<string | null>>,
    placeholder? : string,
    label? : string,
    icon? : any
    rows? : number
    onBlur?: (e: any) => void
    disabled?: boolean
    type?: string
    required?: boolean
}

const TextInput = ({ textValue, setTextValue, placeholder, label, icon, rows, onBlur, disabled, type, required } : props) => {
  const { t } = useTranslation();
  const [blurFinished, setBlurFinished] = useState(false);
  const handleBlur = (e: any) => {
    if (typeof onBlur === "undefined") return;
    onBlur(e);
    setBlurFinished(true);
  }
  
  useEffect(() => {
    if (blurFinished) {
      const timer = setTimeout(() => {
        setBlurFinished(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [blurFinished]);
  
  // if (textValue == null) return <LoadingSpinner />
  return (
    <div className='w-full'>
        {typeof label !== "undefined" ? (<label className="block text-sm font-medium text-gray-900">{label}</label>) : (<></>) }
        
        <div className='relative flex text-gray-900 items-center'>
            {typeof icon !== "undefined" ? (<div className='absolute text-gray-300 left-2 w-5 h-5'>{icon}</div>) : (<></>)}
            {
              typeof rows === "undefined" ? (
                <input 
                onChange={(e) => setTextValue(e.target.value)}
                onBlur={handleBlur}
                placeholder={placeholder || t('textInput.typeHere')} type={typeof type !== "undefined" ? type : "text"} name="input" value={textValue || ""} 
                disabled={disabled}
                required={required}
                className={`${(typeof icon !== "undefined") ? ("pl-9") : ("")} bg-gray-50 text-gray-900 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                />
                ) : (
                <textarea onChange={(e) => setTextValue(e.target.value)}
                onBlur={handleBlur}
                placeholder={placeholder || t('textInput.typeHere')} value={textValue || ""} rows={rows}
                disabled={disabled}
                required={required}
                className={`${(typeof icon !== "undefined") ? ("pl-9") : ("")} bg-gray-50 text-gray-900 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                />
              )
            }

            {
              blurFinished ? 
              <div className='absolute right-2 w-5 h-5 rounded-full text-white p-1 bg-green-600/50'><CheckmarkIcon /></div> :
              <></>
            }
        </div>
    </div>
  )
}

export default TextInput