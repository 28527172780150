import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../../../components/MainButton';
import { startNewSelection } from '../../../../../../data/SelectionPortalRequests';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon, EyeClosedIcon, EyeOpenIcon, TextInput } from 'swap-frontend-library';
import { getSelectionPortalPlanLimit } from '../../../../../../data/PaymentRequests';
import SelectorDropdown from '../../../../../../components/SelectorDropdown';

interface props {
  customerSearchCustomerDetailsId: string | null;
}

const DEFAULT_SOFTWARE_CATEGORIES = [
  "Enterprise Resource Planning Systems (ERP)",
  "Customer Relationship Management Systems (CRM)",
  "Manufacturing Execution Systems (MES)",
  "Warehouse Management Systems (WMS)",
  "Business Intelligence Systems (BI)",
  "Human Resource Management Systems (HRM)",
  "Supply Chain Management Systems (SCM)",
  "Product Lifecycle Management Systems (PLM)",
  "Quality Management Systems (QMS)",
  "Environmental Social Governance Systems (ESG)",
];

const StartSelectionForm = ({ customerSearchCustomerDetailsId }: props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [textInput, setTextInput] = useState("");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [softwareCategory, setSoftwareCategory] = useState<string>("");

  const [rateLimitReached, setRateLimitReached] = useState(true);
  const handleFetchRateLimit = async () => {
    const response = await getSelectionPortalPlanLimit();
    if (response.success) {
      console.log(response.data.maxNumSelections, response.data.currNumSelections, response.data.currNumSelections < response.data.maxNumSelections);
      setRateLimitReached(response.data.maxNumSelections <= response.data.currNumSelections);
    }
  };

  const handleSubmit = async () => {
    setError(false);
    let resp = await startNewSelection(customerSearchCustomerDetailsId, softwareCategory);
    if (!resp.ok) return setError(true);
    navigate(`/user-area/selection-portal/${resp.selectionId}`);
  };

  useEffect(() => { handleFetchRateLimit(); }, []);
  if (rateLimitReached) {
    return (
      <div>
        <div className='flex flex-col items-center gap-2'>
          <div className="md:max-w-[800px] flex flex-col items-center text-center p-2">
            <div className="lg:text-4xl text-2xl font-semibold mt-4">
              {t('selectionForm.rateLimitReached.title')}
            </div>
            <p className="lg:text-md mt-8">
              {t('selectionForm.rateLimitReached.message')}
            </p>
            <div className='w-fit mt-8'>
              <MainButton text={t('selectionForm.rateLimitReached.contactSales')} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className='flex flex-col items-center justify-center gap-2'>
          <div className="md:max-w-[800px] flex flex-col items-center justify-center text-center p-2">
            <div className="lg:text-4xl text-2xl font-semibold mt-4">
              {t('selectionForm.startSelection.title')}
            </div>
            <p className="lg:text-md mt-8">
              {t('selectionForm.startSelection.message')}
            </p>
            {
              !customerSearchCustomerDetailsId ?
              <div className='mt-4 w-1/2'>
                <SoftwareCategoryDropdown 
                  categories={DEFAULT_SOFTWARE_CATEGORIES} 
                  label={''} 
                  placeholderLabel={t('selectionForm.startSelection.dropdownPlaceholder')} 
                  selectedCategory={softwareCategory} 
                  handleSetSelectedCategory={(category: any) => setSoftwareCategory(category)} 
                />
              </div> :
              <></>
            }
            <div className='w-fit mt-8'>
              <MainButton text={t('selectionForm.startSelection.startButton')} func={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const SoftwareCategoryDropdown = ({ label, selectedCategory, handleSetSelectedCategory, placeholderLabel, categories }: { categories: string[], label: string, placeholderLabel: string, selectedCategory: string, handleSetSelectedCategory: (category: any) => void }) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [textInput, setTextInput] = useState<string | null>(null);

  return (
    <div className='w-full'>
      <div 
        onClick={() => setDropdownOpen(prev => !prev)}
        className={`cursor-pointer bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full truncate ${selectedCategory == "" ? "text-gray-400" : "text-gray-900" }`}>
        {selectedCategory || placeholderLabel}
      </div>
      {
        !dropdownOpen ?
        <></> :
        <>
          <div className='absolute z-[6] bg-white border shadow rounded-lg p-2'>
            <div className='space-y-2 mb-2'>
              {
                categories.filter(item => item !== null).map((item: any, index: number) => {
                  return (
                    <div 
                      onClick={() => { handleSetSelectedCategory(item); setTextInput(null); setDropdownOpen(false); }}
                      className={`${selectedCategory == item ? "bg-primary text-white" : "bg-white"} cursor-pointer border rounded-lg p-2 hover:bg-primary hover:text-white`} 
                      key={index}>
                      {item}
                    </div>
                  );
                })
              }
            </div>
            <hr />
            <input
              onChange={(e) => { handleSetSelectedCategory(""); setTextInput(e.target.value); }}
              value={textInput || ""}
              onBlur={() => { if (textInput) handleSetSelectedCategory(textInput); }}
              onKeyDown={(e) => { if (e.key == "Enter" && textInput) { handleSetSelectedCategory(textInput); setDropdownOpen(false); } }}
              placeholder={t('selectionForm.startSelection.dropdownInsertNew')}
              className={`${selectedCategory == textInput ? "border-primary" : ""} bg-gray-50 cursor-pointer border rounded-lg p-2 w-full mt-2`} 
            />
          </div>
          <div onClick={() => setDropdownOpen(false)} className='fixed z-[4] top-0 left-0 w-screen h-screen'></div>
        </>
      }
    </div>
  );
};

export default StartSelectionForm;