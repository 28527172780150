import React, { useEffect } from 'react'
import { CrossIcon } from 'swap-frontend-library';

interface props {
    children: JSX.Element;
    isOpen : boolean;
    setOpen : React.Dispatch<React.SetStateAction<boolean>> | undefined;
    size?: string;
    additionalStyles?: string;
    overlayStyles?: string;
    height?: string;
    width?: string
}

const FormModal = ({children, isOpen, setOpen, size, additionalStyles, overlayStyles, height, width} : props) => {

  useEffect(() => {
    if (isOpen) {document.body.style.overflow = 'hidden';}

    return () => {document.body.style.overflow = 'auto';}
  }, [])

  const handleClose = () => {
    document.body.style.overflow = 'auto';
    if (typeof setOpen !== "undefined") setOpen((prev) : boolean => !prev);
  }

  return (
    <div className={`flex items-center justify-center fixed top-0 left-0 w-screen h-[100dvh] z-[10000] py-8`}>

      <div onClick={() => handleClose()} className={`${overlayStyles || "bg-gray-100 opacity-50"} absolute w-full h-full z-[100]`}></div>
      
      <div className={`flex ${additionalStyles} relative bg-white rounded-lg shadow-2xl ${(size == "large") ? (`xl:w-[90%] md:w-[95%] ${height || "h-full"}`) : (`${width || "xl:w-1/2 md:w-[70%]"} ${height || "h-fit"}`)} mx-auto my-auto z-[100]`}>
        <button onClick={() => handleClose()} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm p-1.5 items-center" data-modal-hide="authentication-modal">
          <div className='w-5 h-5'><CrossIcon /></div>
          <span className="sr-only">Close modal</span>
        </button>
      
        <div className="py-6 px-2 xl:px-8 h-full w-full overflow-y-auto">
          {children}
        </div>
    </div>
    
  </div>
  );
}

export default FormModal