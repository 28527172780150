import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Analysis from './features/Analysis';
import Results from './features/Results';
import SocketInjectorMatchingEngine from '../../../../../../_socket/SocketInjectorMatchingEngine';
import './styles.css';
import { LoadingSpinner } from 'swap-frontend-library';
import { getSelectionPortalPlanLimit } from '../../../../../../data/PaymentRequests';

const MatchingAnalysis = () => {
    const [selectedTab, setSelectedTab] = useState("analysis");

    const [searchParams, setSearchParams] = useSearchParams()
    const [queryUrl, setQueryUrl] = useState("");
    
    const [customerSearch, setCustomerSearch] = useState<any>()
    const [createdDateString, setCreatedDateString] = useState("")
    const [unlockedBy, setUnlockedBy] = useState("")
    
    const [analysisFinished, setAnalysisFinished] = useState(false)
    
    useEffect(() => {
        setQueryUrl(searchParams.get("url") || "")
        setCustomerSearch(null);
    },[])
    
    useEffect(() => {
        setAnalysisFinished(false);
        setQueryUrl(searchParams.get("url") || "")
        setCustomerSearch(null);
    },[selectedTab])
    
    useEffect(() => {
        if (typeof customerSearch === "undefined" || customerSearch == null) return
        const createdDateParsed : Date = new Date(Date.parse(customerSearch.createdDate));
        const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;
    
        let premiumLevel : string | undefined = undefined;
        if (customerSearch.customerSearch.unlockedCustomerSearch.length > 0) {
            premiumLevel = customerSearch.customerSearch.unlockedCustomerSearch[0].orderType;
        }
    
        let unlockedBy = "";
        if (customerSearch.customerSearch.unlockedCustomerSearch.length > 0) {
            unlockedBy = customerSearch.customerSearch.unlockedCustomerSearch[0].email
        }

    },[customerSearch])

    if (typeof customerSearch === "undefined") return <div className='my-8'><LoadingSpinner /></div>
    return (
    <div className='pt-4 h-full w-full relative pt-8'>
        <SocketInjectorMatchingEngine />
        {/* <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
            Matching Area
        </h1> */}

        <div className='absolute z-[2] left-0 top-4 w-full px-4'>
            <div className='w-full h-40 bg-white/70 backdrop-blur-md rounded-lg shadow-lg pt-4 flex flex-col justify-between gap-4'>

                <div className='w-full flex items-center justify-between px-4 gap-8 border-b pb-4'>
                    {
                        customerSearch == null ? 
                        <div className='max-sm:text-lg text-2xl font-semibold truncate'>{queryUrl}</div> :
                        <div className='items-center gap-4 w-full'>
                            <div className='gap-1 flex'>
                                <span className='text-sm font-light'>Created by {unlockedBy}</span>
                                <span className='text-sm font-light'>({createdDateString})</span>
                                {/* <span className='text-sm font-light'>{premiumLevel}</span> */}
                            </div>
                            <div className='max-sm:text-lg text-2xl font-semibold truncate'>{queryUrl}</div>
                            {/* (freeSearch == "1") ? ("Free") : ("Paid") */}
                        </div>
                    }

                    <div 
                    className='cursor-pointer absolute right-5 rounded-full border-blue-700 text-blue-700 hover:to-blue-700 hover:from-[#57e3ff] bg-gradient-to-br from-blue-400 to-[#57e3ff] border-2 shadow shadow-primary/50 font-semibold px-2 transition-all'>Expert mode</div>
                </div>

                <div className='flex text-lg font-semibold'>
                <div onClick={() => setSelectedTab("analysis")} className={`cursor-pointer w-full text-center border-b-8 rounded-bl-lg p-2 ${selectedTab == "analysis" ? "border-primary" : "border-transparent hover:border-gray-100"}`}>
                        Analysis
                    </div>
                    <div onClick={() => {if (analysisFinished) {setSelectedTab("results")}}} className={`w-full text-center border-b-8 rounded-br-lg p-2 ${selectedTab == "results" ? "border-primary" : analysisFinished ? "border-transparent hover:border-gray-100 cursor-pointer" : "border-transparent cursor-not-allowed"}`}>
                        Results
                    </div>
                </div>
            </div>
        </div>

        <div className='h-full'>
            {
                selectedTab == "analysis" ? <Analysis queryUrl={queryUrl} analysisFinished={analysisFinished} setAnalysisFinished={setAnalysisFinished} jumpToResults={() => setSelectedTab("results")} /> :
                selectedTab == "results" ? <Results queryUrl={queryUrl} /> : <></>
            }
        </div>

    </div>
  )
}

export default MatchingAnalysis