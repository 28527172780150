import React, { useState } from 'react'
import TypingText from '../components/TypingText';
import { ArrowDownIcon } from 'swap-frontend-library';

const CompanyDescriptionAnalysis = ({ companyDescription, open, typing, plainStyle } : { companyDescription: string, open?: boolean | null, typing?: boolean, plainStyle?: boolean }) => {
    const [visible, setVisible] = useState(typeof open !== "undefined" && open != null ? open : true);

    return (
        <div 
        // onClick={(e) => {e.stopPropagation(); !visible && open != null ? setVisible(true) : null}} 
        className={`w-full h-fit p-10 max-md:px-8 max-md:py-10 ${ plainStyle ? "" : "shadow border bg-white rounded-lg" } expand-down ${visible ? "" : "cursor-pointer"}`}>
            <div className='relative flex items-center justify-between'>
                <p className='font-semibold text-xl'>Overview of your company</p>
                { 
                    (open != null || typeof open === "undefined") ? (
                        <div onClick={() => setVisible(prev => !prev)} className={`cursor-pointer w-8 h-8 text-gray-300 ${visible ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
                    ) : (
                        <></>
                    ) 
                }
            </div>

            {
                !visible ? <></> : (
                <div className='mt-8'>
                    <div className='w-full h-full'>
                        <TypingText disabled={!typing} text={companyDescription} />
                    </div>
                </div>
                )
            }
        </div>
    )
}

export default CompanyDescriptionAnalysis