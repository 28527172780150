import React, { useState } from 'react'
import { ArrowDownIcon } from 'swap-frontend-library';
import BigRingChartFilterWorking from '../components/BigRingChartFilterWorking';

const IndustryClassificationAnalysis = ({ industryClassification } : { industryClassification : any }) => {
    const [industryClassificationVisible, setIndustryClassificationVisible] = useState(true);
  
    return (
        <div 
        // onClick={() => {!industryClassificationVisible ? setIndustryClassificationVisible(true) : null}} 
        className={`w-full p-10 max-sm:p-4 shadow border rounded-lg bg-white expand-down ${industryClassificationVisible ? "" : "cursor-pointer"}`}>
            <div className='relative flex items-center justify-between max-md:mt-8'>
                <p className='font-semibold text-xl'>Your sector focus</p>
                <div onClick={() => setIndustryClassificationVisible(prev => !prev)} className={`cursor-pointer w-8 h-8 text-gray-300 ${industryClassificationVisible ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
            </div>

            {
                !industryClassificationVisible ? <></> : (
                <div className='mt-8'>
                    <div className='w-full flex items-center justify-center'>
                        {/* colors={colors}  */}
                        <BigRingChartFilterWorking subTitle={'Requirements'} title={`Your industry`} labels={industryClassification.map((item : any) => item.class)} data={industryClassification.map((item : any) => Math.round(item.score*100))} />
                    </div>
                </div>
                )
            }
        </div>
  )
}

export default IndustryClassificationAnalysis