import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteSelectionRequirement, getSelectionRequirements, setSelectionRequirementStatus } from '../../../../../../data/SelectionPortalRequests';
import TableHeader from '../../../../../../components/TableHeader';
import RequirementTableRow from './RequirementTableRow';
import SpeedDial from '../../components/SpeedDial';
import MainButton from '../../../../../../components/MainButton';
import SearchBarFuzzy from '../../../../../../components/SearchBarFuzzy';
import PlusIcon from '../../../../../../assets/img/icons/PlusIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import SelectionGuideInjector from '../../components/SelectionGuideInjector';
import { ArrowDownIcon, InfoIcon } from 'swap-frontend-library';

interface props {
    selectedTab: string,
    selectionId: string,
    handleOpenRequirement: (a: string, b: string) => void,
}

const SelectionRequirementsTable = ({ selectedTab, selectionId, handleOpenRequirement }: props) => {
    const { t } = useTranslation();
    const role = useSelector((state: RootState) => state.user.role);
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const ref = useRef<any>();
    const [loadingError, setLoadingError] = useState(false);
    const [selectionRequirements, setSelectionRequirements] = useState([]);
    const [selectionRequirementFiltered, setSelectionRequirementFiltered] = useState<any[]>([]);
    const [selectionRequirementFilteredAndSearched, setSelectionRequirementFilteredAndSearched] = useState<any[]>([]);
    const [openMenuRequirement, setOpenMenuRequirement] = useState<string>();
    const [archivedVisible, setArchivedVisible] = useState(false);

    const [selectedRequirementCategory1, setSelectedRequirementCategory1] = useState("");
    const [selectedRequirementCategory2, setSelectedRequirementCategory2] = useState("");
    const [selectedRequirementCategory3, setSelectedRequirementCategory3] = useState("");
    const [selectedRequirementCategory, setSelectedRequirementCategory] = useState<any[]>([]);

    const [uniqueRequirementCategories, setUniqueRequirementCategories] = useState<any>({});
    const [uniqueRequirementCategoriesFiltered, setUniqueRequirementCategoriesFiltered] = useState<any>({});
    const [vendorFulfillments, setVendorFulfillments] = useState<any>(undefined);

    const [sortBy, setSortBy] = useState<any>({ key: "selectionRequirements_requirement", asc: true });

    const handleFetchRequirements = async (selectionId: string) => {
        const data = await getSelectionRequirements(selectionId);
        if (data.ok) {
            const requirements = data.data.data.selectionRequirements.map((value: any) => {
                value.selectionRequirements_category = JSON.parse(value.selectionRequirements_category)
                return (value)
            })

            setSelectionRequirements(requirements);
            setSelectionRequirementFiltered(requirements);

            if (role == "guest_vendor") setVendorFulfillments(data.data.data.vendorFulfillments);
            setUniqueRequirementCategories(data.data.data.uniqueCategories);
        } else {
            setLoadingError(true);
        }
    }

    const handleFilterRequirements = () => {
        // console.log(selectedRequirementCategory)
        if (selectedRequirementCategory[0]) {
            const filteredRequirements = selectionRequirements.filter((requirement: any) =>
                ((selectedRequirementCategory[0] == requirement.selectionRequirements_category[0]) &&
                    (!selectedRequirementCategory[1] || (selectedRequirementCategory[1] == requirement.selectionRequirements_category[1]))) &&
                (!selectedRequirementCategory[2] || (selectedRequirementCategory[2] == requirement.selectionRequirements_category[2]))
            )
            // console.log(filteredRequirements)
            setSelectionRequirementFiltered(filteredRequirements);
        } else {
            setSelectionRequirementFiltered(selectionRequirements);
        }
    }

    const handleSort = (currentRequirements: any) => {
        return currentRequirements.sort((a: any, b: any) => {
            const asc = 2 * Number(sortBy.asc) - 1;
            let aVal = a[sortBy.key];
            let bVal = b[sortBy.key];

            if (bVal > aVal) return -asc;
            if (bVal < aVal) return asc;
            if (bVal === aVal) {
                aVal = a["selectionRequirements_requirement"];
                bVal = b["selectionRequirements_requirement"];
                if (bVal > aVal) return -1;
                if (bVal < aVal) return 1;
            }
            return 0;
        });
    }

    const handleSetRequirementStatus = async (requirementId: string, status: string) => {
        if (await setSelectionRequirementStatus(requirementId, status)) {
            handleFetchRequirements(selectionId);
        }
    }

    const handleDeleteRequirement = async (requirementId: string) => {
        if (await deleteSelectionRequirement(requirementId)) {
            handleFetchRequirements(selectionId);
        }
    }

    useEffect(() => { handleFetchRequirements(selectionId); }, [])
    // useEffect(() => { if (selectedTab == "") handleFetchRequirements(selectionId) }, [selectedTab])

    useEffect(() => { handleFilterRequirements(); }, [selectedRequirementCategory])
    useEffect(() => { setSelectionRequirementFilteredAndSearched(selectionRequirementFiltered) }, [selectionRequirementFiltered])

    const toggleExcluded = () => {
        setArchivedVisible(prev => !prev);
    }

    useEffect(() => {
        if (archivedVisible) {
            setTimeout(() => ref.current.scrollIntoView({ inline: 'center', behavior: 'smooth' }), 100)
            // ref.current.scrollIntoView({behaviour: "smooth"});
        }
    }, [archivedVisible])

    const handleSetCategory1 = (category: string) => {
        if (selectedRequirementCategory1 == "" || selectedRequirementCategory1 != category) {
            setSelectedRequirementCategory1(category);
        } else {
            setSelectedRequirementCategory1("");
            setSelectedRequirementCategory2("");
            setSelectedRequirementCategory3("");
        }
    }

    const handleSetCategory2 = (category: string) => {
        if (selectedRequirementCategory2 == "" || selectedRequirementCategory2 != category) {
            setSelectedRequirementCategory2(category);
        } else {
            setSelectedRequirementCategory2("");
            setSelectedRequirementCategory3("");
        }
    }

    const handleSetCategory3 = (category: string) => {
        if (selectedRequirementCategory3 == "" || selectedRequirementCategory3 != category) {
            setSelectedRequirementCategory3(category);
        } else {
            setSelectedRequirementCategory3("");
        }
    }

    useEffect(() => {
        setSelectedRequirementCategory([(selectedRequirementCategory1) ? (selectedRequirementCategory1) : (null), (selectedRequirementCategory2) ? (selectedRequirementCategory2) : (null), (selectedRequirementCategory3) ? (selectedRequirementCategory3) : (null)])
    }, [selectedRequirementCategory1, selectedRequirementCategory2, selectedRequirementCategory3])

    let tableColumnHeaders: any[] = [
        <div onClick={() => setSortBy((prev: any) => ({ key: "selectionRequirements_requirement", asc: prev.key == "selectionRequirements_requirement" ? !prev.asc : true }))} className={`flex items-center gap-2 cursor-pointer`}>
            {t('requirementsTable.title')}<SortDirectionArrow sortKey='selectionRequirements_requirement' sortBy={sortBy} />
        </div>
    ];
    if (role == "guest_vendor") {
        tableColumnHeaders = [
            ...tableColumnHeaders,
            t('requirementsTable.fulfillment'),
            <div className='flex items-center gap-2' title={t('requirementsTable.customizationEffort')}>
                {t('requirementsTable.customizingEffort')}<div className='w-5 h-5'><InfoIcon /></div>
            </div>,
            t('requirementsTable.comment')
        ];
    } else if (role == "customer" || role == "platform_manager" || role == "guest_seeker") {
        tableColumnHeaders = [
            ...tableColumnHeaders,
            <div onClick={() => setSortBy((prev: any) => ({ key: "selectionRequirements_importance", asc: prev.key == "selectionRequirements_importance" ? !prev.asc : true }))} className={`flex items-center gap-2 cursor-pointer`}>
                {t('requirementsTable.priority')}<SortDirectionArrow sortKey='selectionRequirements_importance' sortBy={sortBy} />
            </div>,
            <div onClick={() => setSortBy((prev: any) => ({ key: "selectionRequirements_requirementStatus", asc: prev.key == "selectionRequirements_requirementStatus" ? !prev.asc : true }))} className={`flex items-center gap-2 cursor-pointer`}>
                {t('requirementsTable.status')}<SortDirectionArrow sortKey='selectionRequirements_requirementStatus' sortBy={sortBy} />
            </div>,
            projectRole == "selection_admin" ? t('requirementsTable.action') : ""
        ];
    }

    if (loadingError) {
        return (
            <div className='w-full p-4 pt-6'>
                <div className='bg-white rounded-lg shadow p-12 text-center'>
                    <p>{t('requirementsTable.loadingError')}</p>
                </div>
            </div>
        );
    }
    return (
        <div className='h-full w-full flex relative xl:pl-64 md:pl-44 pl-32'>
            {/* Sidebar for Categories */}
            <div className="absolute left-0 h-full xl:w-64 md:w-44 w-32 flex flex-col font-medium whitespace-nowrap bg-white border-r border-solid basis-0 leading-[150%]">
                <div className="flex flex-col px-3 pb-1.5 overflow-y-auto text-base text-gray-900">
                    {/* <div className="">
                <SearchBarFuzzy translatedPlaceholderItem={'requirement category'} filterKeys={[]} originalData={[]} setFilteredData={() => {}} />
            </div> */}

                    {
                        Object.keys(uniqueRequirementCategories).map((category1: string, index: number) => {
                            if (category1 == null) return (<></>)
                            return (
                                <div>
                                    <div onClick={() => handleSetCategory1(category1)} className={`flex flex-col cursor-pointer justify-center px-2 py-1.5 mt-4 w-full ${(selectedRequirementCategory1 == category1) ? ("bg-primary text-white") : ("hover:bg-primary2")} rounded-lg`}>
                                        <div className="flex gap-3 justify-between">
                                            <div className="w-full truncate">{category1}</div>

                                            <div className={`flex items-center ${(selectedRequirementCategory1 == category1) ? ("rotate-180") : ("")}`}><div className={`w-4 h-4 ${(selectedRequirementCategory1 == category1) ? ("white") : ("black")}`}><ArrowDownIcon /></div></div>
                                        </div>
                                    </div>
                                    {
                                        (selectedRequirementCategory1 == category1) ? (
                                            <div className={`${(selectedRequirementCategory1 == category1) ? ("bg-gray-50") : ("")} rounded-lg py-1.5`}>
                                                <div className={`flex flex-col justify-center pb-1.5 pl-5`}>

                                                    {
                                                        Object.keys(uniqueRequirementCategories[selectedRequirementCategory1]).map((category2: string, index: number) => {
                                                            if (category2 == null) return (<></>)
                                                            return (
                                                                <div>
                                                                    <div onClick={() => handleSetCategory2(category2)}
                                                                        className={`flex flex-col mt-1.5 justify-center px-2 py-1.5 w-full cursor-pointer ${(selectedRequirementCategory2 == category2) ? ("bg-primary text-white") : ("hover:bg-primary2")} rounded-lg`}>
                                                                        <div className="flex gap-1 justify-between">
                                                                            <div className="w-full truncate">{category2}</div>
                                                                            <div className={`flex items-center ${(selectedRequirementCategory2 == category2) ? ("rotate-180") : ("")}`}><div className={`w-4 h-4 ${(selectedRequirementCategory2 == category2) ? ("white") : ("black")}`}><ArrowDownIcon /></div></div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        (selectedRequirementCategory2 == category2) ? (
                                                                            <div className="flex flex-col gap-y-3 justify-center py-1.5 pl-5 mt-2.5">
                                                                                {
                                                                                    uniqueRequirementCategories[selectedRequirementCategory1][selectedRequirementCategory2].map((category3: string[], index: number) => {
                                                                                        if (category3[0] == null) return (<></>)
                                                                                        if (category3.length > 0 && !category3[1]) {
                                                                                            return (
                                                                                                <div onClick={() => handleSetCategory3(category3[0])}
                                                                                                    className={`cursor-pointer rounded-lg px-2 py-1.5 truncate ${(selectedRequirementCategory3 == category3[0]) ? ("bg-primary text-white ") : ("hover:bg-primary2")}`}>
                                                                                                    {category3[0]}
                                                                                                </div>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <div onClick={() => handleSetCategory3(category3.join(", "))}
                                                                                                    className={`cursor-pointer rounded-lg px-2 py-1.5 hover:bg-primary2 truncate ${(selectedRequirementCategory3 == category3.join(", ")) ? ("bg-primary text-white hover:bg-primary") : ("")}`}>
                                                                                                    {category3.join(", ")}
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        ) : (<></>)
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        ) : (<></>)
                                    }
                                </div>
                            )
                        })
                    }

                </div>

                {/* Validate / Submit */}
                <SelectionGuideInjector selectionId={selectionId} acceptedTaskCodes={["requ-vali_reqs", "requ-subm_reqs", "requ-revi_reqs", "requ-subm_reqs-vendor"]} additionalData={{ finalized: vendorFulfillments?.finalized }} />
            </div>

            <div className='w-full h-full overflow-y-scroll'>
                {/* Search / Button Bar */}
                <div className="flex items-center justify-between h-16 px-4">
                    <div className='h-10 w-full lg:w-96'>
                        <SearchBarFuzzy translatedPlaceholderItem={t('requirementsTable.searchPlaceholder')} originalData={selectionRequirementFiltered} setFilteredData={setSelectionRequirementFilteredAndSearched} filterKeys={["selectionRequirements_requirement"]} />
                    </div>
                    <div className="flex justify-between items-center font-medium text-white h-10 max-md:w-full">
                        {
                            projectRole == "selection_admin" ? <MainButton text={t('requirementsTable.addRequirement')} height='h-11' icon={<div className='text-white'><PlusIcon iconColor='currentColor' /></div>} text_location='right' func={() => handleOpenRequirement("-1", t('requirementsTable.newRequirement'))} /> : <></>
                        }
                    </div>
                </div>
                <div className='w-full overflow-x-scroll'>
                    <table className="w-full relative text-sm text-left text-gray-500">
                        <TableHeader header={tableColumnHeaders} />
                        {
                            handleSort(selectionRequirementFilteredAndSearched).filter((item: any) => (item.selectionRequirements_requirementStatus != "archived")).map((selectionRequirement: any, index: number) => {
                                return (
                                    <RequirementTableRow index={index} selectionRequirement={selectionRequirement} vendorFulfillment={{ fulfillment: vendorFulfillments?.fulfillments[selectionRequirement.selectionRequirements_id], finalized: vendorFulfillments?.finalized }} openMenuRequirement={openMenuRequirement} setOpenMenuRequirement={setOpenMenuRequirement} handleOpenRequirement={handleOpenRequirement} handleSetRequirementStatus={handleSetRequirementStatus} handleDeleteRequirement={handleDeleteRequirement} handleFetchRequirements={() => handleFetchRequirements(selectionId)} />
                                );
                            })
                        }
                        {
                            projectRole == "selection_admin" ? (
                                <>
                                    <td colSpan={5}>
                                        <div onClick={toggleExcluded}
                                            className={`absolute flex items-center relative cursor-pointer ${archivedVisible ? "my-4" : "mt-4 mb-2"}`}>
                                            <div className='grow my-2 border-t border-gray-400 border-4'>
                                            </div>
                                            <div className='mx-4 flex flex-col items-center text-gray-400'>
                                                {
                                                    (archivedVisible) ? t('requirementsTable.hideArchived') : t('requirementsTable.seeArchived')
                                                }
                                            </div>
                                            <div className='grow my-2 border-t border-gray-400 border-4'>
                                            </div>
                                        </div>
                                    </td>
                                    {
                                        (archivedVisible) ? (
                                            selectionRequirementFiltered.filter((item: any) => (item.selectionRequirements_requirementStatus == "archived")).map((selectionRequirement: any, index: number) => {
                                                return (
                                                    <RequirementTableRow index={index} selectionRequirement={selectionRequirement} vendorFulfillment={{ fulfillment: vendorFulfillments?.fulfillments[selectionRequirement.selectionRequirements_id], finalized: vendorFulfillments?.finalized }} openMenuRequirement={openMenuRequirement} setOpenMenuRequirement={setOpenMenuRequirement} handleOpenRequirement={handleOpenRequirement} handleSetRequirementStatus={handleSetRequirementStatus} handleDeleteRequirement={handleDeleteRequirement} handleFetchRequirements={() => handleFetchRequirements(selectionId)} />
                                                );
                                            })
                                        ) : (<></>)
                                    }
                                </>
                            ) : <></>
                        }
                        <div ref={ref} className='invisible'></div>
                    </table>
                </div>
            </div>

            <SpeedDial speedDials={projectRole != "selection_admin" ? [] : [
                { tooltip: t('requirementsTable.addRequirementTooltip'), onClick: () => handleOpenRequirement("-1", t('requirementsTable.newRequirement')) },
            ]} defaultDials={true} currentContext='requirements' selectionId={selectionId} reloadTaskTrigger={selectionRequirements} />
        </div>
    );
}

const SortDirectionArrow = ({ sortKey, sortBy }: { sortKey: string, sortBy: any }) => {
    return (
        <div className={`h-4 w-4 ${sortBy.key == sortKey ? "bg-gray-500 rounded-full text-white" : ""} ${sortBy.key == sortKey && !sortBy.asc ? "rotate-180" : ""} transition-all duration-500`}><ArrowDownIcon /></div>
    )
}

export default SelectionRequirementsTable;