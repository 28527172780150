import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from '../../../../../../../components/Toggle';
import { changeRankingDisabled, changeRankingInclusion, changeVendorContactEmail, changeVendorFinancialData, getSelectionVendorAccessSecret, submitRequirementsToVendors, submitScenariosToVendors } from '../../../../../../../data/SelectionPortalRequests';
import { ArrowDownIcon, ArrowRightIcon, CheckmarkIcon } from 'swap-frontend-library';
import TextInput from '../../../../../../../components/TextInput';
import ClipboardIcon from '../../../../../../../assets/img/icons/ClipboardIcon';
import MainButton from '../../../../../../../components/MainButton';
import SidebarRankingInfo from '../../../vendor-ranking/components/SidebarRankingInfo';
import FormModal from '../../../../../../../components/FormModal';
import FormField from '../../../../../../../components/FormField';
import SelectorDropdown from '../../../../../../../components/SelectorDropdown';

const SelectionRankingSettingRow = ({selectionId, selectionSoftwareProduct, handleFetchRanking, quickReload} : {selectionId: string, selectionSoftwareProduct:any, handleFetchRanking: () => {}, quickReload : boolean}) => {
    const { t } = useTranslation();
    const [detailOpen, setDetailOpen] = useState(false);

    return (
    <div className='flex justify-between items-center py-4'>
        
        <div className={`${!selectionSoftwareProduct.disabled ? "grow" : "w-full"} truncate flex flex-col justify-center relative`}>
            <div title={!selectionSoftwareProduct.softwareProduct.customerDetails ? "Platform-Layer" : "Consultant-Layer"} className={`absolute cursor-pointer w-5 h-5 rounded-full ${!selectionSoftwareProduct.softwareProduct.customerDetails ? "bg-gray-200" : "bg-primary text-white"} border flex items-center justify-center text-sm font-semibold p-1 text-gray-600`}>
                {!selectionSoftwareProduct.softwareProduct.customerDetails ? "P" : "C"}
            </div>
            <div className='ml-10'>
                <div className='flex space-x-4 text-lg font-bold'>
                    <p>{selectionSoftwareProduct.softwareProduct.productName}</p>
                    {/* <p className='font-light italic'>{vendorRankingData.softwareFulfillment.note}</p> */}
                </div>
                <div>
                    {selectionSoftwareProduct.softwareProduct.vendorDetails.company}
                </div>
            </div>
        </div>
        
        {/* {
            !item.disabled ? (
                <div className='grow ml-8'><TextInput onBlur={() => handleChangeVendorContactEmail()} textValue={vendorContactEmail} setTextValue={setVendorContactEmail} placeholder='Insert vendor contact email' /></div>
            ) : <></>
        } */}
        
        <SelectionSoftwareProductSettings selectionId={selectionId} selectionSoftwareProduct={selectionSoftwareProduct} handleFetchRanking={handleFetchRanking} quickReload={quickReload} />

        {
            !selectionSoftwareProduct.disabled ? 
            <div className='ml-8'><MainButton text={t('selection.details')} func={() => setDetailOpen(true)} icon={<div className='h-5 w-5'><ArrowRightIcon /></div>} /></div> :
            <></>
        }

        { detailOpen ? <div><FormModal isOpen={detailOpen} setOpen={setDetailOpen}><SelectionSoftwareProductDetails selectionId={selectionId} selectionSoftwareProduct={selectionSoftwareProduct} handleFetchRanking={handleFetchRanking} quickReload={quickReload} /></FormModal></div> : <></> }

    </div>
  )
}

const SelectionSoftwareProductSettings = ({ selectionId, selectionSoftwareProduct, handleFetchRanking, quickReload } : { selectionId : string, selectionSoftwareProduct : any, handleFetchRanking: () => {}, quickReload : boolean }) => {
    const { t } = useTranslation();
    const [included, setIncluded] = useState<boolean>();
    const [disabled, setDisabled] = useState<boolean>();
    const [includedSuccessfully, setIncludedSuccessfully] = useState<boolean>(false);
    const [requirementsEvaluationLink, setRequirementsEvaluationLink] = useState<string | null>(null);
    const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);

    const redirectLink = encodeURIComponent(`/user-area/selection-portal/${selectionId}/`);
    const link = `${sessionStorage.domain}/signin?redirectTo=${redirectLink}&guestType=vendor&accessCode=`;
    const handleFetchVendorAccessSecret = async () => {
        const response = await getSelectionVendorAccessSecret(selectionSoftwareProduct.id)
        if (response.ok && response.data.vendorAccessSecret) {
          setRequirementsEvaluationLink(response.data.vendorAccessSecret == null ? null : link + response.data.vendorAccessSecret);
        }
    }

    useEffect(() => {
        if (disabled) setIncluded(false);
    }, [disabled])

    const handleChangeInclusion = async (include : boolean | undefined) => {
        if (typeof include === "undefined" || include == selectionSoftwareProduct.included) return
        if (await changeRankingInclusion(selectionSoftwareProduct.id, include)) {
            if (quickReload) handleFetchRanking();
        }
    }
    
    const handleChangeDisabled = async (disable : boolean | undefined) => {
        // console.log(item.softwareProduct.productName , selectionId, item.softwareFulfillment.id, item.id, disable)
        if (typeof disable === "undefined" || disable == selectionSoftwareProduct.disabled) return 
        if (await changeRankingDisabled(selectionId, selectionSoftwareProduct.softwareFulfillment.id, selectionSoftwareProduct.id, disable)) {
            if (quickReload) handleFetchRanking();
        }
    }

    const handleSubmitRequirementsToVendor = async (submit: boolean) => {
        if (await submitRequirementsToVendors(selectionSoftwareProduct.id, submit)) {
            if (quickReload) handleFetchRanking();
        }
    }
    
    const handleSubmitScenariosToVendor = async (submit: boolean) => {
        if (await submitScenariosToVendors(selectionSoftwareProduct.id, submit)) {
            if (quickReload) handleFetchRanking();
        }
    }

    useEffect( () => {
        handleChangeInclusion(included);
    }, [included])
    
    useEffect( () => {
        if (disabled) handleChangeInclusion(false);
        handleChangeDisabled(disabled);
    }, [disabled])

    useEffect(() => {
        if (copiedToClipboard) {
          const timeoutId = setTimeout(() => {
            setCopiedToClipboard(false);
          }, 2000);
      
          return () => clearTimeout(timeoutId);
        }
    }, [copiedToClipboard]);

    useEffect(() => {
        setIncluded(selectionSoftwareProduct.included);
        setDisabled(selectionSoftwareProduct.disabled);

        // Only for already included systems
        if (!selectionSoftwareProduct.disabled) handleFetchVendorAccessSecret();
    }, [selectionSoftwareProduct])

    return (
        <>
            {
                !selectionSoftwareProduct.disabled ? (
                    <div className='mx-8'>
                        <p className='font-semibold'>{t('selection.accessRights')}</p>
                        <div className='flex space-x-2 items-center'>
                            <input id={`reqs_${selectionSoftwareProduct.id}`} type='checkbox' checked={selectionSoftwareProduct.requirementsSubmitted != null} onChange={(e) => handleSubmitRequirementsToVendor(e.target.checked)} />
                            <label htmlFor={`reqs_${selectionSoftwareProduct.id}`} className='font-light'>{t('selection.requirements')}</label>
                        </div>
                        <div className='flex space-x-2 items-center'>
                            <input id={`scen_${selectionSoftwareProduct.id}`} type='checkbox' checked={selectionSoftwareProduct.scenariosSubmitted != null} onChange={(e) => handleSubmitScenariosToVendor(e.target.checked)} />
                            <label htmlFor={`scen_${selectionSoftwareProduct.id}`} className='font-light'>{t('selection.scenarios')}</label>
                        </div>
                        {/* <div className='flex space-x-2 items-center'>
                            <input id='reqs' type='checkbox' />
                            <label htmlFor='reqs' className='font-light'>Presentations</label>
                        </div> */}
                    </div>
                ) : <></>
            }
            
            {
                !selectionSoftwareProduct.disabled ? (        
                <div onClick={() => {if (requirementsEvaluationLink) {setCopiedToClipboard(true); navigator.clipboard.writeText(requirementsEvaluationLink ? requirementsEvaluationLink : "")}}}
                className={`ml-8 w-32 h-10 cursor-pointer border rounded-lg bg-white text-gray-400 flex gap-2 items-center justify-center ${requirementsEvaluationLink ? "hover:bg-gray-100" : ""}`}>
                    {
                    !requirementsEvaluationLink ? <div><p className='text-sm p-2 text-center'>{t('selection.noAccessGranted')}</p></div> :
                    copiedToClipboard ? (
                        <>
                        <div className='w-5 h-5'><CheckmarkIcon /></div>
                        <p className='text-sm'>{t('selection.copied')}</p>
                        </>
                    ) : (
                        <>
                        <div className='w-5 h-5'><ClipboardIcon /></div>
                        <p className='text-sm text-center'>{t('selection.accessCode')}</p>
                        </>
                    )
                    }
                </div>
                ) : <></>
            }


            {
                typeof included === "undefined" || typeof disabled === "undefined" ? <></> : (
                <div className='ml-8 flex gap-4'>
                    {
                        disabled || !quickReload ? 
                        <>
                            {/* Disabled systems (not part of selection) */}
                            {
                                includedSuccessfully ?
                                <div className='flex gap-2 text-gray-400'><p>{t('selection.added')}</p><div className='w-6 h-6 bg-gray-100 text-gray-400 border rounded-full'><CheckmarkIcon /></div></div> : 
                                <MainButton text={t('selection.add')} func={() => {handleChangeDisabled(false); setIncludedSuccessfully(true);}} />
                            }
                            <div>
                            </div>
                        </> : (
                        <>
                            {/* Not disabled systems (are part of selection) */}
                            <div>
                                <Toggle enabled={included} setEnabled={setIncluded} disabled={disabled} />
                                <p className='text-xs font-light text-center'>{t('selection.included')}</p>
                            </div>
                            <div>
                                <Toggle enabled={!disabled} setEnabled={setDisabled} />
                                <p className='text-xs font-light text-center'>{t('selection.enabled')}</p>
                            </div>
                        </>
                        )
                    }
                </div>
                )
            }
        </>
    )
}

const SelectionSoftwareProductDetails = ({ selectionId, selectionSoftwareProduct, handleFetchRanking, quickReload } : { selectionId: string, selectionSoftwareProduct : any, handleFetchRanking: () => {}, quickReload : boolean }) => { 
    const { t } = useTranslation();
    const [openSection, setOpenSection] = useState<string>("");
    const [vendorContactEmail, setVendorContactEmail] = useState<string | null>(selectionSoftwareProduct.vendorContactEmail);
    const [preferredCurrency, setPreferredCurrency] = useState<string>(selectionSoftwareProduct.preferredCurrency);

    const handleChangeVendorContactEmail = async () => {
        if (typeof vendorContactEmail === "undefined" || vendorContactEmail == null) return 
        if (await changeVendorContactEmail(selectionSoftwareProduct.id, vendorContactEmail)) {
            // if (quickReload) handleFetchRanking();
        }
    }

    const [financialFormData, setFinancialFormData] = useState<{
        dailyRateDev: number | null,
        dailyRateConsulting: number | null,
        dailyRatePM: number | null,

        interfaceFees: number | null,
        dataMigrationFees: number | null,
        trainingFees: number | null,
        projectManagementFees: number | null,
        consultingFees: number | null,
        otherImplementationFees: number | null,

        pricePerLicenceMonthly: number | null,
        priceForMaintenanceMonthly: number | null,
        otherMonthlyFees: number | null,
    }>();

    const handleChangeVendorEmail = async () => {
        await changeVendorFinancialData(selectionSoftwareProduct.id, financialFormData);
        handleFetchRanking();
    }

    useEffect(() => {
        if (financialFormData && Object.keys(financialFormData).length > 0) {
            handleChangeVendorEmail()
        }
    }, [financialFormData])

    const handleChangeFinancialData = async () => {
        await changeVendorFinancialData(selectionSoftwareProduct.id, {preferredCurrency});
        handleFetchRanking();
    }
    
    useEffect(() => {
        if (preferredCurrency !== selectionSoftwareProduct.preferredCurrency) {
            handleChangeFinancialData();
        }
    }, [preferredCurrency])

    return (
        <div className='overflow-y-auto max-h-[70vh]'>
            <div title={!selectionSoftwareProduct.softwareProduct.customerDetails ? "Platform-Layer" : "Consultant-Layer"} className={`cursor-pointer w-fit h-5 rounded-full ${!selectionSoftwareProduct.softwareProduct.customerDetails ? "bg-gray-200" : "bg-primary text-white"} border flex items-center justify-center text-sm font-semibold text-gray-600 p-2`}>
                {!selectionSoftwareProduct.softwareProduct.customerDetails ? "Platform-Layer" : "Consultant-Layer"}
            </div>

            <div className='flex'>
                <div className='grow'>
                    <p className='text-xl font-light'>{selectionSoftwareProduct.softwareProduct.vendorDetails.company}</p>
                    <p className='text-2xl font-semibold'>{selectionSoftwareProduct.softwareProduct.productName}</p>
                    <a href={selectionSoftwareProduct.softwareProduct.vendorDetails.vendorUrl} target='_blank' className='cursor-pointer font-light underline underline-offset-2 hover:underline-offset-4 transition-all'>{selectionSoftwareProduct.softwareProduct.vendorDetails.vendorUrl}</a>
                </div>

                <div className='flex justify-between items-center py-4 bg-gray-50 rounded-lg border p-4 w-fit'>
                    <SelectionSoftwareProductSettings selectionId={selectionId} selectionSoftwareProduct={selectionSoftwareProduct} handleFetchRanking={handleFetchRanking} quickReload={quickReload} />
                </div>
            </div>

            <div className='mt-8'>
                <p className='font-light'>{selectionSoftwareProduct.softwareProduct.productDescription}</p>
            </div>
            
            <div className='w-full border rounded-lg mt-8'>
                <div onClick={() => {setOpenSection(prev => prev == "contact" ? "" : "contact")}} className='cursor-pointer p-2 flex justify-between items-center'><p>{t('selection.contactDetails')}</p><div className={`w-4 h-4 ${openSection == "contact" ? "rotate-180" : ""}`}><ArrowDownIcon /></div></div>
                {
                    openSection == "contact" ?
                    <div className={`grid grid-cols-1 p-4 border-t`}>
                        <TextInput label={t('selection.contactEmail')} onBlur={() => handleChangeVendorContactEmail()} textValue={vendorContactEmail} setTextValue={setVendorContactEmail} placeholder={t('selection.insertVendorEmail')} />
                    </div> :
                    <></>
                }
            </div>

            <div className='w-full border rounded-lg mt-2'>
                <div onClick={() => {setOpenSection(prev => prev == "financial" ? "" : "financial")}} className='cursor-pointer p-2 flex justify-between items-center'><p>{t('selection.financials')}</p><div className={`w-4 h-4 ${openSection == "financial" ? "rotate-180" : ""}`}><ArrowDownIcon /></div></div>
                {
                    openSection == "financial" ?
                    <div className='border-t p-4 space-y-8'>
                        <div>
                            <p>{t('selection.customizingCosts')}</p>
                            <div className={`grid grid-cols-2 gap-4`}>
                                <FormField existingText={selectionSoftwareProduct.dailyRatePM || ""} placeholder={t('selection.insertDailyRatePM')} label={t('selection.dailyRatePM')} setFormData={setFinancialFormData} formDataKey='dailyRatePM' editing={true} type='number' />
                                <FormField existingText={selectionSoftwareProduct.dailyRateConsulting || ""} placeholder={t('selection.insertDailyRateConsulting')} label={t('selection.dailyRateConsulting')} setFormData={setFinancialFormData} formDataKey='dailyRateConsulting' editing={true} type='number' />
                                <FormField existingText={selectionSoftwareProduct.dailyRateDev || ""} placeholder={t('selection.insertDailyRateDev')} label={t('selection.dailyRateDev')} setFormData={setFinancialFormData} formDataKey='dailyRateDev' editing={true} type='number' />
                            </div>
                        </div>
                        <div>
                            <p>{t('selection.implementationCosts')}</p>
                            <div className={`grid grid-cols-2 gap-4`}>
                                <FormField existingText={selectionSoftwareProduct.interfaceFees || ""} placeholder={t('selection.insertInterfaceFees')} label={t('selection.interfaceFees')} setFormData={setFinancialFormData} formDataKey='interfaceFees' editing={true} type='number' />
                                <FormField existingText={selectionSoftwareProduct.dataMigrationFees || ""} placeholder={t('selection.insertDataMigrationFees')} label={t('selection.dataMigrationFees')} setFormData={setFinancialFormData} formDataKey='dataMigrationFees' editing={true} type='number' />
                                <FormField existingText={selectionSoftwareProduct.trainingFees || ""} placeholder={t('selection.insertTrainingFees')} label={t('selection.trainingFees')} setFormData={setFinancialFormData} formDataKey='trainingFees' editing={true} type='number' />
                                <FormField existingText={selectionSoftwareProduct.projectManagementFees || ""} placeholder={t('selection.insertProjectManagementFees')} label={t('selection.projectManagementFees')} setFormData={setFinancialFormData} formDataKey='projectManagementFees' editing={true} type='number' />
                                <FormField existingText={selectionSoftwareProduct.consultingFees || ""} placeholder={t('selection.insertConsultingFees')} label={t('selection.consultingFees')} setFormData={setFinancialFormData} formDataKey='consultingFees' editing={true} type='number' />
                                <FormField existingText={selectionSoftwareProduct.otherImplementationFees || ""} placeholder={t('selection.insertOtherImplementationFees')} label={t('selection.otherImplementationFees')} setFormData={setFinancialFormData} formDataKey='otherImplementationFees' editing={true} type='number' />
                            </div>
                        </div>
                        <div>
                            <p>{t('selection.operatingCosts')}</p>
                            <div className={`grid grid-cols-2 gap-4`}>
                                <FormField existingText={selectionSoftwareProduct.oneTimeFees || ""} placeholder={t('selection.insertOneTimeFees')} label={t('selection.oneTimeFees')} setFormData={setFinancialFormData} formDataKey='oneTimeFees' editing={true} type='number' />
                                <FormField existingText={selectionSoftwareProduct.pricePerLicenceMonthly || ""} placeholder={t('selection.insertLicenceFees')} label={t('selection.licenceFees')} setFormData={setFinancialFormData} formDataKey='pricePerLicenceMonthly' editing={true} type='number' />
                                <FormField existingText={selectionSoftwareProduct.priceForMaintenanceMonthly || ""} placeholder={t('selection.insertMaintenanceFees')} label={t('selection.maintenanceFees')} setFormData={setFinancialFormData} formDataKey='priceForMaintenanceMonthly' editing={true} type='number' />
                                <FormField existingText={selectionSoftwareProduct.otherMonthlyFees || ""} placeholder={t('selection.insertOtherFees')} label={t('selection.otherFees')} setFormData={setFinancialFormData} formDataKey='otherMonthlyFees' editing={true} type='number' />
                            </div>
                        </div>
                        <div className=''>
                            <p>{t('selection.other')}</p>
                            <SelectorDropdown label={t('selection.preferredCurrency')} items={[{v: "EUR", l:"Euro (€)"}, {v: "USD", l:"US-Dollar ($)"}, {v: "GBP", l:"English Pound (£)"}, {v: "CNY", l: "Chinese Renminbi Yuan (¥)"}]} itemValueKey={'v'} itemLabelKey={'l'} selectedItem={preferredCurrency} setSelectedItem={setPreferredCurrency} />
                        </div>
                    </div> :
                    <></>
                }
            </div>

        </div>
    )
}

export default SelectionRankingSettingRow