import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '../assets/img/icons/ArrowRightIcon';
import ArrowLeftIcon from '../assets/img/icons/ArrowLeftIcon';

interface props {
	totalItems: number,
	visibleRows: number[];
	setVisibleRows: React.Dispatch<React.SetStateAction<number[]>>;
}

const STEP_SIZE = 10;
const NavPagination = ({ totalItems, visibleRows, setVisibleRows }: props) => {
	const { t } = useTranslation();

	useEffect(() => {
		let upper = 10;
		if (upper > totalItems) { upper = totalItems }
		setVisibleRows([Math.min(1, totalItems), upper]);
	}, [totalItems]);

	const handleClick = (direction: string) => {
		if (direction === "forwards") {
			if (visibleRows[1] === totalItems) { return }
			let upper = visibleRows[1] + STEP_SIZE;
			if (upper > totalItems) { upper = totalItems }
			setVisibleRows([visibleRows[0] + STEP_SIZE, upper]);
		} else {
			if (visibleRows[0] === 1) { return }
			let lower = visibleRows[0] - STEP_SIZE;
			if (lower < 0) { lower = 0; }
			setVisibleRows([lower, lower + STEP_SIZE - 1]);
		}
	};

	return (
		<div
			className="block bottom-0 right-0 items-center w-full p-4 bg-white border-t border-gray-200 sm:flex sm:justify-between"
		>
			<div className="flex items-center mb-4 sm:mb-0">
				<div onClick={() => handleClick("backwards")}
					className="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
					<div className='w-7 h-7 flex justify-center items-center '><ArrowLeftIcon iconColor='currentColor' /></div>
				</div>
				<div onClick={() => handleClick("forwards")}
					className="inline-flex justify-center p-1 mr-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100">
					<div className='w-7 h-7 flex justify-center items-center '><ArrowRightIcon iconColor='currentColor' /></div>
				</div>
				<span className="text-sm font-normal text-gray-500">
					{t('navPagination.showing')} 
					<span className="font-semibold text-gray-900"> {visibleRows[0]}-{visibleRows[1]} </span> 
					{t('navPagination.of')} 
					<span className="font-semibold text-gray-900"> {totalItems} </span>
				</span>
			</div>
		</div>
	);
};

export default NavPagination;