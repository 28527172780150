export async function queryUrl(queryUrl : string) {
    const payload = {
        queryUrl: queryUrl,
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    // TODO reaktion auf Frontend einabuen an geeigneter Stelle einbauen
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/search/query-url`, options)
        if (res.ok) {
            console.log("Url successfully queried");
            return {success: true, status: res.status, message: undefined};
        } else {
            console.error(`queryUrl: Bad response ${res.status}`);
            try { 
                const data = await res.json() 
                return {success: false, status: res.status, message: data.message};
            } catch (e) {
                return {success: false, status: res.status, message: undefined};
            }
        }
    } catch (error) {
        console.error(`queryUrl: Error occurred ${error}`);
        return {success: false, status: -1, message: undefined};
    }
}

export async function queryUrlTestingMode(queryUrl : string, testingMode? : any) {
    const payload = {
        queryUrl: queryUrl,
        testingMode: testingMode
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/search/query-url-testing-mode`, options)
        if (res.ok) {
            console.log("Url successfully queried");
            const data = await res.json();
            return {success: true, status: res.status, message: undefined, data: data.data};
        } else {
            console.error(`queryUrl: Bad response ${res.status}`);
            try { 
                const data = await res.json() 
                return {success: false, status: res.status, message: data.message, data: undefined};
            } catch (e) {
                return {success: false, status: res.status, message: undefined, data: undefined};
            }
        }
    } catch (error) {
        console.error(`queryUrl: Error occurred ${error}`);
        return {success: false, status: -1, message: undefined};
    }
}

export async function getSearchValidation() {

    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/search/get-validation-items`, options)
        if (res.ok) {
            console.log("getSearchValidation successfully queried");
            const data = await res.json();
            return data.data;
        } else {
            console.error(`getSearchValidation: Bad response ${res.status}`);
        }
    } catch (error) {
        console.error(`getSearchValidation: Error occurred ${error}`);
    }
}

export async function updateValidationItemsCalculateScores(validationItems : any[]) {
    const payload = {
        validationItems: validationItems,
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/search/calculate-scores`, options)
        if (res.ok) {
            console.log("updateValidationItemsCalculateScores successfully queried");
            return {success: true, status: res.status};
        } else {
            console.error(`updateValidationItemsCalculateScores: Bad response ${res.status}`);
            return {success: false, status: res.status};
        }
    } catch (error) {
        console.error(`updateValidationItemsCalculateScores: Error occurred ${error}`);
        return {success: false, status: -1};
    }
}

export async function getSearchResults(id: string | null) {
    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        let route = `${process.env.REACT_APP_BACKEND}/api/search/get-search-results`;
        if (id) {
            route = `${process.env.REACT_APP_BACKEND}/api/search/get-search-results?customerSearchId=${id}`
        }

        const res = await fetch(route, options)
        if (res.ok) {
            console.log("getSearchResults successfully queried");
            const data = await res.json();
            return data.data;
        } else {
            console.error(`getSearchResults: Bad response ${res.status}`);
        }
    } catch (error) {
        console.error(`getSearchResults: Error occurred ${error}`);
    }
}

export async function getCustomerSearches() {
    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/search/get-customer-searches`, options)
        if (res.ok) {
            console.log("getCustomerSearches successfully queried");
            const data = await res.json();
            return data.data;
        } else {
            console.error(`getCustomerSearches: Bad response ${res.status}`);
        }
    } catch (error) {
        console.error(`getCustomerSearches: Error occurred ${error}`);
    }
}

export async function getAllCustomerSearches() {
    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/search/get-all-customer-searches`, options)
        if (res.ok) {
            console.log("getAllCustomerSearches successfully queried");
            const data = await res.json();
            return data.data;
        } else {
            console.error(`getAllCustomerSearches: Bad response ${res.status}`);
        }
    } catch (error) {
        console.error(`getAllCustomerSearches: Error occurred ${error}`);
    }
}

export async function deleteCustomerSearch(customerSearchIds : string[]) {
    const payload = {
        customerSearchIds: customerSearchIds,
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/search/delete-customer-searches`, options)
        if (res.ok) {
            console.log("deleteCustomerSearch successfully queried");
            return true;
        } else {
            console.error(`deleteCustomerSearch: Bad response ${res.status}`);
        }
    } catch (error) {
        console.error(`deleteCustomerSearch: Error occurred ${error}`);
    }
}

export async function exportCustomerSearchResults( queryUrl : string) {
    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/search/export-customer-search-results?queryUrl=${queryUrl}`, options)
        if (res.ok) {
            console.log("exportCustomerSearchResults successfully queried");
            const data = await res.json();
            return data.data;
        } else {
            console.error(`exportCustomerSearchResults: Bad response ${res.status}`);
        }
    } catch (error) {
        console.error(`exportCustomerSearchResults: Error occurred ${error}`);
    }
}

export async function getSearchResultsFlask(queryUrl: string, code: string | null) {
    const options : RequestInit = {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
      },
      credentials: "include"
    }
  
    let codeString = ""
    if (code != null) codeString = `&code=${code}`;
  
    try {
        let route = `${process.env.REACT_APP_MATCHING_SERVICE}/api/get-search-results?queryUrl=${queryUrl}`+codeString;
  
        const res = await fetch(route, options)
        if (res.ok) {
            console.log("getSearchResultsFlask successfully queried");
            const data = await res.json();
            return data;
        } else {
            console.error(`getSearchResults: Bad response ${res.status}`);
        }
    } catch (error) {
        console.error(`getSearchResults: Error occurred ${error}`);
    }
}