import React, { useEffect, useState } from 'react'
import FormModal from '../../../../../../../components/FormModal';
import TextInput from '../../../../../../../components/TextInput';
import LoadingSpinner from '../../../../../../../components/LoadingSpinner';
import MainButton from '../../../../../../../components/MainButton';
import BarsTwoIcon from '../../../../../../../assets/img/icons/BarsTwoIcon';
import ArrowDownIcon from '../../../../../../../assets/img/icons/ArrowDownIcon';
import { addSelectionScenarioTask, createNotification, deleteSelectionScenarioTask, updateSelectionScenarioTask } from '../../../../../../../data/SelectionPortalRequests';
import TrashIcon from '../../../../../../../assets/img/icons/TrashIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../redux/store';
import { useTranslation } from 'react-i18next';

interface addScenarioModalProps {
    addScenarioTaskModalOpen: boolean
    setAddScenarioTaskModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    scenarioTasks: any[],
    scenarioId: string,
    scenarioTaskId?: string,
    handleFetchScenarios: () => void,
    scenarioTaskCategories: any[] | undefined
}

const ScenarioTaskModal = ({addScenarioTaskModalOpen, setAddScenarioTaskModalOpen, scenarioTasks, scenarioId, scenarioTaskId, handleFetchScenarios, scenarioTaskCategories} : addScenarioModalProps) => {
    const { t } = useTranslation();
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const [scenarioTaskTitle, setScenarioTaskTitle] = useState<string | null>("");
    const [scenarioTaskDescription, setScenarioTaskDescription] = useState<string | null>("");
    const [scenarioTaskCategoryId, setScenarioTaskCategoryId] = useState("");
    const [newScenarioTaskCategoryTitle, setNewScenarioTaskCategoryTitle] = useState<string | null>("");
    const [scenarioTaskOrder, setScenarioTaskOrder] = useState<number>();

    const [newScenarioTasks, setNewScenarioTasks] = useState<any[]>();
    
    const [orderNumberHovered, setOrderNumberHovered] = useState<number>();

    useEffect(() => {
        if (typeof scenarioTaskId !== "undefined") {

            const scenarioTaskToBeEdited = scenarioTasks.filter(item => item.id == scenarioTaskId)[0];
            if (typeof scenarioTaskToBeEdited === "undefined") {
                createNotification("Error: The scenario to be edited could not be found. Please refresh and try again.", "error");
                setAddScenarioTaskModalOpen(false);
            }
            setScenarioTaskTitle(scenarioTaskToBeEdited.title);
            setScenarioTaskDescription(scenarioTaskToBeEdited.description);
        }
    }, [])
    
    useEffect(() => {
        handleAddNewScenarioTasks(false);
    }, [scenarioTaskOrder])
    
    useEffect(() => {
        handleAddNewScenarioTasks(true);
    }, [scenarioTaskCategoryId])
    
    const handleAddNewScenarioTasks = (categoryChanged: boolean) => {
        if (scenarioTaskTitle == "") return; 

        const scenarioTasksForCategory = scenarioTasks.filter(item => item.selectionScenarioTaskCategory.id == scenarioTaskCategoryId);
        
        let order = scenarioTasksForCategory.length+1;
        if (typeof scenarioTaskOrder !== "undefined" && !categoryChanged) {
            order = scenarioTaskOrder;
        } else {
            setScenarioTaskOrder(order);
        }

        const newScenario = {title: scenarioTaskTitle, cleanOrderNumber: -1};
        const newScenariosTemp = [...scenarioTasksForCategory.slice(0,order-1), newScenario, ...scenarioTasksForCategory.slice(order-1)]
        setNewScenarioTasks(newScenariosTemp);
    }

    const uploadScenarioTask = async () => {
        if (scenarioTaskTitle == "" || scenarioTaskTitle == null) return;
        if (scenarioTaskDescription == null) return;

        if (typeof scenarioTaskId !== "undefined") {
            console.log("updating")
            if ((await updateSelectionScenarioTask(scenarioTaskId, scenarioTaskTitle, scenarioTaskDescription)).ok) {
                handleFetchScenarios();
                setAddScenarioTaskModalOpen(false);
            }
        } else {
            if (scenarioTaskCategoryId == "") return;
            if (scenarioTaskCategoryId == "-1" && newScenarioTaskCategoryTitle == "" || newScenarioTaskCategoryTitle == null) return;
            if (typeof scenarioTaskOrder === "undefined") return;
    
            if ((await addSelectionScenarioTask(scenarioTaskTitle, scenarioTaskDescription, scenarioTaskOrder, scenarioTaskCategoryId, newScenarioTaskCategoryTitle, scenarioId)).ok) {
                handleFetchScenarios();
                setAddScenarioTaskModalOpen(false);
            }
        }
    }
    
    const handleDeleteScenarioItem = async () => {
        if (typeof scenarioTaskId === "undefined") return;
        if (await deleteSelectionScenarioTask(scenarioTaskId)) {
            handleFetchScenarios();
            setAddScenarioTaskModalOpen(false);
        }
    }

    const dragStart = () => {
        console.log("DragStart")
    }
    
    const enterDrop = (e : any, subsequentOrderNumber : number) => {
        e.preventDefault();
        setOrderNumberHovered(subsequentOrderNumber);
    }
    
    const leaveDrop = (e : any) => {
        e.preventDefault();
        setOrderNumberHovered(undefined);
    }
    
    const onDrop = (e : any, subsequentOrderNumber : number) => {
        e.preventDefault();
        setOrderNumberHovered(undefined);
        setScenarioTaskOrder(subsequentOrderNumber);
    }

    return (
        <FormModal isOpen={addScenarioTaskModalOpen} setOpen={setAddScenarioTaskModalOpen} size={''}>
            <>
            <h3 className="mb-4 text-xl font-medium text-gray-900">
                {typeof scenarioTaskId === "undefined" ? t('scenarioTaskModal.addTask') : t('scenarioTaskModal.editTask')}
            </h3>
                <div className='mt-4 space-y-4'>
                    <TextInput 
                        textValue={scenarioTaskTitle} 
                        setTextValue={setScenarioTaskTitle} 
                        placeholder={t('scenarioTaskModal.taskTitlePlaceholder')} 
                        label={t('scenarioTaskModal.taskTitleLabel')}
                    />
                    <TextInput 
                        textValue={scenarioTaskDescription} 
                        setTextValue={setScenarioTaskDescription} 
                        placeholder={t('scenarioTaskModal.taskDescriptionPlaceholder')} 
                        label={t('scenarioTaskModal.taskDescriptionLabel')} 
                        rows={4} 
                    />
                    {
                        typeof scenarioTaskId === "undefined" ? (
                            <>
                            {
                                (typeof scenarioTaskCategories === "undefined" || scenarioTaskTitle == "") ? (<></>) : 
                                <div>
                                    <label className="block text-sm font-medium text-gray-900">{t('scenarioTaskModal.categoryLabel')}</label>
                                    <select onChange={(event) => {setScenarioTaskCategoryId(event.target.value)}}
                                    id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" style={{WebkitAppearance: "none"}}>
                                        <option value="" selected disabled hidden>{t('scenarioTaskModal.chooseHere')}</option>
                                        {
                                            scenarioTaskCategories.map((item : any, index : number) => {
                                                return (<option key={index} value={item.id}>{item.cleanOrderNumber}. {item.title}</option>);
                                            })
                                        }
                                        <option key={-1} value={"-1"}>{t('scenarioTaskModal.addNewCategory')}</option>
                                    </select>
                                </div>
                            }
                            {
                                (scenarioTaskCategoryId == "-1") ? (
                                    <TextInput 
                                        textValue={newScenarioTaskCategoryTitle} 
                                        setTextValue={setNewScenarioTaskCategoryTitle} 
                                        placeholder={t('scenarioTaskModal.newCategoryPlaceholder')} 
                                        label={t('scenarioTaskModal.newCategoryLabel')} 
                                    />
                                ) : (<></>)
                            }
                            <div>
                                <p className="block text-sm font-medium text-gray-900">{t('scenarioTaskModal.taskOrderLabel')}</p>
                                <div className='h-64 overflow-y-auto border-t border-b px-4'>
                                    {   typeof newScenarioTasks !== "undefined" ? (
                                        <>
                                            {
                                                newScenarioTasks.map((item:any, index:number) => (
                                                    <div>
                                                        <div className={`h-2 mb-1 border-b-4 border-dashed ${(orderNumberHovered == item.cleanOrderNumber && scenarioTaskOrder != orderNumberHovered) ? ("") : ("border-white")}`}></div>
                                                        <div key={index} draggable={ item.cleanOrderNumber == -1 ? true : false } onDragStart={(e) => dragStart()} className={`${(item.cleanOrderNumber == -1) ? "border-primary bg-primary text-white cursor-pointer" : "border-gray-300 bg-gray-100"} relative text-lg font-medium rounded-lg p-4 h-10 flex items-center justify-between border `}>
                                                            {(item.cleanOrderNumber == -1) ? (<></>) : (<div onDrop={(e) => onDrop(e, item.cleanOrderNumber)} onDragOver={(e) => enterDrop(e, item.cleanOrderNumber)} onDragLeave={(e) => leaveDrop(e)} className='left-0 -top-6 absolute w-full h-10'></div>)}
                                                            <div className='flex space-x-2 truncate'>
                                                                <p className='font-bold'>{index+1}.</p> 
                                                                <p className=''>{item.title}</p> 
                                                                {(item.cleanOrderNumber != index+1 && item.cleanOrderNumber != -1) ? <p className='italic font-light'>{t('scenarioTaskModal.previousOrder', { order: item.cleanOrderNumber })}</p> : ""}
                                                            </div>
                                                            {
                                                                (item.cleanOrderNumber == -1) ? (
                                                                    <div className='flex items-center'>
                                                                        <div onClick={() => setScenarioTaskOrder((prev:any) => {if (prev > 1) {return (prev-1)} else {return prev}})} className='flex items-center w-8 h-8 rotate-180 text-white'><ArrowDownIcon iconColor="currentColor" iconHeight='w-full' iconWidth='h-full' /></div>
                                                                        <div onClick={() => setScenarioTaskOrder((prev:any) => {if (prev < newScenarioTasks.length+1) {return (prev+1)} else {return prev}})} className='flex items-center w-8 h-8 rotate text-white'><ArrowDownIcon iconColor="currentColor" iconHeight='w-full' iconWidth='h-full' /></div>
                                                                        <div className='h-6 w-6'><BarsTwoIcon /></div>
                                                                    </div>
                                                                ) : (<></>)
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {(scenarioTaskOrder == newScenarioTasks.length) ? (<></>) : (<div onDrop={(e) => onDrop(e, newScenarioTasks.length)} onDragOver={(e) => enterDrop(e, newScenarioTasks.length)} onDragLeave={(e) => leaveDrop(e)} className={`-translate-y-7 w-full h-10 border-b-4 border-dashed ${(orderNumberHovered == newScenarioTasks.length) ? ("") : ("border-white")}`}></div>)}
                                        </>
                                    ) : (<></>)
                                    }
                                </div>
                            </div>
                            </>
                        ) : (<></>)
                    }
                    {
                        projectRole === "selection_admin" ? (        
                        <div className='flex space-x-2'>
                            <div className='grow'><MainButton text={t('scenarioTaskModal.saveButton')} link={''} func={() => uploadScenarioTask()}/></div>
                            {
                                typeof scenarioTaskId !== "undefined" ? 
                                <div><MainButton text={t('scenarioTaskModal.deleteButton')} link={''} icon={<div className='w-5 h-5'><TrashIcon /></div>} func={() => handleDeleteScenarioItem()} style='light'/></div> :
                                <></>
                            }
                        </div>
                        ) : (<></>)
                    }
                </div>

            </>
        </FormModal>
    )
}

export default ScenarioTaskModal