import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, LoadingSpinner, TextInput } from 'swap-frontend-library';
import MainButton from '../../../../../../../../components/MainButton';
import { useNavigate } from 'react-router-dom';
import { generatePresentationSummary } from '../../../../../../../../data/SelectionPortalRequests';

const OverallResultsScreen = ({presentationId, handleFetchScenarioEvaluations, scenarios, scenarioEvaluations, setSelectedScenario} : {presentationId:string, handleFetchScenarioEvaluations: () => {}, scenarios:any, scenarioEvaluations:any, setSelectedScenario: React.Dispatch<React.SetStateAction<any>>}) => {
    const { t } = useTranslation();
    const [overallSummary, setOverallSummary] = useState<string | null>(null);
    const presentationSummaryTextareaRef = useRef<HTMLTextAreaElement>(null);
    const [scenarioDescriptionTextareaHeight, setScenarioDescriptionTextareaHeight] = useState("inherit");
    const toggleScenarioDescription = () => {
        if (typeof presentationSummaryTextareaRef.current === "undefined" || presentationSummaryTextareaRef.current == null) return;
        if (scenarioDescriptionTextareaHeight != "inherit") {
            setScenarioDescriptionTextareaHeight("inherit");
        } else {
            setScenarioDescriptionTextareaHeight(`${presentationSummaryTextareaRef.current.scrollHeight}px`);
        }
    }

    const summaries = scenarioEvaluations.summaries;
    const navigate = useNavigate();

    const handleGenerateSummaries = async () => {
        const response = await generatePresentationSummary(presentationId);
        if (response.ok) {
            await handleFetchScenarioEvaluations();
        }
    }

    return (
    <div className='p-4'>
        <div className="flex flex-col p-5 bg-white rounded-lg shadow max-md:max-w-full">
            {
                (false) ? (<LoadingSpinner />) : (
                    <>
                    <p className='text-sm font-semibold'>{t('overallResults.title')}</p>
                    <div>
                        <div className={`mt-2 relative group col-span-3 flex items-center justify-start space-x-1`}>
                            {
                                [...Array(6).keys()].map((ithDot: any, index: number) => {
                                    return (
                                        <div
                                        className={`w-4 h-4 flex justify-center group/${ithDot} ${(ithDot < Math.round(scenarioEvaluations.avgRatingOverall)) ? ("bg-primary"): ("bg-gray-100")} rounded-full`}>
                                        </div>
                                    )
                                })
                            }
                            {
                                scenarioEvaluations.avgRatingOverall == null ? (
                                    <div className='pl-4'>
                                        {t('overallResults.noAverage')}
                                    </div>
                                ) : (
                                    <div className='pl-4'>
                                        {t('overallResults.average', { avg: scenarioEvaluations.avgRatingOverall.toFixed(1) })}
                                    </div>
                                )
                            }
                        </div>

                        <div>
                            <div onClick={() => toggleScenarioDescription()}
                            className='flex items-center space-x-4 font-semibold text-lg cursor-pointer mt-5'>
                                <p className='text-sm font-semibold'>{t('overallResults.evaluationSummary')}</p>
                                { scenarioDescriptionTextareaHeight != "inherit" ? <div className='rotate-180 w-6 h-6'><ArrowDownIcon /></div> : <div className='rotate-0 w-6 h-6'><ArrowDownIcon /></div>}
                            </div>
                            <div>
                            {
                                scenarioEvaluations.overallEvaluationSummary != "" && typeof scenarioEvaluations.overallEvaluationSummary !== "undefined" && scenarioEvaluations.overallEvaluationSummary != null ? (
                                    <div className='text-left grow w-full text-sm bg-gray-50 rounded-lg border p-2 pt-6 relative'>
                                        <div className='absolute right-5 -top-3 rounded-full border-blue-700/90 text-blue-700/90 bg-gradient-to-br from-blue-700/50 to-[#57e3ff] border-2 shadow shadow-primary/50 font-semibold px-2'>{t('overallResults.aiGenerated')}</div>
                                        <textarea ref={presentationSummaryTextareaRef} disabled={true} className={`w-full italic space-y-2 rounded bg-transparent tracking-tight leading-2 text-gray-900 ${scenarioDescriptionTextareaHeight == "inherit" ? "line-clamp-2" : ""}`} style={{height: scenarioDescriptionTextareaHeight}} value={scenarioEvaluations.overallEvaluationSummary} />
                                        { scenarioDescriptionTextareaHeight == "inherit" ? <p onClick={() => toggleScenarioDescription()} className='font-semibold cursor-pointer'>{t('overallResults.more')}</p> : <></>}
                                    </div>
                                ) : <></>
                            }
                            </div>
                        </div>
                    </div>
                    </>
                )
            }
        </div>

        <div className='grid grid-cols-3 gap-4 mt-4'>
            {
                scenarios.sort((a:any,b:any) => a.cleanOrderNumber-b.cleanOrderNumber).map((scenario:any, index:number) => (
                    <div onClick={() => {setSelectedScenario(scenario); navigate(`?scenarioId=${scenario.id}`);}}
                    className={`transition-[background-color] duration-500 relative flex flex-col items-center justify-start text-center rounded-lg max-md:max-w-full`}>
                        <ScenarioCardWithSummary scenarioEvaluationsOfScenario={scenarioEvaluations.scenarioStatistics[scenario.id]} summary={summaries[scenario.id]} scenario={scenario} />
                    </div>
                )
            )}
        </div>

        <div className="mt-4 flex flex-col p-5 bg-white rounded-lg shadow max-md:max-w-full">
            <div className='w-64'><MainButton func={() => handleGenerateSummaries()} text={t('overallResults.generateSummaries')} additionalStyles='border-blue-700/90 text-blue-700/90 hover:border-amber-400 bg-gradient-to-br from-blue-700/50 to-[#57e3ff] border-2 shadow shadow-primary/50 font-semibold' /></div>
        </div>
    </div>
  )
}

const ScenarioCardWithSummary = ({scenarioEvaluationsOfScenario, scenario, summary} : {scenarioEvaluationsOfScenario:any, scenario:any, summary:any}) => {
    const { t } = useTranslation();
    const textbox = useRef<HTMLTextAreaElement>(null);

    let averageScore = 0;
    if (typeof scenarioEvaluationsOfScenario !== "undefined") averageScore = scenarioEvaluationsOfScenario.statistics.sumRatings / scenarioEvaluationsOfScenario.statistics.numRatings;

    return (
        <div
        className={`transition-all duration-500 bg-white relative flex flex-col h-full w-full items-center justify-start text-center px-5 pt-5 pb-2.5 rounded-lg shadow max-md:max-w-full hover:bg-gray-100 cursor-pointer`}>
            <div className='font-light italic'>
                {t('scenarioCard.scenarioNumber', { number: scenario.cleanOrderNumber })}
            </div>
            <div className='font-semibold text-lg'>
                {scenario.title}
            </div>
            <div className={`mt-2 relative group col-span-3 flex items-center justify-center space-x-1 items-center`}>
                {
                    [...Array(6).keys()].map((ithDot: any, index: number) => {
                        return (
                            <div
                            className={`w-4 h-4 flex justify-center group/${ithDot} ${(ithDot < Math.round(averageScore)) ? ("bg-primary"): ("bg-gray-100 hover:bg-gray-200")} rounded-full`}>
                            </div>
                        )
                    })
                }
                <div className='pl-4'>
                    {t('scenarioCard.averageScore', { score: averageScore.toFixed(1) })}
                </div>
            </div>

            {
                summary != "" && typeof summary !== "undefined" ? (
                    <div className='text-left grow w-full my-8 text-sm bg-gray-50 rounded-lg border p-2 py-6 relative'>
                        <div className='absolute right-5 -top-3 rounded-full border-blue-700/90 text-blue-700/90 hover:border-amber-400 bg-gradient-to-br from-blue-700/50 to-[#57e3ff] border-2 shadow shadow-primary/50 font-semibold px-2'>{t('scenarioCard.aiGenerated')}</div>
                        <p className='italic'>{summary.split("\n")[0]} ...</p>
                        <p className='font-semibold'>{t('scenarioCard.more')}</p>
                    </div>
                ) : <></>
            }
        </div>
    )
}

export default OverallResultsScreen;