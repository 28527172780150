import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCostComparison, getSelectionDetails, getSelectionRanking } from '../../../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import { ArrowDownIcon, CheckmarkIcon, CrossIcon, getImage, LoadingSpinner, MessageIcon, QuestionmarkIcon, SettingsFilledIcon, WarningIcon } from 'swap-frontend-library';
import GearIcon from '../../../../../../../assets/img/icons/GearIcon';
import PercentageCircle from '../../../../../../../components/PercentageCircle';
import ComparisonCostsCustomization from './features/ComparisonCostsCustomization';
import ComparisonCostsOperation from './features/ComparisonCostsOperation';
import TotalCostCell from './features/components/TotalCostCell';
import CostSettingsModal from './features/components/CostSettingsModal';
import ComparisonCostsImplementation from './features/ComparisonCostsImplementation';

const ComparisonCosts = () => {
    const { t } = useTranslation();
    const { selectionId } = useOutletContext<any>();
    const [error, setError] = useState("");
    const [selectionRequirements, setSelectionRequirements] = useState([]);
    const [selectionDetails, setSelectionDetails] = useState<any[]>();
    const [vendorRanking, setVendorRanking] = useState<any[]>();
    const [aggregateCosts, setAggregateCosts] = useState<any[]>();
    const [categoriesWithCosts, setCategoriesWithCosts] = useState<any>();
    const [softwareProductFinancials, setSoftwareProductFinancials] = useState<any>();

    const [operatingSettingsElement, setOperatingSettingsElement] = useState<React.ReactHTMLElement<HTMLDivElement>>();
    const [customizingSettingsElement, setCustomizingSettingsElement] = useState<React.ReactHTMLElement<HTMLDivElement>>();

    const settingsRef = useRef<HTMLDivElement>(null);
    
    const handleFetchRanking = async () => {
        const response = await getSelectionRanking(selectionId);
        if (response.ok) {
            setVendorRanking(response.data.data.results.filter((item: any) => item.included));
        }
    }
    
    const handleFetchComparison = async () => {
        const response = await getCostComparison(selectionId);
        if (response.ok) {
            setSelectionRequirements(response.data.data.selectionRequirementWithSoftwareProductCostsParsedCategories);
            setAggregateCosts(response.data.data.aggregateCosts);
            setCategoriesWithCosts(response.data.data.uniqueCategoriesTreeWithCosts);
            setSoftwareProductFinancials(response.data.data.softwareProductFinancials);
        } else {
            setError(response.data.errorCode);
        }
    }

    const handleGetSelectionDetails = async () => {
        const response = await getSelectionDetails(selectionId);
        if (response.ok) {
            setSelectionDetails(response.data.data);
        }
    }
    
    useEffect(() => {
        handleGetSelectionDetails();
    }, []);

    useEffect(() => {
        handleFetchRanking();
        handleFetchComparison();
    }, []);

    if (error || vendorRanking?.length === 0) {
        return (
            <div className='m-4 bg-white shadow rounded-lg p-8 space-y-8 text-gray-700'>
                <p className='text-xl font-semibold'>{t('comparisonCosts.error.title')}</p>
                <p>{t('comparisonCosts.error.description')}</p>
            </div>
        );
    }
    if (typeof selectionRequirements === "undefined" || typeof aggregateCosts === "undefined" || typeof categoriesWithCosts === "undefined" || typeof vendorRanking === "undefined") return <LoadingSpinner />;
    return (
        <div className='relative h-full w-full flex'>
            <table className='border-separate border-spacing-0 h-fit table-fixed border-collapse overflow-y-auto overflow-x-auto pr-8 pb-8'>
                <thead className='sticky top-0 z-[3] bg-white/30 backdrop-blur shadow'>
                    <tr>
                        <td className='sticky left-0 z-[2] bg-gray-50/50 backdrop-blur'></td>
                        {
                            vendorRanking.map((vendorData: any) => {
                                return (
                                    <VendorColumn vendorData={vendorData} aggregateCosts={aggregateCosts} softwareProductFinancials={softwareProductFinancials} />
                                );
                            })
                        }
                    </tr>
                </thead>

                <tbody>

                    {/* Operating cost analysis */}
                    <ComparisonCostsOperation selectionDetails={selectionDetails} vendorRanking={vendorRanking} aggregateCosts={aggregateCosts} softwareProductFinancials={softwareProductFinancials} setOperatingSettingsElement={setOperatingSettingsElement} />
                    
                    {/* Operating cost analysis */}
                    <ComparisonCostsImplementation selectionDetails={selectionDetails} vendorRanking={vendorRanking} aggregateCosts={aggregateCosts} softwareProductFinancials={softwareProductFinancials} setOperatingSettingsElement={setOperatingSettingsElement} />

                    {/* Customization cost analysis */}
                    <ComparisonCostsCustomization selectionDetails={selectionDetails} categoriesWithCosts={categoriesWithCosts} vendorRanking={vendorRanking} softwareProductFinancials={softwareProductFinancials} selectionRequirements={selectionRequirements} aggregateCosts={aggregateCosts} setCustomizingSettingsElement={setCustomizingSettingsElement} />

                </tbody>
            </table>

            <div ref={settingsRef} className='fixed bottom-5 right-5 flex flex-col items-end justify-end gap-4'>
            </div>
            {
                typeof operatingSettingsElement !== "undefined" || typeof customizingSettingsElement !== "undefined" ? 
                <div className='fixed z-[3] bottom-5 right-5 flex flex-col items-end justify-end gap-4'>
                    {operatingSettingsElement}
                    {customizingSettingsElement}
                </div> :
                <></>
            }
        </div>
    );
}

const VendorColumn = ({ vendorData, aggregateCosts, softwareProductFinancials }: any) => {
    const [imageSource, setImageSource] = useState("");
    const handleFetchImage = async () => {
        getImage(vendorData.softwareProduct.id, "/api/product/get-logo?id=", '').then(frontendImageLocation => { setImageSource(frontendImageLocation); });
    }
    useEffect(() => { handleFetchImage(); }, []);

    return (
        <td className=''>
            <div className='w-40 h-56 p-1'>
                <div className='bg-white rounded-lg shadow border h-full w-full space-y-2 flex flex-col items-center justify-end p-2'>
                    {
                        (imageSource === "") ? (
                            <></>
                        ) : (
                            <img className="h-8 w-full object-contain p-1" src={imageSource} />
                        )
                    }
                    <div className='text-center'>
                        <p className='font-bold line-clamp-2'>{vendorData.softwareProduct.productName}</p>
                        <p className='font-light text-sm line-clamp-2'>{vendorData.softwareProduct.vendorDetails.company}</p>
                    </div>

                </div>
            </div>
        </td>
    );
}

export default ComparisonCosts;