import { useEffect, useState } from "react";
import { WarningIcon } from "swap-frontend-library";

const TotalCostCell = ({vendorData, aggregateCosts, softwareProductFinancials, isLastItem, warningElement, projectManagementFactor, consultingFactor} : any) => {
    const [factoredCosts, setFactoredCosts] = useState(0);

    const handleCalcCosts = () => {
        const rawCosts = aggregateCosts[vendorData.id] == -1 ? 0 : aggregateCosts[vendorData.id];
        if (typeof projectManagementFactor !== "undefined" && typeof consultingFactor !== "undefined") {
            setFactoredCosts(rawCosts + (rawCosts * projectManagementFactor) + (rawCosts * consultingFactor));
        } else {
            setFactoredCosts(rawCosts);
        }
    }

    useEffect(() => handleCalcCosts(), [projectManagementFactor, consultingFactor, aggregateCosts])

    return (
        <td className=''>
            <div className='w-40 h-24'>
                <div className={`bg-white/70 backdrop-blur-lg border-y h-full w-full space-y-2 flex flex-col items-center justify-center p-2 border-primary text-xl font-bold text-primary ${isLastItem ? "rounded-r-lg border-r" : ""}`}>
                    <div className=''>
                        {
                            factoredCosts.toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[vendorData.id].preferredCurrency })
                        }
                    </div>

                    {warningElement}
                </div>
            </div>
        </td>
    )
}

export default TotalCostCell