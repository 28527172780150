import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { getSelectionPhaseTaskDetails, updateSelectionPhaseTaskDetails, updateSelectionPhaseTaskDetailsVendor } from '../../../../../data/SelectionPortalRequests';
import MainButton from '../../../../../components/MainButton';
import SelectionPhaseModal from '../selection-guide/SelectionPhaseModal';
import { SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS } from './SpeedDial';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

interface props {
    selectionId: string
    acceptedTaskCodes: string[]
    reloadTaskTrigger?: any,
    additionalData?: any
}

const SelectionGuideInjector = ({selectionId, acceptedTaskCodes, reloadTaskTrigger, additionalData} : props) => {
    const { t } = useTranslation(); // Initialize t function
    const role = useSelector((state: RootState) => state.user.role);
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const navigate = useNavigate();
    const location = useLocation();
    const { selectionTaskId, setSelectionTaskId, setSelectionPhase } = useOutletContext<any>();
    const [ taskCompleted, setTaskCompleted ] = useState();
    const [ taskMetric, setTaskMetric ] = useState();
    const [ uniqueTaskCode, setUniqueTaskCode ] = useState();
    let injectionItem = <></>;
    const [responseWindowForTaskCode, setResponseWindowForTaskCode] = useState("");
    
    useEffect(() => {handleFetchSelectionTaskDetails()}, [])
    useEffect(() => {handleFetchSelectionTaskDetails()}, [selectionTaskId])
    useEffect(() => {handleFetchSelectionTaskDetails()}, [reloadTaskTrigger])
    
    const handleFetchSelectionTaskDetails = async () => {
        if (typeof selectionId === "undefined") return;
        if (selectionTaskId == "") setUniqueTaskCode(undefined);
        const selectionPhaseTaskDetails = await getSelectionPhaseTaskDetails(selectionTaskId);
        // console.log(selectionPhaseTaskDetails)
        setTaskCompleted(selectionPhaseTaskDetails.data.data.completed);
        setTaskMetric(selectionPhaseTaskDetails.data.data.metric);
        setUniqueTaskCode(selectionPhaseTaskDetails.data.data.selectionPhaseTask.uniqueTaskCode);
    }

    const handleUpdateSelectionPhase = async (redirect? : string) => {
        const response = await updateSelectionPhaseTaskDetails(selectionTaskId);
        console.log(response)
        if (response.data.newSelectionSelectionPhaseTaskSaved != null)  {
            setSelectionTaskId(response.data.newSelectionSelectionPhaseTaskSaved.id);
        } else {
            setSelectionTaskId("");
        }
        if (response.data.nextSelectionPhaseTask != null) setSelectionPhase(response.data.nextSelectionPhaseTask.selectionPhase);
        if (typeof uniqueTaskCode !== "undefined") setResponseWindowForTaskCode(uniqueTaskCode);
        if (typeof redirect !== "undefined") navigate(redirect);
    }
    
    const handleUpdateSelectionPhaseVendor = async (selectionTaskId: string) => {
        const response = await updateSelectionPhaseTaskDetailsVendor(selectionTaskId);
    }

    // REQUIREMENTS PHASE
    if (projectRole == "selection_admin" && uniqueTaskCode == "requ-vali_reqs" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex grow gap-5 justify-between items-end px-10 mb-6 mt-6 text-xs text-gray-800 max-md:px-5">
                <div className='w-full'><MainButton text={t('requirements.validate')} func={() => handleUpdateSelectionPhase()} /></div>
            </div>
        )
    } else if (projectRole == "selection_admin" && uniqueTaskCode == "requ-subm_reqs" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex grow gap-5 justify-between items-end px-10 mb-6 mt-6 text-xs text-gray-800 max-md:px-5">
                <div className='w-full'><MainButton text={t('requirements.submitToVendors')} func={() => setResponseWindowForTaskCode(uniqueTaskCode)} /></div>
            </div>
        )
    } else if ((role == "vendor" || role == "guest_vendor") && acceptedTaskCodes.includes("requ-subm_reqs-vendor")) {
        injectionItem = (
            <div className="flex grow gap-5 justify-between items-end px-10 mb-6 mt-6 text-xs text-gray-800 max-md:px-5">
                <div className='w-full whitespace-normal'><MainButton text={additionalData?.finalized ? t('requirements.undoFinalization') : t('requirements.finalizeEvaluation')} style={additionalData?.finalized ? 'light' : ''} func={() => {handleUpdateSelectionPhaseVendor("requ-subm_reqs-vendor"); window.location.reload();}} /></div>
            </div>
        )
    } else if (projectRole == "selection_admin" && uniqueTaskCode == "requ-revi_reqs" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex grow gap-5 justify-between items-end px-10 mb-6 mt-6 text-xs text-gray-800 max-md:px-5">
                <div className='w-full'><MainButton text={t('requirements.updateShortlist')} link='../vendor-ranking?endPhase=requirements' /></div>
            </div>
        )
    } else if (projectRole == "selection_admin" && uniqueTaskCode == "requ-revi_reqs" && acceptedTaskCodes.includes("FINISH_PHASE")) {
        if (taskCompleted) {
            injectionItem = (
                <div className="border-l px-2">
                    <div className='w-full'><MainButton text={t('requirements.finishPhase')} func={() => {handleUpdateSelectionPhase(location.pathname+"?endPhase=finish");}} /></div>
                </div>
            )
        } else if (projectRole == "selection_admin") {
            injectionItem = (
                <div className="border-l px-2 flex items-center text-center font-normal">
                    <p className='w-96 border px-2 py-1 rounded border-amber-600 bg-amber-200'>
                        {taskMetric} {SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS[uniqueTaskCode as keyof typeof SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS]}
                    </p>
                </div>
            )
        }
    }
    // SCENARIOS PHASE
    else if (projectRole == "selection_admin" && uniqueTaskCode == "scen-subm_scen" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex h-full font-normal">
                <MainButton text={t('scenarios.submitToVendors')} func={() => setResponseWindowForTaskCode(uniqueTaskCode)} />
            </div>
        )
    } 
    // PRESENTATIONS PHASE
    else if (projectRole == "selection_admin" && uniqueTaskCode == "pres-sche_pres" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex h-full w-72 grow justify-end items-center font-normal">
                <div className='w-full'><MainButton text={t('presentations.completeSchedule')} func={() => {handleUpdateSelectionPhase()}} /></div>
            </div>
        )
    } else if (projectRole == "selection_admin" && uniqueTaskCode == "pres-cond_pres" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex h-full w-72 grow justify-end items-center font-normal">
                <div className='w-full'><MainButton text={t('presentations.finishRunning')} func={() => {handleUpdateSelectionPhase()}} /></div>
            </div>
        )
    } else if (projectRole == "selection_admin" && uniqueTaskCode == "pres-eval_pres" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex h-full w-72 grow justify-end items-center font-normal">
                <div className='w-full'><MainButton text={t('presentations.evaluate')} func={() => {handleUpdateSelectionPhase()}} /></div>
            </div>
        )
    } else if (projectRole == "selection_admin" && uniqueTaskCode == "pres-gene_shor" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex h-full w-72 grow justify-end items-center font-normal">
                <div className='w-full'><MainButton text={t('presentations.updateShortlist')} link='../vendor-ranking?endPhase=presentations' /></div>
            </div>
        )
    } else if (uniqueTaskCode == "pres-gene_shor" && acceptedTaskCodes.includes("FINISH_PHASE")) {
        if (taskCompleted) {
            injectionItem = (
                <div className="border-l px-2">
                    <div className='w-full'><MainButton text={t('presentations.finishPhase')} func={() => {handleUpdateSelectionPhase(location.pathname+"?endPhase=finish");}} /></div>
                </div>
            )
        } else {
            injectionItem = (
                <div className="border-l px-2 flex items-center text-center font-normal">
                    <p className='w-96 border px-2 py-1 rounded border-amber-600 bg-amber-200'>
                        {taskMetric} {SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS[uniqueTaskCode as keyof typeof SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS]}
                    </p>
                </div>
            )
        }
    } 
    // CONTRACTS PHASE
    else if (projectRole == "selection_admin" && uniqueTaskCode == "cont-nego_prop" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex h-full w-72 grow justify-end items-center font-normal">
                <MainButton text={t('contracts.finalizeSelection')} height="h-10" func={() => {handleUpdateSelectionPhase()}} />
            </div>
        )
    } 

    return (
        <>
        {injectionItem}
        {(projectRole == "selection_admin" && responseWindowForTaskCode != "") ? (<SelectionPhaseModal selectionPhaseTaskCode={responseWindowForTaskCode} setSelectionPhaseTaskCode={setResponseWindowForTaskCode}  />) : (<></>)}
        </>
    )
}

export default SelectionGuideInjector