import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnalysisIcon, ArrowLeftIcon, ArrowRightIcon, CakeChartIcon, CheckoutFilledIcon, FilledInfoIcon, FourSquaresFilledIcon, HeadsetIcon, ProfileFilledIcon, SearchIcon, SettingsFilledIcon, SignoutIcon } from 'swap-frontend-library';

const useSPNavItems = (collapsedSidebar?: boolean, handleCollapseSidebar?: () => void) => {
    const { t } = useTranslation();

    const menu_items = [
        {
          label: t("userAreaMenu.dashboard"),
          icon: <div className='w-6 h-6 text-gray-500'><CakeChartIcon /></div>,
          roleLink: {
            "platform_manager": "", 
            "vendor": "", 
          },
          topAlign: true,
        },
        {
          label: t("userAreaMenu.dashboard"),
          icon: <div className='w-6 h-6 text-gray-500'><FourSquaresFilledIcon /></div>,
          roleLink: {
            "customer": ""
          },
          topAlign: true
        },
        {
          label: t("userAreaMenu.selections"),
          icon: <div className='w-6 h-6 text-gray-500'><AnalysisIcon /></div>,
          roleLink: {
            "customer": "selection-browser"
          },
          topAlign: true
        },
        {
          label: t("userAreaMenu.matchings"),
          icon: <div className='w-6 h-6 text-gray-500'><SearchIcon /></div>,
          roleLink: {
            // "customer": "matching-area/overview",
            "platform_manager": "matching-area/overview"
          },
          topAlign: true
        },
        {
          label: t("userAreaMenu.sales"),
          icon: <div className='w-6 h-6 text-gray-700'><CakeChartIcon /></div>,
          roleLink: {
            "vendor": "#", 
            "platform_manager": "sales", 
          },
          topAlign: true
        },
        {
          label: t("userAreaMenu.leads"),
          icon: <div className='w-6 h-6 text-gray-700'><CakeChartIcon /></div>,
          roleLink: {
            "vendor": "leads", 
          },
          topAlign: true
        },
        {
          label: t("userAreaMenu.settings"),
          isSettings: true,
          icon: <div className='w-6 h-6 text-gray-700'><SettingsFilledIcon /></div>,
          roleLink: {
            "platform_manager": [
              {
                label: t("userAreaMenu.settingsSubMenu.softwareProducts"),
                link: "settings/software"
              },
              {
                label: t("userAreaMenu.settingsSubMenu.customers"),
                link: "settings/customers"
              },
              {
                label: t("userAreaMenu.settingsSubMenu.vendor"),
                link: "settings/vendors"
              },
              {
                label: t("userAreaMenu.settingsSubMenu.platformManagers"),
                link: "settings/admin"
              },
              {
                label: t("userAreaMenu.settingsSubMenu.matchingLogic"),
                link: "settings/matching-logic"
              },
              {
                label: t("userAreaMenu.settingsSubMenu.platformSettings"),
                link: "settings/platform-settings"
              },
            ], 
            "vendor": [
              {
                label: t("userAreaMenu.settingsSubMenu.softwareProducts"),
                link: "settings/software"
              },
            ], 
          },
          topAlign: true
        },
        // Bottom 
        // {
        //   label: t("shop"),
        //   icon: <div className='w-6 h-6 text-gray-700'><CheckoutFilledIcon /></div>,
        //   roleLink: {
        //     "platform_manager": "shop",
        //     "vendor": "shop", 
        //     "customer": "shop"
        //   },
        //   topAlign: false
        // },
        {
          label: t("userAreaMenu.profile"),
          icon: <div className='w-6 h-6 text-gray-700'><ProfileFilledIcon /></div>,
          roleLink: {
            "customer": "profile-settings"
          },
          topAlign: false
        }, 
        {
          label: t("userAreaMenu.signOut"),
          icon: <div className='w-6 h-6 text-gray-700'><SignoutIcon /></div>,
          roleLink: {
            "platform_manager": "/signout", 
            "vendor": "/signout", 
            "customer": "/signout"
          },
          topAlign: false
        },
        // {
        //   label: t("documentation"),
        //   icon: <div className='w-6 h-6 text-gray-700'><FilledInfoIcon /></div>,
        //   roleLink: {
        //     "platform_manager": "profile-settings", 
        //     "vendor": "profile-settings", 
        //     "customer": "profile-settings"
        //   },
        //   topAlign: false
        // }, 
        // {
        //   label: t("help"),
        //   icon: <div className='w-6 h-6 text-gray-700'><HeadsetIcon /></div>,
        //   roleLink: {
        //     "platform_manager": "profile-settings", 
        //     "vendor": "profile-settings", 
        //     "customer": "profile-settings"
        //   },
        //   topAlign: false
        // },
        {
          label: t("Schließen"),
          icon: (collapsedSidebar) ? (<div className='w-4 h-4 text-gray-700'><ArrowRightIcon /></div>) : (<div className='w-4 h-4 text-gray-700'><ArrowLeftIcon /></div>),
          roleLink: {
            "platform_manager": "#", 
            "vendor": "#", 
            "customer": "#"
          },
          onClick: handleCollapseSidebar,
          topAlign: false
        }
      ];
  return menu_items;
}

export default useSPNavItems;