import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import DocumentCard from './DocumentCard'
import CrossIcon from '../../../../../../assets/img/icons/CrossIcon'
import DropzoneUpload from '../../../../../../components/DropzoneUpload'
import { uploadDocument } from '../../../../../../data/SelectionPortalRequests'
import { getImage } from '../../../../../../data/ProductRequests'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store'

interface props {
    open: boolean,
    softwareProductDocument: any
    handleOpenVendorDocuments: (id: string) => void,
    documentType: string,
    handleFetchDocuments: () => void
}

const VendorDocumentRow = ({open, softwareProductDocument, handleOpenVendorDocuments, documentType, handleFetchDocuments} : props) => {
    const { t } = useTranslation();
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const [ uploadedFile, setUploadedFile ] = useState<string | ArrayBuffer | null>();
    const [ filename, setFilename ] = useState<string>("");
    const [ uploadError, setUploadError ] = useState(false);

    const handleUploadDocument = async () => {
        if (uploadedFile) {
            if (await uploadDocument(softwareProductDocument.id, filename, documentType, uploadedFile)) {
                setUploadError(false);
                setUploadedFile(undefined);
                handleFetchDocuments();
            } else {
                setUploadError(true);
            }
        }
    }

    const [imageSource, setImageSource] = useState("");
    
    const handleFetchImage = async (id: string) => {
        getImage(id, "/api/product/get-logo?id=", '').then(frontendImageLocation => {
            setImageSource(frontendImageLocation);
        });
    }

    useEffect(() => {
        handleFetchImage(softwareProductDocument.softwareProduct.id);
    }, [])
    
    useEffect(() => {
        handleFetchImage(softwareProductDocument.softwareProduct.id);
    }, [softwareProductDocument])

    useEffect(() => {
        handleUploadDocument();
    }, [uploadedFile])

  return (
    <>
        <div onClick={() => handleOpenVendorDocuments(softwareProductDocument.id)}
        className={`flex flex-col cursor-pointer shadow max-md:max-w-full h-32 mt-2 bg-white hover:bg-gray-50 ${open ? "rounded-t-lg" : "bg-white border-white rounded-lg"} `}>
            <div className='flex space-x-4 h-full p-4'>

              {/* Logo */}
              <div className='h-full w-[150px] flex items-center justify-center max-sm:hidden'>
                  {
                      (imageSource == "") ? (
                          <div className='h-full w-full p-4 flex items-center text-center justify-center font-bold text-white bg-gray-300 rounded'>
                              {softwareProductDocument.softwareProduct.vendorDetails.company}
                          </div>
                      ) : (
                          <img className="max-h-full" src={imageSource} />
                      )
                  }
              </div>

              <div className='flex grow max-sm:max-w-1/2 items-center h-full'>
                <div>
                    <div className='flex space-x-4 text-lg font-bold'>
                      <p>{softwareProductDocument.softwareProduct.productName}</p>
                      {/* <p className='font-light italic'>{softwareProductDocument.softwareFulfillment.note}</p> */}
                    </div>
                    <div>
                        {softwareProductDocument.softwareProduct.vendorDetails.company}
                    </div>
                </div>
            </div>

            </div>
          </div>

          {
            open ? (
                <div className='flex flex-col bg-gray-200 shadow w-full p-5 rounded-b-lg'>
                    <div className='flex flex-wrap gap-4 mt-2 grow'>
                    {
                        softwareProductDocument.selectionSoftwareProductDocument.map((documentDetails:any, index:number) => {
                        return (
                            <DocumentCard documentDetails={documentDetails} handleFetchDocuments={handleFetchDocuments} />
                        )
                        })

                    }

                        {
                            projectRole == "selection_admin" ? (
                            <div className='w-56 h-64'>
                                <DropzoneUpload setUploadedFile={setUploadedFile} setFilename={setFilename} fileType={t('vendorDocumentRow.fileType')} maxFileSize={t('vendorDocumentRow.maxFileSize')} />
                            </div>
                            ) : <></>
                        }
                    </div>
                </div>
            ) : (<></>)
          }
          
    </>
  )
}

export default VendorDocumentRow