import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPresentationEvaluationUser, getSelectionScenarioItems } from '../../../../../../../../data/SelectionPortalRequests';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { ArrowLeftIcon, ArrowRightIcon, CrossIcon, LoadingSpinner, MainButton } from 'swap-frontend-library';
import ScenarioEvaluation from './components/ScenarioEvaluation';
import { socket } from '../../../../../../../../_socket/socket';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';
import './EvaluationArea.css'

interface props {
    presentationId: string,
    meetingItem: any
}

const EvaluationArea = ({presentationId, meetingItem} : props) => {
    const { t } = useTranslation();
    const { selectionId } = useOutletContext<any>();
    const userEmail = useSelector((state: RootState) => state.user.email);
    const [scenarios, setScenarios] = useState<any[]>();
    const [scenarioEvaluations, setScenarioEvaluations] = useState<any[]>();
    const [selectedScenario, setSelectedScenario] = useState<any>();
    const [liveScenarioTaskId, setLiveScenarioTaskId] = useState<any | null>(null);
    const websocketConnected = useSelector((state: RootState) => state.socket.websocketConnected);
    const navigate = useNavigate();
    
    const handleFetchScenarios = async () => {
        const response = await getSelectionScenarioItems(selectionId);
        if (response.ok) {
            setScenarios(response.data.data);
            if (typeof selectedScenario === "undefined" && response.data.data.length > 0) {
                setSelectedScenario(response.data.data[0])
            } else {
                setSelectedScenario(null)
            }
        }
    }
    
    const handleFetchScenarioEvaluations = async () => {
        const response = await getPresentationEvaluationUser(presentationId);
        if (response.ok) {
            setScenarioEvaluations(response.data.data);
        }
    }

    useEffect(() => {
        setLiveScenarioTaskId(meetingItem.liveScenarioTaskId);
    }, [])

    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    useEffect(() => {
        if (!websocketConnected) return

        const handleJoinedPresentation = (data : any) => {
            console.log(`Websocket ${data.id} joined presentation ${data.presentationId} successfully`);
        }

        const handleSetActiveScenarioTask = (data : any) => {
            setLiveScenarioTaskId({liveScenarioId: data.liveScenarioId, liveScenarioTaskId: data.liveScenarioTaskId})
        }

        socket.on("i joined presentation", handleJoinedPresentation);
        socket.on("set active scenarioTaskId", handleSetActiveScenarioTask);

        handleFetchScenarios();
        handleFetchScenarioEvaluations();

        socket.emit("join presentation", { presentationId, email:userEmail });
        // socket.emit("test room", { presentationId });
        
        return () => {
            socket.emit("leave presentation", { presentationId })
            socket.off("joined presentation");
            socket.off("set active scenarioTaskId");
        }
    }, [websocketConnected])

    const scrollRef = useRef<HTMLUListElement>(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
        scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
        scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };
    
    if (typeof scenarios === "undefined" || typeof selectedScenario === "undefined" || typeof scenarioEvaluations === "undefined") return <LoadingSpinner />

    if (selectedScenario == null) {
        return (
            <div className='flex items-center justify-center w-full h-64 bg-white m-4 rounded shadow font-semibold'>
                {t('evaluationArea.noScenariosDefined')}
            </div>
        )
    }
    
    return (
        <div>
            <div className="flex h-12 pl-5 text-base font-semibold leading-6 text-text-gray-800 bg-white border-b border-solid">
                <ul ref={scrollRef}
                className="flex overflow-x-auto hide-scrollbar w-full h-full pt-2 text-sm w-full font-medium text-center text-gray-500">
                    {
                        scenarios.map((scenario:any, index:number) => {
                            return (
                                <li 
                                // ref={(selectedTab == scenario.id) ? (ref) : (undefined)}
                                onClick={() => {setSelectedScenario(scenario); navigate(`?scenarioId=${scenario.id}`);}}
                                className={`relative cursor-pointer flex items-end justify-between mr-2 p-2 border-b-4 ${(selectedScenario?.id == scenario.id) ? ("text-primary border-primary") : ("border-transparent hover:text-gray-600 hover:border-gray-300")}`}>
                                    <p className={`inline-block align-bottom h-full truncate w-64 rounded-t-lg`}>
                                        {t('evaluationArea.scenarioTitle', { order: scenario.cleanOrderNumber, title: scenario.title })}
                                    </p>
                                    {
                                        liveScenarioTaskId?.liveScenarioId == scenario.id ? (
                                        <div className='absolute right-0 bg-primary3 text-white rounded p-1'>
                                            {t('evaluationArea.live')}
                                        </div>
                                        ) : <></>
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
                <div className='h-12 w-16 flex items-center justify-center'>
                    <button
                    onClick={scrollLeft}
                    className="h-full px-2 flex w-6 h-6 items-center justify-center text-gray-500 hover:text-gray-700">
                        <ArrowLeftIcon />
                    </button>
                    <button
                    onClick={scrollRight}
                    className="h-full px-2 flex w-6 h-6 items-center justify-center text-gray-500 hover:text-gray-700">
                        <ArrowRightIcon />
                    </button>
                </div>
            </div>
            <div>
                <ScenarioEvaluation scenario={selectedScenario} scenarioEvaluations={scenarioEvaluations} presentationId={presentationId} handleFetchScenarioEvaluations={handleFetchScenarioEvaluations} liveScenarioTaskId={liveScenarioTaskId} setLiveScenarioTaskId={setLiveScenarioTaskId} />
            </div>
        </div>
  )
}

export default EvaluationArea