"use client";
import { io } from "socket.io-client";
export const socket = io(process.env.REACT_APP_BACKEND, {
    transports: ['websocket'],
    withCredentials: true,
    reconnection: false,
    autoConnect: false
});

export const socketMatchingEngine = io(process.env.REACT_APP_MATCHING_SERVICE, {
    transports: ['websocket'],
    withCredentials: true,
    reconnection: false,
    autoConnect: false
});