import React from 'react'
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '../../../../../../../assets/img/icons/ArrowRightIcon'

interface props {
    item: any,
    handleSelectRequirementTemplate: (item:any) => void,
    isSelectionRequirement: boolean,
}

const RequirementInputWizardRow = ({item, handleSelectRequirementTemplate, isSelectionRequirement} : props) => {
  const { t } = useTranslation();
  const createdDateParsed : Date = new Date(Date.parse(item.createdDate));
  const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;

  return (
    <div 
    className={`p-2 text-gray-900 ${isSelectionRequirement ? "bg-gray-200 border-white" : "hover:text-primary hover:bg-primary2 cursor-pointer"} border-b`} 
    onClick={() => {if (!isSelectionRequirement) handleSelectRequirementTemplate({...item, existingSelectionRequirementId: item.staticRequirementId == null ? item.id : null})}}>
        <div className='flex justify-between'>
            <div className='flex italic'>
                {item.category[0] ? <><p className='px-2 text-sm truncate max-w-32'>{item.category[0]}</p></> : <></>}
                {item.category[1] ? <><div className='flex items-center'><ArrowRightIcon iconWidth='10' iconColor='currentColor' /></div><p className='px-2 text-sm truncate'>{item.category[1]}</p></> : <></>}
                {item.category[2] ? <><div className='flex items-center'><ArrowRightIcon iconWidth='10' iconColor='currentColor' /></div><p className='px-2 text-sm truncate'>{item.category[2]}</p></> : <></>}
            </div>
            {
              item.staticRequirementId == null && typeof item.selection !== "undefined" ? (
                  <p className='px-2 text-sm truncate font-light'>{t('requirementRow.created', { createdDateString, project: item.selection.selectionTitle || t('requirementRow.untitled') })}</p>
              ) : <></>
            }
            {isSelectionRequirement ? <><p className='px-2 text-sm truncate'>{item.archived ? <p className='text-orange-400'>{t('requirementRow.archived')}</p> : <p className='text-green-600'>{t('requirementRow.active')}</p>}</p></> : <></>}
        </div>
        <p className='w-full px-2 text-md truncate'>{item.requirement}</p>
    </div>
  )
}

export default RequirementInputWizardRow