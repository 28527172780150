import React, { useState } from 'react';
import i18n from '../../../../18n';

const LanguageSelector = ({smallMode} : {smallMode?:boolean}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedLng, setSelectedLng] = useState(i18n.language.length > 2 ? i18n.language.slice(0,2) : i18n.language);

    const handleOpenDropdown = () => {
        setDropdownOpen(prev => !prev);
    }

    const handleSelectLng = (lngCode : string) => {
        i18n.changeLanguage(lngCode);
        setSelectedLng(lngCode);
        setDropdownOpen(false);
    }

    // console.log(selectedLng.length > 2 ? selectedLng.slice(0,2) : selectedLng);

    return (
    <div className="relative md:order-2">
        {/* className="text-gray-500 hover:text-gray-900" */}
        <div onClick={() => handleOpenDropdown()} className="text-gray-600 font-medium inline-flex items-center py-1 rounded-lg cursor-pointer w-full py-2">
            <img className="w-5 h-5 mr-2 rounded-full hover:border" src={`/img/icons/flag_${selectedLng}.svg`} />
            {smallMode ? "" : (selectedLng == "de") ? ("Deutsch") : (selectedLng == "en") ? ("English") : ("")}
        </div>
        {/* <!-- Dropdown --> */}

        
        <div className={`${(dropdownOpen) ? ("") : ("hidden")} absolute right-full ${smallMode ? "bottom-0" : "top-0"} z-50 my-2 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow-lg border`}>
            <ul className="py-2 font-medium" role="none">
                {
                    AVAILABLE_LANGUAGES.map((lang, index) => (
                        <li key={index}>
                            <div onClick={() => handleSelectLng(lang.code)} className="cursor-pointer block px-4 pr-7 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                                <div className="inline-flex items-center">
                                    <img className="h-3.5 w-3.5 rounded-full mr-2" src={`/img/icons/${lang.icon}`} />
                                    {lang.name}
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    </div>
  )
}

const AVAILABLE_LANGUAGES = [
    { code: "en", name: "English", icon: "flag_en.svg" },
    { code: "de", name: "Deutsch", icon: "flag_de.svg" },
    // { code: "fr", name: "Français", icon: "flag_fr.svg" }
]

export default LanguageSelector