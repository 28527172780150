import React, { useEffect, useState } from 'react'
import MainButton from '../../../../../../components/MainButton'
import CrossIcon from '../../../../../../assets/img/icons/CrossIcon';
import SpeedDial from '../../components/SpeedDial';
import SearchBarFuzzy from '../../../../../../components/SearchBarFuzzy';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import SettingsFilledIcon from '../../../../../../assets/img/icons/SettingsFilledIcon';
import ScenarioModal from './components/ScenarioModal';
import { getSelectionCompanyDescription, setSelectionCompanyDescription } from '../../../../../../data/SelectionPortalRequests';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import TextInput from '../../../../../../components/TextInput';
import WarningIcon from '../../../../../../assets/img/icons/WarningIcon';
import CheckmarkIcon from '../../../../../../assets/img/icons/CheckmarkIcon';
import ScenarioCard from './components/ScenarioCard';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import SelectionGuideInjector from '../../components/SelectionGuideInjector';
import { useTranslation } from 'react-i18next';

interface scenarioOverviewProps {
    handleOpenScenario: (id:string, name:string) => void,
    scenarios: any[],
    selectionId: string,
    handleFetchScenarios: () => void,
}

const ScenarioOverview = ({handleOpenScenario, scenarios, selectionId, handleFetchScenarios} : scenarioOverviewProps) => {
    const { t } = useTranslation();
    const role = useSelector((state: RootState) => state.user.role);
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const [addScenarioModalOpen, setAddScenarioModalOpen] = useState(false);
    const [modifyScenarioId, setModifyScenarioId] = useState<string>();
    const [filteredScenarios, setFilteredScenarios] = useState<any[]>([]);
    
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const [companyDescription, setCompanyDescription] = useState<string | null>();
    const [newDescription, setNewDescription] = useState<string | null>(null);
    
    const [scenarioAggregations, setScenarioAggregations] = useState<any[]>();
    
    useEffect(() => {
        if (!addScenarioModalOpen) { setModifyScenarioId(undefined); }
    }, [addScenarioModalOpen])
    
    useEffect(() => {
        if (typeof modifyScenarioId !== "undefined") setAddScenarioModalOpen(true);
    }, [modifyScenarioId])
    
    const handleFetchSelectionCompanyDescription = async () => {
        const response = await getSelectionCompanyDescription(selectionId)
        if (response.ok) {
            const desc = response.data.data.companyDescription;
            setCompanyDescription(desc == "" ? null : desc);
        }
    }
    
    const handleSaveNewSelectionCompanyDescription = async () => {
        if (newDescription != companyDescription && newDescription != null) {
            await setSelectionCompanyDescription(selectionId, newDescription);
            handleFetchSelectionCompanyDescription();
        }
        setNewDescription(null);
    }

    // console.log(scenarios);

    useEffect(() => { 
        setFilteredScenarios(scenarios);
        handleFetchSelectionCompanyDescription(); 
    }, [])
    
    useEffect(() => { 
        setFilteredScenarios(scenarios);
    }, [scenarios])

    return (
    <div>

        <div className="flex flex-col p-5 bg-white rounded-lg shadow max-md:max-w-full">
            {
                (typeof companyDescription === "undefined") ? (<LoadingSpinner />) : (
                    <>
                    <p className='text-sm font-semibold'>{t('scenarioOverview.companyDescription')}</p>

                    <div className='mb-5'>
                        {
                            (newDescription != null) ? (
                                <TextInput textValue={newDescription} setTextValue={setNewDescription} rows={4} />
                            ) : (
                                    companyDescription == null  ? (t('scenarioOverview.enterCompanyDescription')) : (
                                        <>
                                        <div onClick={() => setDescriptionOpen(prev => !prev)}
                                        className={`${descriptionOpen ? "" : "line-clamp-2"}`}>
                                            {companyDescription}
                                        </div>
                                        <div onClick={() => setDescriptionOpen(prev => !prev)} className='w-fit text-primary text-sm italic cursor-pointer hover:underline hover:underline-offset-3'>{descriptionOpen ? t('scenarioOverview.less') : t('scenarioOverview.more')}</div>
                                        </>

                                )
                            )
                        }
                    </div>

                    {
                        projectRole == "selection_admin" ? (
                            <div className='flex mt-5 space-x-2'>
                                {
                                    newDescription != null ? (
                                        <div className='flex space-x-2'>
                                            <MainButton text={t('scenarioOverview.save')} link={''} func={() => handleSaveNewSelectionCompanyDescription()} />
                                            <MainButton text={t('scenarioOverview.cancel')} link={''} func={() => setNewDescription(null)} style='light' />
                                        </div>
                                    ) : (
                                        <div>
                                            <MainButton text={t('scenarioOverview.edit')} link={''} func={() => setNewDescription(companyDescription == null ? "" : companyDescription)} style='light' />
                                        </div>
                                    )
                                }
                            </div>
                        ) : <></>
                    }

                    </>
                )
            }
        </div>

        <div className='border-t mt-4 pt-4 flex items-center justify-between'>
            <div className='w-64 h-10'><SearchBarFuzzy translatedPlaceholderItem={t('scenarioOverview.scenario')} originalData={scenarios} filterKeys={['title', 'description']} setFilteredData={setFilteredScenarios} /></div>
            {
                projectRole == "selection_admin" ? (
                    <div className='flex space-x-2'>
                        <MainButton height='h-10' text={t('scenarioOverview.addNewScenario')} text_location='right' link={''} func={() => setAddScenarioModalOpen(true)} icon={<div className='w-5 h-5 text-white rotate-45'><CrossIcon iconColor='currentColor' /></div>} />
                    </div>
                ) : <></>
            }
        </div>

        <div className='grid grid-cols-3 gap-4 mt-4'>
            {
                filteredScenarios.sort((a:any,b:any) => a.cleanOrderNumber-b.cleanOrderNumber).map((scenario:any, index:number) => {
                    return (
                        <ScenarioCard index={index} scenario={scenario} handleOpenScenario={handleOpenScenario} setModifyScenarioId={setModifyScenarioId} handleFetchScenarios={handleFetchScenarios} isLastItem={filteredScenarios.length == index+1} setAddScenarioModalOpen={setAddScenarioModalOpen} />
                    )
                })
            }
        </div>
        
        { (addScenarioModalOpen) ? <ScenarioModal addScenarioModalOpen={addScenarioModalOpen} setAddScenarioModalOpen={setAddScenarioModalOpen} scenarios={scenarios} selectionId={selectionId} scenarioId={modifyScenarioId} handleFetchScenarios={handleFetchScenarios} /> : <></> }

        <div className='absolute bottom-5 left-5 shadow-lg'>
          <SelectionGuideInjector selectionId={selectionId} acceptedTaskCodes={["scen-subm_scen"]} />
        </div>
        
        <SpeedDial speedDials={projectRole != "selection_admin" ? [] : [
            {tooltip: t('scenarioOverview.addScenario'), onClick:() => setAddScenarioModalOpen(true)},
            {tooltip: t('scenarioOverview.changeOrder'), onClick:() => console.log("Not implemented"), icon:<div className='text-gray-300 w-8 h-8'><RefreshIcon iconColor='currentColor' iconHeight='h-full' iconWidth='w-full'/></div>},
        ]} defaultDials={true} currentContext='scenarios' selectionId={selectionId} />

    </div>
  )
}

export default ScenarioOverview