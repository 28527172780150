import React, { useEffect, useState } from 'react'
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, CheckmarkIcon, HeadsetIcon, LoadingSpinner, MainButton, MessageIcon, ProfileFilledIcon, SettingsFilledIcon } from 'swap-frontend-library';
import SidebarParticipants from './features/SidebarParticipants';
import EvaluationScreen from './features/EvaluationArea';
import { getSelectionPresentation } from '../../../../../../../data/SelectionPortalRequests';
import SidebarChat from './features/SidebarChat';
import { socket } from '../../../../../../../_socket/socket';
import SidebarDetails from './features/SidebarDetails';
import { RootState } from '../../../../../../../redux/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const loader = async ({ params } : any) : Promise<String> => {
    if (params.presentationId) {
        return params.presentationId;
    } else {
        return "";
    }
}

const ParticipationScreen = () => {
    const { t } = useTranslation();
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const presentationId : string = String(useLoaderData());
    const [meetingItem, setMeetingItem] = useState<any>();
    const [sidebarContent, setSidebarContent] = useState("");
    const [unseenMessages, setUnseenMessages] = useState(false);
    const websocketConnected = useSelector((state: RootState) => state.socket.websocketConnected);
    
    const [messages, setMessages] = useState<any[]>([]);

    const handleGetSelectionPresentation = async () => {
        // console.log(presentationId);
        const response = await getSelectionPresentation(presentationId);
        if (response.ok) {
            setMeetingItem(response.data.data);

            const oldChatMessages = response.data.data.selectionPresentationChatMessages;
            if (typeof oldChatMessages !== "undefined") setMessages(oldChatMessages);
        }
    }

    useEffect(() => {
        if (sidebarContent == "chat") setUnseenMessages(false);
    }, [sidebarContent])
    
    useEffect(() => {
        if (sidebarContent != "chat" && messages.length > 0) setUnseenMessages(true);
    }, [messages])
    
    useEffect(() => {
        if (websocketConnected) handleGetSelectionPresentation();
    }, [websocketConnected])

    useEffect(() => {
        const handleReceiveMessage = (data : any) => {
            setMessages(prev => [...prev, data.chatMessage])
        }
    
        socket.on("send message", handleReceiveMessage);

        return () => {
            socket.off("send message");
        }
    }, [])

    if (typeof meetingItem == "undefined") {
        return (<LoadingSpinner />)
    }

    const dateParsed : Date = new Date(meetingItem.timeFrom);
    const dateString : string = `${dateParsed.getDate().toString().padStart(2, '0')}.${(dateParsed.getMonth()+1).toString().padStart(2, '0')}.${dateParsed.getFullYear()}`;
    
    const timeFrom = `${(new Date(meetingItem.timeFrom)).getHours().toString().padStart(2, '0')}:${(new Date(meetingItem.timeFrom)).getUTCMinutes().toString().padStart(2, '0')}`;
    const timeTo = `${(new Date(meetingItem.timeTo)).getHours().toString().padStart(2, '0')}:${(new Date(meetingItem.timeTo)).getUTCMinutes().toString().padStart(2, '0')}`;

    const now = new Date(); // Get the current timestamp
    const isLive = now >= new Date(meetingItem.timeFrom) && now <= new Date(meetingItem.timeTo);
    
    return (
        <div className='h-full w-full flex flex-col'>
            <div className="relative pl-16 pr-3 w-full h-16 flex items-center gap-3 py-2.5 text-sm font-medium leading-5 text-gray-900 bg-white border-b border-solid max-md:pr-5">
                <Link to={`../`} className='absolute left-3 items-center justify-start cursor-pointer w-fit hover:bg-gray-50 p-2 rounded text-gray-400'>
                    <div className='w-4 h-4'><ArrowLeftIcon/></div>
                </Link>

                <div className='w-full flex items-center justify-between'>
                    <div className='flex-1 min-w-0'> 
                        <div className='truncate flex items-center'>
                            <span className='font-semibold text-xl text-primary'>{meetingItem.selectionSoftwareProduct.softwareProduct.vendorDetails.company}</span>
                            <span className='ml-1 font-semibold text-xl text-primary'>{meetingItem.selectionSoftwareProduct.softwareProduct.productName}</span>
                        </div>
                        <div className='flex gap-1'>
                            <p className='text-sm font-light'>{meetingItem.title || t('participationScreen.vendorPresentation')}</p>
                            <p className='text-sm font-light'>{dateString},</p>
                            <p className='text-sm font-light'>{timeFrom} h - {timeTo} h</p>
                        </div>

                    </div>

                    <div className='flex items-center space-x-2 w-fit flex-shrink-0'>
                        { isLive || meetingItem.presentationStatus == "live" ? (
                            <div className='ml-4 flex items-center gap-2'>
                                <div className='relative w-4 h-4'>
                                    <div className='absolute left-0 top-0 bg-primary w-full h-full rounded-full'></div>
                                    <div className='absolute left-0 top-0 animate-ping bg-primary w-full h-full rounded-full'></div>
                                </div>
                                <p className='font-semibold text-xl text-primary'>{t('participationScreen.live')}</p> 
                            </div>
                        )
                        : <></> }
                        {
                            meetingItem.presentationStatus == "planned" ? (<div className='xl:w-56 w-32 h-16 p-2'><div className='border-2 border-dashed border-gray-400 bg-gray-400/10 h-full w-full rounded flex items-center justify-center text-center font-semibold text-gray-400'>{t('participationScreen.presentationPlanned')}</div></div>) :
                            meetingItem.presentationStatus == "completed" ? (<div className='xl:w-56 w-32 h-16 p-2'><div className='border-2 border-dashed border-primary bg-primary/10 h-full w-full rounded flex items-center justify-center text-center font-semibold text-primary'>{t('participationScreen.presentationCompleted')}</div></div>) :
                            meetingItem.presentationStatus == "canceled" ? (<div className='xl:w-56 w-32 h-16 p-2'><div className='border-2 border-dashed border-red-400 bg-red-400/10 h-full w-full rounded flex items-center justify-center text-center font-semibold text-red-400'>{t('participationScreen.presentationCanceled')}</div></div>) :
                            meetingItem.videoconferenceLink != "" && meetingItem.videoconferenceLink != null ? (
                                <a rel="noopener noreferrer" target='_blank' href={meetingItem.videoconferenceLink.includes("http") ? meetingItem.videoconferenceLink : "https://"+meetingItem.videoconferenceLink} className='xl:w-56 w-32 h-16 p-2'>
                                    <div className='flex items-center justify-center gap-4 bg-primary rounded h-full text-white font-semibold'>
                                        {t('participationScreen.joinCall')}
                                        <div className='w-6 h-6 hidden xl:block'><HeadsetIcon /></div>
                                    </div>
                                </a>
                            ) : <></>
                        }
                        <div onClick={() => {setSidebarContent(prev => (prev == "details") ? ("") : ("details"));}}
                        className={`cursor-pointer flex items-center text-gray-400 justify-center h-10 w-10 p-1.5 rounded-full ${sidebarContent == "details" ? "bg-primary text-white" : "hover:bg-gray-100"}`}>
                            <SettingsFilledIcon />
                            {/* {sidebarContent == "analysis" ? "white" : "currentColor"} */}
                        </div>
                        <div onClick={() => {setSidebarContent(prev => (prev == "participants") ? ("") : ("participants"));}}
                        className={`cursor-pointer flex items-center text-gray-400 border border-transparent justify-center h-10 w-10 p-1 rounded-full ${sidebarContent == "participants" ? "bg-primary text-white" : "hover:bg-gray-100"}`}>
                            <ProfileFilledIcon />
                        </div>
                        <div onClick={() => {setSidebarContent(prev => (prev == "chat") ? ("") : ("chat"));}}
                        className={`relative cursor-pointer text-gray-400 p-1 flex items-center border border-transparent justify-center h-10 w-10 rounded-full ${sidebarContent == "chat" ? "bg-primary text-white" : "hover:bg-gray-100"}`}>
                            <MessageIcon />
                            {
                                unseenMessages ? <div className='absolute rounded-full w-3 h-3 bg-primary right-0 bottom-0'></div> : <></>
                            }
                            <div></div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='relative h-full grow flex overflow-y-hidden'>
                <div className='w-full overflow-y-auto'>
                    <EvaluationScreen presentationId={meetingItem.id} meetingItem={meetingItem} />
                </div>
                {
                    sidebarContent == "" ? (<></>) : (
                        <div className='absolute shadow-lg right-0 h-full flex flex-col bg-white w-fit border-l'>
                            <div className='absolute h-16 text-2xl p-4 font-semibold w-96'>{t(`participationScreen.${sidebarContent}`)}</div>
                            <div className='p-4 pt-16 flex h-full'>
                                {
                                    (sidebarContent == "details") ? (<SidebarDetails presentationId={presentationId} meetingItem={meetingItem} handleGetSelectionPresentation={handleGetSelectionPresentation} />) :
                                    (sidebarContent == "participants") ? (<SidebarParticipants participants={meetingItem.selectionPresentationParticipant} presentationId={presentationId} handleGetSelectionPresentation={handleGetSelectionPresentation} />) :
                                    (sidebarContent == "chat") ? (<SidebarChat messages={messages} presentationId={presentationId} />) : <></>
                                }
                            </div>
                        </div>
                    )
                }
            </div>
            {
                projectRole === 'selection_admin' ? (
                <div className='absolute bottom-5 right-5 shadow-lg'>
                    <MainButton text={t('participationScreen.results')} link='results' />
                </div>
                ) : <></>
            }
        </div>
    )
}

export default ParticipationScreen