import React, { useState } from 'react'
import TypingText from './TypingText'
import { ArrowDownIcon } from 'swap-frontend-library'
import { OpenIcon } from 'swap-frontend-library';

const TopNRequirementRow = (props : {item : any}) => {
    const [reasoningOpen, setReasoningOpen] = useState(false);
  return (
    <>
        <div onClick={() => setReasoningOpen(prev => !prev)} className='py-4 h-full flex items-center justify-between cursor-pointer'>
            <div className='md:flex'>
                <p className='text-3xl text-nowrap text-primary font-bold italic whitespace-nowrap flex-shrink-0'>{props.item[1] > 1 ? props.item[1] : Math.round(props.item[1] * 100)} %</p>
                <div className={`md:ml-5 text-lg font-semibold text-gray-900 ${reasoningOpen ? "" : "line-clamp-2"}`}>
                    {/* <TypingText typingSpeed={30} text={`${props.item[0]}`} /> */}
                    {props.item[0]}
                </div>
            </div>
            <div>
                <p className='w-6 h-6'><ArrowDownIcon /></p>
            </div>
        </div>
        {
            !reasoningOpen ? <></> : (
            <div className='space-y-3 mb-4'>
                {
                    props.item[2].length == 0 ? <p className='font-light'>Derived based on best practices in your industry and competitive environment</p> :
                    props.item[2].map((item2 : any, index : number) => (
                        <div >
                            <p className='italic font-light'>"{item2.originText.split("_")[1]}"</p>
                            <a href={item2.originUrl} target="_blank" className='underline underline-offset-2 hover:underline-offset-4 transition-all flex text-primary/80 hover:text-primary'>{item2.originUrl} <div className='w-6 h-6'><OpenIcon /></div></a>
                        </div>
                    ))
                }
            </div>
            )
        }
    </>
  )
}

export default TopNRequirementRow