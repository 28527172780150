import React, { useEffect, useRef, useState } from 'react'
import MessageBubble from '../../requirement-manager/components/MessageBubble';
import { addChat, addMessage, getMessages, getUsersSelection } from '../../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';

interface props {
    chatId: string | undefined,
    selectedChatEmail: string | undefined,
    setChatId: React.Dispatch<React.SetStateAction<string | undefined>>;
    handleSwitchChatsToRecipient: (recipientId: string) => void;
    // handleFetchChats: () => Promise<void>;
}

const ChatWindow = ({chatId, setChatId, selectedChatEmail, handleSwitchChatsToRecipient} : props) => {
    const {t} = useTranslation();
    const ref = useRef<any>();
    const [newMessage, setNewMessage] = useState("");
    const [messages, setMessages] = useState<any>();
    const [userSelection, setUsersSelection] = useState<any[]>();
    const [scroll_temporary, setScroll_temporary] = useState(false);
    const { selectionId } = useOutletContext<any>();
    const email = useSelector((state: RootState) => state.user.email);

    const handleFetchUsersSelection = async () => {
        const data = await getUsersSelection(selectionId);
        setUsersSelection(data.data.data);
    }
    
    useEffect(() => {
        if (chatId == "") {
            handleFetchUsersSelection();
        }
    }, [chatId])

    const handleChangeRecipientId = async (newRecipientId: string) => {
        
        // TODO Nur wenn Chat noch nicht vorhanden
        // TODO Erstelle Chat erst nach erster Nachricht
        const response = await addChat([newRecipientId], selectionId);
        if (response.ok) {
            handleSwitchChatsToRecipient(newRecipientId);
        }
    }

    const handleFetchMessages = async () => {
        if (chatId != "" && typeof chatId !== "undefined") {
            const response = await getMessages(chatId);
            if (response.ok) {
                // console.log(response.data.data)
                setMessages(response.data.data);
            }
        }
    }

    useEffect(() => {
        if (scroll_temporary) {
            ref.current.scrollIntoView({ behavior: "smooth" });
            setScroll_temporary(false);
        }
    }, [messages])

    useEffect(() => {
            handleFetchMessages();
            setScroll_temporary(true);
    }, [chatId])

    const handleSendMessage = async () => {
        if (typeof chatId !== "undefined") {
            const response = await addMessage(chatId, newMessage);

            if (response.ok) {
                setNewMessage("");
                await handleFetchMessages();
                setScroll_temporary(true);
            }
        }
    }

    useEffect(() => {
        const interval = setInterval(handleFetchMessages, 5000);
        return () => {
            clearInterval(interval);
        }
    }, [chatId]);


  return (
    <div className='relative flex w-full h-full'>

        <div className='absolute border-b w-full p-4 h-16 top-0 bg-white flex items-center'>
            {
                (chatId == "") ? (
                    <>
                        {    
                            (typeof userSelection === "undefined") ? (<></>) : (
                                <div className='w-full'>
                                    <select onChange={(event) => handleChangeRecipientId(event.target.value)}
                                    id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" style={{WebkitAppearance: "none"}}>
                                        <option value="" selected disabled hidden>{t("chatWindow.chooseHere")}</option>
                                        {
                                            userSelection.filter((item:any) => item.user).map((item : any, index : number) => {
                                                if ((item.user.email).toLowerCase() != email.toLowerCase()) {
                                                    return (<option key={index} value={item.user.id}>{item.user.email}</option>);
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                            )
                    }
                    </>
                ) : (
                    <p className='font-bold text-xl'>{selectedChatEmail}</p>
                )
            }
        </div>

        {
            (chatId != "") ? (
                <div className='py-8 mt-16 mb-20 w-full content-end grid grid-cols-1'>
                    <div className='w-full overflow-y-auto px-8 space-y-2'>
                    {
                        (typeof messages === "undefined") ? (<LoadingSpinner />) : (messages.length == 0) ? (<></>) : (
                        <>
                        {
                            messages.map((message: any, index:string) => {
                                return (
                                    <MessageBubble messageEmail={message.userMessage_email} ownEmail={email} text={message.chatMessage_message} date={message.chatMessage_updatedDate} />
                                )
                            })
                        }
                        </>
                        )
                    }
                        <div ref={ref}></div>
                    </div>
                </div>
            ) : (<></>)
        }

        <div className='flex items-center absolute bottom-0 h-20 border-t p-4 bg-white w-full'>
            <div className='w-full'>   
                <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                <div className="relative">
                    <input 
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => (e.key === 'Enter') ? (handleSendMessage()) : (null)}
                    type="search" id="search" value={newMessage}
                    className="block w-full p-4 text-sm text-gray-900 border border-gray-300 focus:border-gray-500 rounded-lg bg-gray-50" placeholder={t("chatWindow.messagePlaceholder")} required />
                    <button onClick={() => handleSendMessage()}
                    type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-primary border hover:bg-primary2 hover:text-primary hover:border-primary font-medium rounded-lg text-sm px-4 py-2">{t("chatWindow.sendButton")}</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ChatWindow