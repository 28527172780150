"use client"
import React, { useState, useEffect } from 'react';
import { socket } from './socket';
import { LoadingSpinner } from 'swap-frontend-library';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { setWebsocketConnected } from '../redux/socketSlice';

const SocketInjector = () => {
    const websocketConnected = useSelector((state: RootState) => state.socket.websocketConnected);
    const dispatch = useDispatch();
    
    useEffect(() => {
        console.log("Selection Portal socket init");
        let reconnectTimer : any = null;
        const reconnectDelay = 5000;

        const attemptReconnect = () => {
            if (!socket.connected) {
                console.log("Attempting to reconnect...");
                socket.connect();
            }
        };

        const onConnected = (data : {id : string}) => {
            console.log("Connected Selection Portal Socket. Websocket id: " + data.id);
            // setSocketConnected(true);
            dispatch(setWebsocketConnected(true));
            if (reconnectTimer) {
                clearInterval(reconnectTimer);
                reconnectTimer = null;
            }
        };

        const onDisconnect = () => {
            console.log("Disconnected. Scheduling reconnect...");
            // setSocketConnected(false);
            dispatch(setWebsocketConnected(false));
            if (!reconnectTimer) {
                reconnectTimer = setInterval(attemptReconnect, reconnectDelay);
            }
        };
    
        socket.connect();

        socket.on('connected', onConnected);
        socket.on('disconnect', onDisconnect);

        return () => {
            socket.disconnect();
            socket.off('disconnect', onDisconnect);
            if (reconnectTimer) {
                clearInterval(reconnectTimer);
            }
        };
    }, []);
    
    if (!websocketConnected) {
        return (
            <div className='absolute w-full h-full bg-white/30 backdrop-blur z-[2000] flex items-center justify-center'>
                <div className='bg-white/90 shadow-lg border rounded-xl p-16 text-center flex flex-col items-center justify-center w-[90%] xl:w-[50%]'>
                    <p className='text-4xl font-semibold mb-16'>Ups... The connection was interrupted.</p>
                    <p className='text-xl font-semibold'>We are trying to re-establish the connection. Please wait a moment...</p>
                    <p className='text-xl font-light mb-16'>If the problem persists, reload the page or open the page in a new window.If this doesn't help, please contact our support at <a href={`mailto:${sessionStorage.contactEmail}`} className='cursor-pointer underline'>{sessionStorage.contactEmail}</a>.</p>
                    <LoadingSpinner />
                </div>
            </div>
        )
    } else {
        return (<></>)
        // return (<div className='absolute bottom-0 left-5 z-[10000]'>{String(websocketConnected)}</div>)
    }
}

export default SocketInjector