import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPresentationEvaluationOverall, getSelectionScenarioItems, getUsersSelection } from '../../../../../../../../data/SelectionPortalRequests';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, CrossIcon, LoadingSpinner, MainButton } from 'swap-frontend-library';
import { socket } from '../../../../../../../../_socket/socket';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';
import ScenarioList from './ScenarioList';
import OverallResultsScreen from './OverallResultsScreen';
import ParticipantIcon from '../../presentation-overview/components/ParticipantIcon';
// import './EvaluationArea.css'

interface props {
    presentationId: string,
    meetingItem: any
}

const ScenarioListWrapper = ({presentationId, meetingItem} : props) => {
    const { t } = useTranslation();
    const { selectionId } = useOutletContext<any>();
    const [scenarios, setScenarios] = useState<any[]>();
    const [scenarioEvaluations, setScenarioEvaluations] = useState<any>();
    const [numberOfEvaluatios, setNumberOfEvaluations] = useState<number>();
    const [numberOfComments, setNumberOfComments] = useState<number>();
    const [selectedScenario, setSelectedScenario] = useState<any>();
    
    const [resultStatsOpen, setResultStatsOpen] = useState(false);
    const [resultStatsParticipantsOpen, setResultStatsParticipantsOpen] = useState(false);
    const [resultStatsGroupsOpen, setResultStatsGroupsOpen] = useState(false);
    
    const [filterSelectedParticipant, setFilterSelectedParticipant] = useState<string>("");
    const [filterSelectedParticipants, setFilterSelectedParticipants] = useState<string[]>([]);
    const [filterSelectedParticipantGroup, setFilterSelectedParticipantGroup] = useState<string | null>(null);
    const [filterSelectedCustomGroup, setFilterSelectedCustomGroup] = useState<string | null>(null);

    const [liveScenarioTaskId, setLiveScenarioTaskId] = useState<any | null>(null);
    const navigate = useNavigate();

    const [userGroups, setUserGroups] = useState<any>();
    const handleFetchUsersSelection = async () => {
        const data = await getUsersSelection(selectionId);
        // console.log("AAA", data.data.data)
        const customGroups = data.data.data.reduce((r:any, a:any) => {
            let group = a.selectionCustomGroup == null ? "no_group" : a.selectionCustomGroup;
            const key = group.replace(" ", "_");
            r[key] = r[key] || [];
            r[key].push(a.id);
            
            return r;
        }, Object.create(null));

        // console.log("customGroups", data)
        
        const participantGroups = data.data.data.reduce((r:any, a:any) => {
            let group = a.selectionParticipantGroup == null ? "no_group" : a.selectionParticipantGroup;
            const key = group.replace(" ", "_");
            r[key] = r[key] || [];
            r[key].push(a.id);
            
            return r;
        }, Object.create(null));
        setUserGroups({customGroups, participantGroups});
    }
    
    const handleFetchScenarios = async () => {
        const response = await getSelectionScenarioItems(selectionId);
        if (response.ok) {
            setScenarios(response.data.data);
            if (typeof selectedScenario === "undefined" && response.data.data.length > 0) {
                setSelectedScenario({id: "overall"});
            } else {
                setSelectedScenario(null)
            }
        }
    }
    
    const handleFetchScenarioEvaluations = async (selectedUsers?:string[]) => {
        const response = await getPresentationEvaluationOverall(presentationId, selectedUsers);
        if (response.ok) {
            // console.log(response.data.data)
            setScenarioEvaluations(response.data.data);
            setNumberOfEvaluations(response.data.data.numberOfEvaluations);
            setNumberOfComments(response.data.data.numberOfComments);
        }
    }

    useEffect(() => {
        setLiveScenarioTaskId(meetingItem.liveScenarioTaskId);
    }, [])

    useEffect(() => {
        handleFetchScenarios();
        handleFetchScenarioEvaluations();
        handleFetchUsersSelection();
    }, [])

    useEffect(() => {
        if (filterSelectedParticipantGroup == null) return
        setFilterSelectedCustomGroup(null);
        setFilterSelectedParticipant("");
        
        setFilterSelectedParticipants(userGroups.participantGroups[filterSelectedParticipantGroup]);
        handleFetchScenarioEvaluations(userGroups.participantGroups[filterSelectedParticipantGroup]);
    }, [filterSelectedParticipantGroup])
    
    useEffect(() => {
        if (filterSelectedCustomGroup == null) return
        setFilterSelectedParticipantGroup(null);
        setFilterSelectedParticipant("");

        setFilterSelectedParticipants(userGroups.customGroups[filterSelectedCustomGroup]);
        handleFetchScenarioEvaluations(userGroups.customGroups[filterSelectedCustomGroup]);
    }, [filterSelectedCustomGroup])
    
    useEffect(() => {
        if (filterSelectedParticipant == "") return
        setFilterSelectedParticipantGroup(null);
        setFilterSelectedCustomGroup(null);

        setFilterSelectedParticipants([filterSelectedParticipant]);
        handleFetchScenarioEvaluations([filterSelectedParticipant]);
    }, [filterSelectedParticipant])

    const handleUnfilter = (reload:boolean) => {
        setFilterSelectedCustomGroup(null);
        setFilterSelectedParticipantGroup(null);
        setFilterSelectedParticipant("");
        setFilterSelectedParticipants([]);
        if (reload) handleFetchScenarioEvaluations();
    }

    const scrollRef = useRef<HTMLUListElement>(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
        scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
        scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };
    
    if (typeof scenarios === "undefined" || typeof selectedScenario === "undefined" || typeof scenarioEvaluations === "undefined") return <LoadingSpinner />

    if (selectedScenario == null) {
        return (
            <div className='flex items-center justify-center w-full h-64 bg-white m-4 rounded shadow font-semibold'>
                {t('scenarioListWrapper.noScenariosDefined')}
            </div>
        )
    }
    
    return (
        <div>
            <div className='border-b bg-white p-4 w-full font-semibold text-xl text-gray-700 flex flex-col gap-4'>
                <div onClick={() => setResultStatsOpen(prev => !prev)} className='cursor-pointer text-center flex items-center justify-center gap-2 bg-amber-400/30 h-full p-2 rounded border-2 border-amber-400 border-dashed text-amber-600'>
                    {t('scenarioListWrapper.resultEvaluation')}
                    <div className={`w-4 h-4 ${resultStatsOpen ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div>
                </div>
                {
                    resultStatsOpen ? (
                        <div className='flex flex-col bg-white border text-left gap-4 rounded-lg'>
                            <div className='w-full text-sm font-normal p-4'>
                                <p className='font-semibold text-sm mb-4'>{t('scenarioListWrapper.statistics')}</p> 
                                <div className='grid grid-cols-2'>
                                    <p>{numberOfEvaluatios} {t('scenarioListWrapper.evaluations')}</p>
                                    <p>{numberOfComments} {t('scenarioListWrapper.comments')}</p>
                                </div>
                            </div>
                            <div className='w-full font-normal text-sm border-t p-4'>
                                <div onClick={() => setResultStatsGroupsOpen(prev => !prev)} className='flex items-center gap-2 cursor-pointer'>
                                    <p className='font-semibold text-sm'>{t('scenarioListWrapper.filterByGroups')}</p> 
                                    <div className={`w-4 h-4 ${resultStatsGroupsOpen ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div>
                                </div>
                                {
                                    resultStatsGroupsOpen ? (
                                        <>
                                        <div className='grid grid-cols-3 gap-2 mt-4'>
                                            {
                                                Object.keys(userGroups.customGroups).map((groupKey : any) => {
                                                    let groupLabel = groupKey.replace("_"," ");
                                                    // console.log(filterSelectedCustomGroup, groupKey, filterSelectedCustomGroup == groupKey)
                                                    // groupLabel = groupLabel[0].toUpperCase() + groupLabel.slice(1);
                                                    return (
                                                    <div onClick={() => {filterSelectedCustomGroup == groupKey ? handleUnfilter(true) : setFilterSelectedCustomGroup(groupKey)}}
                                                    className={`${filterSelectedCustomGroup == groupKey ? "bg-primary" : "bg-white hover:bg-gray-50"} flex items-center gap-4 border cursor-pointer rounded-r-full rounded-l-full text-left pl-2 pr-4 py-2`}>
                                                        <div className='truncate'>
                                                            <p className='font-semibold text-nowrap p-2'>{groupKey=="no_group" ? t("scenarioListWrapper."+groupKey) : groupKey}</p>
                                                        </div>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='grid grid-cols-3 gap-2 mt-4 pt-4 border-t'>
                                            {
                                                Object.keys(userGroups.participantGroups).map((groupKey : any) => {
                                                    // console.log(groupKey, filterSelectedParticipantGroup)
                                                    let groupLabel = groupKey.replace("_"," ");
                                                    // groupLabel = groupLabel[0].toUpperCase() + groupLabel.slice(1);
                                                    return (
                                                    <div onClick={() => {filterSelectedParticipantGroup == groupKey ? handleUnfilter(true) : setFilterSelectedParticipantGroup(groupKey)}}
                                                    className={`${filterSelectedParticipantGroup == groupKey ? "bg-primary" : "bg-white hover:bg-gray-50"} flex items-center gap-4 border cursor-pointer rounded-r-full rounded-l-full text-left pl-2 pr-4 py-2`}>
                                                        <div className='truncate'>
                                                            <p className='font-semibold text-nowrap p-2'>{t("scenarioListWrapper."+groupKey)}</p>
                                                        </div>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                            <div className='w-full font-normal text-sm border-t p-4'>
                                <div onClick={() => setResultStatsParticipantsOpen(prev => !prev)} className='flex items-center gap-2 cursor-pointer'>
                                    <p className='font-semibold text-sm'>{t('scenarioListWrapper.filterByParticipants')}</p> 
                                    <div className={`w-4 h-4 ${resultStatsParticipantsOpen ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div>
                                </div>
                                {
                                    resultStatsParticipantsOpen ? (
                                        <div className='grid grid-cols-3 gap-2 mt-4'>
                                            {
                                                meetingItem.selectionPresentationParticipant.filter((item:any) => ((filterSelectedParticipant == "") || (filterSelectedParticipant == item.userSelection.id))).sort((a : any, b : any) => ((a.userSelection.user?.email || a.userSelection.guestEmail) < (b.userSelection.user?.email || b.userSelection.guestEmail) ? -1 : 1)).map((participant : any) => {
                                                    let avgRating = 0;
                                                    if (typeof scenarioEvaluations.participantStatistics[participant.userSelection.id] !== "undefined") avgRating = scenarioEvaluations.participantStatistics[participant.userSelection.id].sumRatings/scenarioEvaluations.participantStatistics[participant.userSelection.id].numRatings;
                                                    return (
                                                    <div onClick={() => {if (filterSelectedParticipants.includes(participant.userSelection.id)) {handleUnfilter(true);} else {handleUnfilter(false); setFilterSelectedParticipant(participant.userSelection.id);}}}
                                                    className={`${filterSelectedParticipants.includes(participant.userSelection.id) ? "bg-primary" : "bg-white hover:bg-gray-50"} flex items-center gap-4 border cursor-pointer rounded-r-full rounded-l-full text-left pl-2 pr-4 py-2`}>
                                                        <ParticipantIcon name={(participant.userSelection.user?.email || participant.userSelection.guestEmail).replace("@", " ")} checked={false} />
                                                        <div className='truncate'>
                                                            <p className='font-semibold text-nowrap'>{participant.userSelection.user?.email || participant.userSelection.guestEmail}</p>
                                                            {
                                                                typeof scenarioEvaluations.participantStatistics[participant.userSelection.id] !== "undefined" ? (
                                                                    <div>
                                                                        <p className='font-light text-sm'>{scenarioEvaluations.participantStatistics[participant.userSelection.id].numRatings} {t('scenarioListWrapper.ratings')}, {scenarioEvaluations.participantStatistics[participant.userSelection.id].numComments} {t('scenarioListWrapper.comments')}</p>
                                                                        <div className={`relative group col-span-3 flex items-center justify-start space-x-1 items-center`}>
                                                                            {
                                                                                [...Array(6).keys()].map((ithDot: any, index: number) => {
                                                                                    return (
                                                                                        <div
                                                                                        className={`w-4 h-4 flex justify-center group/${ithDot} ${(ithDot < Math.round(avgRating)) ? ("bg-primary"): ("bg-gray-100 hover:bg-gray-200")} rounded-full`}>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <div className='pl-4 text-sm font-light'>
                                                                                Ø {avgRating.toFixed(1)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <p className='font-light text-sm'>0 {t('scenarioListWrapper.ratings')}, 0 {t('scenarioListWrapper.comments')}</p>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                        </div>
                    ) : <></>
                }
            </div>
            <div className="flex items-center pl-5 text-base font-semibold leading-6 text-text-gray-800 bg-white border-b border-solid">
                <ul ref={scrollRef}
                className="flex overflow-x-auto hide-scrollbar w-full h-full pt-2 text-sm w-full font-medium text-center text-gray-500">
                     <li 
                    // ref={(selectedTab == scenario.id) ? (ref) : (undefined)}
                    onClick={() => {setSelectedScenario({id: "overall"}); navigate(`?scenarioId=overall`);}}
                    className={`relative cursor-pointer flex flex-col mr-2 p-2 border-b-4 bg-primary/20 rounded-t text-primary ${(selectedScenario?.id == "overall") ? ("text-primary border-primary") : ("border-transparent hover:border-gray-300")}`}>
                        <p className={`inline-block align-bottom h-full truncate w-64 rounded-t-lg`}>
                         {t('scenarioListWrapper.overall')}
                        </p>
                        <div className={`mt-2 relative group col-span-3 flex items-center justify-center space-x-1 items-center`}>
                            {
                                [...Array(6).keys()].map((ithDot: any, index: number) => {
                                    return (
                                        <div
                                        className={`w-4 h-4 flex justify-center group/${ithDot} ${(ithDot < Math.round(scenarioEvaluations.avgRatingOverall)) ? ("bg-primary"): ("bg-gray-100")} rounded-full`}>
                                        </div>
                                    )
                                })
                            }
                            {
                                scenarioEvaluations.avgRatingOverall == null ? (
                                    <div className='pl-4'>
                                        Ø -
                                    </div>
                                ) : (
                                    <div className='pl-4'>
                                        Ø {scenarioEvaluations.avgRatingOverall.toFixed(1)}
                                    </div>
                                )
                            }
                        </div>
                    </li>
                    {
                        scenarios.map((scenario:any, index:number) => {
                            let averageScore = 0;
                            if (typeof scenarioEvaluations.scenarioStatistics[scenario.id] !== "undefined") averageScore = (scenarioEvaluations.scenarioStatistics[scenario.id].statistics.sumRatings / scenarioEvaluations.scenarioStatistics[scenario.id].statistics.numRatings);
                            return (
                                <li 
                                // ref={(selectedTab == scenario.id) ? (ref) : (undefined)}
                                onClick={() => {setSelectedScenario(scenario); navigate(`?scenarioId=${scenario.id}`);}}
                                className={`relative cursor-pointer flex flex-col mr-2 p-2 border-b-4 ${(selectedScenario?.id == scenario.id) ? ("text-primary border-primary") : ("border-transparent hover:text-gray-600 hover:border-gray-300")}`}>
                                    <p className={`inline-block align-bottom h-full truncate w-64 rounded-t-lg`}>
                                    {t('scenarioListWrapper.scenario', { order: scenario.cleanOrderNumber, title: scenario.title })}
                                    </p>
                                    <div className={`mt-2 relative group col-span-3 flex items-center justify-center space-x-1 items-center`}>
                                        {
                                            [...Array(6).keys()].map((ithDot: any, index: number) => {
                                                return (
                                                    <div
                                                    className={`w-4 h-4 flex justify-center group/${ithDot} ${(ithDot < Math.round(averageScore)) ? ("bg-primary"): ("bg-gray-100 hover:bg-gray-200")} rounded-full`}>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className='bg-white pl-4'>
                                            Ø {averageScore.toFixed(1)}
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
                <div className='h-12 w-16 flex items-center justify-center'>
                    <button
                    onClick={scrollLeft}
                    className="h-full px-2 flex w-6 h-6 items-center justify-center text-gray-500 hover:text-gray-700">
                        <ArrowLeftIcon />
                    </button>
                    <button
                    onClick={scrollRight}
                    className="h-full px-2 flex w-6 h-6 items-center justify-center text-gray-500 hover:text-gray-700">
                        <ArrowRightIcon />
                    </button>
                </div>
            </div>
            <div>
                {
                    selectedScenario.id == "overall" ? (
                        <OverallResultsScreen presentationId={presentationId} handleFetchScenarioEvaluations={handleFetchScenarioEvaluations} scenarioEvaluations={scenarioEvaluations} scenarios={scenarios} setSelectedScenario={setSelectedScenario} />
                    ) : (
                        <ScenarioList scenario={selectedScenario} scenarioEvaluations={scenarioEvaluations} presentationId={presentationId} handleFetchScenarioEvaluations={handleFetchScenarioEvaluations} liveScenarioTaskId={liveScenarioTaskId} setLiveScenarioTaskId={setLiveScenarioTaskId} />
                    )
                }
            </div>
        </div>
  )
}

export default ScenarioListWrapper