import React, { useState } from 'react'
import TypingText from '../components/TypingText'
import { ArrowDownIcon } from 'swap-frontend-library';

const CompanyProfileAnalysis = ({ companyProfile, open, typing, plainStyle } : { companyProfile : any, open? : boolean | null, typing?: boolean, plainStyle?: boolean}) => {
    const [visible, setVisible] = useState(typeof open !== "undefined" && open != null ? open : true);

    return (
        <div 
        // onClick={(e) => {e.stopPropagation(); !visible && open != null ? setVisible(true) : null}} 
        className={`w-full h-fit p-10 max-md:px-8 max-md:py-10 ${ plainStyle ? "" : "shadow border bg-white rounded-lg" } expand-down ${visible ? "" : "cursor-pointer"}`}>
            <div className='relative flex items-center justify-between'>
                <p className='font-semibold text-xl'>Your company profile</p>
                { 
                    (open != null || typeof open === "undefined") ? (
                        <div onClick={() => setVisible(prev => !prev)} className={`cursor-pointer w-8 h-8 text-gray-300 ${visible ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
                    ) : (
                        <></>
                    ) 
                }
            </div>

            {
                !visible ? <></> : (    
                    <div className='w-full h-full expand-down mt-4'>
                        <div className='space-y-4'>
                            { companyProfile.industryKeywords == "NA" || companyProfile.industryKeywords == null ? <></> : (
                                <div>
                                    <p className='font-semibold'>Competitive industries</p>
                                    <p className='font-normal'><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyProfile.industryKeywords) ? companyProfile.industryKeywords.join(", ") : companyProfile.industryKeywords} /></p>
                                </div>
                            )}
                            { companyProfile.offerings == "NA" || companyProfile.offerings == null ? <></> : (
                                <div>
                                    <p className='font-semibold'>Product and service offerings</p>
                                    <p className='font-normal'><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyProfile.offerings) ? companyProfile.offerings.join(", ") : companyProfile.offerings} /></p>
                                </div>
                            )}
                            { companyProfile.customerGroups == "NA" || companyProfile.customerGroups == null ? <></> : (
                                <div>
                                    <p className='font-semibold'>Customer groups</p>
                                    <p className='font-normal'><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyProfile.customerGroups) ? companyProfile.customerGroups.join(", ") : companyProfile.customerGroups} /></p>
                                </div>
                            )}
                            { companyProfile.salesMarkets == "NA" || companyProfile.salesMarkets == null ? <></> : (
                                <div>
                                    <p className='font-semibold'>Sales markets</p>
                                    <p className='font-normal'><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyProfile.salesMarkets) ? companyProfile.salesMarkets.join(", ") : companyProfile.salesMarkets} /></p>
                                </div>
                            )}
                            { companyProfile.establishedSince == "NA" || companyProfile.establishedSince == null ? <></> : (
                                <div>
                                    <p className='font-semibold'>Company formation / operations</p>
                                    <p className='font-normal'><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyProfile.establishedSince) ? companyProfile.establishedSince.join(", ") : companyProfile.establishedSince} /></p>
                                </div>
                            )}
                            { companyProfile.employeeCount == 0 || companyProfile.employeeCount == null ? <></> : (
                                <div>
                                    <p className='font-semibold'>Employees</p>
                                    <p className='font-normal'><TypingText disabled={!typing} typingSpeed={30} text={Array.isArray(companyProfile.employeeCount) ? companyProfile.employeeCount.join(", ") : String(companyProfile.employeeCount)} /></p>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        </div>
  )
}

export default CompanyProfileAnalysis