import React, { useEffect, useState } from 'react'
import { getSelections } from '../../../../../data/SelectionPortalRequests';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import SelectionBrowserTable from './features/SelectionBrowserTable';
import { useTranslation } from 'react-i18next';

const SelectionBrowser = () => {
    const { t } = useTranslation();

    return (
    <div className='p-4 h-full pt-16 relative'>
        <div className="absolute mb-4 col-span-full xl:mb-2 h-10 top-4">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                {t('selectionBrowser.title')}
            </h1>
        </div>

        <div className=' bg-white shadow rounded-lg p-4 h-full'>
            <SelectionBrowserTable />
        </div>

    </div>
  )
}

export default SelectionBrowser