import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CrossIcon from '../../../../../assets/img/icons/CrossIcon'
import QuestionmarkIcon from '../../../../../assets/img/icons/QuestionmarkIcon';
import RocketIcon from '../../../../../assets/img/icons/RocketIcon';
import Tooltip from '../../../../../components/Tooltip';
import HelpDeskWindow from '../help-desk/features/HelpDeskWindow';
import NextStepsWidget from '../selection-cockpit/features/NextStepsWidget';
import { getSelectionPhase, getSelectionPhaseTaskDetails, updateSelectionPhaseTaskDetails } from '../../../../../data/SelectionPortalRequests';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import ArrowRightIcon from '../../../../../assets/img/icons/ArrowRightIcon';
import MainButton from '../../../../../components/MainButton';
import { useOutletContext } from 'react-router-dom';
import i18n from '../../../../../18n';

export const SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS = {
    "requ-prio_reqs": "requirements must still be assigned an importance.",
    "requ-revi_reqs": "further systems must be excluded so that a maximum of 10 systems are on the shortlist.",
    "scen-deve_scen": "scenario tasks available. Add some more to complete this tasks.",
    "cont-requ_prop": "proposals uploaded. Add some more to complete this tasks.",
    "pres-gene_shor": "further systems must be excluded so that a maximum of 4 systems are on the shortlist.",
}

const SELECTION_GUIDE_PHASE_TASK_COMPLETE_LABELS = {
    "requ-docu_reqs": "You have added requirements and can complete the task."
}

interface SpeedDial {
    icon?: JSX.Element
    tooltip: string,
    onClick: () => void;
}

interface props {
    speedDials: SpeedDial[],
    defaultDials: boolean,
    currentContext?: string
    offsetX?: string
    offsetY?: string
    selectionId?: string
    reloadTaskTrigger?: any
}

const SpeedDial = ({ speedDials, defaultDials, currentContext, offsetX, offsetY, selectionId, reloadTaskTrigger } : props) => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;
    const { selectionPhase, selectedSelectionPhase, selectionTaskId, setSelectionTaskId } = useOutletContext<any>();
    const [helpDeskOpen, setHelpDeskOpen] = useState(false);
    const [taskOpen, setTaskOpen] = useState(false);
    const [taskCollapsed, setTaskCollapsed] = useState(false);

    const [currentSelectionPhase, setCurrentSelectionPhase] = useState("");
    const [selectionTask, setSelectionTask] = useState<any | null>();
    const [selectionTaskDetails, setSelectionTaskDetails] = useState<any | null>();

    useEffect(() => { if (helpDeskOpen) setTaskOpen(false); },[helpDeskOpen])
    useEffect(() => { if (taskOpen) setHelpDeskOpen(false); },[taskOpen])

    const handleFetchSelectionTaskDetails = async () => {
        if (typeof selectionId === "undefined") return;
        if (currentContext != selectionPhase) return;

        const selectionPhaseTaskDetails = await getSelectionPhaseTaskDetails(selectionTaskId);
        setSelectionTask(selectionPhaseTaskDetails.data.data.selectionPhaseTask)
        setSelectionTaskDetails(selectionPhaseTaskDetails.data.data);
    }

    // console.log(currentContext, selectionPhase, selectionId)

    const handleUpdateSelectionPhase = async () => {
        const response = await updateSelectionPhaseTaskDetails(selectionTaskId);
        setSelectionTaskId(response.data.newSelectionSelectionPhaseTaskSaved.id);
        // setSelectionTask(response.data.nextSelectionPhaseTask);
        // setCurrentSelectionPhase(response.data.nextSelectionPhaseTask.selectionPhase);

        // const selectionPhaseTaskDetails = await getSelectionPhaseTaskDetails(response.data.newSelectionSelectionPhaseTaskSaved.id);
        // setSelectionTaskDetails(selectionPhaseTaskDetails.data.data);
        setTaskOpen(false);
    }

    useEffect(() => {handleFetchSelectionTaskDetails()},[])
    useEffect(() => {handleFetchSelectionTaskDetails()},[selectionTaskId])
    useEffect(() => {handleFetchSelectionTaskDetails()},[reloadTaskTrigger])

    return (
    <div className={`absolute ${typeof offsetY !== "undefined" ? offsetY : "bottom-5"} ${typeof offsetX !== "undefined" ? offsetX : "right-5"} z-[1000] flex items-center`}>
        {       
            typeof selectionId !== "undefined" && currentContext == selectionPhase && selectionTaskId != "" ? (
                <div onClick={() => {if (!taskCollapsed) {setTaskOpen(prev => !prev);}}}
                className={`absolute max-w-[50vw] ${taskOpen ? "rounded-l-lg" : "h-24 rounded-l-full"} right-12 bottom-0 flex items-center px-5 py-5 pr-12 bg-primary2 border rounded-r-lg border-primary shadow-lg cursor-pointer`}>

                    {
                        taskCollapsed ? <></> :
                        <div className='flex flex-col'>

                            <h3 className="text-base font-normal text-gray-500">
                                {t('speedDial.nextSelectionTask')}
                            </h3>
                            
                            { typeof selectionTask === "undefined" ? <div className='w-64'><LoadingSpinner /></div> : (
                                <div> 
                                {
                                    selectionTask == null ? (
                                    <>
                                        <div className="flex mt-2 space-x-4 justify-between items-center w-full text-md text-gray-900">
                                            <p>{t('speedDial.noNewTask')}</p>
                                        </div>
                                    </>
                                    ) : (
                                    <>
                                        <span className="text-md font-bold text-gray-900 truncate sm:text-xl">
                                            {selectionTask[`title_${currentLanguage}`]}
                                        </span>

                                        {
                                            taskOpen ? (
                                                <div>
                                                    <p className='mt-2 text-md text-gray-900 text-wrap'>{selectionTask[`description_${currentLanguage}`]}</p>
                                                    {
                                                        selectionTaskDetails != null ? (
                                                            <>
                                                                {
                                                                    (selectionTaskDetails.completed) ? (
                                                                        <div>
                                                                            <p className='my-2 text-md font-semibold text-gray-900 text-wrap'>{t('speedDial.taskComplete', { defaultValue: "The task has been successfully fulfilled and can be completed." })}</p>
                                                                            <div className='flex' onClick={(e) => {e.stopPropagation(); handleUpdateSelectionPhase()}}><MainButton text={t('speedDial.completeTask')} /></div>
                                                                        </div>
                                                                    ) : (typeof selectionTaskDetails.metric !== "undefined") ? (
                                                                        <p className='mt-2 text-md font-semibold text-gray-900 text-wrap'>{selectionTaskDetails.metric} {t(`speedDial.incomplete.${selectionTaskDetails.uniqueTaskCode}`, { defaultValue: SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS[selectionTaskDetails.uniqueTaskCode as keyof typeof SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS] })}</p>
                                                                    ) : <></>
                                                                }
                                                            </>
                                                        ) : (<></>)
                                                    }
                                                </div>
                                            ) : (<></>)
                                        }
                                        
                                    </>
                                    )
                                }
                                </div>
                            )}
                        </div> 
                    }

                    {
                        taskOpen ? <></> : 
                        <div onClick={(e) => {e.stopPropagation(); setTaskOpen(false); setTaskCollapsed(prev => !prev);}}
                        className={`${taskCollapsed ? "rotate-180 -translate-x-2" : ""} text-gray-300 ml-1 transition-all`}>
                            <ArrowRightIcon iconColor='currentColor' iconHeight='32' iconWidth='32' />
                        </div> 
                    }

                </div>
            ) : (<></>)
        }

        <div draggable={true} className={`flex flex-col z-[3] items-center justify-center group/maindial transitions-all duration-500`}>

            <div className=''>
                <div className={`p-2 space-y-2 hidden ${!helpDeskOpen ? "group-hover/maindial:block" : ""}`}>
                    {
                        speedDials.map((item:any, index:number) => {
                            return (
                                <div className='group/subdials flex items-center'>
                                    <div 
                                    onClick={item.onClick}
                                    className='rounded-full shadow-lg flex items-center justify-center bg-gray-50 border-2 h-16 w-16 hover:scale-[1.05] transitions-all duration-500 cursor-pointer'>
                                        { item.icon || <div className='text-gray-300 w-8 h-8 rotate-45'><CrossIcon iconColor='currentColor' /></div> }
                                    </div>
                                    <Tooltip text={item.tooltip} hoverGroup={"subdials"} width={"w-56"} height={"min-h-12"} xTranslate={"-translate-x-60"} />
                                </div>
                            )
                        })
                    }
                </div>

                {
                    defaultDials ? (
                        <>
                        <div onClick={() => setHelpDeskOpen(true)}
                        className={`p-2 ${speedDials.length > 0 ? "border-t-4" : ""} hidden ${!helpDeskOpen ? "group-hover/maindial:block" : ""}`}>
                            <div className='group/subdials flex items-center justify-center'>
                                <div 
                                className='flex items-center justify-center z-[2] rounded-full shadow-lg flex items-center justify-center bg-gray-50 border-2 border-primary h-16 w-16 hover:scale-[1.05] transitions-all duration-500 cursor-pointer'>
                                    <div className='rounded-full text-primary text-5xl w-8 h-8'>
                                        <RocketIcon iconColor='currentColor' />
                                    </div>
                                </div>
                                <div className='absolute z-[0] animate-ping h-10 w-10 bg-primary rounded-full'></div>
                                
                                <Tooltip text={t('speedDial.superchargeTooltip')} hoverGroup={"subdials"} width={"w-56"} height={"min-h-12"} xTranslate={"-translate-x-40"} />
                            </div>
                        </div>
                        </>
                    ) : (<></>)
                }

            </div>
            
            <div 
            onClick={() => {if (helpDeskOpen) {setHelpDeskOpen(false)}}}
            className='rounded-full z-[2] shadow-lg flex items-center justify-center bg-primary h-24 w-24 hover:scale-[1.05] transitions-all duration-500 cursor-pointer'>
                <div className={`text-white w-16 h-16 ${!helpDeskOpen ? "rotate-45" :""} transitions-all duration-500`}>
                    <CrossIcon iconColor='currentColor' />
                </div>
            </div>

            { (helpDeskOpen) ? (<HelpDeskWindow currentContext={currentContext} setHelpDeskOpen={setHelpDeskOpen} />) : (<></>) }

        </div>
    </div>
  )
}

export default SpeedDial