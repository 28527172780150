import React, { useEffect, useState } from 'react'
import { getPlatformProduct, updatePlatformProduct } from '../../../../../../../data/PlatformRequests';
import { SelectorDropdown, TextInput } from 'swap-frontend-library';
import MainButton from '../../../../../../../components/MainButton';
import { t } from 'i18next';

const PlatformProductForm = ({openProductId, handleFetchProducts} : {openProductId?: string, handleFetchProducts:() => {}}) => {

    const [productId, setProductId] = useState<string | null>(null);
    const [description, setDescription] = useState<string | null>(null);
    const [features, setFeatures] = useState<string | null>(null);
    const [productCode, setProductCode] = useState<string | null>(null);
    const [type, setType] = useState<string | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [linkToAdditionalProductInfo, setLinkToAdditionalProductInfo] = useState<string | null>(null);
    const [price, setPrice] = useState<number>();
    const [discount, setDiscount] = useState<number | null>(null);
    const [hightlight, setHighlight] = useState<boolean | null>(null);

    const [success, setSuccess] = useState(false);

    const setProductValues = (product : any) => {
        setProductId(product.id);
        setDescription(product.description);
        setFeatures(product.features);
        setProductCode(product.productCode);
        setType(product.type);
        setName(product.name);
        setPrice(product.price);
        setDiscount(product.discountPercent);
        setHighlight(product.highlight);
        setLinkToAdditionalProductInfo(product.linkToAdditionalProductInfo);
    }

    const handleFetchProduct = async () => {
        if (typeof openProductId != "undefined") {
            const response = await getPlatformProduct(openProductId);
            console.log(response)
            setProductValues(response);
        } else {
            setProductValues({
                id: null,
                description: null,
                features: null,
                productCode: null,
                type: null,
                name: null,
                price: null,
                linkToAdditionalProductInfo: null,
                highlight: null,
                discount: 0
            });
        }
    }

    useEffect(() => {
    handleFetchProduct();
    }, [])

    const handleUpdateProduct = async () => {
        console.log(productCode, name, description, features, type, price);
        if (productCode == null || name == null || name == "" || type == null || type == "" || price == null) {
            console.log("No product values set");
            return;
        }

        console.log(hightlight)
        const success = await updatePlatformProduct(productId, productCode, name, description, features, type, price, discount, hightlight == null ? false : hightlight, linkToAdditionalProductInfo);
        setSuccess(success);
        if (success) {
            handleFetchProducts();
        }
    }
    
      
  return (
    <div className='max-h-[75vh] overflow-y-auto  px-4'>
        <h3 className="mb-4 text-xl font-medium text-gray-900 ">{typeof openProductId == "undefined" ? "Add" : "Update"} platform product</h3>
        {(success) ? (
            <div className={``}>
                Product updated successfully
            </div>
        ) : (
            <>
            <div className='space-y-4'>
                <TextInput label="Product code (Caution: Relevant for purchasing logic)" textValue={productCode} setTextValue={setProductCode} />
                <TextInput label="Name" textValue={name} setTextValue={setName} />

                <SelectorDropdown label='Type' items={[
                    {value: "matching", label: "Matching result"},
                    {value: "portal", label: "Selection portal plan"},
                    {value: "addon", label: "Add-on product"},
                ]} itemValueKey={'value'} itemLabelKey={'label'} selectedItem={type} setSelectedItem={setType} />
                
                <TextInput label="Description" textValue={description} setTextValue={setDescription} rows={6} />
                <TextInput label="Features (use prefix ** for highlight, e.g., **Feature, use --url for link to more information)" textValue={features} setTextValue={setFeatures} rows={6} />
                
                <div>
                    <label className="block text-sm font-medium text-gray-900 ">Price</label>
                    <input placeholder={`Price`} value={price} onChange={(event) => {setPrice(Number(event.target.value))}} type="number" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required></input>
                </div>
                
                <div>
                    <label className="block text-sm font-medium text-gray-900">Discount (percent)</label>
                    <input placeholder={`Discount (percent)`} value={discount == null ? 0 : discount} onChange={(event) => {setDiscount(Number(event.target.value))}} type="number" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required></input>
                </div>
                
                <div className='flex h-8 items-center gap-2'>
                    <input checked={hightlight == null ? false : hightlight} onChange={(event:any) => {setHighlight(prev => !prev)}} type="checkbox" id="highlight" required></input>
                    <label htmlFor="highlight" className="w-full block text-sm font-medium text-gray-900">Highlight product</label>
                </div>
                
                <TextInput label="Link to additional product information" textValue={linkToAdditionalProductInfo} setTextValue={setLinkToAdditionalProductInfo} />
            </div>

            <div className='mt-4'>
                <MainButton text={'Save'} func={() => handleUpdateProduct()} />
            </div>
            </>
        )}
    </div>
)
}

export default PlatformProductForm