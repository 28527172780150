import React, {useEffect, useState} from 'react';
import { CheckmarkIcon, CheckoutFilledIcon, CrossIcon, getImage, LockIcon, OpenIcon, PercentageCircle } from 'swap-frontend-library';

interface props {
    idx: number,
    id: string,
    productName: string,
    productDescription: string,
    productDetails?: any,
    productUrl?: string,
    references: any[],
    referenceAvg: number,
    softwareFulfillmentNote: string,
    companyName: string,
    score: number,
    customerSearchSoftwareProductId?: string
    numberOfReferences: number,
    topFeatures: Array<string>,
    topFeaturesScores: Array<any>,
}

const ResultRow = ({idx, id, productName, productDescription, productUrl, productDetails, references, referenceAvg, softwareFulfillmentNote, companyName, score, customerSearchSoftwareProductId, numberOfReferences, topFeatures, topFeaturesScores} : props) => {
    const [imageSource, setImageSource] = useState("");
    const [open, setOpen] = useState(false);
    // const [avgReferenceScore, setAvgReferenceScore] = useState(
    //     references?.map((reference:any, index:number) => {
    //         return parseFloat(reference.score);
    //     }).reduce((a:number, b:number) => a+b, 0) / (references?.length == 1 ? 1 : references?.length) * 100
    // );
    // const [avgReferenceScore, setAvgReferenceScore] = useState(referenceAvg);

    const handleFetchImage = async () => {
        getImage(id, "/api/product/get-logo?id=", '').then(frontendImageLocation => {
            setImageSource(frontendImageLocation);
        });
    }

    useEffect(() => {
        handleFetchImage();
    }, [id])

    // // <a key={idx} href={(typeof id === "undefined") ? ("javascript:void(0)") : (`${`${process.env.NEXT_PUBLIC_LANDING_PAGE}/product/${id}?csspId=${(typeof customerSearchSoftwareProductId !== "undefined") ? (`${customerSearchSoftwareProductId}`) : ("")}`}`)} target={(typeof id === "undefined") ? ("") : ("_blank")} rel="noopener noreferrer" >
    // {/* <a href={(id=="") ? ("#") : (`${`${process.env.NEXT_PUBLIC_LANDING_PAGE}/product/${id}`}`)} target={(id=="") ? ("") : ("_blank")} rel="noopener noreferrer" > */}
    return (
    <div onClick={() => {setOpen(prev => !prev)}} className='cursor-pointer border shadow bg-white rounded-lg transition-all'>
        <div className={`${(id == "") ? (``) : (`cursor-pointer`)} ${open ? "border-b" : ""} flex items-center gap-9.5 gap-y-6 pr-4 max-md:flex-wrap max-sm:gap-x-5`}>

            <div className='relative w-full grid grid-cols-12 gap-4 text-sm font-light'>
                {/* <div className='absolute -left-16 h-full flex items-center'>
                    <div className={`${idx==0 ? "text-4xl text-amber-400" : idx==1 ? "text-2xl text-gray-400" : idx == 2 ? "text-xl text-amber-600" : "text-primary/70" } h-8 w-8 flex items-center justify-center font-bold text-right`}>{idx+1}</div>
                </div> */}
                <div className='max-md:col-span-full col-span-5 grid grid-cols-8 gap-1 md:border-r'>
                    <div className='relative col-span-4 flex items-center justify-start gap-2'>
                    {
                        (typeof id !== "undefined") ? (
                            <>
                                {
                                    <div className="w-[100px] min-w-[100px] h-[70px] min-h-[70px] rounded-l-[80px] min-h-20 flex items-center justify-center p-2 px-5">
                                        {
                                            (imageSource != "") ? 
                                            (<img src={imageSource} />) : <></>
                                        }
                                    </div>
                                }
                                <div className="overflow-x-hidden ml-2">
                                    <h6 className="text-base leading-5 text-secondary1 font-semibold truncate">{productName}</h6>
                                    <p className="font-normal leading-4 text-sm text-secondary1 mt-2 truncate">{companyName}</p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="w-[100px] min-w-[100px] h-[70px] min-h-[70px] rounded-l-[80px] min-h-20 flex items-center justify-center p-2 px-5 bg-primary/30">
                                </div>
                                
                                <div className="overflow-x-hidden ml-2">
                                    <h6 className="text-base leading-5 text-secondary1 font-semibold truncate">Hidden software name</h6>
                                    <p className="font-normal leading-4 text-sm text-secondary1 mt-2 truncate">Hidden company name</p>
                                </div>
                                
                                <div className='absolute w-full h-full flex items-center justify-center group'>
                                    <div className='absolute bg-white/30 backdrop-blur-lg blur-sm w-full h-full rounded-[80px]'></div>
                                    <div className='absolute flex items-center justify-center transition-all gap-4'>
                                        <div className='w-10 h-10 text-primary3 group-hover:-translate-x-5 transition-all'><LockIcon /></div>
                                        <div className='group-hover:inline-block hidden -translate-x-5 font-semibold text-primary3'>Unlock top-5 systems</div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    </div>

                    <div className={`${(id == "") ? (`blur-md`) : (``)} col-span-2 p-1 gap-4 flex items-center justify-center`}>
                        <PercentageCircle rating={Math.round(score)} />
                    </div>

                    <div className='col-span-2 flex items-center justify-center font-semibold py-2 px-3'>
                        {
                            numberOfReferences > 0 ?
                            <div title={(referenceAvg*0.7 + numberOfReferences*0.3)?.toFixed(2)} className='relative group w-fit h-full rounded-full bg-primary flex items-center justify-between gap-1 px-3 transition-all'>
                                <div className='group-hover:hidden h-8 w-8 bg-white text-primary rounded-full p-1'><CheckmarkIcon /></div>
                                <p className='w-8 h-7 text-lg text-white text-center'>{numberOfReferences}</p>
                                <p className='hidden group-hover:inline right-0 border-l pl-1 w-16 h-7 text-lg text-white text-center'>{referenceAvg?.toFixed(0)} %</p>
                            </div> : <></>
                        }
                    </div>
                </div>
                <div className='max-md:hidden col-span-7 grid grid-cols-5 gap-1 py-3'>
                    {
                        topFeatures.slice(0,5).map((topFeature:any, index:number) => {
                            const usable_score = topFeaturesScores[topFeature] != 0 ? 
                                topFeaturesScores[topFeature] :
                                numberOfReferences > 0 ?
                                (score-(score*(1/numberOfReferences/2))) : // If the feature is not present but has references, use reference score
                                0;
                            const feature_score = Math.min(100, (usable_score) / 100) * 100;

                            // console.log(score, score*(1/numberOfReferences), usable_score, feature_score)

                            return (
                                <div className='flex items-center justify-center'>
                                    <div className={`w-8 h-8 text-white rounded-full p-1`} style={{ backgroundColor: `hsl(${feature_score}, 70%, 60%)` }}>
                                        {/* {Math.round(feature_score)} */}
                                    </div>
                                </div>
                                // <div className='flex items-center justify-center'><div className='w-8 h-8 text-white'>{topFeaturesScores[topFeature] > 60 ? <div className='bg-lime-600 rounded-full p-1'><CheckmarkIcon /></div> : <div className='bg-red-600 rounded-full p-1 '><CrossIcon /></div>}</div></div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
        
        {
            open ? 
            <div className='p-4 flex flex-col gap-8'>
                <div className="overflow-x-hidden">
                    <h6 className="text-2xl font-semibold truncate">{productName}</h6>
                    <p className="font-semibold leading-4 text-secondary1 mt-2 truncate">{companyName}</p>
                    <div className='flex items-center w-full gap-1'>
                        <a className='truncate underline underline-offset-2 hover:underline-offset-4 transition-all cursor-pointer' href={productUrl} target='_blank' onClick={(e) => e.stopPropagation()}>{productUrl}</a>
                        <div className='w-4 h-4'><OpenIcon /></div>
                    </div>
                </div>
                <p className='font-normal leading-4 text-secondary1 '>{productDescription}</p>

                {
                    typeof productDetails !== "undefined" ? 
                    <div className='mt-2 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-4 overflow-y-hidden'>
                        {
                            typeof productDetails.markets !== "undefined" && productDetails.markets.length != 0 ? 
                            <div>
                                <p>Adressed markets</p>
                                <div className='flex flex-wrap gap-2'>
                                    {
                                        productDetails.markets.map((market:string, index:number) => {
                                            return (
                                                <div key={index} className='border rounded p-1'>{market}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div> : <></>
                        }
                        {
                            typeof productDetails.subIndustries !== "undefined" && productDetails.subIndustries.length != 0 ? 
                            <div>
                                <p>Adressed industries</p>
                                <div className='flex flex-wrap gap-1'>
                                    {
                                        productDetails.subIndustries.map((subIndustry:string, index:number) => {
                                            return (
                                                <div key={index} className='border rounded p-1'>{subIndustry}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div> : <></>
                        }
                        {
                            typeof productDetails.customerSize !== "undefined" ? 
                            <div>
                                <p>Adressed company sizes</p>
                                <div className='flex flex-wrap gap-1'>
                                    {
                                        Object.keys(productDetails.customerSize).map((companySize:string, index:number) => {
                                            if (productDetails.customerSize[companySize] > 0)
                                            return (
                                                <div key={index} className='border rounded p-1'>{companySize}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div> : <></>
                        }
                    </div>
                    : <></>
                }

                <div className='relative border-4 border-primary bg-blue-100/20 rounded-lg p-3'>
                    <div 
                    className='cursor-pointer absolute right-5 -top-3 rounded-full border-blue-700 text-blue-700 hover:to-blue-700 hover:from-[#57e3ff] bg-gradient-to-br from-blue-400 to-[#57e3ff] border-2 shadow shadow-primary/50 font-semibold px-2 transition-all'>Expert insights</div>
                    <div>
                        <p>Similar users</p>
                        <div className='mt-2 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-4 overflow-y-hidden'>
                            {
                                typeof references === "undefined" ? <></> :
                                references.map((reference:any, index:number) => {
                                    return (
                                        <div key={index} className='border rounded p-3 grid grid-cols-12 items-center gap-4'>
                                            <div className='col-span-3'>
                                                <PercentageCircle rating={Math.round(reference.score*100)} />
                                            </div>
                                            <div className='col-span-9'>
                                                <p className='text-lg font-semibold truncate w-full'>{reference.referenceName}</p>
                                                <div className='flex items-center w-full gap-1'>
                                                    <a className='truncate underline underline-offset-2 hover:underline-offset-4 transition-all cursor-pointer' href={reference.referenceUrl} target='_blank'>{reference.referenceUrl}</a>
                                                    <div className='w-4 h-4'><OpenIcon /></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='mt-4'>
                        <p>Important features</p>
                        <div className='mt-2 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-4 overflow-y-hidden'>
                            {
                                topFeatures.map((topFeature:any, index:number) => {
                                    const usable_score = topFeaturesScores[topFeature] != 0 ? 
                                        topFeaturesScores[topFeature] :
                                        numberOfReferences > 0 ?
                                        (score-(score*(1/numberOfReferences/2))) : // If the feature is not present but has references, use reference score
                                        0;
                                    const feature_score = Math.min(100, (usable_score) / 100) * 100;

                                    // console.log(score, score*(1/numberOfReferences), usable_score, feature_score)

                                    return (
                                        <div key={index} className='h-16 border rounded p-3 flex items-center gap-4'>
                                            <div className={`w-8 h-8 text-white rounded-full p-1`} style={{ backgroundColor: `hsl(${feature_score}, 70%, 60%)` }}>
                                                {/* {Math.round(feature_score)} */}
                                            </div>
                                            <p className='font-normal leading-4 text-secondary1 '>{topFeature}</p>
                                        </div>
                                        // <div className='flex items-center justify-center'><div className='w-8 h-8 text-white'>{topFeaturesScores[topFeature] > 60 ? <div className='bg-lime-600 rounded-full p-1'><CheckmarkIcon /></div> : <div className='bg-red-600 rounded-full p-1 '><CrossIcon /></div>}</div></div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div> :
            <></>
        }
    </div>
    // </a>
  )
}

export default ResultRow