import React from 'react';
import { Link } from 'react-router-dom';
import MainButton from '../../../../../../components/MainButton';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';

const OnboardingLanding = () => {
  const {t} = useTranslation();
  return (
    <div>
      <h1 className="text-5xl font-semibold text-secondary1 mt-2 leading-58 max-xl:text-[44px] max-xl:leading-[48px] max-sm:text-40">{t("welcome")}</h1>
      
      <p className="text-secondary2 font-normal my-6 max-xl:max-w-[480px] max-lg:max-w-lg">
        {t('aboutUsDescription')}
      </p>
      <p className="text-secondary2 font-normal my-6 max-xl:max-w-[480px] max-lg:max-w-lg">
        {t('companyAim')}
      </p>

      <div className="flex justify-between mt-10">
        <MainButton text='Start' text_location='left' icon={<ArrowRightIcon iconColor='white' />} icon_color='' link="create-account" />
      </div>
    </div>
  )
}

export default OnboardingLanding