import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRequirementComparison, getSelectionRanking, getSelectionRequirement, getSelectionRequirements } from '../../../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import { ArrowDownIcon, CheckmarkIcon, CrossIcon, getImage, LoadingSpinner, MessageIcon, QuestionmarkIcon } from 'swap-frontend-library';
import GearIcon from '../../../../../../../assets/img/icons/GearIcon';
import PercentageCircle from '../../../../../../../components/PercentageCircle';

const ComparisonRequirements = () => {
    const { t } = useTranslation();
    const {selectionId} = useOutletContext<any>();
    const [error, setError] = useState("");
    const [selectionRequirements, setSelectionRequirements] = useState([]);
    const [vendorRanking, setVendorRanking] = useState<any[]>();
    const [aggregateScores, setAggregateScores] = useState<any[]>();
    const [categoriesWithScores, setCategoriesWithScores] = useState<any>();
    
    const handleFetchRanking = async () => {
        const response = await getSelectionRanking(selectionId);
        if (response.ok) {
            setVendorRanking(response.data.data.results.filter((item:any) => item.included));
        }
    }
    
    const handleFetchComparison = async () => {
        const response = await getRequirementComparison(selectionId);
        if (response.ok) {
            setSelectionRequirements(response.data.data.selectionRequirementWithSoftwareProductScoresParsedCategories);
            setAggregateScores(response.data.data.aggregateScores);
            setCategoriesWithScores(response.data.data.uniqueCategoriesTreeWithScores);
        } else {
            setError(response.data.errorCode);
        }
    }

    useEffect(() => {handleFetchRanking(); handleFetchComparison()}, [])

    if (error || vendorRanking?.length == 0) {
        return (
            <div className='m-4 bg-white shadow rounded-lg p-8 space-y-8 text-gray-700'>
                <p className='text-xl font-semibold'>{t('comparisonRequirements.error.title')}</p>
                <p>{t('comparisonRequirements.error.description')}</p>
            </div>
        )
    }
    if (typeof selectionRequirements === "undefined" || typeof aggregateScores === "undefined" || typeof categoriesWithScores === "undefined") return <LoadingSpinner />
    return (
        <div className='relative h-full w-full flex'>
            <table className='border-separate border-spacing-0 h-fit table-fixed border-collapse overflow-y-auto overflow-x-auto pr-8 pb-8'>
                <thead className='sticky top-0 z-[3] bg-white/30 backdrop-blur shadow'>
                    <tr>
                        <td className='sticky left-0 z-[2] bg-gray-50/50 backdrop-blur'></td>
                        {
                            vendorRanking?.map((vendorData:any) => {
                                return (
                                    <VendorColumn vendorData={vendorData} aggregateScores={aggregateScores} />
                                )
                            })
                        }
                    </tr>
                </thead>

                <tbody>
                    {
                        Object.keys(categoriesWithScores).map((category_0: any, index: number) => {
                            const subCategoriesWithScores = categoriesWithScores[category_0].subCategories;
                            const scores = categoriesWithScores[category_0].scores;
                            return (
                                <>
                                <CategoryRow category_0={category_0} vendorRanking={vendorRanking} categoriesWithScores={categoriesWithScores}>
                                    <>
                                    {
                                        Object.keys(subCategoriesWithScores).map((category_1: any, index: number) => {

                                            const lastCategory = index+1 == Object.keys(subCategoriesWithScores).length;

                                            const requirements = selectionRequirements.filter((selectionRequirement : any) => (selectionRequirement.category[0] == category_0 && selectionRequirement.category[1] == category_1))
                                            return (
                                                <>
                                                    <SubCategoryRow category_0={category_0} category_1={category_1} vendorRanking={vendorRanking} categoriesWithScores={categoriesWithScores} isLast={lastCategory}>

                                                        {
                                                            requirements.map((selectionRequirement: any, index: number) => {
                                                                const lastRequirement = index+1 == requirements.length;
                                                                return (
                                                                    <RequirementComparisonRow selectionRequirement={selectionRequirement} vendorRanking={vendorRanking} isLast={lastCategory && lastRequirement} />
                                                                );
                                                            })
                                                        }
                                                    </SubCategoryRow>

                                                </>
                                            )
                                        })
                                    }
                                    </>
                                </CategoryRow>
                                </>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
  )
}

const VendorColumn = ({vendorData, aggregateScores} : any) => {
    const { t } = useTranslation();
    const [imageSource, setImageSource] = useState("")
    const handleFetchImage = async () => {
        getImage(vendorData.softwareProduct.id, "/api/product/get-logo?id=", '').then(frontendImageLocation => {setImageSource(frontendImageLocation);});
    }
    useEffect(() => {handleFetchImage()},[])
    return (
        <td className=''>
            <div className='w-40 h-56 p-1'>
                <div className='bg-white rounded-lg shadow border h-full w-full space-y-2 flex flex-col items-center justify-end p-2'>
                    {
                        (imageSource == "") ? (
                            <></>
                        ) : (
                            <img className="h-8 w-full object-contain p-1" src={imageSource} />
                        )
                    }
                    <div className='text-center'>
                        <p className='font-bold line-clamp-2'>{vendorData.softwareProduct.productName}</p>
                        <p className='font-light text-sm line-clamp-2'>{vendorData.softwareProduct.vendorDetails.company}</p>
                    </div>

                    <div className=''>
                        <PercentageCircle rating={aggregateScores[vendorData.id]} />
                    </div>
                </div>
            </div>
        </td>
    )
}

const CategoryRow = ({children, category_0, vendorRanking, categoriesWithScores} : {children:any, category_0:string, vendorRanking:any[] | undefined, categoriesWithScores:any}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    return (
        <>
            <tr onClick={() => setOpen(prev => !prev)} className='cursor-pointer'>
                <td className='sticky left-0 z-[2] bg-gray-50'>
                    <div className={`bg-white mt-4 ml-4 h-20 sm:w-[400px] md:w-[600px] py-2 pl-2 pr-4 border-l border-t rounded-tl-lg ${open ? "" : "border-b rounded-bl-lg"} text-xl font-semibold flex items-center gap-2`}>
                        <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div>
                        <p>{category_0}</p>
                    </div>
                </td>

                {
                    vendorRanking?.map((currentVendorRanking:any, index:number) => {
                        return (
                            <td key={index} className=''>
                                <div className={`bg-white mt-4 h-20 w-40 py-2 border-t ${open ? "" : "border-b"} ${index+1 == vendorRanking.length ? `border-r ${open ? "rounded-tr-lg" : "rounded-r-lg"}` : ""} text-xl font-semibold flex items-center justify-center`}>
                                    <PercentageCircle rating={categoriesWithScores[category_0].scores[currentVendorRanking.id]} />
                                </div>
                            </td>
                        )
                    })
                }
            </tr>
            {
                open ? 
                children :
                <></>
            }
        </>
    )

}

const SubCategoryRow = ({children, category_0, category_1, vendorRanking, categoriesWithScores, isLast} : {children:any, category_0:string, category_1:string, vendorRanking:any[] | undefined, categoriesWithScores:any, isLast: boolean}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    return (
        <>
            <tr onClick={() => setOpen(prev => !prev)} className='cursor-pointer'>
                <td className='sticky left-0 z-[2] bg-gray-50'>
                    <div className={`bg-white sm:w-[400px] md:w-[600px] ml-4 py-2 pl-2 pr-4 h-16 border-l text-md font-semibold flex items-center gap-2 ${isLast && !open ? `border-b ${open ? "" : "rounded-bl-lg"}` : ""} `}>
                        <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div>
                        <p>{category_1}</p>
                    </div>
                </td>

                {
                    vendorRanking?.map((currentVendorRanking:any, index: number) => {
                        return (
                            <td key={index}>
                                <div className={`bg-white py-2 h-16 w-40 text-md font-semibold flex items-center justify-center ${isLast && !open ? `border-b` : ""} ${index+1 == vendorRanking.length ? `border-r ${isLast && !open ? "rounded-br-lg" : ""}` : ""}`}>
                                    <div className='scale-[0.8] opacity-70'>
                                        <PercentageCircle rating={categoriesWithScores[category_0].subCategories[category_1].scores[currentVendorRanking.id]} />
                                    </div>
                                </div>
                            </td>
                        )
                    })
                }
            </tr>
            {
                open ? 
                children :
                <></>
            }
        </>
    )

}

const RequirementComparisonRow = ({selectionRequirement, vendorRanking, isLast} : {selectionRequirement:any, vendorRanking:any, isLast:boolean}) => {
    const { t } = useTranslation();
    const [softwareFulfillments, setSoftwareFulfillments] = useState<any[]>();

    const reformatFulfillments = async () => {
        setSoftwareFulfillments(selectionRequirement.selectionSoftwareProductRequirementFulfillments.reduce((r:any, a:any) => {
            const selectionSoftwareProductId = a.selectionSoftwareProduct.id;
            r[selectionSoftwareProductId] = r[selectionSoftwareProductId] || [];
            r[selectionSoftwareProductId] = {comment:a.comment, score:a.score};
            return r;
        }, Object.create(null)));
    }

    useEffect(() => {reformatFulfillments()},[])

    if (typeof softwareFulfillments == "undefined") return <LoadingSpinner />
    const importance = selectionRequirement.importance;
    return (
        <tr className=''>
            <td className='sticky left-0 z-[2] bg-gray-50 h-24'>
                <div className={`h-full sm:w-[400px] md:w-[600px] flex items-center justify-between gap-4 bg-white px-8 ml-4 border-l ${isLast ? "rounded-bl-lg border-b" : ""}`}>
                    <p className='w-full text-wrap line-clamp-2'>{selectionRequirement.requirement}</p>
                    <div className='flex space-x-1 items-center group'>
                        <div
                        className={`w-6 h-6 ${(33 <= Math.ceil(importance)) ? ("bg-primary text-white"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full flex items-center justify-center font-bold`}>C</div>
                        <div
                        className={`w-6 h-6 ${(66 <= Math.ceil(importance)) ? ("bg-primary text-white"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full flex items-center justify-center font-bold`}>B</div>
                        <div
                        className={`w-6 h-6 ${(100 <= Math.ceil(importance)) ? ("bg-primary text-white"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full flex items-center justify-center font-bold`}>A</div>
                    </div>
                </div>
            </td>

            {
                vendorRanking?.map((currentVendorRanking:any, index:number) => {
                    const relevantFulfillment = softwareFulfillments[currentVendorRanking.id];
                    return (
                        <VendorFulfillmentCell relevantFulfillment={relevantFulfillment} isLastRow={isLast} isLastCell={index+1 == vendorRanking.length} /> 
                    )
                })
            }
        </tr>
    )
}

const VendorFulfillmentCell = ({relevantFulfillment, isLastRow, isLastCell} : {relevantFulfillment:any, isLastRow:boolean, isLastCell:boolean}) => {
    const { t } = useTranslation();
    const [commentVisible, setCommentVisible] = useState(false);
    const comment = typeof relevantFulfillment !== "undefined" && relevantFulfillment.comment ? relevantFulfillment.comment : "";
    // onClick={() => setCommentVisible(prev => !prev)}
    return (
        <td className={`${commentVisible ? "h-64" : "h-24 "} transition-all relative`}>
            <div className={`h-full bg-white flex items-center w-40 justify-center ${isLastRow ? "border-b" : ""} ${isLastCell ? "border-r" : ""} ${isLastRow && isLastCell? "rounded-br-lg" : ""}`}>
                {
                    typeof relevantFulfillment === "undefined" ? 
                    <div title={t('comparisonRequirements.notEvaluated')} className={`w-8 h-8 border rounded-full p-1 text-white bg-gray-400`}><QuestionmarkIcon /></div> :
                    relevantFulfillment.score == 3 ? 
                    <div title={t('comparisonRequirements.fullyFulfilled')} className={`w-8 h-8 border rounded-full p-1 text-white bg-lime-400`}><CheckmarkIcon /></div> :
                    relevantFulfillment.score == 1 ? 
                    <div title={t('comparisonRequirements.partiallyFulfilled')} className={`w-8 h-8 border rounded-full p-1 text-white bg-amber-300`}><GearIcon /></div>
                    : <div title={t('comparisonRequirements.notFulfilled')} className={`w-8 h-8 border rounded-full p-1 text-white bg-red-200`}><CrossIcon /></div>
                }
            </div>
            {
                comment ?
                <div className='absolute top-4 right-4 w-6 h-6 text-gray-400 cursor-pointer group' title={comment}>
                    <MessageIcon />
                    <div className="fixed z-[2] max-w-[300px] bg-white/50 backdrop-blur p-4 border rounded-lg shadow group-hover:inline hidden">
                        <p className='font-semibold'>{t('comparisonRequirements.vendorComment')}</p>
                        <p className='italic'>"{comment}"</p>
                    </div>
                </div> :
                <></>
            }
        </td>
    )
}

export default ComparisonRequirements