import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addSelectionScenario, createNotification, deleteSelectionScenario, updateSelectionScenario } from '../../../../../../../data/SelectionPortalRequests';
import FormModal from '../../../../../../../components/FormModal';
import TextInput from '../../../../../../../components/TextInput';
import LoadingSpinner from '../../../../../../../components/LoadingSpinner';
import MainButton from '../../../../../../../components/MainButton';
import BarsTwoIcon from '../../../../../../../assets/img/icons/BarsTwoIcon';
import ArrowDownIcon from '../../../../../../../assets/img/icons/ArrowDownIcon';
import TrashIcon from '../../../../../../../assets/img/icons/TrashIcon';

interface addScenarioModalProps {
    addScenarioModalOpen: boolean;
    setAddScenarioModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    scenarios: any[];
    selectionId: string;
    scenarioId?: string;
    handleFetchScenarios: () => void;
}

const ScenarioModal = ({ addScenarioModalOpen, setAddScenarioModalOpen, scenarios, selectionId, scenarioId, handleFetchScenarios }: addScenarioModalProps) => {
    const { t } = useTranslation();
    const [scenarioTitle, setScenarioTitle] = useState<string | null>('');
    const [scenarioDescription, setScenarioDescription] = useState<string | null>('');
    const [scenarioOrder, setScenarioOrder] = useState<number>();

    const [newScenarios, setNewScenarios] = useState<any[]>();
    const [orderNumberHovered, setOrderNumberHovered] = useState<number>();

    useEffect(() => {
        setNewScenarios(scenarios);

        if (typeof scenarioId !== 'undefined') {
            const scenarioToBeEdited = scenarios.filter(item => item.id == scenarioId)[0];
            if (typeof scenarioToBeEdited === 'undefined') {
                createNotification(t('scenarioModal.errorScenarioNotFound'), 'error');
                setAddScenarioModalOpen(false);
            }
            setScenarioTitle(scenarioToBeEdited.title);
            setScenarioDescription(scenarioToBeEdited.description);
            setScenarioOrder(scenarioToBeEdited.cleanOrderNumber);
        }
    }, []);

    useEffect(() => {
        handleAddNewScenario();
    }, [scenarioOrder]);

    const handleAddNewScenario = () => {
        if (typeof scenarioId !== 'undefined') return;
        if (scenarioTitle == '') return;

        let order = scenarios.length + 1;
        if (typeof scenarioOrder !== 'undefined') {
            order = scenarioOrder;
        } else {
            setScenarioOrder(order);
        }

        const newScenario = { title: scenarioTitle, cleanOrderNumber: -1 };
        const newScenariosTemp = [...scenarios.slice(0, order - 1), newScenario, ...scenarios.slice(order - 1)];
        setNewScenarios(newScenariosTemp);
    };

    const uploadScenario = async () => {
        if (scenarioTitle == '' || scenarioTitle == null) return;
        if (scenarioDescription == '' || scenarioDescription == null) return;

        if (typeof scenarioId !== 'undefined') {
            if ((await updateSelectionScenario(scenarioId, scenarioTitle, scenarioDescription)).ok) {
                setAddScenarioModalOpen(false);
                handleFetchScenarios();
            }
        } else {
            if (typeof scenarioOrder === 'undefined') return;

            if ((await addSelectionScenario(scenarioTitle, scenarioDescription, scenarioOrder, selectionId)).ok) {
                setAddScenarioModalOpen(false);
                handleFetchScenarios();
            }
        }
    };

    const dragStart = () => {
        console.log('DragStart');
    };

    const enterDrop = (e: any, subsequentOrderNumber: number) => {
        e.preventDefault();
        setOrderNumberHovered(subsequentOrderNumber);
    };

    const leaveDrop = (e: any) => {
        e.preventDefault();
        setOrderNumberHovered(undefined);
    };

    const onDrop = (e: any, subsequentOrderNumber: number) => {
        e.preventDefault();
        setOrderNumberHovered(undefined);
        setScenarioOrder(subsequentOrderNumber);
    };

    const handleDeleteScenario = async () => {
        if (typeof scenarioId === 'undefined') return;
        if (await deleteSelectionScenario(scenarioId)) {
            handleFetchScenarios();
            setAddScenarioModalOpen(false);
        }
    };

    return (
        <FormModal isOpen={addScenarioModalOpen} setOpen={setAddScenarioModalOpen} size={''}>
            <>
                <h3 className="mb-4 text-xl font-medium text-gray-900">
                    {typeof scenarioId === 'undefined' ? t('scenarioModal.addScenario') : t('scenarioModal.editScenario')}
                </h3>
                <div className="mt-4 space-y-4">
                    <TextInput
                        textValue={scenarioTitle}
                        setTextValue={setScenarioTitle}
                        placeholder={t('scenarioModal.scenarioTitlePlaceholder')}
                        label={t('scenarioModal.scenarioTitle')}
                        onBlur={() => handleAddNewScenario()}
                    />
                    <TextInput
                        textValue={scenarioDescription}
                        setTextValue={setScenarioDescription}
                        placeholder={t('scenarioModal.scenarioDescriptionPlaceholder')}
                        label={t('scenarioModal.scenarioDescription')}
                        rows={4}
                    />

                    {typeof scenarioId !== 'undefined' ? (
                        <></>
                    ) : (
                        <div>
                            <p className="block text-sm font-medium text-gray-900">{t('scenarioModal.scenarioOrder')}</p>

                            <div className="h-64 overflow-y-auto border-t border-b px-4">
                                {typeof newScenarios !== 'undefined' ? (
                                    <>
                                        {newScenarios.map((item: any, index: number) => (
                                            <div>
                                                <div
                                                    className={`h-2 mb-1 border-b-4 border-dashed ${
                                                        orderNumberHovered == item.cleanOrderNumber && scenarioOrder != orderNumberHovered
                                                            ? ''
                                                            : 'border-white'
                                                    }`}
                                                ></div>
                                                <div
                                                    key={index}
                                                    draggable={item.cleanOrderNumber == -1 ? true : false}
                                                    onDragStart={(e) => dragStart()}
                                                    className={`${
                                                        item.cleanOrderNumber == -1
                                                            ? 'border-primary bg-primary text-white cursor-pointer'
                                                            : 'border-gray-300 bg-gray-100'
                                                    } relative text-lg font-medium rounded-lg p-4 h-10 flex items-center justify-between border `}
                                                >
                                                    {item.cleanOrderNumber == -1 ? (
                                                        <></>
                                                    ) : (
                                                        <div
                                                            onDrop={(e) => onDrop(e, item.cleanOrderNumber)}
                                                            onDragOver={(e) => enterDrop(e, item.cleanOrderNumber)}
                                                            onDragLeave={(e) => leaveDrop(e)}
                                                            className="left-0 -top-6 absolute w-full h-10"
                                                        ></div>
                                                    )}
                                                    <div className="flex space-x-2 truncate">
                                                        <p className="font-bold">{index + 1}.</p>
                                                        <p>{item.title}</p>
                                                        {item.cleanOrderNumber != index + 1 && item.cleanOrderNumber != -1 ? (
                                                            <p className="italic font-light">{t('scenarioModal.previousOrder', { order: item.cleanOrderNumber })}</p>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    {item.cleanOrderNumber == -1 ? (
                                                        <div className="flex items-center">
                                                            <div
                                                                onClick={() =>
                                                                    setScenarioOrder((prev: any) => {
                                                                        if (prev > 1) {
                                                                            return prev - 1;
                                                                        } else {
                                                                            return prev;
                                                                        }
                                                                    })
                                                                }
                                                                className="flex items-center w-8 h-8 rotate-180 text-white"
                                                            >
                                                                <ArrowDownIcon iconColor="currentColor" iconHeight="w-full" iconWidth="h-full" />
                                                            </div>
                                                            <div
                                                                onClick={() =>
                                                                    setScenarioOrder((prev: any) => {
                                                                        if (prev < scenarios.length + 1) {
                                                                            return prev + 1;
                                                                        } else {
                                                                            return prev;
                                                                        }
                                                                    })
                                                                }
                                                                className="flex items-center w-8 h-8 rotate text-white"
                                                            >
                                                                <ArrowDownIcon iconColor="currentColor" iconHeight="w-full" iconWidth="h-full" />
                                                            </div>
                                                            <div className="h-6 w-6">
                                                                <BarsTwoIcon />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                        {scenarioOrder == newScenarios.length ? (
                                            <></>
                                        ) : (
                                            <div
                                                onDrop={(e) => onDrop(e, newScenarios.length)}
                                                onDragOver={(e) => enterDrop(e, newScenarios.length)}
                                                onDragLeave={(e) => leaveDrop(e)}
                                                className={`-translate-y-7 w-full h-10 border-b-4 border-dashed ${
                                                    orderNumberHovered == newScenarios.length ? '' : 'border-white'
                                                }`}
                                            ></div>
                                        )}
                                    </>
                                ) : (
                                    <LoadingSpinner />
                                )}
                            </div>
                        </div>
                    )}

                    <div className="flex space-x-2">
                        <div className="grow">
                            <MainButton text={t('scenarioModal.save')} link={''} func={() => uploadScenario()} />
                        </div>
                        {typeof scenarioId !== 'undefined' ? (
                            <div>
                                <MainButton
                                    text={t('scenarioModal.delete')}
                                    link={''}
                                    icon={
                                        <div className="w-5 h-5">
                                            <TrashIcon />
                                        </div>
                                    }
                                    func={() => handleDeleteScenario()}
                                    style="light"
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </>
        </FormModal>
    );
};

export default ScenarioModal;