import React, { useState } from 'react'
import MainButton from '../../../../../../components/MainButton'
import SelectorDropdown from '../../../../../../components/SelectorDropdown';
import { exportSelection } from '../../../../../../data/SelectionPortalRequests';
import { useTranslation } from 'react-i18next';

const SelectionExport = ({selection} : {selection : any}) => {
    const { t } = useTranslation();
    const [selectedDataToExport, setSelectedDataToExport] = useState<string | null>(null);
    const [selectedFormat, setSelectedFormat] = useState<string>("csv");

    const handleFetchExportedData = async () => {
        if (!selectedDataToExport) return
        const response = await exportSelection(selection.id, selectedDataToExport, selectedFormat);
        if ("requirements" in response) {
            makeDownload(response, "requirements");
        }
        if ("scenarios" in response) {
            makeDownload(response, "scenarios");
        }
        if ("presentations" in response) {
            makeDownload(response, "presentations");
        }
        if ("results" in response) {
            makeDownload(response, "results");
        }
    }

    const makeDownload = (data:any, key:string) => {
        const blob = new Blob([selectedFormat === 'json' ? JSON.stringify(data[key]) : data[key]], { type: selectedFormat === 'json' ? 'application/json' : 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', key+'.'+selectedFormat);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
    }

    return (
        <div className="mt-4 shadow-md rounded-lg w-full bg-white p-5 flex flex-col gap-4">
            <div className="text-lg font-semibold text-left text-gray-900 bg-white">
                {t('selectionExport.title')}
            </div>
            <div className='flex flex-col gap-4'>
                <div className='flex gap-4 py-2'>
                    <SelectorDropdown 
                        items={[
                            {label: t('selectionExport.requirements'), value: 'requirements'}, 
                            {label: t('selectionExport.scenarios'), value: 'scenarios'}, 
                            {label: t('selectionExport.presentations'), value: 'presentations'}, 
                            {label: t('selectionExport.results'), value: 'results'}
                        ]} 
                        itemValueKey={'value'} 
                        itemLabelKey={'label'} 
                        selectedItem={selectedDataToExport} 
                        setSelectedItem={setSelectedDataToExport} 
                    />
                    <SelectorDropdown 
                        items={[
                            {label: t('selectionExport.json'), value: 'json'}, 
                            {label: t('selectionExport.csv'), value: 'csv'}
                        ]} 
                        itemValueKey={'value'} 
                        itemLabelKey={'label'} 
                        selectedItem={selectedFormat} 
                        setSelectedItem={setSelectedFormat} 
                    />
                </div>
                
                <div className='w-64'>
                    <MainButton text={t('selectionExport.exportButton')} func={() => handleFetchExportedData()} />
                </div>
                
            </div>
        </div>
  )
}

export default SelectionExport