import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCockpitDataGuest } from '../../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import { getImage, LoadingSpinner } from 'swap-frontend-library';

const SelectionGuestCockpit = () => {
    const { t } = useTranslation();
    const { selectionId, setSelectedSelectionPhase } = useOutletContext<any>();
    const [cockpitData, setCockpitData] = useState<any>();
    const [imageSource, setImageSource] = useState("");

    const handleFetchCockpitData = async () => {
        const response = await getCockpitDataGuest(selectionId);
        if (response.ok) {
            console.log(response);
            setCockpitData(response.data.data);

            getImage(response.data.data.softwareProductDetails.softwareProduct.id, "/api/product/get-logo?id=", '').then(frontendImageLocation => {
                setImageSource(frontendImageLocation);
            });
        }
    };

    useEffect(() => {
        handleFetchCockpitData();
    }, []);

    if (typeof cockpitData === "undefined") return <LoadingSpinner />;
    return (
        <div className='p-4 w-full'>
            <div className="grid gap-4">
                <div className="w-full grid grid-cols-12 gap-4">
                    {
                        !cockpitData.selectionDetails ? <></> :
                        <div className='col-span-6 p-4 h-full bg-white rounded-lg shadow sm:p-6 space-y-4'>
                            <div className='h-32'>
                                {
                                cockpitData.selectionDetails.selectionLogo ?
                                <img className='h-full w-full object-contain object-left' src={
                                    cockpitData.selectionDetails.selectionLogo.startsWith("http") ?
                                    cockpitData.selectionDetails.selectionLogo : "https://" + cockpitData.selectionDetails.selectionLogo
                                } /> : <></>
                                }
                            </div>

                            <div>
                                <p className="text-2xl font-semibold w-full rounded leading-6 text-gray-900">{cockpitData.selectionDetails.selectionTitle}</p>
                                <p className="mt-4 w-full rounded leading-6 text-gray-900">{cockpitData.selectionDetails.companyDescription}</p>

                                <p className='mt-4 font-semibold'>{t('selectionGuestCockpit.currentPhase')}</p>
                                <p className="w-full rounded leading-6 text-gray-900">{cockpitData.selectionDetails.selectionPhase[0].toUpperCase() + cockpitData.selectionDetails.selectionPhase.slice(1)}</p>
                            </div>
                        </div>
                    }

                    {
                        !cockpitData.softwareProductDetails ? <></> :
                        <div className='col-span-6 p-4 h-full bg-white rounded-lg shadow sm:p-6 space-y-4'>
                            <div className='h-32'>
                                {
                                imageSource ?
                                <img className='h-full w-full object-contain object-left' src={imageSource} /> : <></>
                                }
                            </div>
                            <div>
                                <p className="text-2xl font-semibold w-full rounded leading-6 text-gray-900">{cockpitData.softwareProductDetails.softwareProduct.vendorDetails.company} {cockpitData.softwareProductDetails.softwareProduct.productName}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default SelectionGuestCockpit;