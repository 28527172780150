import { LockIcon } from "swap-frontend-library"
import MainButton from "../../../../../../../../components/MainButton"

const ResultsPreview = ({ jumpToResults } : { matchingMetrics : any[], jumpToResults: () => void }) => {
  
    return (
        <div 
        // onClick={() => {!industryClassificationVisible ? setIndustryClassificationVisible(true) : null}} 
        className={`w-full flex flex-col items-center p-10 max-sm:p-4 shadow border rounded-lg bg-white bg-gradient-to-br from-white/30 to-[#57e3ff]/10 border-2 border-primary expand-down`}>

            <div className='mt-8 flex flex-col items-center justify-center gap-4 py-8 mb-10'>
            <div className='w-full h-full text-center text-7xl font-bold py-4 mb-4'>Matching finished</div>
                <div className='w-64'>
                    <MainButton text={'See Top-20 systems'} func={() => jumpToResults()} />
                </div>
            </div>


        </div>
  )
}

export default ResultsPreview