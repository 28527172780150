import React, { useEffect, useState } from 'react'
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import { UpdateSelectionRequirementKeywords, createNewSelectionRequirement, createNotification, deleteSelectionRequirement, getSelectionRequirement, getUniqueSelectionRequirementCategories, setSelectionRequirementStatus, updateSelectionRequirement } from '../../../../../../data/SelectionPortalRequests';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import CommentWindow from './CommentWindow';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';
import SpeedDial from '../../components/SpeedDial';
import TextInput from '../../../../../../components/TextInput';
import SelectorDropdown from '../../../../../../components/SelectorDropdown';
import MainButton from '../../../../../../components/MainButton';
import TrashIcon from '../../../../../../assets/img/icons/TrashIcon';
import WarningIcon from '../../../../../../assets/img/icons/WarningIcon';
import QuickMenu from '../../../../../../components/QuickMenu';
import RequirementInputWizard from './RequirementWizard/RequirementInputWizard';
import { ArrowDownIcon, CheckmarkIcon, CrossIcon, InfoIcon, LockIcon, MessageIcon } from 'swap-frontend-library';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import GearIcon from '../../../../../../assets/img/icons/GearIcon';
import { useTranslation } from 'react-i18next';

interface props {
    requirementId : string,
    selectionId : string,
    handleOpenRequirement : (a:string, b:string) => void,
    handleCloseRequirement : (a:string) => void,
    handleUpdateRequirementTab : (a:string, b:string) => void,
}

const SelectionRequirementForm = ({requirementId, selectionId, handleOpenRequirement, handleCloseRequirement, handleUpdateRequirementTab} : props) => {
    const { t } = useTranslation();
    const role = useSelector((state: RootState) => state.user.role);
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const [requirement, setRequirement] = useState<any>();
    const [editingLocked, setEditingLocked] = useState<any>(true);
    const [categories, setCategories] = useState<any[]>();
    const [importanceDots, setImportanceDots] = useState<number>();
    const [softwareFulfillments, setSoftwareFulfillments] = useState<any[]>();
    const [isNewRequirement, setIsNewRequirement] = useState<boolean>(false);
    
    const [openMenuForId, setOpenMenuForId] = useState<string>();
    
    const [selectedCategoryDataLayer, setSelectedCategoryDataLayer] = useState<any>(["uniqueCategoriesTreeSelectionLayer"]);
    const [uniqueRequirementCategoriesByLayer, setUniqueRequirementCategoriesByLayer] = useState<any>();
    const [uniqueRequirementCategories, setUniqueRequirementCategories] = useState<any>();
    const [newDescription, setNewDescription] = useState<string | null>(null);
    const [newRequirementTitle, setNewRequirementTitle] = useState<string | null>(null);
    const [newRequirementStaticRequirementId, setNewRequirementStaticRequirementId] = useState<string | null>(null);
    const [existingSelectinRequirementId, setExistingSelectinRequirementId] = useState<string | null>(null);
    const [newCategory1, setNewCategory1] = useState<string>("");
    const [newCategory2, setNewCategory2] = useState<string>("");
    const [newCategory3, setNewCategory3] = useState<string>("");

    const [editCategories, setEditCategories] = useState(false);

    const handleGetSelectionRequirement = async () => {        
        const uniqueRequirementCategoriesTree = await getUniqueSelectionRequirementCategories(selectionId);
        if (uniqueRequirementCategoriesTree.ok) {
            setUniqueRequirementCategoriesByLayer(uniqueRequirementCategoriesTree.data.data)
            setUniqueRequirementCategories(uniqueRequirementCategoriesTree.data.data.uniqueCategoriesTreeSelectionLayer)
        }
        
        if (requirementId == "-1") { 
            setIsNewRequirement(true); 
            setImportanceDots(0);
            setCategories([]);
            setRequirement([]);
            return;
        }

        const response = await getSelectionRequirement(requirementId);
        if (response.ok) {
            setRequirement(response.data.data.selectionRequirement);
            setCategories(JSON.parse(response.data.data.selectionRequirement.category));
            setSoftwareFulfillments(response.data.data.fulfillments);

            if (response.data.data.selectionRequirement.staticRequirementId == null && response.data.data.selectionRequirement.existingSelectionRequirementId == null) { setEditingLocked(false); }
            
            const importance = response.data.data.selectionRequirement.importance;
            setImportanceDots(Math.ceil(importance));
        }
        setIsNewRequirement(false);
    }

    const handleSetImportance = async (importanceLevel: number) => {
        if (requirementId == "-1") {
            return setImportanceDots(importanceLevel);
        }
        await handleUpdateRequirement({importance: importanceLevel});
    }

    const handleDeleteRequirement = async () => {
        if (await deleteSelectionRequirement(requirementId)) {
            handleCloseRequirement(requirementId);
        }
    }

    const handleSetRequirementStatus = async (requirementId: string, status : string) => {
        if (await setSelectionRequirementStatus(requirementId, status)) {
            handleGetSelectionRequirement();
        }
    }
    
    const handleUpdateRequirement = async ({title, description, category, importance} :  UpdateSelectionRequirementKeywords) => {
        const response = await updateSelectionRequirement(requirementId, title, description, category, importance)
        if (response.ok) {
            if (typeof title !== "undefined") {
                handleUpdateRequirementTab(requirementId, response.data.data.requirement);
                handleGetSelectionRequirement();
            } else {
                handleGetSelectionRequirement();
            }
        }
    }

    
    useEffect(() => {
        handleGetSelectionRequirement();
    }, [requirementId])
    
    useEffect(() => {
        if (editCategories && typeof categories !== "undefined") {
            if (categories.length >= 1) setNewCategory1(categories[0])
            if (categories.length >= 2) setNewCategory2(categories[1])
            if (categories.length >= 3) setNewCategory3(categories[2])
        }
    }, [editCategories])

    useEffect(() => {
        if (typeof uniqueRequirementCategoriesByLayer === "undefined") return;
        const relevantCategories = selectedCategoryDataLayer.reduce((previousValue:any, currentValue:any) => {
            previousValue = {...previousValue, ...uniqueRequirementCategoriesByLayer[currentValue]};
            return previousValue
        }, {})
        setUniqueRequirementCategories(relevantCategories);
    },[selectedCategoryDataLayer])

    const handleSaveNewRequirement = async () => {
        if (newRequirementTitle == "" || newRequirementTitle == null) {createNotification("Saving requirement failed: You need to add a title.", "warning"); return;}
        // if (importanceDots == 0 || typeof importanceDots === "undefined") {createNotification("Saving requirement failed: You need to add an importance.", "warning"); return;}
        // if (newDescription == "" || newDescription == null) {createNotification("Saving requirement failed: You need to add a description.", "warning"); return;}
        if (newCategory1 == "" || newCategory2 == "") {createNotification("Saving requirement failed: You need to add categories.", "warning"); return;}
        
        let cleanImportanceDots : Number | null;
        if (importanceDots == 0 || typeof importanceDots === "undefined") { cleanImportanceDots = null; } else { cleanImportanceDots = importanceDots; }
        
        let cleanDescription : string;
        if (newDescription == "" || newDescription == null) { cleanDescription = "" } else { cleanDescription = newDescription; }

        // console.log(newRequirementTitle, newDescription, importanceDots, JSON.stringify([newCategory1, newCategory2, newCategory3]));

        const response = await createNewSelectionRequirement(selectionId, newRequirementTitle, cleanDescription, JSON.stringify([newCategory1, newCategory2, newCategory3, null]), cleanImportanceDots, newRequirementStaticRequirementId, existingSelectinRequirementId);
        if (response.ok) {
            handleCloseRequirement("-1");
            handleOpenRequirement(response.data.data.id, response.data.data.requirement);
        }
    }
    
  return (
    <div className='h-full overflow-y-auto'>
        <div className={`w-full flex flex-col ${((typeof requirement !== "undefined" && (requirement.staticRequirementId != null  || requirement.existingSelectionRequirementId != null)) && editingLocked && projectRole == "selection_admin") ? "border-2 border-dashed border-gray-800" : ""}`}>
            {(typeof requirement === "undefined" || typeof categories === "undefined" || typeof importanceDots === "undefined") ? (<LoadingSpinner />) : (
                <>
                    {
                        ((requirement.staticRequirementId != null || requirement.existingSelectionRequirementId != null) && editingLocked && projectRole == "selection_admin") ? (
                        <div className='absolute z-10 bg-gray-300 h-16 p-2 mr-0.5 text-sm font-semibold flex gap-4 w-full'>
                            <p className='w-full'>{t('requirement.lockedMessage')}</p>
                            <div>
                                <MainButton text={t('requirement.unlock')} style='light' func={() => setEditingLocked(false)} />
                            </div>
                        </div>
                        ) : (<></>)
                    }
                    
                    <div className={`relative m-6 ${((requirement.staticRequirementId != null || requirement.existingSelectionRequirementId != null) && editingLocked) ? "mt-20" : "mt-6" } flex flex-col p-5 bg-white rounded-lg shadow max-md:max-w-full`}>
                        <div className="text-md relative font-normal tracking-tight leading-9 text-gray-900 max-md:max-w-full">
                            <div className="w-full mb-4 flex content-center w-full text-sm font-medium leading-5 text-gray-900 bg-white">
                                {
                                    isNewRequirement || editCategories ? (
                                        <div>
                                            <p className='text-sm font-semibold'>{t('category')}</p>
                                            <div className="flex items-center space-x-4">
                                                <RequirementCategoryDropdown categories={Object.keys(uniqueRequirementCategories)} label={t('requirement.category1')} placeholderLabel={t('requirement.category1')} selectedCategory={newCategory1} handleSetSelectedCategory={(category:any) => {setNewCategory2(""); setNewCategory3(""); setNewCategory1(category)}} />
                                                {/* <SelectorDropdown items={Object.keys(uniqueRequirementCategories).map(item => ({label: item, value: item}))} itemValueKey={'value'} itemLabelKey={'label'} selectedItem={newCategory1} setSelectedItem={setNewCategory1} placeholderLabel='Category 1'/> */}

                                                {
                                                    (newCategory1 !== "") ? (
                                                        <>
                                                        <ArrowRightIcon iconHeight='12' iconWidth='12' iconColor='black' />
                                                        {/* <SelectorDropdown items={Object.keys(uniqueRequirementCategories[newCategory1]).map(item => ({label: item, value: item}))} itemValueKey={'value'} itemLabelKey={'label'} selectedItem={newCategory2} setSelectedItem={setNewCategory2} placeholderLabel='Category 2' /> */}
                                                        <RequirementCategoryDropdown categories={newCategory1 in uniqueRequirementCategories ? Object.keys(uniqueRequirementCategories[newCategory1]) : []} label={t('requirement.category2')} placeholderLabel={t('requirement.category2')} selectedCategory={newCategory2} handleSetSelectedCategory={(category:any) => {setNewCategory3(""); setNewCategory2(category)}} />
                                                        </>
                                                    ) : (<></>)
                                                }
                                                {
                                                    (newCategory1 !== "" && newCategory2 !== "") ? (
                                                        //  && typeof uniqueRequirementCategories[newCategory1][newCategory2] !== "undefined"
                                                        <>
                                                        <ArrowRightIcon iconHeight='12' iconWidth='12' iconColor='black' />
                                                        {/* <SelectorDropdown items={uniqueRequirementCategories[newCategory1][newCategory2].map((item:any) => ({label: item[0], value: item[0]}))} itemValueKey={'value'} itemLabelKey={'label'} selectedItem={newCategory3} setSelectedItem={setNewCategory3} placeholderLabel='Category 3'/> */}
                                                        <RequirementCategoryDropdown categories={(newCategory1 in uniqueRequirementCategories && newCategory2 in uniqueRequirementCategories[newCategory1]) ? uniqueRequirementCategories[newCategory1][newCategory2] : []} label={t('requirement.category3')} placeholderLabel={t('requirement.category3')} selectedCategory={newCategory3} handleSetSelectedCategory={(category:any) => {setNewCategory3(category)}} />
                                                        </>
                                                    ) : (<></>)
                                                }
                                            </div>
                                            <div className='flex items-center mt-2 gap-2'>
                                                <p className='text-gray-600'>{t("requirement.selectCategoryTemplates")}</p>
                                                <div onClick={() => setSelectedCategoryDataLayer((prev:any[]) => prev.includes("uniqueCategoriesTreeSelectionLayer") ? prev.filter((i:any) => i != "uniqueCategoriesTreeSelectionLayer") : [...prev, "uniqueCategoriesTreeSelectionLayer"])} className={`flex items-center rounded-full border px-1 cursor-pointer text-gray-600 ${selectedCategoryDataLayer.includes("uniqueCategoriesTreeSelectionLayer") ? "bg-gray-200" : "bg-gray-50"}`}>{t("requirement.selectionLayer")} <div className='w-4 h-4'><CrossIcon /></div></div>
                                                <div onClick={() => setSelectedCategoryDataLayer((prev:any[]) => prev.includes("uniqueCategoriesTreeCustomerLayer") ? prev.filter((i:any) => i != "uniqueCategoriesTreeCustomerLayer") : [...prev, "uniqueCategoriesTreeCustomerLayer"])} className={`flex items-center rounded-full border px-1 cursor-pointer text-gray-600 ${selectedCategoryDataLayer.includes("uniqueCategoriesTreeCustomerLayer") ? "bg-gray-200" : "bg-gray-50"}`}>{t("requirement.customerLayer")} <div className='w-4 h-4'><CrossIcon /></div></div>
                                                <div onClick={() => setSelectedCategoryDataLayer((prev:any[]) => prev.includes("uniqueCategoriesTreePlatformLayer") ? prev.filter((i:any) => i != "uniqueCategoriesTreePlatformLayer") : [...prev, "uniqueCategoriesTreePlatformLayer"])} className={`flex items-center rounded-full border px-1 cursor-pointer text-gray-600 ${selectedCategoryDataLayer.includes("uniqueCategoriesTreePlatformLayer") ? "bg-gray-200" : "bg-gray-50"}`}>{t("requirement.platformLayer")} <div className='w-4 h-4'><CrossIcon /></div></div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div onClick={() => {if (!editingLocked) setEditCategories(true)}} className="flex gap-5 rounded-md max-md:flex-wrap max-md:max-w-full">
                                            
                                            {
                                            (categories[0] != "") ? (
                                                <div
                                                className='flex items-center'>
                                                <div className="flex-auto">{categories[0]}</div>
                                                </div>
                                            ) : (<></>)
                                            }
                                            {
                                            (categories[1] != "" && categories[1] != null) ? (
                                                <div
                                                className='flex items-center'>
                                                <ArrowRightIcon iconHeight='12' iconWidth='12' iconColor='black' />
                                                <div className="flex-auto ml-4">{categories[1]}</div>
                                                </div>
                                            ) : (<></>)
                                            }
                                            {
                                            (categories[2] != "" && categories[2] != null) ? (
                                                <div
                                                className='flex items-center'>
                                                <ArrowRightIcon iconHeight='12' iconWidth='12' iconColor='black' />
                                                <div className="flex-auto ml-4">{categories[2]}</div>
                                                </div>
                                            ) : (<></>)
                                            }
                                        </div>
                                    )
                                }
                            </div>

                            <p className='text-sm font-semibold'>{t('requirement.title')}</p>
                            {
                                isNewRequirement ? (
                                    <RequirementInputWizard selectionId={selectionId} newRequirementTitle={newRequirementTitle} setNewRequirementTitle={setNewRequirementTitle} setNewCategory1={setNewCategory1} setNewCategory2={setNewCategory2} setNewCategory3={setNewCategory3} setNewDescription={setNewDescription} setNewRequirementStaticRequirementId={setNewRequirementStaticRequirementId} setExistingSelectinRequirementId={setExistingSelectinRequirementId} />
                                ) : (
                                    <input disabled={editingLocked || projectRole != "selection_admin"} onBlur={() => {if (newRequirementTitle != null && newRequirementTitle != "") {handleUpdateRequirement({title: newRequirementTitle})} else {createNotification(t('requirement.updateFailedTitle'), "warning"); setNewRequirementTitle(null);}}} className="w-full rounded text-xl font-bold tracking-tight leading-9 text-gray-900" value={newRequirementTitle != null ? newRequirementTitle : requirement.requirement} onChange={(e) => setNewRequirementTitle(e.target.value)}/>
                                )
                            }

                            {
                                role == "guest_vendor" ? <></> : (
                                    <div className="mt-5">
                                        <p className='text-sm font-semibold'>{t('requirement.importance')}</p>
                                        <div className='flex space-x-2'>
                                            {/* {
                                                [...Array(10).keys()].map((ithDot: any, index: Number) => {
                                                    return (
                                                        <div onClick={() => {if (projectRole == "selection_admin") handleSetImportance(ithDot+1)}}
                                                        className={`w-4 h-4 ${(ithDot < importanceDots) ? ("bg-primary"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full`}></div>
                                                    )
                                                })
                                            } */}

                                            <div onClick={(e) => {e.stopPropagation(); if (projectRole == "selection_admin") {handleSetImportance(33)}}}
                                            className={`w-6 h-6 ${(33 <= importanceDots) ? ("bg-primary text-white"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full flex items-center justify-center font-bold`}>C</div>
                                            <div onClick={(e) => {e.stopPropagation(); if (projectRole == "selection_admin") {handleSetImportance(66)}}}
                                            className={`w-6 h-6 ${(66 <= importanceDots) ? ("bg-primary text-white"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full flex items-center justify-center font-bold`}>B</div>
                                            <div onClick={(e) => {e.stopPropagation(); if (projectRole == "selection_admin") {handleSetImportance(100)}}}
                                            className={`w-6 h-6 ${(100 <= importanceDots) ? ("bg-primary text-white"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full flex items-center justify-center font-bold`}>A</div>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="w-full relative justify-between mt-8 max-md:flex-wrap max-md:max-w-full">
                                <p className='text-sm font-semibold'>{t('requirement.description')}</p>
                                {
                                    isNewRequirement ? (<TextInput textValue={newDescription} setTextValue={setNewDescription} rows={6} />) : (
                                        <textarea disabled={editingLocked || projectRole != "selection_admin"} rows={4} onBlur={() => {if (newDescription != null) {handleUpdateRequirement({description: newDescription})} else {createNotification(t('requirement.updateFailedDescription'), "warning"); setNewDescription(null);}}} className="w-full rounded text-md tracking-tight leading-9 text-gray-900" value={newDescription != null ? newDescription : requirement.description} onChange={(e) => setNewDescription(e.target.value)}/>
                                    )
                                }
                            </div>
                        </div>

                        {!isNewRequirement && !editCategories ? (
                            <div className='absolute bottom-5 right-5 flex space-x-4 h-6'>
                                <div 
                                className='h-6 w-6 flex items-center justify-center hover:bg-gray-100 rounded-full'>
                                {
                                    (requirement.requirementStatus == "warning") ? (
                                        <div className='group w-full h-10 text-amber-400 flex items-center justify-center'>
                                            <WarningIcon iconColor='currentColor' />
                                            <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-28 group-hover:block hidden'>
                                                {t('requirement.warningMessage')}
                                            </div>
                                        </div>
                                    ) : (requirement.requirementStatus == "auto_completed") ? (
                                        <div className='group w-full h-10 text-green-400 font-bold flex items-center justify-center'>
                                            (<div className='w-6 h-6'><CheckmarkIcon /></div>)
                                            <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-28 group-hover:block hidden'>
                                                {t('requirement.autoCompletedMessage')}
                                            </div>
                                        </div>
                                    ) : (requirement.requirementStatus == "completed") ? (
                                        <div className='group w-full h-10 text-green-400 flex items-center justify-center'>
                                            <div className='w-6 h-6'><CheckmarkIcon /></div>
                                            <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-28 group-hover:block hidden'>
                                                {t('requirement.completedMessage')}
                                            </div>
                                        </div>
                                    ) : (requirement.requirementStatus == "archived") ? (
                                        <div className='group w-full h-10 text-red-400 flex items-center justify-center'>
                                            <div className='h-8 w-8'><CrossIcon /></div>
                                            <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-28 group-hover:block hidden'>
                                                {t('requirement.archivedMessage')}
                                            </div>
                                        </div>
                                    ) : (<></>)
                                }

                                </div>

                                {
                                    projectRole == "selection_admin" && !editCategories ? (
                                        <QuickMenu id={requirement.id} openMenuForId={openMenuForId} setOpenMenuForId={setOpenMenuForId} menuItems={[
                                            {label: t('requirement.completed'), value: "completed", icon: <div className='w-6 h-6 text-green-400'><CheckmarkIcon /></div>, function: handleSetRequirementStatus },
                                            {label: t('requirement.warning'), value: "warning", icon: <div className='text-amber-400'><WarningIcon iconColor='currentColor' /></div>, function: handleSetRequirementStatus },
                                            {label: t('requirement.archived'), value: "archived", icon: <div className='text-red-400'><CrossIcon /></div>, function: handleSetRequirementStatus },
                                            {label: t('requirement.delete'), value: "delete", icon: <div className='text-red-400'><TrashIcon /></div>, function: handleDeleteRequirement }
                                        ]} />
                                    ) : <></>
                                }
                                

                                {/* <div onClick={handleDeleteRequirement}
                                className='h-10 w-10 flex items-center justify-center cursor-pointer hover:bg-gray-100 rounded-full'>
                                    <div className='w-8 h-8 text-gray-400'><TrashIcon /></div>
                                </div> */}
                            </div>
                        ) : (<></>)}
                        
                        
                        {isNewRequirement ? (<div className='w-full flex justify-end'><div className='mt-4 w-fit'><MainButton text={t('requirement.save')} func={handleSaveNewRequirement} /></div></div>) : (<></>)}
                        {editCategories ? (<div className='w-full flex justify-end'><div className='mt-4 w-fit'><MainButton text={t('requirement.save')} func={() => handleUpdateRequirement({category: JSON.stringify([newCategory1, newCategory2, newCategory3])})} /></div></div>) : (<></>)}
                    </div>

                    {
                        isNewRequirement || role == "guest_vendor" ? (<></>) : (
                        <div className="grow grid gap-4 mx-6 mb-6 xl:grid-cols-2">
                            <div className="flex flex-col pt-5 overflow-y-auto pb-2.5 mt-5 bg-white rounded-lg shadow max-md:max-w-full">
                                <div className="text-xl px-5 font-bold tracking-tight leading-9 text-gray-900 max-md:max-w-full">
                                    {t('requirement.fulfillments')}
                                </div>
                                <div className='grow max-h-96 overflow-y-auto '>
                                    {
                                        // (requirement.staticRequirementId != null || requirement.existingSelectionRequirementId != null) && 
                                        typeof softwareFulfillments !== "undefined" && softwareFulfillments.length > 0 ? (
                                        <table className="w-full text-sm text-left text-gray-500">
                                            <TableHeader header={["Software", "Fulfillment", "Comment"]} />
                                            {
                                                softwareFulfillments.map((softwareFulfillment: any, index: number) => {

                                                    // console.log(softwareFulfillment.selectionSoftwareProductRequirementFulfillments[0])
                                                    return (
                                                        <tbody className={`${softwareFulfillment.included ? "" : "opacity-50 hover:opacity-100"}`} title={`${softwareFulfillment.included ? "Included system" : "Excluded system"}`}>
                                                            <TableRow rowItems={["", 
                                                            <div className='flex items-center gap-6'>
                                                                {
                                                                    softwareFulfillment.included ? (
                                                                        <div className='w-8 h-8 rounded-full bg-primary/20 border border-primary/20 text-primary flex items-center justify-center font-bold text-xl'>{softwareFulfillment.rank}</div>
                                                                    ) : (
                                                                        <div className='w-8 h-8 rounded-full bg-gray-400/20 border border-gray-400/20 text-gray-400 flex items-center justify-center font-bold text-xl p-1'><CrossIcon /></div>
                                                                    )
                                                                }
                                                                <div>
                                                                    <p className='text-xs font-light'>{softwareFulfillment.softwareProduct.vendorDetails.company}</p>
                                                                    <p className='font-semibold'>{softwareFulfillment.softwareProduct.productName}</p>
                                                                </div>
                                                            </div>, 
                                                            softwareFulfillment.selectionSoftwareProductRequirementFulfillments[0].score == 3 ? 
                                                            <div title="Fully fulfilled" className={`w-8 h-8 border rounded-full p-1 text-white bg-lime-400`}><CheckmarkIcon /></div> :
                                                            softwareFulfillment.selectionSoftwareProductRequirementFulfillments[0].score == 1 ? 
                                                            <div title="Fulfilled with customizing or additional modules (please specify)" className={`w-8 h-8 border rounded-full p-1 text-white bg-amber-300`}><GearIcon /></div>
                                                            : <div title="Not fulfilled" className={`w-8 h-8 border rounded-full p-1 text-white bg-red-200`}><CrossIcon /></div>,
                                                            // <div className='w-6 h-6' title={softwareFulfillment.selectionSoftwareProductRequirementFulfillments[0] ? "Platform template" : "Customer template"}><InfoIcon /></div>
                                                            softwareFulfillment.selectionSoftwareProductRequirementFulfillments[0].comment ? <div title={softwareFulfillment.selectionSoftwareProductRequirementFulfillments[0].comment} className='w-6 h-6'><MessageIcon /></div> : <></>
                                                            ]
                                                            } />
                                                        </tbody>
                                                    );
                                                })
                                            }
                                        </table>
                                        ) : (
                                            <div className='relative w-full h-full overflow-hidden'>
                                                <div className='absolute w-full h-full'>
                                                    <div className='absolute h-full w-full z-5 bg-white/30 backdrop-blur rounded flex flex-col items-center justify-center p-6 text-center'>
                                                        <div className='w-12 h-12 bg-primary text-white p-2 rounded-full'><LockIcon /></div>
                                                        <p className='text-xl font-semibold mt-4'>{t('requirement.systemFulfillment')}</p>
                                                        <p className=''>{t('requirement.systemFulfillmentMessage')}</p>
                                                    </div>
                                                </div>
                                                <div className='p-2'>
                                                    {
                                                        [{software: "System A", fulfillment: 90, type: "Standard"}, {software: "System A", fulfillment: 90, type: "Standard"}, {software: "System A", fulfillment: 90, type: "Standard"}, {software: "System A", fulfillment: 90, type: "Standard"}, {software: "System A", fulfillment: 90, type: "Standard"}, {software: "System A", fulfillment: 90, type: "Standard"}]
                                                        .map((softwareFulfillment: any, index: number) => {

                                                            return (
                                                                <tbody className="cursor-pointer">
                                                                    <TableRow rowItems={["", softwareFulfillment.software, `${softwareFulfillment.fulfillment} %`, softwareFulfillment.type, "None"]} />
                                                                </tbody>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            {
                                role == "guest_vendor" ? <></> : (
                                    <div className="flex flex-col px-5 pt-5 pb-2.5 mt-5 bg-white rounded-lg shadow max-md:max-w-full">
                                        <div className="text-xl font-bold tracking-tight leading-9 text-gray-900 max-md:max-w-full">
                                            {t('requirement.comments')}
                                        </div>
                                        <div className='h-full'>
                                            <CommentWindow requirementId={requirementId} />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        )
                    }
                </>
            )}

            <SpeedDial speedDials={[]} defaultDials={true} currentContext='requirements' offsetY={isNewRequirement ? "bottom-5" : "bottom-32"}/>

        </div>
    </div>
  )
}

{/* <SelectorDropdown items={Object.keys(uniqueRequirementCategories[newCategory1]).map(item => ({label: item, value: item}))} itemValueKey={'value'} itemLabelKey={'label'} selectedItem={newCategory2} setSelectedItem={setNewCategory2} placeholderLabel='Category 2' /> */}
const RequirementCategoryDropdown = ({label, selectedCategory, handleSetSelectedCategory, placeholderLabel, categories} : {categories:string[], label:string, placeholderLabel:string, selectedCategory:string, handleSetSelectedCategory:(category:any) => void}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [textInput, setTextInput] = useState<string | null>(null)
    return (
        <div>
            <div 
            onClick={() => setDropdownOpen(prev => !prev)}
            className={`cursor-pointer bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-64 truncate ${selectedCategory == "" ? "text-gray-400" : "text-gray-900" }`}>
                {selectedCategory || placeholderLabel}
            </div>
            {
                !dropdownOpen ?
                <></> :
                <>
                    <div className='absolute z-[6] bg-white border shadow rounded-lg p-2'>
                        <div className='space-y-2 mb-2'>
                            {
                                categories.filter(item => item !== null).map((item : any, index : number) => {
                                    return (
                                        <div 
                                        onClick={() => {handleSetSelectedCategory(item); setTextInput(null); setDropdownOpen(false)}}
                                        className={`${selectedCategory == item ? "bg-primary text-white" : "bg-white"} cursor-pointer border rounded-lg p-2 hover:bg-primary hover:text-white`} key={index}>{item}</div>
                                    );
                                })
                            }
                        </div>
                        <hr />
                        <input
                        onChange={(e) => {handleSetSelectedCategory(""); setTextInput(e.target.value)}}
                        value={textInput || ""}
                        onBlur={() => {if (textInput) handleSetSelectedCategory(textInput)}}
                        placeholder='Insert new category'
                        className={`${selectedCategory == textInput ? "border-primary" : ""} bg-gray-50 cursor-pointer border rounded-lg p-2 w-full mt-2`} />
                    </div>
                    <div onClick={() => setDropdownOpen(false)} className='fixed z-[4] top-0 left-0 w-screen h-screen'></div>
                </>
            }
        </div>
    )
}

export default SelectionRequirementForm