import React, { useState } from 'react'
import { ArrowDownIcon, LinkIcon, OpenIcon, PercentageCircle } from 'swap-frontend-library';
import FormModal from '../../../../../../../../components/FormModal';

const SimilarCompaniesAnalysis = ({ numberOfSimilarCompanies, similarCompanies } : { numberOfSimilarCompanies : number, similarCompanies : any[] }) => {
    const [similarCompaniesVisible, setSimilarCompanies] = useState(true);
    const [expertViewOpen, setExpertViewOpen] = useState(false);
  
    return (
        <div 
        // onClick={() => {!similarCompaniesVisible ? setSimilarCompanies(true) : null}} 
        className={`relative w-full p-10 max-sm:p-4 shadow border rounded-lg bg-white expand-down ${similarCompaniesVisible ? "" : "cursor-pointer"}`}>
            <div 
            onClick={() => setExpertViewOpen(true)}
            className='cursor-pointer absolute right-5 -top-3 rounded-full border-blue-700/90 text-blue-700 hover:to-blue-400 hover:from-[#57e3ff] bg-gradient-to-br from-blue-400 to-[#57e3ff] border-2 shadow shadow-primary/50 font-semibold px-2 transition-all'>Expert insights</div>
            
            <div className='relative flex items-center justify-between max-md:my-8'>
                <p className='font-semibold text-xl'>Similar companies</p>
                <div onClick={() => setSimilarCompanies(prev => !prev)} className={`cursor-pointer w-8 h-8 text-gray-300 ${similarCompaniesVisible ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
            </div>

            {
                !similarCompaniesVisible ? <></> : (
                <div className='mt-8'>
                    <div className='w-full h-full text-center text-7xl font-bold py-16 mb-10'>
                        {numberOfSimilarCompanies}
                    </div>

                </div>
                )
            }

            {
                expertViewOpen ?
                <FormModal isOpen={expertViewOpen} setOpen={setExpertViewOpen} size='large' additionalStyles='py-8 px-4 bg-gradient-to-br from-white/30 to-[#57e3ff]/10 border-2 border-primary'>
                    <>
                        <div 
                        className='cursor-pointer absolute left-5 -top-3 rounded-full border-blue-700 text-blue-700 hover:to-blue-700 hover:from-[#57e3ff] bg-gradient-to-br from-blue-400 to-[#57e3ff] border-2 shadow shadow-primary/50 font-semibold px-2 transition-all'>Expert insights</div>
                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-4 overflow-y-hidden'>
                            {
                                similarCompanies.map((item:any, index:number) => {
                                    return (
                                        <div className='border rounded p-3 grid grid-cols-12 items-center gap-4'>
                                            <div className='col-span-3'>
                                                <PercentageCircle rating={Math.round(item.score*100)} />
                                            </div>
                                            <div className='col-span-9'>
                                                <p className='text-lg font-semibold truncate w-full'>{item.referenceName}</p>
                                                <div className='flex items-center w-full gap-1'>
                                                    <a className='truncate underline underline-offset-2 hover:underline-offset-4 transition-all cursor-pointer' href={item.referenceUrl} target='_blank'>{item.referenceUrl}</a>
                                                    <div className='w-4 h-4'><OpenIcon /></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                </FormModal> :
                <></>
            }
        </div>
  )
}

export default SimilarCompaniesAnalysis