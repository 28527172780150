import { createNotification } from "./SelectionPortalRequests";

export async function fetchCustomerData() {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/get-customers`, options);
        
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            console.error(`fetchCustomerDetails: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`fetchCustomerDetails: Error occurred ${error}`);
    }
}

export async function fetchCustomerAccountData() {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/get-customer-accounts`, options);
        
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            console.error(`fetchCustomerData: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`fetchCustomerData: Error occurred ${error}`);
    }
}

export async function fetchCustomerDetails(customerAccountId?: string) {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/get-customer-details${typeof customerAccountId !== "undefined" ? "?customerAccountId="+customerAccountId : ""}`, options);
        
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            console.error(`fetchCustomerDetails: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`fetchCustomerDetails: Error occurred ${error}`);
    }
}

export async function registerCustomer(inputEmail: string, inputPassword: string, inputCompanyName: string) {

    const registrationDetails = {
        email: inputEmail,
        password: inputPassword,
        companyName: inputCompanyName
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(registrationDetails),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/register-customer`, options);
    
        if (res.ok) {
            const data = await res.json();
            return {success: true, status: res.status};
        } else {
            console.error(`registerCustomer: Bad response ${res.status}`);
            return {success: false, status: res.status};
        }
    } catch(error) {
        console.error(`registerCustomer: Error occurred ${error}`);
        return {success: false, status: undefined};
    }   
}

export async function registerCustomerManager(inputEmail: string, inputPassword: string, firstname: string, lastname: string, inputCompanyId: string) {

    const registrationDetails = {
        email: inputEmail,
        password: inputPassword,
        firstname: firstname,
        lastname: lastname,
        companyId: inputCompanyId
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(registrationDetails),
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/register-customer-manager`, options);
    
        if (res.ok) {
            const data = await res.json();
            return {success: true, status: res.status};
        } else {
            console.error(`registerCustomerManager: Bad response ${res.status}`);
            return {success: false, status: res.status};
        }
    } catch(error) {
        console.error(`registerCustomerManager: Error occurred ${error}`);
        return {success: false, status: undefined};
    }   
}

export async function deleteCustomerAccount (id: string) {
    const payload = {
        id: id,
    };
    
    const options : RequestInit = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/delete-customer-account`, options)
        if (res.ok) {
            console.log("deleteCustomerAccount: Deleted customer account successfully");
        } else {
            console.error(`deleteCustomerAccount: Bad response ${res.status}`);
        }

    } catch (error) {
        console.error(`deleteCustomerAccount: Error occurred ${error}`);
    }
}

export async function restoreCustomerAccount (id: string) {
    const payload = {
        id,
    };
    
    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/restore-customer-account`, options)
        if (res.ok) {
            console.log("restoreCustomerAccount: Restored customer account successfully");
        } else {
            console.error(`restoreCustomerAccount: Bad response ${res.status}`);
        }

    } catch (error) {
        console.error(`restoreCustomerAccount: Error occurred ${error}`);
    }
}

export async function restoreCustomerDetails (id: string) {
    const payload = {
        id,
    };
    
    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/restore-customer-details`, options)
        if (res.ok) {
            console.log("restoreCustomerDetails: Restored customer details successfully");
        } else {
            console.error(`restoreCustomerDetails: Bad response ${res.status}`);
        }

    } catch (error) {
        console.error(`restoreCustomerDetails: Error occurred ${error}`);
    }
}

export async function deleteCustomerDetails (id: string) {
    const payload = {
        id: id,
    };
    
    const options : RequestInit = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/delete-customer-details`, options)
        if (res.ok) {
            console.log("deleteCustomerDetails: Deleted customer details successfully");
            return true;
        } else {
            console.error(`deleteCustomerDetails: Bad response ${res.status}`);
            return false;
        }
        
    } catch (error) {
        console.error(`deleteCustomerDetails: Error occurred ${error}`);
        return false;
    }
}

export async function upsertCustomerDetailsManager(id: string | undefined, companyName: string, note: string) {

    const registrationDetails = {
        id,
        companyName,
        note
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(registrationDetails),
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/upsert-customer-details-manager`, options);
    
        if (res.ok) {
            const data = await res.json();
            return {success: true, status: res.status};
        } else {
            console.error(`upsertCustomerDetailsManager: Bad response ${res.status}`);
            return {success: false, status: res.status};
        }
    } catch(error) {
        console.error(`upsertCustomerDetailsManager: Error occurred ${error}`);
        return {success: false, status: undefined};
    }   
}

export async function upsertCustomerDetails(formData: any, customerDetailsId?: string) {

    const payload = {
        formData,
        customerDetailsId
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/upsert-customer-details`, options);
    
        const data = await res.json();
        if (res.ok) {
            createNotification(data.message, "success")
            return {success: true, status: res.status};
        } else {
            createNotification(data.message, "error")
            console.error(`upsertCustomerDetails: Bad response ${res.status}`);
            return {success: false, status: res.status};
        }
    } catch(error) {
        console.error(`upsertCustomerDetails: Error occurred ${error}`);
        return {success: false, status: undefined};
    }   
}

export async function upsertAccountDetails(formData: any) {

    const payload = {
        formData
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/upsert-account-details`, options);
    
        const data = await res.json();
        if (res.ok) {
            createNotification(data.message, "success")
            return {success: true, status: res.status};
        } else {
            createNotification(data.message, "error")
            console.error(`upsertCustomerDetails: Bad response ${res.status}`);
            return {success: false, status: res.status};
        }
    } catch(error) {
        console.error(`upsertCustomerDetails: Error occurred ${error}`);
        return {success: false, status: undefined};
    }   
}

export async function updateCustomerOrgAdmin(userId: string, isOrgAdmin: boolean) {

    const payload = {
        userId,
        isOrgAdmin
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/update-org-admin`, options);
    
        if (res.ok) {
            const data = await res.json();
            return {success: true, status: res.status};
        } else {
            console.error(`handleUpdateCustomerOrgAdmin: Bad response ${res.status}`);
            return {success: false, status: res.status};
        }
    } catch(error) {
        console.error(`handleUpdateCustomerOrgAdmin: Error occurred ${error}`);
        return {success: false, status: undefined};
    }   
}