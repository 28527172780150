import React, { useEffect, useState } from 'react'
import ParticipantIcon from '../../presentation-overview/components/ParticipantIcon'
import { updatePresentationParticipant, getUsersSelection, notifyParticipantsViaEmail } from '../../../../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import { CheckmarkIcon, LoadingSpinner, MainButton } from 'swap-frontend-library';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';
import { socket } from '../../../../../../../../_socket/socket';
import { useTranslation } from 'react-i18next';

const SidebarParticipants = ({participants, presentationId, handleGetSelectionPresentation} : {participants: string[], presentationId: string, handleGetSelectionPresentation: () => {}}) => {
  const { t } = useTranslation();
  const { selectionId, setSelectedSelectionPhase } = useOutletContext<any>();
  const [usersSelection, setUsersSelection] = useState<any[]>();
  const [notified, setNotified] = useState(false);
  const [connectedUsers, setConnectedUsers] = useState<string[]>([]);
  const projectRole = useSelector((state: RootState) => state.selection.projectRole);
  const editingLocked = projectRole != "selection_admin";
  
  const handleFetchUsersSelection = async () => {
    const data = await getUsersSelection(selectionId);
    // console.log(data.data.data)
    if (data.ok) {
      setUsersSelection(data.data.data);
    } else {
      setUsersSelection([]);
    }
  }
  
  const handleAddParticipant = async (userSelectionId: string) => {
    if (editingLocked) return;
    const response = await updatePresentationParticipant(presentationId, userSelectionId, false);
    if (response.ok) {
      handleGetSelectionPresentation();
    } 
  }
  
  const handleDeleteParticipant = async (userSelectionId: string) => {
    if (editingLocked) return;
    const response = await updatePresentationParticipant(presentationId, userSelectionId, true);
    if (response.ok) {
      handleGetSelectionPresentation();
    } 
  }
  
  const handleNotifyPresentationParticipantsViaEmail = () => {
    if (editingLocked) return;
    notifyParticipantsViaEmail(presentationId)
    setNotified(true);
  }

  useEffect(() => {
    handleFetchUsersSelection();
  }, [])

  useEffect(() => {
    const handleTrackUserActivity = (data : any) => {
      if (data.joined != null && data.joined) console.log(`Websocket ${data.id} joined presentation ${data.presentationId}`);
      if (data.joined != null && !data.joined) console.log(`Websocket ${data.id} left presentation ${data.presentationId}`);
      socket.emit("get connected users", { presentationId });
    }

    const handleUpdateConnectedUsers = (data : any) => {
      // setConnectedUsers(data.connectedUsers.map((email:string) => email.toLocaleLowerCase()));
      setConnectedUsers(data.connectedUsers);
    }

    socket.on("someone joined or left presentation", handleTrackUserActivity);
    socket.on("receive connected users", handleUpdateConnectedUsers);

    // socket.emit("test room", { presentationId });
    socket.emit("get connected users", { presentationId });
    
    return () => {
        socket.off("someone joined or left presentation");
    }
  }, [])

  return (
    <div className='flex flex-col gap-4 p-4 w-96'>
      <div className='flex flex-col gap-4 mb-8 overflow-y-auto'>
        {
            participants.sort((a : any, b : any) => ((a.userSelection.user?.email || a.userSelection.guestEmail) < (b.userSelection.user?.email || b.userSelection.guestEmail) ? -1 : 1)).map((participant : any) => {
              // console.log(participant)
              return (
                <div onClick={() => handleDeleteParticipant(participant.userSelection.id)}
                className='flex items-center gap-4 hover:bg-gray-50 cursor-pointer rounded-r-full rounded-l-full'>
                    <ParticipantIcon name={(participant.userSelection.user?.email || participant.userSelection.guestEmail).replace("@", " ")} checked={false} />
                    <p className='font-semibold text-nowrap'>{(participant.userSelection.user?.email || participant.userSelection.guestEmail)}</p>
                </div>
              )
          })
        }
        {
            (typeof usersSelection === "undefined") ? <LoadingSpinner /> : (
              usersSelection.filter(selectionUser => !participants.map((participant : any) => participant.userSelection.id).includes(selectionUser.id)).sort((a : any, b : any) => ((a.user?.email || a.guestEmail) < (b.user?.email || b.guestEmail) ? -1 : 1)).map(selectionUser => (
                  <div onClick={() => handleAddParticipant(selectionUser.id)}
                  className='opacity-30 hover:opacity-100 flex items-center gap-4 hover:bg-gray-50 cursor-pointer rounded-r-full rounded-l-full'>
                      <ParticipantIcon name={(selectionUser.user?.email || selectionUser.guestEmail).replace("@", " ")} checked={false} />
                      <p className='font-semibold text-nowrap'>{(selectionUser.user?.email || selectionUser.guestEmail)}</p>
                  </div>
              ))
            )
        }
      </div>

      {
        (editingLocked) ? <></> : (!notified) ? (
          <MainButton text={t('sidebarParticipants.notify')} func={handleNotifyPresentationParticipantsViaEmail} />
        ) : (
          <div className='text-green-500 font-semibold flex gap-4'>
            <div className='w-6 h-6'><CheckmarkIcon /></div>
            <p>{t('sidebarParticipants.notificationsSent')}</p>
          </div>
        )
      }
    </div>
  )
}

export default SidebarParticipants