import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, LoadingSpinner, SettingsFilledIcon, WarningIcon } from 'swap-frontend-library';
import { getSelectionDetails, updateSelectionData } from '../../../../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import TotalCostCell from './components/TotalCostCell';
import CostSettingsModal from './components/CostSettingsModal';
import TextInput from '../../../../../../../../components/TextInput';
import SelectorDropdown from '../../../../../../../../components/SelectorDropdown';
import MainButton from '../../../../../../../../components/MainButton';

const ComparisonCostsImplementation = ({selectionDetails, vendorRanking, aggregateCosts, softwareProductFinancials, setOperatingSettingsElement} : {selectionDetails: any, vendorRanking:any[], aggregateCosts:any, softwareProductFinancials:any, setOperatingSettingsElement:React.Dispatch<React.SetStateAction<any>>}) => {
  const { t } = useTranslation();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [implementationCostsOpen, setImplementationCostsOpen] = useState(false);
  
  const [totalCosts, setTotalCosts] = useState<any>();
  
  // Settings
  const [initialNumberOfUsers, setInitialNumberOfUsers] = useState<any>();
  const [numberOfUsers, setNumberOfUsers] = useState<any>();
  const [timespan, setTimespan] = useState<string>("m");

  const handleUpdateCosts = () => {
    setTotalCosts((prev:any) => 
      Object.keys(softwareProductFinancials).reduce((prevReduce:any, curr:any) => {

        prevReduce[curr] = {
          projectManagementFees: softwareProductFinancials[curr].projectManagementFees || 0,
          consultingFees: softwareProductFinancials[curr].consultingFees || 0,
          dataMigrationFees: softwareProductFinancials[curr].dataMigrationFees || 0,
          interfaceFees: softwareProductFinancials[curr].interfaceFees || 0,
          trainingFees: softwareProductFinancials[curr].trainingFees || 0,
          otherImplementationFees: softwareProductFinancials[curr].otherImplementationFees || 0,
        };

        return prevReduce;
      }, Object.create(null))
    )
  }

  useEffect(() => {
    handleUpdateCosts();

    setNumberOfUsers(selectionDetails.numberOfUsers);
    setInitialNumberOfUsers(selectionDetails.numberOfUsers);
  }, [])
  
  useEffect(() => {
    handleUpdateCosts();
  }, [numberOfUsers, timespan])

  if (typeof totalCosts === "undefined") return <LoadingSpinner />

  return (
    <>
      <tr onClick={() => setImplementationCostsOpen(prev => !prev)}
        className='sticky top-56 z-[3] cursor-pointer'>
        <td className='sticky left-0 z-[3] py-2'>
            <div className={`h-24 sm:w-[400px] md:w-[600px] pl-2 flex items-center gap-4 bg-white/70 backdrop-blur-lg ml-4 border-l border-y border-primary rounded-l-lg text-xl font-bold text-primary`}>
                <div className={`w-4 h-4 ${implementationCostsOpen ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div>
                {t('comparisonCostsImplementation.totalImplementationCosts')}
                {/* <div onClick={(e) => {e.stopPropagation(); setSettingsOpen(prev => !prev)}} className={`relative ml-auto mr-4 w-10 h-10 rounded-full p-1.5 border border-primary group hover:bg-white hover:text-primary transition-all flex justify-center ${settingsOpen ? "bg-white text-primary" : "bg-primary text-white"}`}>
                    <SettingsFilledIcon />
                </div> */}
            </div>
        </td>
        {
            vendorRanking.map((vendorData:any, index:number) => {
                const lastVendor = index+1 == vendorRanking.length;
                const totalCost = {[vendorData.id]: (totalCosts[vendorData.id].projectManagementFees || 0) + (totalCosts[vendorData.id].consultingFees || 0) + (totalCosts[vendorData.id].dataMigrationFees || 0) + (totalCosts[vendorData.id].interfaceFees || 0) + (totalCosts[vendorData.id].trainingFees || 0) + (totalCosts[vendorData.id].otherImplementationFees || 0)};
                
                return (
                    <TotalCostCell vendorData={vendorData} aggregateCosts={totalCost} softwareProductFinancials={softwareProductFinancials} isLastItem={lastVendor} warningElement={
                      <>
                        {
                          (!softwareProductFinancials[vendorData.id].oneTimeFees && !softwareProductFinancials[vendorData.id].pricePerLicenceMonthly) ? 
                          <div title={t('comparisonCostsOperation.checkFinancialData')} className='absolute top-2 right-2 w-5 h-5 text-amber-600'><WarningIcon /></div> : 
                          <></>
                        }
                      </>
                    } />
                )
            })
        }
      </tr>

      {
        implementationCostsOpen ? 
        <>
          <ImplementationCost_projectManagementFees vendorRanking={vendorRanking} softwareProductFinancials={softwareProductFinancials} totalCosts={totalCosts} />
          <ImplementationCost_consultingFees vendorRanking={vendorRanking} softwareProductFinancials={softwareProductFinancials} totalCosts={totalCosts} />
          <ImplementationCost_dataMigrationFees vendorRanking={vendorRanking} softwareProductFinancials={softwareProductFinancials} totalCosts={totalCosts} />
          <ImplementationCost_interfaceFees vendorRanking={vendorRanking} softwareProductFinancials={softwareProductFinancials} totalCosts={totalCosts} />
          <ImplementationCost_trainingFees vendorRanking={vendorRanking} softwareProductFinancials={softwareProductFinancials} totalCosts={totalCosts} />
          <ImplementationCost_otherFees vendorRanking={vendorRanking} softwareProductFinancials={softwareProductFinancials} totalCosts={totalCosts} />
        </> :
        <></>
      }

      {/* <div className={`${settingsOpen ? "flex" : "hidden"} justify-center fixed right-5 bottom-5 z-[1000]`}><CostSettingsModal setOpen={setSettingsOpen}><SettingsOperatingCosts numberOfUsers={numberOfUsers} setNumberOfUsers={setNumberOfUsers} timespan={timespan} setTimespan={setTimespan} /></CostSettingsModal></div> */}
    </>
  )
}

const ImplementationCost_projectManagementFees = ({vendorRanking, softwareProductFinancials, totalCosts} : {vendorRanking:any[], softwareProductFinancials:any, totalCosts : any}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (typeof totalCosts === "undefined") return <LoadingSpinner />
  
  return (
    <tr onClick={() => setOpen(prev => !prev)} className='cursor-pointer'>
      <td className='sticky left-0 z-[2] bg-gray-50'>
          <div className={`bg-white mt-4 ml-4 h-20 sm:w-[400px] md:w-[600px] py-2 pl-2 pr-4 border-l border-t rounded-tl-lg ${open ? "" : "border-b rounded-bl-lg"} text-xl font-semibold flex items-center gap-2`}>
              {/* <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div> */}
              <div className={`w-4 h-4`}></div>
              <p>{t('comparisonCostsImplementation.projectManagementFees')}</p>
          </div>
      </td>

      {
          vendorRanking?.map((currentVendorRanking:any, index:number) => {
              return (
                  <td key={index} className=''>
                      <div className={`bg-white mt-4 h-20 w-40 py-2 border-t ${open ? "" : "border-b"} ${index+1 == vendorRanking.length ? `border-r ${open ? "rounded-tr-lg" : "rounded-r-lg"}` : ""} text-xl font-semibold flex items-center justify-center`}>
                        {totalCosts[currentVendorRanking.id].projectManagementFees.toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[currentVendorRanking.id].preferredCurrency })}
                      </div>
                  </td>
              )
          })
      }
    </tr>
  )
}

const ImplementationCost_consultingFees = ({vendorRanking, softwareProductFinancials, totalCosts} : {vendorRanking:any[], softwareProductFinancials:any, totalCosts : any}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (typeof totalCosts === "undefined") return <LoadingSpinner />
  
  return (
    <tr onClick={() => setOpen(prev => !prev)} className='cursor-pointer'>
      <td className='sticky left-0 z-[2] bg-gray-50'>
          <div className={`bg-white mt-4 ml-4 h-20 sm:w-[400px] md:w-[600px] py-2 pl-2 pr-4 border-l border-t rounded-tl-lg ${open ? "" : "border-b rounded-bl-lg"} text-xl font-semibold flex items-center gap-2`}>
              {/* <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div> */}
              <div className={`w-4 h-4`}></div>
              <p>{t('comparisonCostsImplementation.consultingFees')}</p>
          </div>
      </td>

      {
          vendorRanking?.map((currentVendorRanking:any, index:number) => {
              return (
                  <td key={index} className=''>
                      <div className={`bg-white mt-4 h-20 w-40 py-2 border-t ${open ? "" : "border-b"} ${index+1 == vendorRanking.length ? `border-r ${open ? "rounded-tr-lg" : "rounded-r-lg"}` : ""} text-xl font-semibold flex items-center justify-center`}>
                        {totalCosts[currentVendorRanking.id].consultingFees.toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[currentVendorRanking.id].preferredCurrency })}
                      </div>
                  </td>
              )
          })
      }
    </tr>
  )
}

const ImplementationCost_dataMigrationFees = ({vendorRanking, softwareProductFinancials, totalCosts} : {vendorRanking:any[], softwareProductFinancials:any, totalCosts : any}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (typeof totalCosts === "undefined") return <LoadingSpinner />
  
  return (
    <tr onClick={() => setOpen(prev => !prev)} className='cursor-pointer'>
      <td className='sticky left-0 z-[2] bg-gray-50'>
          <div className={`bg-white mt-4 ml-4 h-20 sm:w-[400px] md:w-[600px] py-2 pl-2 pr-4 border-l border-t rounded-tl-lg ${open ? "" : "border-b rounded-bl-lg"} text-xl font-semibold flex items-center gap-2`}>
              {/* <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div> */}
              <div className={`w-4 h-4`}></div>
              <p>{t('comparisonCostsImplementation.dataMigrationFees')}</p>
          </div>
      </td>

      {
          vendorRanking?.map((currentVendorRanking:any, index:number) => {
              return (
                  <td key={index} className=''>
                      <div className={`bg-white mt-4 h-20 w-40 py-2 border-t ${open ? "" : "border-b"} ${index+1 == vendorRanking.length ? `border-r ${open ? "rounded-tr-lg" : "rounded-r-lg"}` : ""} text-xl font-semibold flex items-center justify-center`}>
                        {totalCosts[currentVendorRanking.id].dataMigrationFees.toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[currentVendorRanking.id].preferredCurrency })}
                      </div>
                  </td>
              )
          })
      }
    </tr>
  )
}

const ImplementationCost_interfaceFees = ({vendorRanking, softwareProductFinancials, totalCosts} : {vendorRanking:any[], softwareProductFinancials:any, totalCosts : any}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (typeof totalCosts === "undefined") return <LoadingSpinner />
  
  return (
    <tr onClick={() => setOpen(prev => !prev)} className='cursor-pointer'>
      <td className='sticky left-0 z-[2] bg-gray-50'>
          <div className={`bg-white mt-4 ml-4 h-20 sm:w-[400px] md:w-[600px] py-2 pl-2 pr-4 border-l border-t rounded-tl-lg ${open ? "" : "border-b rounded-bl-lg"} text-xl font-semibold flex items-center gap-2`}>
              {/* <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div> */}
              <div className={`w-4 h-4`}></div>
              <p>{t('comparisonCostsImplementation.interfaceFees')}</p>
          </div>
      </td>

      {
          vendorRanking?.map((currentVendorRanking:any, index:number) => {
              return (
                  <td key={index} className=''>
                      <div className={`bg-white mt-4 h-20 w-40 py-2 border-t ${open ? "" : "border-b"} ${index+1 == vendorRanking.length ? `border-r ${open ? "rounded-tr-lg" : "rounded-r-lg"}` : ""} text-xl font-semibold flex items-center justify-center`}>
                        {totalCosts[currentVendorRanking.id].interfaceFees.toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[currentVendorRanking.id].preferredCurrency })}
                      </div>
                  </td>
              )
          })
      }
    </tr>
  )
}

const ImplementationCost_trainingFees = ({vendorRanking, softwareProductFinancials, totalCosts} : {vendorRanking:any[], softwareProductFinancials:any, totalCosts : any}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (typeof totalCosts === "undefined") return <LoadingSpinner />
  
  return (
    <tr onClick={() => setOpen(prev => !prev)} className='cursor-pointer'>
      <td className='sticky left-0 z-[2] bg-gray-50'>
          <div className={`bg-white mt-4 ml-4 h-20 sm:w-[400px] md:w-[600px] py-2 pl-2 pr-4 border-l border-t rounded-tl-lg ${open ? "" : "border-b rounded-bl-lg"} text-xl font-semibold flex items-center gap-2`}>
              {/* <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div> */}
              <div className={`w-4 h-4`}></div>
              <p>{t('comparisonCostsImplementation.trainingFees')}</p>
          </div>
      </td>

      {
          vendorRanking?.map((currentVendorRanking:any, index:number) => {
              return (
                  <td key={index} className=''>
                      <div className={`bg-white mt-4 h-20 w-40 py-2 border-t ${open ? "" : "border-b"} ${index+1 == vendorRanking.length ? `border-r ${open ? "rounded-tr-lg" : "rounded-r-lg"}` : ""} text-xl font-semibold flex items-center justify-center`}>
                        {totalCosts[currentVendorRanking.id].trainingFees.toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[currentVendorRanking.id].preferredCurrency })}
                      </div>
                  </td>
              )
          })
      }
    </tr>
  )
}

const ImplementationCost_otherFees = ({vendorRanking, softwareProductFinancials, totalCosts} : {vendorRanking:any[], softwareProductFinancials:any, totalCosts : any}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (typeof totalCosts === "undefined") return <LoadingSpinner />
  
  return (
    <tr onClick={() => setOpen(prev => !prev)} className='cursor-pointer'>
      <td className='sticky left-0 z-[2] bg-gray-50'>
          <div className={`bg-white my-4 mt-4 ml-4 h-20 sm:w-[400px] md:w-[600px] py-2 pl-2 pr-4 border-l border-t rounded-tl-lg ${open ? "" : "border-b rounded-bl-lg"} text-xl font-semibold flex items-center gap-2`}>
              {/* <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div> */}
              <div className={`w-4 h-4`}></div>
              <p>{t('comparisonCostsImplementation.otherImplementationCosts')}</p>
          </div>
      </td>

      {
          vendorRanking?.map((currentVendorRanking:any, index:number) => {
              return (
                  <td key={index} className=''>
                      <div className={`bg-white mt-4 h-20 w-40 py-2 border-t ${open ? "" : "border-b"} ${index+1 == vendorRanking.length ? `border-r ${open ? "rounded-tr-lg" : "rounded-r-lg"}` : ""} text-xl font-semibold flex items-center justify-center`}>
                        {totalCosts[currentVendorRanking.id].otherImplementationFees.toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[currentVendorRanking.id].preferredCurrency })}
                      </div>
                  </td>
              )
          })
      }
    </tr>
  )
}

export default ComparisonCostsImplementation