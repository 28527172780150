import React, { useEffect, useState } from 'react'
import { fetchRequirement } from '../../../../../../../../data/SoftwareFulfillmentRequests'
import { useTranslation } from 'react-i18next'

interface props {
    selectedRequirement: any
}

const RequirementForm = ({selectedRequirement}: props) => {
    const {t} = useTranslation();
    const [requirement, setRequirement] = useState<any>();
    const handleFetchRequirement = (requirementId: string) => {
        if (typeof requirementId !== "undefined") {
            fetchRequirement(requirementId).then(data => {
                if (data) {
                    setRequirement(data);
                }
            });
        }
    }

    useEffect(() => {
        handleFetchRequirement(selectedRequirement);
    }, [selectedRequirement])

  return (
    <div>
        <h3 className="mb-4 text-xl font-medium text-gray-900">Anforderung</h3>
        {/* {(typeof requirement !== "undefined") ? (requirement.keywords) : ("Leer")} */}
        <div className="max-h-[75vh] overflow-y-auto p-2 space-y-6">
            <div>
                <label className="block text-sm font-medium text-gray-900">{t("staticReqId")}</label>
                <input
                // <input onChange={(event) => {setProductName(event.target.value)}}
                placeholder={`${t("staticReqId")}`} value={(typeof requirement !== "undefined") ? (requirement.staticRequirementId) : ("")} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"  disabled required></input>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-900">{t("requirementLabel")}</label>
                <input
                // <input onChange={(event) => {setProductName(event.target.value)}}
                placeholder={`${t("requirementLabel")}`} value={(typeof requirement !== "undefined") ? (requirement.requirement) : ("")} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" disabled required></input>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-900">{t("category")}</label>
                <input
                // <input onChange={(event) => {setProductName(event.target.value)}}
                placeholder={`${t("category")}`} value={(typeof requirement !== "undefined") ? (requirement.category) : ("")} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" disabled required></input>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-900">{t("topKeywords")}</label>
                {/* <textarea onChange={(event) => {setProductDescription(event.target.value)}} */}
                <textarea 
                name="" id="" placeholder={`Top-Keywords`}  value={(typeof requirement !== "undefined") ? (JSON.parse(requirement.keywords).top_keywords) : ("")} rows={5} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" disabled required></textarea>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-900">{t("keywords")}</label>
                {/* <textarea onChange={(event) => {setProductDescription(event.target.value)}} */}
                <textarea 
                name="" id="" placeholder={`${t("keywords")}`}  value={(typeof requirement !== "undefined") ? (JSON.parse(requirement.keywords).keywords) : ("")} rows={5} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" disabled required></textarea>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-900">{t("description")}</label>
                {/* <textarea onChange={(event) => {setProductDescription(event.target.value)}} */}
                <textarea 
                name="" id="" placeholder={`${t("description")}`}  value={(typeof requirement !== "undefined") ? (requirement.description) : ("")} rows={5} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" disabled required></textarea>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-900">{t("industryWeights")}</label>
                {/* <textarea onChange={(event) => {setProductDescription(event.target.value)}} */}
                <textarea 
                name="" id="" placeholder={`${t("industryWeights")}`}  value={(typeof requirement !== "undefined") ? (requirement.industryWeights) : ("")} rows={5} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" disabled required></textarea>
            </div>
        </div>
    </div>
  )
}

export default RequirementForm