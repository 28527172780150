import React, { useState } from 'react'
import MainButton from '../../../../../../components/MainButton'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequirementsFinished = ({ setOpen } : props) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div className='w-full min-h-[50vh] flex flex-col justify-between '>
            
        <div className='grow flex items-center'>
            
            <div className='w-full h-fit flex flex-col items-center'>
                <img src="/img/graphics/celebration.png" className='h-64 mb-16' />
                <p className='text-5xl font-bold text-center'>{t('requirementsFinished.title')}</p>
                <p className='max-w-64 whitespace-normal mt-10 px-16 font-normal text-center'>{t('requirementsFinished.description')}</p>
            </div>
            
        </div>
        
        <div className='my-10 gap-2 flex justify-center items-center'>
            <MainButton text={t('requirementsFinished.continue')} link={location.pathname.replace("vendor-ranking", "/")} additionalStyles='bg-amber-400 hover:shadow-amber-400' />
        </div>

    </div>
  )
}

export default RequirementsFinished