import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import FormModal from '../../../../../components/FormModal';
import StartSelectionForm from './components/StartSelectionForm';
import { getSelections } from '../../../../../data/SelectionPortalRequests';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { InfoIcon, LoadingSpinner, PlusIcon } from 'swap-frontend-library';
import SelectionLogo from './components/SelectionLogo';
import MainButton from '../../../../../components/MainButton';
import { setIsOrgAdmin } from '../../../../../redux/userSlice';

const SelectionCockpit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [startSelectionFormOpen, setStartSelectionFormOpen] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState<string | null>(null);

  const isOrgAdmin = useSelector((state: RootState) => state.user.isOrgAdmin);
  const email = useSelector((state: RootState) => state.user.email);

  const [personalActiveSelections, setPersonalActiveSelections] = useState<any[]>();
  const [customerDetailsSelections, setCustomerDetailsSelections] = useState<any[]>();
  // const [isOrgAdmin, setIsOrgAdmin] = useState(false);


  const handleFetchSelections = async () => {
    const response = await getSelections(email);
    // console.log(response.data.data)
    setPersonalActiveSelections(response.data.data.personalActiveSelections);
    setCustomerDetailsSelections(response.data.data.customerDetailsSelections);
  }

  useEffect(() => {handleFetchSelections();}, [])

  return (
    <div className='p-4 w-full h-full overflow-y-auto'>
      {(startSelectionFormOpen) ? (<FormModal isOpen={startSelectionFormOpen} setOpen={setStartSelectionFormOpen} size="regular"><StartSelectionForm customerSearchCustomerDetailsId={selectedSearch}/></FormModal>) : (<div className="hidden"></div>)}
        <div className='w-full grow'>
          {
            (typeof personalActiveSelections === "undefined" || typeof customerDetailsSelections === "undefined") ? (<LoadingSpinner />) : (
              <div className='space-y-8'>
                <div>
                  <div className="mb-4 col-span-full xl:mb-2 flex items-center gap-4">
                    <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                      {t('selectionCockpit.yourOngoingSelections')}
                    </h1>

                    {
                      isOrgAdmin ?
                      <div className='w-fit'>
                        <MainButton height='h-8' text={t(`add`)} style='light' func={() => {setSelectedSearch(null); setStartSelectionFormOpen(true);}} icon={<div className='w-4 h-4 text-primary'><PlusIcon /></div>} />
                      </div> :
                      <></>

                    }
                    
                  </div>
                  {
                    personalActiveSelections.length == 0 ? 
                    <div className='w-full bg-white border rounded-lg h-64 flex flex-col items-center justify-center'>
                      <div className='w-10 h-10 mb-4 text-gray-400'><InfoIcon /></div>
                      <p className='text-gray-600'>{t('selectionCockpit.noProjectAvailable')}</p>

                      {
                        isOrgAdmin ? 
                        <div className='mt-8'>
                          <MainButton text={t('selectionCockpit.startNewSelection')} func={() => {setSelectedSearch(null); setStartSelectionFormOpen(true);}} icon={<div className='w-6 h-6 text-white'><PlusIcon /></div>} />
                        </div> : <></>

                      }
                    </div> :
                    <div className='grid grid-cols-3 gap-4 max-md:grid-cols-1 mt-8'>
                      {
                        personalActiveSelections.filter((item:any) => item.selectionStatus == "ongoing" || item.selectionStatus == "paused").map((value:any, index:number) => {
                          const createdDateParsed : Date = new Date(Date.parse(value.createdDate));
                          const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;
    
                          return (
                            <>
                            <div onClick={() => {navigate(`/user-area/selection-portal/${value.id}`)}}
                            className="relative flex items-end h-64 w-full bg-white border shadow cursor-pointer transition-all hover:shadow-lg rounded-lg">
                              <div className="p-5 text-lg font-semibold text-left text-gray-900 w-full h-64 truncate flex flex-col items-start justify-end">
                                <div className='absolute -top-3 right-4 bg-white rounded-full border-2 px-2 text-sm font-light'>{value.selectionSoftwareCategory}</div>
                                <SelectionLogo selection={value} />
                                <div className='w-32'>
                                  <p className='mt-4'>{value.selectionTitle || value.customerSearchCustomerDetails?.customerSearch.queryUrl || t('selectionCockpit.untitledSelection')}</p>
                                  <p className="mt-1 text-sm font-normal text-gray-500">
                                    {t('selectionCockpit.phaseStatus', { phase: value.selectionPhase[0].toUpperCase() + value.selectionPhase.slice(1), status: value.selectionStatus[0].toUpperCase() + value.selectionStatus.slice(1) })}
                                  </p>
                                  <p className="mt-1 text-sm font-normal text-gray-500">
                                    {t('selectionCockpit.createdDate', { date: createdDateString, company: value.customerDetails == null ? "Guest" : value.customerDetails.company })}
                                  </p>
                                </div>
                              </div>
                            </div>
                            </>
                          )
                        })
                      }
                    </div>
                  }

                  {/* <div onClick={() => {setSelectedSearch(null); setStartSelectionFormOpen(true);}}
                  className="relative flex items-center justify-center border h-64 w-full bg-white shadow cursor-pointer transition-all hover:scale-[1.02] rounded-lg">
                    <div className='w-32 h-32 text-gray-400'><PlusIcon /></div>
                  </div> */}

                </div>

              </div>
            ) 
          }
        </div>
    </div>
  )
}

export default SelectionCockpit