import React, { useEffect, useState } from 'react'
import { PlusIcon, RefreshIcon, SearchBar, TableHeader, TableRow } from 'swap-frontend-library'
import { deletePlatformProduct, getPlatformProducts } from '../../../../../../data/PlatformRequests';
import PlatformProductForm from './forms/PlatformProductForm';
import FormModal from '../../../../../../components/FormModal';
import { useTranslation } from 'react-i18next';

const PlatformProductPricingSettings = () => {
  const {t} = useTranslation();

  const [platformProducts, setPlatformProducts] = useState<any[]>();
  const [productFormOpen, setProductFormOpen] = useState(false);
  const [openProductId, setOpenProductId] = useState<string>();
  const [openAdvancedOptions, setOpenAdvancedOptions] = useState(false);

  const handleFetchProducts = async () => {
    const response = await getPlatformProducts();
    setPlatformProducts(response)
  }

  useEffect(() => {
    handleFetchProducts();
  }, [])

  const handleOpenProduct = (id : string) => {
    setOpenProductId(id);
    setProductFormOpen(true);
  }

  const handleDeleteProduct = async (id : string) => {
    const success = await deletePlatformProduct(id);
    console.log("Deleting product with id: ", id, "Success: ", success);
    if (success) {
      handleFetchProducts();
    }

  }

  return (
    <>
      {(productFormOpen) ? (<FormModal isOpen={productFormOpen} setOpen={setProductFormOpen} size="regular"><PlatformProductForm openProductId={openProductId} handleFetchProducts={handleFetchProducts} /></FormModal>) : (<div className="hidden"></div>)}
      <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
            Product pricing
            <p className="mt-1 text-sm font-normal text-gray-500">Set product pricing</p>
        </div>

        <div
            className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200"
        >
        <div className="w-full mb-1">
            <div className="items-center justify-between block sm:flex">
                <div className='sm:w-64 xl:w-96'>
                    {/* <SearchBar translatedPlaceholderItem={`${t("progressCard.productText")}`} originalData={softwareProductData} setFilteredData={setFilteredData} filterKey={"productName"} /> */}
                </div>
            
            <div className="flex items-center h-[40px] ml-auto space-x-2 sm:space-x-3">
                <button
                onClick={() => handleFetchProducts()}
                type="button"
                data-refresh
                className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                >
                <div className='w-4 h-4 mr-2'><RefreshIcon /></div>
                {t("refresh")}
                </button>
                <button
                onClick={() => {setOpenProductId(undefined);setProductFormOpen(true)}}
                id="createProductButton"
                className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto"
                type="button"
                >
                <div className='w-4 h-4 mr-2'><PlusIcon /></div>
                {t("add")}
                </button>
            </div>
            </div>
        </div>
        </div>

        <div className='bg-white space-y-4'>
          <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <TableHeader header={["Product code", "Name", "Type", "Price", "Discount (%)", "Highlight", "Description", "Features", "Link", ""]} />
                </thead>
                {
                    (typeof platformProducts === "undefined" || platformProducts.length == 0) ? (<tbody className='text-center'><tr><td colSpan={6} className='h-16'>No invite codes available...</td></tr></tbody>) : (
                      platformProducts.sort((a:any, b:any) => (a.type < b.type ? -1 : 1)).map(({id, productCode, name, type, price, discountPercent, highlight, description, features, linkToAdditionalProductInfo} : any, index : number) => {
                            // const createdDateParsed : Date = new Date(Date.parse(createdDate));
                            // const validUntilDateParsed : Date = new Date(Date.parse(activated));
                            // const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}-${createdDateParsed.getHours()}:${createdDateParsed.getMinutes()}`;
                            // const validUntilDateString : string = `${validUntilDateParsed.getDate()}.${validUntilDateParsed.getMonth()+1}.${validUntilDateParsed.getFullYear()}-${validUntilDateParsed.getHours()}:${validUntilDateParsed.getMinutes()}`;
                            return (
                                <tbody key={index} className="cursor-pointer" onClick={() => handleOpenProduct(id)}>
                                    <TableRow 
                                        rowItems={[index, productCode, name, type, price, discountPercent, String(highlight), String(description != null && description.length > 0), String(features != null && features.length > 0), String(linkToAdditionalProductInfo != null && linkToAdditionalProductInfo.length > 0), (
                                          <div>
                                            <button onClick={(e) => {e.stopPropagation(); handleDeleteProduct(id)}} className="text-red-500 hover:text-red-700">Delete</button>
                                          </div>
                                        ) ]}
                                        // deleteHandler={() => {handleOpenDeleteModal({id,inviteCode})}}
                                    />
                                </tbody>
                            )
                        })
                    )
                }
            </table>
            {/* <NavPagination totalItems={filteredData.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} /> */}
        </div>

      </div>
    </>
  )
}

export default PlatformProductPricingSettings