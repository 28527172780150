import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, CheckmarkIcon, getImage, InfoIcon, LoadingSpinner, MainButton, MessageIcon, TextInput } from 'swap-frontend-library';
import { getPresentationEvaluationOverall, getSelectionPresentations, getSelectionScenarioItems } from '../../../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';

const ComparisonPresentations = () => {
    const { t } = useTranslation();
    const { selectionId } = useOutletContext<any>();    
    const [selectionPresentations, setSelectionPresentations] = useState<any>();
    const [scenarios, setScenarios] = useState<any>();
    const [scenarioEvaluations, setScenarioEvaluations] = useState<any>();
    const [error, setError] = useState("");

    const handleGetSelectionPresentations = async () => {
        const response = await getSelectionPresentations(selectionId);
        if (response.ok) {
            const presentations = response.data.data;
            setSelectionPresentations(presentations);
        }
    };

    const handleFetchScenarios = async () => {
        const response = await getSelectionScenarioItems(selectionId);
        if (response.ok) {
            setScenarios(response.data.data);
        }
    };

    const handleFetchScenarioEvaluations = async () => {
        const presentationResults = await Promise.all(selectionPresentations.map(async (presentation: any) => {
            const response = await getPresentationEvaluationOverall(presentation.id, undefined);
            if (response.ok) {
                setError("");
                return response.data.data;
            }
        }));
        setScenarioEvaluations(presentationResults);
    };

    useEffect(() => {
        handleGetSelectionPresentations();
        handleFetchScenarios();
    }, []);

    useEffect(() => {
        if (typeof selectionPresentations !== "undefined" && selectionPresentations.length > 0) {
            handleFetchScenarioEvaluations();
        } else {
            setError("1");
        }
    }, [selectionPresentations]);

    if (error) {
        return (
            <div className='m-4 bg-white shadow rounded-lg p-8 space-y-8 text-gray-700'>
                <p className='text-xl font-semibold'>{t('comparisonPresentations.error.title')}</p>
                <p>{t('comparisonPresentations.error.description')}</p>
            </div>
        );
    }

    if (typeof scenarios === "undefined" || typeof scenarioEvaluations === "undefined" || typeof selectionPresentations === "undefined") return <LoadingSpinner />;
    return (
        <div className='space-y-8'>
            <div className='p-4 grid grid-cols-12 pl-6 sticky top-0 z-[3] bg-white/30 backdrop-blur shadow'>
                <div className='col-span-4'>
                </div>
                <div className='col-span-8 flex gap-4'>
                    {
                        selectionPresentations.map((presentation: any) => <VendorColumn vendorData={presentation} />)
                    }
                </div>
            </div>
            <div className='p-4'>
                {
                    [...scenarios, ...scenarios].map((scenario: any, index: number) => {
                        return (
                            <ScenarioRow scenario={scenario} scenarioEvaluations={scenarioEvaluations} index={index} />
                        );
                    })
                }
            </div>
        </div>
    );
};

const VendorColumn = ({ vendorData }: { vendorData: any }) => {
    const { t } = useTranslation();
    const [imageSource, setImageSource] = useState("");
    const handleFetchImage = async () => {
        getImage(vendorData.selectionSoftwareProduct.softwareProduct.id, "/api/product/get-logo?id=", '').then(frontendImageLocation => { setImageSource(frontendImageLocation); });
    };
    useEffect(() => { handleFetchImage(); }, []);

    const productName = vendorData.selectionSoftwareProduct.softwareProduct.productName;
    const vendorName = vendorData.selectionSoftwareProduct.softwareProduct.vendorDetails.company;
    return (
        <div className='w-40 h-40 pb-2 bg-white rounded-lg shadow border space-y-2 flex flex-col justify-end'>
            {
                (imageSource == "") ? (
                    <></>
                ) : (
                    <img className="h-8 w-full object-contain p-1" src={imageSource} />
                )
            }
            <div className='text-center'>
                <p className='font-bold line-clamp-2'>{productName}</p>
                <p className='font-light text-sm line-clamp-2'>{vendorName}</p>
            </div>
        </div>
    );
};

const ScenarioRow = ({ scenario, scenarioEvaluations, index }: { scenario: any, scenarioEvaluations: any, index: number }) => {
    const { t } = useTranslation();
    const [sortedUniqueCategories, setSortedUniqueCategories] = useState<any[]>();

    const handleGetUniqueScenarioCategories = () => {
        const cateogories = scenario.selectionScenarioTasks.map((item: any) => item.selectionScenarioTaskCategory);
        const cateogoryIds = scenario.selectionScenarioTasks.map((item: any) => item.selectionScenarioTaskCategory.id);
        const uniqueCategories = cateogories.filter((item: any, index: number) => (cateogoryIds.indexOf(item.id) === index));
        const newSortedUniqueCategories = uniqueCategories.sort((a: any, b: any) => a.orderNumber - b.orderNumber);
        setSortedUniqueCategories(newSortedUniqueCategories);
    };

    useEffect(() => {
        handleGetUniqueScenarioCategories();
    }, [scenario]);

    return (
        <div className='flex flex-col'>
            <div className='flex items-end'>
                <p className="rounded text-xl font-bold tracking-tight leading-9 text-gray-900">{t('scenarioRow.title', { index: index + 1, title: scenario.title })}</p>
            </div>
            {
                typeof sortedUniqueCategories === "undefined" ? <></> : sortedUniqueCategories.map((category: any) => (
                    <ScenarioCategoryRow scenarioCategory={category}>
                        {
                            scenario.selectionScenarioTasks.filter((item: any) => item.selectionScenarioTaskCategory.id == category.id).map((task: any) => (
                                <ScenarioTaskRow scenarioTask={task} presentations={scenarioEvaluations} />
                            ))
                        }
                    </ScenarioCategoryRow>
                ))
            }
        </div>
    );
};

const ScenarioCategoryRow = ({ children, scenarioCategory }: { children: any, scenarioCategory: any }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);
    return (
        <div className='flex flex-col gap-2 mt-4 bg-white p-4 rounded-lg shadow'>
            <div onClick={() => setOpen(prev => !prev)} className='flex items-center gap-2 cursor-pointer'>
                <p className='font-semibold'>{scenarioCategory.title}</p>
                <div className={`w-4 h-4 ${open ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
            </div>
            {
                open ? (
                    <div className='flex flex-col gap-2'>
                        {children}
                    </div>
                ) : (<></>)
            }
        </div>
    );
};

interface scenarioTaskRowProps {
    scenarioTask: any, 
    presentations: any, 
}

const ScenarioTaskRow = ({ scenarioTask, presentations }: scenarioTaskRowProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    return (
        <div onClick={() => setOpen(prev => !prev)} className={`cursor-pointer w-full rounded-lg shadow p-4 flex flex-col gap-4 bg-white`}>
            <div className={`grid grid-cols-12 gap-4`}>
                <div className={`${open ? "" : "truncate"} col-span-4 flex`}>
                    <span className='font-semibold inline'>{scenarioTask.title}:</span> {scenarioTask.description}
                </div>
                <div className='col-span-8 flex gap-4'>
                    {
                        presentations.map((presentation: any) => {
                            const evaluation = presentation.evaluations[scenarioTask.id];
                            let averageScore = 0;
                            if (typeof evaluation !== "undefined") averageScore = evaluation.statistics.sumRatings / evaluation.statistics.numRatings;
                            return (
                                <div className='w-40'>
                                    <div className={`relative group flex items-center justify-center items-center`}>
                                        <div className='text-center'>
                                            {t('scenarioTaskRow.averageScore', { score: averageScore.toFixed(1) })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default ComparisonPresentations;