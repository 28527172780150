import { CrossIcon } from "swap-frontend-library"

const CostSettingsModal = ({ children, setOpen }: { children: React.ReactNode, setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
    return (
        <div onClick={(e) => e.stopPropagation()} className='bg-white/90 backdrop-blur-lg shadow-lg border border-primary rounded-lg text-gray-700 cursor-auto max-md:pr-8'>
            <div className="absolute w-8 h-8 top-2 right-2 hover:bg-gray-100 text-gray-400 p-1 rounded-full cursor-pointer" onClick={() => setOpen(false)}><CrossIcon /></div>
            {children}
        </div>
    )
}

export default CostSettingsModal