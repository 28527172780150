import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../../../components/TextInput';
import SearchIcon from '../../../../../../assets/img/icons/SearchIcon';
import CrossIcon from '../../../../../../assets/img/icons/CrossIcon';
import RocketIcon from '../../../../../../assets/img/icons/RocketIcon';
import MainButton from '../../../../../../components/MainButton';
import BriefcaseIcon from '../../../../../../assets/img/icons/BriefcaseIcon';

import importedHelpItems from "./temp_help.json";
import Fuse from 'fuse.js';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import ArrowLeftIcon from '../../../../../../assets/img/icons/ArrowLeftIcon';
import ConsultantMarketplaceWindow from './ConsultantMarketplaceWindow';
import i18n from '../../../../../../18n';

interface helpDeskWindowCardProps {
  helpItem : any
  currentContext?: string
  setSelectedHelpDetails : React.Dispatch<React.SetStateAction<any>>,
}

const HelpDeskWindowCard = ({ helpItem, currentContext, setSelectedHelpDetails } : helpDeskWindowCardProps) => {
  const currentLanguage = i18n.language;

  return (
    <div key={helpItem.id} onClick={() => setSelectedHelpDetails(helpItem)} className={`${(helpItem.phase == currentContext) ? "border-primary" : ""} xl:h-52 h-32 border w-full p-1 xl:p-4 rounded-lg shadow hover:shadow-md flex flex-col justify-center cursor-pointer`}>
      <p className={`${(helpItem.phase == currentContext) ? "text-primary" : ""} font-light text-sm italic text-center`}>{helpItem.phase[0].toUpperCase()}{helpItem.phase.slice(1)}</p>
      <p className={`${(helpItem.phase == currentContext) ? "text-primary" : ""} font-bold text-center line-clamp-2 mb-2`}>{helpItem[`title_${currentLanguage}`]}</p>
      <p className='text-center line-clamp-4 hidden xl:block'>{helpItem[`description_${currentLanguage}`]}</p>
    </div>
  )
}

interface helpDeskWindowProps {
  setHelpDeskOpen : React.Dispatch<React.SetStateAction<boolean>>,
  currentContext?: string
}

const HelpDeskWindow = ({ setHelpDeskOpen, currentContext } : helpDeskWindowProps) => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const [searchTerm, setSearchTerm] = useState<string | null>("");
  const [helpItems, setHelpItems] = useState<any[]>(importedHelpItems);
  const [configuredFuse, setConfiguredFuse] = useState<Fuse<any>>();
  const [filteredHelpItems, setFilteredHelpItems] = useState<any[]>();

  const [selectedHelpDetails, setSelectedHelpDetails] = useState<any>(); 
  const [consultantMarketplaceOpen, setConsultantMarketplaceOpen] = useState(false); 
  
  useEffect(() => {
    const fuseOptions = {
      keys: [ "title", "description", "content", "phase" ],
      threshold: .6
    }
    const fuse = new Fuse(helpItems, fuseOptions);
    setConfiguredFuse(fuse);
  }, [])
  
  const handleFilterHelpItems = () => {
    if (typeof configuredFuse === "undefined") return;
    if (searchTerm == "" || searchTerm == null) {setFilteredHelpItems(helpItems); return;}
    
    const filteredList = configuredFuse.search(searchTerm);
    setFilteredHelpItems(filteredList.map((item:any) => item.item));
  }
  
  useEffect(handleFilterHelpItems, [])
  useEffect(handleFilterHelpItems, [configuredFuse])
  useEffect(handleFilterHelpItems, [searchTerm])

  return (
    <div className='absolute flex flex-col p-4 right-0 bottom-28 bg-white border-2 w-[70vw] lg:w-[35vw] h-[70dvh] xl:h-[60vh] shadow-lg rounded-lg'>
        <div className='w-full relative'>
          <div onClick={() => setHelpDeskOpen(false)} className='ml-auto w-8 h-8 p-1 hover:bg-gray-50 rounded-full cursor-pointer'>
            <CrossIcon />
          </div>
        </div>

        {
          typeof selectedHelpDetails !== "undefined" ? (
            <>
            <div onClick={() => setSelectedHelpDetails(undefined)} className='absolute w-fit h-8 my-1 text-gray-900 space-x-2 cursor-pointer p-2 rounded-md flex items-center bg-white hover:bg-gray-100'>
              <ArrowLeftIcon iconColor='currentColor' />
              <div>{t('helpDesk.back')}</div>
            </div>

            <div className='my-4 px-8'>
              <p className={`${(selectedHelpDetails.phase == currentContext) ? "text-primary" : ""} font-light text-sm italic text-center`}>
                {selectedHelpDetails.phase[0].toUpperCase()}{selectedHelpDetails.phase.slice(1)}
              </p>
              <p className={`${(selectedHelpDetails.phase == currentContext) ? "text-primary" : ""} font-bold text-center mb-2`}>
                {selectedHelpDetails[`title_${currentLanguage}`]}
              </p>
              <p className='text-center italic'>{selectedHelpDetails[`description_${currentLanguage}`]}</p>
            </div>

            <div className='p-4 px-8 grow overflow-y-auto border-b border-t'>
                {
                  selectedHelpDetails[`content_${currentLanguage}`].split("\n").map((paragraph:string, index:number) => (
                    <p className='mb-8'>{paragraph}</p>
                  ))
                }
            </div>
            </>
          ) : (consultantMarketplaceOpen) ? (
            <ConsultantMarketplaceWindow setConsultantMarketplaceOpen={setConsultantMarketplaceOpen} />
          ) : (
            <>
              <div className='w-full flex flex-col items-center text-center text-md xl:text-2xl font-bold text-primary'>
                <div className='mb-2 xl:mb-4 w-10 h-10 xl:w-16 xl:h-16 border-2 border-primary text-primary rounded-full p-1 xl:p-2'>
                  <RocketIcon iconColor='currentColor' />
                </div>
                {t('helpDesk.howCanWeHelp')}
              </div>
      
              <div className='mt-2 xl:mt-4'>
                <TextInput 
                  textValue={searchTerm} 
                  setTextValue={setSearchTerm} 
                  placeholder={t('helpDesk.searchPlaceholder')} 
                  icon={<SearchIcon iconColor='currentColor' iconWidth='w-full' iconHeight='h-full' />} 
                />
              </div>
      
              {(typeof filteredHelpItems === "undefined") ? (
                <div className='grow w-full flex items-center justify-center'>
                  <LoadingSpinner />
                </div>
              ) : (
                <div className='p-4 grow overflow-y-auto mt-2 xl:mt-4 grid grid-cols-2 gap-4 border-b border-t'>
                  {
                    filteredHelpItems.filter((item:any) => item.phase == currentContext).map((item:any, index:number) => (
                      <HelpDeskWindowCard helpItem={item} currentContext={currentContext} setSelectedHelpDetails={setSelectedHelpDetails} />
                    ))
                  }
                  {
                    filteredHelpItems.filter((item:any) => item.phase != currentContext).map((item:any, index:number) => (
                      <HelpDeskWindowCard helpItem={item} setSelectedHelpDetails={setSelectedHelpDetails} />
                    ))
                  }
                </div>
              )}
            </>
          )
        }

        {/* {
          consultantMarketplaceOpen ? (<></>) : (
            <div className='w-full mt-8 text-white'>
              <MainButton 
                text={t('helpDesk.getExpertHelp')} 
                link={''} 
                icon={<div className='w-6 h-6'><BriefcaseIcon /></div>} 
                func={() => {setSelectedHelpDetails(undefined); setConsultantMarketplaceOpen(true)}} 
              />
            </div>
          )
        } */}
    </div>
  )
}

export default HelpDeskWindow;