import React from 'react';
import { createBrowserRouter, Outlet } from "react-router-dom";

// Layout and default pages
import RootLayout from './pages/other/root-layout/Index';
import ErrorPage from './pages/other/error-page/Index';

// Account management
import Signin from './pages/other/auth/sign-in/Index';
import Signout from './pages/other/auth/sign-out/Index';

// Product
import SoftwareFulfillmentSurvey, {loader as softwareFulfillmentLoader} from './pages/user-area/features/management-console/software-fulfillment-survey/Index';

// Onboarding
import VendorOnboardingIndex from './pages/user-area/features/management-console/vendor-onboarding/Index';
import VendorOnboardingLanding from './pages/user-area/features/management-console/vendor-onboarding/features/OnboardingLanding';
import VendorOnboardingAccount from './pages/user-area/features/management-console/vendor-onboarding/features/OnboaringCreateAccount';
import VendorOnboardingProduct from './pages/user-area/features/management-console/vendor-onboarding/features/OnboardingProducts';
import VendorOnboardingFinsihed from './pages/user-area/features/management-console/vendor-onboarding/features/OnboardingFinished';

// Console
import UserAreaRoot from './pages/user-area/Index';
import Dashboard from './pages/user-area/features/Dashboard';
import Inbox from './pages/user-area/features/selection-portal/inbox/Index';
import Shop from './pages/user-area/features/management-console/Shop';
import ProfileSettings from './pages/user-area/features/management-console/ProfileSettings';
import ManagementConsole from './pages/user-area/features/management-console/Index';

// Vendor
import LeadsManager from './pages/user-area/features/leads-manager/LeadsManager';
import PayForLeadModal from './pages/user-area/features/leads-manager/components/PayForLeadModal'
import SoftwareEditor from './pages/user-area/features/management-console/object-settings/SoftwareEditor';

// Admin Console
import VendorsAdmin from './pages/user-area/features/management-console/VendorsAdmin';
import CustomersAdmin from './pages/user-area/features/management-console/object-settings/CustomersAdmin';
import SalesAdmin from './pages/user-area/features/management-console/SalesAdmin';
import ManagerAdmin from './pages/user-area/features/management-console/object-settings/ManagerAdmin';
import MatchingLogicManager from './pages/user-area/features/management-console/matching-logic/MatchingConficManager';

// Selection Portal
import SelectionPortalRoot, {loader as selectionPortalLoader} from './pages/user-area/features/selection-portal/Index';
import SelectionCockpit from './pages/user-area/features/selection-portal/selection-cockpit/Index';
import RequirementManager from './pages/user-area/features/selection-portal/requirement-manager/Index';
import ScenarioManager from './pages/user-area/features/selection-portal/scenario-manager/Index';
import PresentationManager from './pages/user-area/features/selection-portal/presentation-manager/Index';
import SelectionSettings from './pages/user-area/features/selection-portal/selection-settings/SelectionSettings';
import VendorRankingIndex from './pages/user-area/features/selection-portal/vendor-ranking/Index';
import VendorRankingRanking from './pages/user-area/features/selection-portal/vendor-ranking/features/Ranking';
import VendorRankingComparison from './pages/user-area/features/selection-portal/vendor-ranking/features/Comparison';

import ContractManager from './pages/user-area/features/selection-portal/contract-manager/Index';
import DocumentOverview from './pages/user-area/features/selection-portal/contract-manager/features/DocumentOverview';
import DocumentReader from './pages/user-area/features/selection-portal/contract-manager/features/DocumentReader';

import Protected from './pages/other/root-layout/features/ProtectedRoute';
import OverviewLogic from './pages/user-area/features/management-console/matching-logic/1_overview/Index';
import RequirementConfig from './pages/user-area/features/management-console/matching-logic/2_algorithms/requirementConfig/Index';
import FactConfig from './pages/user-area/features/management-console/matching-logic/2_algorithms/factConfig/Index';
import MatchingSettings from './pages/user-area/features/management-console/matching-logic/3_settings/Index';
import RedirectUserArea from './pages/other/root-layout/features/RedirectUserArea';
import AnalyticsInjector from './_analytics/AnalyticsInjector';

import PresentationOverview from './pages/user-area/features/selection-portal/presentation-manager/features/presentation-overview/PresentationOverview';
import ParticipationScreen, {loader as presentationIdLoader} from './pages/user-area/features/selection-portal/presentation-manager/features/participation-screen/ParticipationScreen';
import ResultEvaluationScreen, {loader as presentationIdLoaderResults} from './pages/user-area/features/selection-portal/presentation-manager/features/result-evaluation-screen/ResultEvaluationScreen';
import PlatformSettings from './pages/user-area/features/management-console/platform-settings/PlatformSettings';

// Customer-Portal
import SelectionBrowser from './pages/user-area/features/customer-portal/selection-browser/SelectionBrowser';
import MatchingOverview from './pages/user-area/features/customer-portal/matching-area/MatchingOverview/MatchingOverview';
import MatchingAnalysis from './pages/user-area/features/customer-portal/matching-area/MatchingAnalysis/MatchingAnalysis';

const router = createBrowserRouter([
    {
    path: "/",
    element: <AnalyticsInjector><RootLayout /></AnalyticsInjector>,
    errorElement: <ErrorPage />,
    children: [
        {
        path: "/",
        element: <RedirectUserArea />,
        },
        {
        path: "/signin",
        element: <Protected protectedIfLoggedIn={true} allowedRoles={["customer", "vendor", "platform_manager"]} ><Signin /></Protected>,
        },
        {
        path: "/signout",
        element: <Signout />,
        },
        {
        path: "/product/software-fulfillment-survey/:softwareFulfillmentId?",
        element: <Protected protectedIfLoggedIn={false} allowedRoles={["vendor", "platform_manager"]} ><SoftwareFulfillmentSurvey softwareFulfillmentIdProp="" exitAction={undefined}  /></Protected>,
        loader: softwareFulfillmentLoader
        },
        {
        path: "/onboarding",
        element: <VendorOnboardingIndex />,
        children: [
            {
            index: true,
            element: <Protected protectedIfLoggedIn={true} allowedRoles={[]} ><VendorOnboardingLanding /></Protected>
            },
            {
            path: "create-account",
            element: <Protected protectedIfLoggedIn={true} allowedRoles={["vendor"]} ><VendorOnboardingAccount /></Protected>
            },
            {
            path: "create-product",
            element: <Protected protectedIfLoggedIn={false} allowedRoles={["vendor"]} ><VendorOnboardingProduct /></Protected>
            },
            {
            path: "next-steps",
            element: <Protected protectedIfLoggedIn={false} allowedRoles={["vendor"]} ><VendorOnboardingFinsihed /></Protected>
            },
        ]
        },
        {
            path: "/user-area",
            element: <Protected protectedIfLoggedIn={false} allowedRoles={["customer", "vendor", "platform_manager", "guest_vendor", "guest_seeker"]} ><UserAreaRoot /></Protected>,
            children: [
                {
                index: true, 
                element: <Dashboard />
                },
                {
                path: "selection-browser",
                element: <Protected protectedIfLoggedIn={false} allowedRoles={["customer", "platform_manager"]} ><SelectionBrowser /></Protected>
                },
                {
                path: "matching-area",
                element: <Protected protectedIfLoggedIn={false} allowedRoles={["customer", "platform_manager"]} ><Outlet /></Protected>,
                children: [
                    {
                    path: "overview",
                    element: <MatchingOverview />
                    },
                    {
                    path: "analysis",
                    element: <MatchingAnalysis />
                    }
                ]
                },
                {
                path: "shop",
                element: <Protected protectedIfLoggedIn={false} allowedRoles={["customer", "vendor", "platform_manager"]} ><Shop /></Protected>
                },
                {
                path: "leads",
                element: <Protected protectedIfLoggedIn={false} allowedRoles={["vendor", "platform_manager"]} ><LeadsManager /></Protected>,
                children: [
                    { 
                    path: "unlock", 
                    element: <PayForLeadModal />
                    },
                ]
                },
                {
                path: "sales",
                element: <Protected protectedIfLoggedIn={false} allowedRoles={["platform_manager"]} ><SalesAdmin /></Protected>
                },
                {
                path: "profile-settings",
                element: <Protected protectedIfLoggedIn={false} allowedRoles={["customer", "vendor", "platform_manager"]} ><ProfileSettings /></Protected>
                },
                {
                    path: "settings",
                    element: <Protected protectedIfLoggedIn={false} allowedRoles={["vendor", "platform_manager"]} ><ManagementConsole /></Protected>,
                    children: [
                        {
                        path: "software",
                        element: <Protected protectedIfLoggedIn={false} allowedRoles={["vendor", "platform_manager"]} ><SoftwareEditor /></Protected>
                        },
                        {
                        path: "vendors",
                        element: <Protected protectedIfLoggedIn={false} allowedRoles={["platform_manager"]} ><VendorsAdmin /></Protected>
                        },
                        {
                        path: "customers",
                        element: <Protected protectedIfLoggedIn={false} allowedRoles={["platform_manager"]} ><CustomersAdmin /></Protected>
                        },
                        {
                        path: "admin",
                        element: <Protected protectedIfLoggedIn={false} allowedRoles={["platform_manager"]} ><ManagerAdmin /></Protected>
                        },
                        {
                        path: "matching-logic",
                        element: <Protected protectedIfLoggedIn={false} allowedRoles={["platform_manager"]} ><MatchingLogicManager /></Protected>,
                        children: [
                            {
                                path: "overview",
                                element: <OverviewLogic />
                            },
                            {
                                path: "facts",
                                element: <FactConfig />
                            },
                            {
                                path: "requirements",
                                element: <RequirementConfig />
                            },
                            {
                                path: "settings",
                                element: <MatchingSettings />
                            },
                        ]
                        },
                        {
                            path: "platform-settings",
                            element: <Protected protectedIfLoggedIn={false} allowedRoles={["platform_manager"]} ><PlatformSettings /></Protected>
                        },
                    ]
                },
                {
                    path: "selection-portal/:selectionId",
                    element: <Protected protectedIfLoggedIn={false} allowedRoles={["customer", "vendor", "platform_manager", "guest_vendor", "guest_seeker"]} ><SelectionPortalRoot /></Protected>,
                    loader: selectionPortalLoader,
                    children: [
                    {
                        path: "", 
                        element: <SelectionCockpit />
                    },
                    {
                        path: "vendor-ranking",
                        element: <VendorRankingIndex />,
                        children: [
                            {
                                index: true, 
                                element: <VendorRankingRanking />
                            },
                            {
                                path: "compare", 
                                element: <VendorRankingComparison />
                            },
                        ]
                    },
                    {
                        path: "inbox",
                        element: <Inbox />
                    },
                    {
                        path: "settings", 
                        element: <SelectionSettings />
                    },
                    {
                        path: "requirement-manager", 
                        element: <RequirementManager />,
                    },
                    {
                        path: "scenario-manager", 
                        element: <ScenarioManager />,
                    },
                    {
                        path: "presentation-manager", 
                        element: <PresentationManager />,
                        children: [
                        {
                            index: true,
                            element: <PresentationOverview />
                        },
                        {
                            path: ":presentationId",
                            loader: presentationIdLoader,
                            element: <ParticipationScreen />
                        },
                        {
                            path: ":presentationId/results",
                            loader: presentationIdLoaderResults,
                            element: <ResultEvaluationScreen />
                        }
                        ]
                    },
                    {
                        path: "contract-manager", 
                        element: <ContractManager />,
                        children: [
                            {
                            index: true,
                            element: <DocumentOverview />
                            },
                            {
                            path: "document",
                            element: <DocumentReader />
                            }
                        ]
                        
                    },
                ]
                },
            ]
        },
        {
            path: "/error/:manualErrorMessage",
            element: <ErrorPage />,
        },
    ]},
]);

export default router;