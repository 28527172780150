// import useTypewriterNoDelete from '@/app/_components/useTypewriterNodelete';
import React from 'react'

const TypingText = (props : {text : string, typingSpeed? : number, disabled?: boolean, style?: string}) => {
  // const text = useTypewriterNoDelete({
  //     availbleTexts: [props.text],
  //     typingSpeed: props.typingSpeed || 5
  // });

  if (props.disabled) {
    return <>{props.text}</>
  }

  return (
    <div className={props.style}>{props.text}</div>
  )
}

export default TypingText