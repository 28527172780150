import React, { useEffect, useRef, useState } from 'react'
import FormModal from '../../../../../../components/FormModal'
import TableHeader from '../../../../../../components/TableHeader'
import TableRow from '../../../../../../components/TableRow'
import { useTranslation } from 'react-i18next'
import MainButton from '../../../../../../components/MainButton'
import { ArrowDownIcon, UploadIcon } from 'swap-frontend-library'
import { updateCustomerOrgAdmin, upsertCustomerDetails, upsertCustomerDetailsManager } from '../../../../../../data/UserCustomerRequests'
import FormField from '../../../../../../components/FormField'
import { getPlatformProducts, uploadCustomPlatformLogo } from '../../../../../../data/PlatformRequests'
import SelectorDropdown from '../../../../../../components/SelectorDropdown'
import { changeSelectionPortalPlan } from '../../../../../../data/PaymentRequests'
import PlatformEmailSettings from '../../platform-settings/features/PlatformEmailSettings'
import PlatformEmailTemplateSettings from '../../platform-settings/features/PlatformEmailTemplateSettings'

const AddCustomerDetailsForm = ({selectedCustomer, handleFetchCustomerData} : {selectedCustomer:any, handleFetchCustomerData:() => void}) => {
    const { t } = useTranslation();
    const [platformProducts, setPlatformProducts] = useState();
    
    const [orgFormData, setOrgFormData] = useState<{
        company?: string,
        address_street?: string,
        address_city?: string,
        address_zip?: string,
        address_country?: string,
        contact_phone?: string,
        contact_email?: string,
    }>();

    const [selectionPortalPlan, setSelectionPortalPlan] = useState(selectedCustomer?.payment_selectionPortalPlan?.productCode);
    
    const [createdSuccessfully, setCreatedSuccessfully] = useState(false);
    
    const [customerAccountsOpen, setCustomerAccountsOpen] = useState(false);
    const [customerSearchesOpen, setCustomerSearchesOpen] = useState(false);
    const [customerSelectionsOpen, setCustomerSelectionsOpen] = useState(false);
    
    const [settings_useDefaultSmtp, setSettings_useDefaultSmtp] = useState(selectedCustomer?.settings_useDefaultSmtp);
    const [settings_useDefaultTemplates, setSettings_useDefaultTemplates] = useState(selectedCustomer?.settings_useDefaultTemplates);

    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = () => {
        if (formRef.current?.checkValidity()) {
            handleUpdateCustomerData();
            setCreatedSuccessfully(true);
        } else {
            formRef.current?.reportValidity();
        }
    };

    const handleGetPlatformProducts = async () => {
        const response = await getPlatformProducts();

        const products = response.filter((item:any) => item.type == "portal")
        setPlatformProducts(products);
    }

    useEffect(() => {handleGetPlatformProducts()},[])
    
    useEffect(() => {
        if (selectionPortalPlan == selectedCustomer?.payment_selectionPortalPlan?.id) return;
        changeSelectionPortalPlan(selectedCustomer.id, selectionPortalPlan)
    },[selectionPortalPlan])

    const handleUpdateCustomerData = async () => {
        if ((await upsertCustomerDetails(orgFormData, selectedCustomer?.id)).success) {
            setOrgFormData(undefined);
            handleFetchCustomerData();
        }
    }

    const handleToggleEmailSettings = async (checked:boolean, emailSettingsKey:string) => {
        if ((await upsertCustomerDetails({[emailSettingsKey]: checked}, selectedCustomer?.id)).success) {
            if (emailSettingsKey === "settings_useDefaultTemplates") setSettings_useDefaultTemplates(checked);
            if (emailSettingsKey === "settings_useDefaultSmtp") setSettings_useDefaultSmtp(checked);
            handleFetchCustomerData();
        }
    }
    
    const handleUpdateCustomerOrgAdmin = async (userId:string, isOrgAdmin:boolean) => {
        if ((await updateCustomerOrgAdmin(userId, isOrgAdmin)).success) { handleFetchCustomerData(); }
        else {
            console.error("Error: Updating org admin not successful.")
        }
    }

    const handleUpload = async (file : Blob) => {
        if (typeof file === 'undefined' || !file) return;
        
        const originalFileName = (file as File).name; // Falls Blob, casten zu File
        const fileExtension = originalFileName.split('.').pop(); // Datei-Endung extrahieren
        const mimeType = file.type;

        // Falls keine Dateiendung ermittelt werden kann, Fallback setzen
        const validExtensions = ["png", "jpg", "jpeg", "svg", "gif", "webp"];
        const extension = validExtensions.includes(fileExtension?.toLowerCase() ?? "")
            ? fileExtension
            : mimeType.split('/')[1] || "png"; // Falls keine Endung vorhanden, aus MIME-Typ bestimmen

        // Neuen Dateinamen mit beibehaltener Endung setzen
        const newFileName = `${selectedCustomer.id}.${extension}`;

        const renamedFile = new File([file], newFileName, { type: file.type });
        await uploadCustomPlatformLogo(renamedFile, selectedCustomer.id, newFileName);
        handleFetchCustomerData();
    }
    
    return (
    <div className='h-full overflow-y-hidden relative'>
    <div className='h-full pt-8 pb-2'> 
        <h3 className="absolute top-0 mb-4 text-xl font-medium text-gray-900">{t("customer")}</h3>

        {
            (createdSuccessfully) ? (<p className='w-96'>{t("createdSuccessfully")}</p>) :
            (
                <div className="grid grid-cols-2 gap-4 h-full">
                    <div className='col-span-1 h-full overflow-y-auto px-4'>
                        <form ref={formRef} className="space-y-6" onSubmit={(e) => { e.preventDefault(); }}>
                            <div className='space-y-2 max-h-full'>
                                <FormField existingText={selectedCustomer?.company || ""} label={t("companyName")} setFormData={setOrgFormData} formDataKey='company' editing={true} />

                                <div>
                                    <p className='block text-sm font-medium text-gray-900'>{t("Address")}</p>
                                    <div className='space-y-2'>
                                        <FormField existingText={selectedCustomer?.address_street || ""} placeholder={t("Street")} setFormData={setOrgFormData} formDataKey='address_street' editing={true} />
                                        <FormField existingText={selectedCustomer?.address_city || ""} placeholder={t("City")} setFormData={setOrgFormData} formDataKey='address_city' editing={true} />
                                        <FormField existingText={selectedCustomer?.address_zip || "" } placeholder={t("Zip")} setFormData={setOrgFormData} formDataKey='address_zip' editing={true} />
                                        <FormField existingText={selectedCustomer?.address_country || ""} placeholder={t("Country")} setFormData={setOrgFormData} formDataKey='address_country' editing={true} />
                                    </div>
                                </div>
                                
                                <div>
                                    <p className='block text-sm font-medium text-gray-900'>{t("Contact")}</p>
                                    <div className='space-y-2'>
                                        <FormField existingText={selectedCustomer?.contact_phone || ""} placeholder={t("phone")} setFormData={setOrgFormData} formDataKey='contact_phone' editing={true} />
                                        <FormField existingText={selectedCustomer?.contact_email || ""} placeholder={t("email")} setFormData={setOrgFormData} formDataKey='contact_email' editing={true} />
                                    </div>
                                </div>
                                
                                {
                                    typeof selectedCustomer === "undefined" ? <></> :
                                    <>
                                        <div>
                                            <p className='block text-sm font-medium text-gray-900'>{t("Selection Portal Plan")}</p>
                                            <div className='space-y-2'>
                                                <SelectorDropdown items={platformProducts || []} itemValueKey={'productCode'} itemLabelKey={'name'} selectedItem={selectionPortalPlan} setSelectedItem={setSelectionPortalPlan} />
                                            </div>
                                        </div> 
                                    </>
                                }
                                
                                <div>
                                    <p className='block text-sm font-medium text-gray-900'>{t("Advanced")}</p>
                                    <div className='space-y-2'>
                                        <FormField existingText={selectedCustomer?.settings_domain || ""} placeholder={t("Domain")} setFormData={setOrgFormData} formDataKey='settings_domain' editing={true} />

                                        <div>
                                            <p className=' text-sm font-medium text-gray-900'>Colors</p>
                                            <div className='grid grid-cols-2 gap-2'>
                                                <FormField existingText={selectedCustomer?.settings_colors_primary1 || ""} placeholder={t("Primary 1")} setFormData={setOrgFormData} formDataKey='settings_colors_primary1' editing={true} />
                                                <FormField existingText={selectedCustomer?.settings_colors_primary2 || ""} placeholder={t("Primary 2")} setFormData={setOrgFormData} formDataKey='settings_colors_primary2' editing={true} />
                                                <FormField existingText={selectedCustomer?.settings_colors_secondary1 || ""} placeholder={t("Secondary 1")} setFormData={setOrgFormData} formDataKey='settings_colors_secondary1' editing={true} />
                                                <FormField existingText={selectedCustomer?.settings_colors_secondary2 || ""} placeholder={t("Secondary 2")} setFormData={setOrgFormData} formDataKey='settings_colors_secondary2' editing={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                {
                                    orgFormData ? (
                                        <div className='w-full mt-8'><MainButton text={t("save")} link="" func={handleSubmit} /></div>
                                    ) : (<></>)
                                }
                        </form>

                        {
                            typeof selectedCustomer !== "undefined" ?
                            <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
                                <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
                                    Logo
                                    <p className="mt-1 text-sm font-normal text-gray-500">Set page logo</p>
                                    <img className='h-20 w-auto max-w-full' src={`${process.env.REACT_APP_BACKEND}/public/img/platform-logo/${selectedCustomer?.settings_logoPath}`} />
                                </div>
                        
                                <div className='p-4 bg-white'>
                                {/* <DropzoneUpload setUploadedFile={setUploadedFile} label={'Upload new page logo'} fileType={'SVG'} maxFileSize={'20 mb'} /> */}
                                <label htmlFor='input-upload' className="cursor-pointer inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300">
                                    <div className='h-6 w-6'><UploadIcon /></div>
                                    Upload
                                    <input onChange={(event) => {if (event.target.files) { handleUpload(event.target.files[0])}}} id='input-upload' type="file" name="file" className="hidden"></input>
                                </label>
                                </div>
                            </div> : <></>
                        }

                        {/* Advanced settings */}
                        {
                            typeof selectedCustomer === "undefined" ? <></> :
                            <div>
                                <div className='py-2'>
                                    <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
                                        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
                                            <div className='flex items-center gap-4'>
                                                <p>Default email smtp settings</p>
                                                <input type='checkbox' checked={settings_useDefaultSmtp} onChange={(e) => handleToggleEmailSettings(e.target.checked, "settings_useDefaultSmtp")} />
                                            </div>

                                            {
                                                settings_useDefaultSmtp ? 
                                                <></> :
                                                <PlatformEmailSettings customerDetails={selectedCustomer} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='py-2'>
                                <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
                                        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white">
                                            <div className='flex items-center gap-4'>
                                                <p>Default email template settings</p>
                                                <input type='checkbox' checked={settings_useDefaultTemplates} onChange={(e) => handleToggleEmailSettings(e.target.checked, "settings_useDefaultTemplates")} />
                                            </div>
        
                                            {
                                                settings_useDefaultTemplates ? <></> :
                                                <PlatformEmailTemplateSettings customerDetails={selectedCustomer} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    

                    <div className='col-span-1 h-full overflow-y-auto'>
                        {
                            typeof selectedCustomer !== "undefined" ? (
                                <div className='p-2'>
                                    <div className="relative mt-5 shadow-md sm:rounded-lg">
                                        <div onClick={() => setCustomerAccountsOpen(prev => !prev)} className=" cursor-pointer p-5 text-lg font-semibold text-left text-gray-900 flex items-center">
                                            {t("customerAccounts")} ({selectedCustomer.users.length})
                                            <div className={`w-6 h-6 ${customerAccountsOpen ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
                                        </div>
                                        {
                                            customerAccountsOpen ? (
                                                <table className="w-full text-sm text-left text-gray-500">
                                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                                        <TableHeader header={[`${t("email")}`, `${t("firstname")}`, `${t("lastname")}`, `${t("Org. admin")}`]} />
                                                    </thead>
                                                    {
                                                        selectedCustomer.users.map(({id, email, firstname, lastname, isOrgAdmin} : any, index : number) => {
                                                            return (
                                                                <tbody className="cursor-pointer">
                                                                    <TableRow
                                                                        rowItems={[`${index}`, email, firstname, lastname, <input defaultChecked={isOrgAdmin} onChange={(e) => {handleUpdateCustomerOrgAdmin(id, e.target.checked);}} type='checkbox' />]}
                                                                    />
                                                                </tbody>
                                                            )
                                                        })
                                                    }
                                                </table>
                                            ) : (<></>)
                                        }
                                    </div>
                                    
                                    <div className="relative mt-5 shadow-md sm:rounded-lg">
                                        <div onClick={() => setCustomerSearchesOpen(prev => !prev)} className=" cursor-pointer p-5 text-lg font-semibold text-left text-gray-900 flex items-center">
                                            {t("Matchings")} ({selectedCustomer.customerSearchCustomerDetails.length})
                                            <div className={`w-6 h-6 ${customerSearchesOpen ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
                                        </div>
                                        {
                                            customerSearchesOpen ? (
                                                <table className="w-full text-sm text-left text-gray-500">
                                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                                        <TableHeader header={[`${t("queryUrl")}`, `${t("freeSearch")}`, `${t("createdOn")}`]} />
                                                    </thead>
                                                    {
                                                        selectedCustomer.customerSearchCustomerDetails.map(({id, company, freeSearch, createdDate} : any, index : number) => {
                                                            const date : Date = new Date(Date.parse(createdDate));
                                                            const dateString : string = `${date.getDate().toString().padStart(2,"0")}.${(date.getMonth()+1).toString().padStart(2,"0")}.${date.getFullYear()}`;
                                                            return (
                                                                <tbody className="cursor-pointer" onClick={() => window.open(`/search/result?customerSearchId=${id}`)}>
                                                                    <TableRow
                                                                        rowItems={[`${index}`, company, `${freeSearch ? "1": "0"}`, dateString]}
                                                                    />
                                                                </tbody>
                                                            )
                                                        })
                                                    }
                                                </table>
                                            ) : (<></>)
                                        }
                                    </div>
                                    
                                    <div className="relative mt-5 shadow-md sm:rounded-lg">
                                        <div onClick={() => setCustomerSelectionsOpen(prev => !prev)} className=" cursor-pointer p-5 text-lg font-semibold text-left text-gray-900 flex items-center">
                                            {t("Selections")} ({selectedCustomer.selections.length})
                                            <div className={`w-6 h-6 ${customerSelectionsOpen ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
                                        </div>
                                        {
                                            customerSelectionsOpen ? (
                                                <table className="w-full text-sm text-left text-gray-500">
                                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                                        <TableHeader header={[`${t("Id")}`, `${t("title")}`, `${t("status")}`, `${t("phase")}`, `${t("createdOn")}`]} />
                                                    </thead>
                                                    {
                                                        selectedCustomer.selections.map(({id, selectionTitle, selectionStatus, selectionPhase, createdDate} : any, index : number) => {
                                                            const date : Date = new Date(Date.parse(createdDate));
                                                            const dateString : string = `${date.getDate().toString().padStart(2,"0")}.${(date.getMonth()+1).toString().padStart(2,"0")}.${date.getFullYear()}`;
                                                            return (
                                                                <tbody className="cursor-pointer" onClick={() => window.open(`/user-area/selection-portal/${id}`)}>
                                                                    <TableRow
                                                                        rowItems={[`${index}`, <div className='truncate w-16'>{id}</div>, selectionTitle, selectionStatus, selectionPhase, dateString]}
                                                                    />
                                                                </tbody>
                                                            )
                                                        })
                                                    }
                                                </table>
                                            ) : (<></>)
                                        }
                                    </div>
                                </div>
                            ) : (
                            <>
                            </>
                            )
                        }
                    </div>
                </div>
            )
        }

    </div>
    </div>
  )
}

export default AddCustomerDetailsForm