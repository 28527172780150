import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { 
    getSelectionDetails, 
    getSelectionLogo, 
    setSelectionCompanyDescription, 
    updateSelectionData, 
    updateSelectionTitle, 
    uploadSelectionLogo 
} from '../../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import { CrossIcon, DropzoneUpload, getImage, LoadingSpinner, UploadIcon } from 'swap-frontend-library';
import SelectorDropdown from '../../../../../../components/SelectorDropdown';

const SelectionDetails = ({ selection, handleGetSelectionDetails }: { selection: any, handleGetSelectionDetails: () => {} }) => {
    const { t } = useTranslation();
    const { selectionId } = useOutletContext<any>();
    const textbox = useRef<any>();
    const [companyDescription, setCompanyDescription] = useState<string>();
    const [selectionTitle, setSelectionTitle] = useState<string>();
    const [selectionStatus, setSelectionStatus] = useState<string>();
    const [selectionCreatedDate, setSelectionCreatedDate] = useState<string>();
    const [uploadNewLogo, setUploadNewLogo] = useState(false);

    const [selectionIndustry, setSelectionIndustry] = useState<string>();
    const [currentSelectionTag, setCurrentSelectionTag] = useState<string>();
    const [selectionTags, setSelectionTags] = useState<string[]>();

    const availableSelectionStatus = [
        { label: t('selectionDetails.ongoing'), value: "ongoing" }, 
        { label: t('selectionDetails.paused'), value: "paused" }, 
        { label: t('selectionDetails.completed'), value: "completed" }, 
        { label: t('selectionDetails.canceled'), value: "canceled" }, 
        { label: t('selectionDetails.archived'), value: "archived" }
    ]

    useEffect(() => adjustHeight(), [companyDescription]);
    const adjustHeight = () => {
        if (textbox.current == null) return;
        textbox.current.style.height = "inherit";
        textbox.current.style.height = `${textbox.current.scrollHeight}px`;
    };

    const handleSetSelectionTitle = async () => {
        if (typeof selectionTitle == "undefined") return;
        const response = await updateSelectionTitle(selectionId, selectionTitle);
        if (response.ok) {
            handleGetSelectionDetails();
        }
    };

    const handleSaveNewSelectionCompanyDescription = async () => {
        if (typeof companyDescription == "undefined") return;
        if (await setSelectionCompanyDescription(selectionId, companyDescription)) {
            handleGetSelectionDetails();
        };
    };

    const handleUploadSelectionLogo = async (image: File) => {
        if (typeof image == "undefined" || image == null) return;
        console.log(image);
        if (await uploadSelectionLogo(selectionId, image, selectionId + "." + image.name.split(".").at(-1))) {
            handleFetchImage();
        };
    };

    const handleUploadSelectionData = async (selectionData: any) => {
        if (await updateSelectionData(selectionId, selectionData)) {
            handleGetSelectionDetails();
        };
    };

    useEffect(() => { if (selectionTags != selection.selectionTags && selectionTags?.length != 0) handleUploadSelectionData({ selectionTags }) }, [selectionTags]);
    useEffect(() => { if (selectionStatus != selection.selectionStatus) handleUploadSelectionData({ selectionStatus }) }, [selectionStatus]);

    const [imageSource, setImageSource] = useState<string | undefined>("");

    const handleFetchImage = async () => {
        getSelectionLogo(selection.id, undefined).then(frontendImageLocation => {
            setImageSource(frontendImageLocation);
        });
    };

    useEffect(() => {
        handleFetchImage();
        setCompanyDescription(selection.companyDescription);
        setSelectionTitle(selection.selectionTitle);
        setSelectionStatus(selection.selectionStatus);
        setSelectionIndustry(selection.selectionIndustry);
        setSelectionTags(selection.selectionTags ? selection.selectionTags : []);

        const createdDateParsed: Date = new Date(Date.parse(selection.createdDate));
        const createdDateString: string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth() + 1}.${createdDateParsed.getFullYear()} ${createdDateParsed.getHours().toString().padStart(2, "0")}:${createdDateParsed.getMinutes().toString().padStart(2, "0")}`;
        setSelectionCreatedDate(createdDateString);
    }, []);

    const editingLocked = false;

    if (typeof selection === "undefined" && typeof companyDescription === "undefined" && typeof selectionTitle === "undefined" && typeof selectionStatus === "undefined" && typeof selectionCreatedDate === "undefined") return <LoadingSpinner />;
    return (
        <div className='grid grid-cols-2 gap-4'>
            <div className="relative shadow-md col-span-1 rounded-lg w-full bg-white p-5 flex flex-col gap-4">
                <div className='absolute -top-3 right-4 bg-white rounded-full border-2 px-2 text-sm font-light'>{selection.selectionSoftwareCategory}</div>
                <div className="text-lg font-semibold text-left text-gray-900 bg-white">
                    {t('selectionDetails.title')}
                </div>

                <div>
                    <div className="font-semibold">
                        {t('selectionDetails.logo')}
                    </div>
                    <div>
                        {
                            typeof imageSource !== "undefined" && !uploadNewLogo ? <img className='h-auto max-h-64 w-full object-contain' src={imageSource} /> :
                                selection.customerSearchCustomerDetails != null && !uploadNewLogo ? (
                                    <>
                                        {
                                            selection.customerSearchCustomerDetails?.customerSearch.companyLogos.length > 0 ?
                                                <img className='h-auto max-h-64 w-full object-contain' src={
                                                    selection.customerSearchCustomerDetails.customerSearch.companyLogos[0].startsWith("http") ?
                                                        selection.customerSearchCustomerDetails.customerSearch.companyLogos[0] : "https://" + selection.customerSearchCustomerDetails.customerSearch.companyLogos[0]
                                                } /> : <></>
                                        }
                                    </>
                                ) : <></>
                        }
                        <label htmlFor='input-upload' className="mt-4 cursor-pointer inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300">
                            <div className='text-white w-6 h-6'><UploadIcon /></div>
                            {t('selectionDetails.upload')}
                            <input onChange={(event) => { if (event.target.files) { handleUploadSelectionLogo(event.target.files[0]) } }} id='input-upload' type="file" name="file" className="hidden"></input>
                        </label>
                    </div>
                </div>

                <div>
                    <p className='font-semibold'>{t('selectionDetails.projectTitle')}</p>
                    <input
                        disabled={editingLocked}
                        onBlur={() => handleSetSelectionTitle()}
                        className="w-full rounded tracking-tight leading-6 text-gray-900"
                        value={selectionTitle != null ? selectionTitle : ""}
                        placeholder={t('selectionDetails.projectTitlePlaceholder')}
                        onChange={(e) => setSelectionTitle(e.target.value)}
                    />
                </div>

                <div>
                    <p className='font-semibold'>{t('selectionDetails.companyDescription')}</p>
                    <textarea
                        ref={textbox}
                        disabled={editingLocked}
                        onBlur={() => handleSaveNewSelectionCompanyDescription()}
                        className="w-full rounded tracking-tight leading-6 text-gray-900"
                        value={companyDescription != null ? companyDescription : ""}
                        placeholder={t('selectionDetails.companyDescriptionPlaceholder')}
                        onChange={(e) => setCompanyDescription(e.target.value)}
                    />
                </div>

                <div className='w-full grid grid-cols-2 gap-4'>
                    <div className='w-full'>
                        <p className='font-semibold'>{t('selectionDetails.started')}</p>
                        <div className="w-1/2 rounded tracking-tight leading-6 text-gray-900">{selectionCreatedDate}</div>
                    </div>
                    <div className='w-full'>
                        <p className='font-semibold'>{t('selectionDetails.creationType')}</p>
                        <div className="w-1/2 rounded tracking-tight leading-6 text-gray-900">{selection.customerSearchCustomerDetails != null ? t('selectionDetails.createdFromMatching') : t('selectionDetails.createdFromEmpty')}</div>
                    </div>
                </div>
            </div>
            <div className='gap-4 grid'>
                <div className="shadow-md rounded-lg w-full bg-white p-5 grid gap-4">
                    <div className="text-lg font-semibold text-left text-gray-900 bg-white">
                        {t('selectionDetails.preferences')}
                    </div>

                    <div className='w-full grid grid-cols-2 gap-4'>
                        <div className='w-full'>
                            <p className='font-semibold'>{t('selectionDetails.currentPhase')}</p>
                            <div className="w-1/2 rounded tracking-tight leading-6 text-gray-900">{t("selectionDetails."+selection.selectionPhase)}</div>
                        </div>
                        <div className='w-full'>
                            <p className='font-semibold'>{t('selectionDetails.status')}</p>
                            <SelectorDropdown items={availableSelectionStatus} itemValueKey={'value'} itemLabelKey={'label'} selectedItem={selectionStatus} setSelectedItem={setSelectionStatus} />
                        </div>
                        <div className='w-full'>
                            <p className='font-semibold'>{t('selectionDetails.publishCustomerDetails')}</p>
                            <input type='checkbox' checked={selection.showCustomerDetailsToVendors} className='w-6 h-6' onChange={(e) => { handleUploadSelectionData({ showCustomerDetailsToVendors: e.target.checked }) }} />
                        </div>
                    </div>

                </div>
                <div className="shadow-md rounded-lg w-full bg-white p-5 grid gap-4">
                    <div className="text-lg font-semibold text-left text-gray-900 bg-white">
                        {t('selectionDetails.metaData')}
                    </div>

                    <div>
                        <p className='font-semibold'>{t('selectionDetails.companyIndustry')}</p>
                        <input
                            disabled={editingLocked}
                            onBlur={() => { if (selectionIndustry != selection.selectionIndustry) handleUploadSelectionData({ selectionIndustry }) }}
                            className="w-full rounded tracking-tight leading-6 text-gray-900"
                            value={selectionIndustry != null ? selectionIndustry : ""}
                            placeholder={t('selectionDetails.companyIndustryPlaceholder')}
                            onChange={(e) => setSelectionIndustry(e.target.value)}
                        />
                    </div>

                    <div className='w-full'>
                        <p className='font-semibold'>{t('selectionDetails.selectionTags')}</p>
                        <div className='max-w-96 gap-2'>
                            <div className='flex flex-wrap gap-2'>
                                {
                                    selectionTags?.map((tag: any) => (<div className='flex items-center gap-2 p-1 bg-gray-100 rounded'>{tag} <div onClick={() => { setSelectionTags((prev: any) => (prev.filter((tag2: any) => tag != tag2))) }} className='rounded-full cursor-pointer bg-gray-200 text-gray-600 w-4 h-4 p-1a'><CrossIcon /></div></div>))
                                }
                            </div>

                            <input
                                disabled={editingLocked}
                                className="w-full rounded tracking-tight leading-6 text-gray-900"
                                value={currentSelectionTag != null ? currentSelectionTag : ""}
                                placeholder={t('selectionDetails.selectionTagsPlaceholder')}
                                onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                        setSelectionTags((prev: any) => [...prev, currentSelectionTag]); setCurrentSelectionTag("");
                                    } else if (e.key === "Backspace" && currentSelectionTag == "") {
                                        setSelectionTags((prev: any) => prev.slice(0, prev.length - 1));
                                    }
                                }}
                                onChange={(e: any) => { setCurrentSelectionTag(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectionDetails;