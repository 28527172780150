import React, { useEffect, useState } from 'react'
import FormModal from '../../../../../../../components/FormModal'
import TableHeader from '../../../../../../../components/TableHeader';
import TableRow from '../../../../../../../components/TableRow';
import MainButton from '../../../../../../../components/MainButton';
import ArrowDownIcon from '../../../../../../../assets/img/icons/ArrowDownIcon';
import { CrossIcon, DropdownMenuForFilter, SettingsFilledIcon } from 'swap-frontend-library';
import SelectorDropdown from '../../../../../../../components/SelectorDropdown';
import { useTranslation } from 'react-i18next';

interface props {
    requirementTemplates: any[],
    requirementTemplatesWizardModelOpen: boolean,
    setRequirementTemplatesWizardModelOpen: React.Dispatch<React.SetStateAction<boolean>>,
    uniqueRequirementCategories: any,
    setSelectedRequirementTemplate: React.Dispatch<React.SetStateAction<any | undefined>>,
}

const RequirementInputWizardModal = ({requirementTemplates, uniqueRequirementCategories, requirementTemplatesWizardModelOpen, setRequirementTemplatesWizardModelOpen, setSelectedRequirementTemplate} : props) => {
    const { t } = useTranslation();
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [selectedRequirementCategory1, setSelectedRequirementCategory1] = useState("");
    const [selectedRequirementCategory2, setSelectedRequirementCategory2] = useState("");
    const [selectedRequirementCategory3, setSelectedRequirementCategory3] = useState("");

    const availableLayers = ["customer", "platform"];
    const [selectedDataLayers, setSelectedDataLayers] = useState(availableLayers);
    
    const [selectionProjectTitles, setSelectionProjectTitles] = useState(
        Array.from(
            new Set(
                requirementTemplates.filter(requirement => requirement.selectionTitle != null).map(requirement => requirement.selectionTitle)
            )
        ).map(
            title => ({l: title})
        )
        // .concat({ l: "All" })
    );
    const [selectedProject, setSelectedProject] = useState(null);

    const [selectionRequirementFiltered, setSelectionRequirementFiltered] = useState<any[]>([]);
    const [uniqueRequirementCategoriesLocal, setUniqueRequirementCategoriesLocal] = useState<any>(uniqueRequirementCategories);
    
    const handleSetCategory1 = (category: string) => {
        if (selectedRequirementCategory1 == "" || selectedRequirementCategory1 != category) {
          setSelectedRequirementCategory1(category);
        } else {
          setSelectedRequirementCategory1("");
          setSelectedRequirementCategory2("");
          setSelectedRequirementCategory3("");
        }
      }
      
      const handleSetCategory2 = (category: string) => {
        if (selectedRequirementCategory2 == "" || selectedRequirementCategory2 != category) {
          setSelectedRequirementCategory2(category);
        } else {
          setSelectedRequirementCategory2("");
          setSelectedRequirementCategory3("");
        }
      }
      
      const handleSetCategory3 = (category: string) => {
        if (selectedRequirementCategory3 == "" || selectedRequirementCategory3 != category) {
          setSelectedRequirementCategory3(category);
        } else {
          setSelectedRequirementCategory3("");
        }
      }

      useEffect(() => { handleFilterRequirements(); }, [selectedRequirementCategory1, selectedRequirementCategory2, selectedRequirementCategory3, selectedDataLayers, selectedProject])
      useEffect(() => { 
        if (selectedProject) {
            setSelectedDataLayers(["customer"]);
        }
       }, [selectedProject])

      const handleFilterRequirements = () => {
        // console.log(selectedRequirementCategory)
        let filteredRequirements = [];
        if (selectedRequirementCategory1) {
            filteredRequirements = requirementTemplates.filter((requirement : any) =>
                ((selectedRequirementCategory1 == requirement.category[0]) && 
                !selectedRequirementCategory2 || (selectedRequirementCategory2 == requirement.category[1])) &&
                !selectedRequirementCategory3 || (selectedRequirementCategory3 == requirement.category[2])
            )
            // setSelectionRequirementFiltered(filteredRequirements);
        } else {
            // setSelectionRequirementFiltered(requirementTemplates);
            filteredRequirements = requirementTemplates;
        }

        filteredRequirements = filteredRequirements.filter((requirement : any) => selectedDataLayers.includes(requirement.layer) && (!selectedProject || selectedProject == requirement.selectionTitle));
        setSelectionRequirementFiltered(filteredRequirements);
    }

    // TODO: This was copied from backend ... remove redundancy
    const generateUniqueCateogories = (selectionRequirements:any, categoryKey:string) => {
        const uniqueCategories : any[] = [...new Set(selectionRequirements.map((item : any) => JSON.stringify(item[categoryKey])))];
        // const uniqueCategories : any[] = [...new Set(selectionRequirements.map((item : any) => item.selectionRequirements_category))];
    
        const uniqueCategoriesTree = uniqueCategories.map((obj:string, idx:number) => (JSON.parse(obj))).reduce((r : any, a : any) => {
            r[a[0]] = r[a[0]] || {};
            // if (!Object.keys(r[a[0]]).includes(a[1])) {
            r[a[0]][a[1]] = r[a[0]][a[1]] || [];
            r[a[0]][a[1]].push(a.slice(2));
            // }
            return r;
        }, Object.create(null));
    
        return uniqueCategoriesTree;
    }

    useEffect(() => {
        const newUniqueCategoryTree = generateUniqueCateogories(selectionRequirementFiltered, "category");
        setUniqueRequirementCategoriesLocal(newUniqueCategoryTree)
    }, [selectionRequirementFiltered])

    const handleCopyRequirementTemplate = (requirementTemplate: any) => {
        setSelectedRequirementTemplate({ requirement: requirementTemplate.requirement, description: requirementTemplate.description, category: requirementTemplate.category, staticRequirementId: requirementTemplate.staticRequirementId, existingSelectionRequirementId: requirementTemplate.staticRequirementId == null ? requirementTemplate.id : null });
        setRequirementTemplatesWizardModelOpen(false);
    }

    const changeDataLayer = (e: any, layer: string) => {
        if (e.target.checked) {
            setSelectedDataLayers((prev) => [...prev, layer]);
        } else {
            setSelectedDataLayers((prev) => prev.filter((item) => item !== layer));
        }
    }
    
    return (
    <FormModal isOpen={requirementTemplatesWizardModelOpen} setOpen={setRequirementTemplatesWizardModelOpen} size='large'>
        <div className='h-full pt-16 relative'>
            <div className='w-full h-16 absolute top-0'>
                <div className='flex items-center'>
                    <h3 className="text-xl font-medium text-gray-900 pb-2">{t('requirementWizard.title')}</h3>
                </div>
            </div>

            <div className="flex h-full border-y gap-0 max-md:flex-wrap w-full max-md:max-w-full">
      
                <div className="max-h-full w-64 flex flex-col font-medium whitespace-nowrap bg-white border-r border-solid basis-0 leading-[150%]">
                    <div className="flex flex-col overflow-y-auto px-3 pt-4 pb-1.5 text-base text-gray-900">

                    {
                        Object.keys(uniqueRequirementCategoriesLocal).map((category1: string, index: number) => {
                        return (
                            <div>
                            <div onClick={() => handleSetCategory1(category1)} className={`flex flex-col cursor-pointer justify-center px-2 py-1.5 mt-4 w-full ${(selectedRequirementCategory1 == category1) ? ("bg-primary text-white") : ("hover:bg-primary2")} rounded-lg`}>
                            <div className="flex gap-3 justify-between">
                                <div className="w-full truncate">{category1}</div>
                                
                                <div className={`flex items-center ${(selectedRequirementCategory1 == category1) ? ("rotate-180") : ("")}`}><ArrowDownIcon iconColor={(selectedRequirementCategory1 == category1) ? ("white") : ("black")}/></div>
                            </div>
                            </div>
                            {
                            (selectedRequirementCategory1 == category1) ? (
                                <div className={`${(selectedRequirementCategory1 == category1) ? ("bg-gray-50") : ("")} rounded-lg py-1.5`}>
                                <div className={`flex flex-col justify-center pb-1.5 pl-5`}>
                                    
                                    {
                                    Object.keys(uniqueRequirementCategoriesLocal[selectedRequirementCategory1]).map((category2: string, index: number) => {

                                        return (
                                        <div>
                                            <div onClick={() => handleSetCategory2(category2)}
                                            className={`flex flex-col mt-1.5 justify-center px-2 py-1.5 w-full cursor-pointer ${(selectedRequirementCategory2 == category2) ? ("bg-primary text-white") : ("hover:bg-primary2")} rounded-lg`}>
                                            <div className="flex gap-1 justify-between">
                                                <div className="w-full truncate">{category2}</div>
                                                <div className={`flex items-center ${(selectedRequirementCategory2 == category2) ? ("rotate-180") : ("")}`}><ArrowDownIcon iconColor={(selectedRequirementCategory2 == category2) ? ("white") : ("black")}/></div>
                                            </div>
                                            </div>
                                            {
                                            (selectedRequirementCategory2 == category2) ? (
                                                <div className="flex flex-col gap-y-3 justify-center py-1.5 pl-5 mt-2.5">
                                                {
                                                    uniqueRequirementCategoriesLocal[selectedRequirementCategory1][selectedRequirementCategory2].map((category3: string[], index: number) => {
                                                    if (category3.length > 0 && !category3[1]) {
                                                        return (
                                                        <div onClick={() => handleSetCategory3(category3[0])}
                                                        className={`cursor-pointer rounded-lg px-2 py-1.5 truncate ${(selectedRequirementCategory3 == category3[0]) ? ("bg-primary text-white ") : ("hover:bg-primary2")}`}>
                                                            {category3[0]}
                                                        </div>
                                                        )
                                                    } else {
                                                        return (
                                                        <div onClick={() => handleSetCategory3(category3.join(", "))}
                                                        className={`cursor-pointer rounded-lg px-2 py-1.5 hover:bg-primary2 truncate ${(selectedRequirementCategory3 == category3.join(", ")) ? ("bg-primary text-white hover:bg-primary") : ("")}`}>
                                                            {category3.join(", ")}
                                                        </div>
                                                        )
                                                    }
                                                    })
                                                }
                                                </div>
                                            ) : (<></>)
                                            }
                                        </div>
                                        )
                                    })
                                    }
                                </div>
                                </div>
                            ) : (<></>)
                            }
                            </div>
                        )
                        })
                    }

                    </div>

                    <div className='mt-auto mb-4 mx-4'>
                        <MainButton text={t('requirementWizard.settings')} func={() => setSettingsOpen(true)} icon={<div className='w-6 h-6'><SettingsFilledIcon /></div>} />
                    </div>

                    {/* <div className='h-96 bg-gray-50 mt-auto rounded-lg border'>

                    </div> */}
                </div>

                <div className="flex flex-col flex-1 w-64">

                    <div className='h-full overflow-y-auto'>

                        <div className="flex gap-0 overflow-auto max-md:flex-wrap w-full h-full max-md:max-w-full">

                            <table className="w-full h-fit relative text-sm text-left text-gray-500">
                            {/* `${selectionRequirement.selectionRequirements_importance} %` */}
                                <TableHeader header={[t("requirementWizard.tabletitle"), t("requirementWizard.copyToSelection")]} sticky={true} />
                                {
                                    selectionRequirementFiltered.map((requirementTemplate: any, index: number) => {
                                        const createdDateParsed : Date = new Date(Date.parse(requirementTemplate.createdDate));
                                        const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;

                                        // if (requirementTemplate.staticRequirementId == null) console.log(requirementTemplate)

                                        return (
                                            <tbody
                                            onClick={() => handleCopyRequirementTemplate(requirementTemplate)}
                                            id={requirementTemplate.id} className={`cursor-pointer opacity-70 hover:opacity-100 transitions-all duration-100`}>
                                                <TableRow maxRowWidths={["","",""]}
                                                rowItems={[
                                                    index, 
                                                    <div className='flex flex-col'>
                                                        <p className='font-semibold'>{requirementTemplate.requirement}</p>
                                                        {
                                                            requirementTemplate.staticRequirementId == null ? (
                                                                <p className='font-light'>(created {createdDateString}, project {requirementTemplate.selection?.selectionTitle || "untitled"})</p>
                                                            ) : <></>
                                                        }
                                                    </div>, 
                                                    <div className='flex items-center justify-center'><MainButton text={t('requirementWizard.copy')} /></div>
                                                ]} />
                                            </tbody>
                                        );
                                    })
                                }
                            </table>

                        </div>
                    
                    </div>
                </div>
                <div className='h-12 bg-gray-900'></div>

            </div>

            {
                settingsOpen ? (
                    <div className='bottom-0 absolute w-full bg-white/50 backdrop-blur-lg z-[2] shadow-lg rounded-lg border p-4'>
                        <div className='flex items-center justify-between'>
                            <div className='text-lg font-semibold flex items-center gap-4'>
                                {t('requirementWizard.settings')}<div className='w-6 h-6'><SettingsFilledIcon /></div>
                            </div>
                            <div onClick={() => setSettingsOpen(false)} className='w-8 h-8 text-gray-400 rotate-180 cursor-pointer rounded-full hover:bg-gray-100 flex items-center justify-center p-1'><CrossIcon /></div>
                        </div>
                        <div className='grid grid-cols-2'>
                            <div className=''>
                                <p className='py-1'>{t('requirementWizard.filterDataLayer')}</p>
                                {/* <DropdownMenuForFilter defaultLabel={'All'} resetSelectionLabel={'All'} options={["platform", "customer"]} label={'Layer'} filteredData={selectionRequirementFiltered} filterKey={'layer'} setFilteredData={setSelectionRequirementFiltered} originalData={requirementTemplates} /> */}

                                <div className='flex gap-4'>
                                    <div className='flex items-center border border-gray-300 px-3 py-1 bg-gray-50 rounded-lg cursor-pointer'>
                                        <label className='flex items-center gap-2'>
                                            <input
                                                className='w-8 h-8'
                                                type="checkbox"
                                                checked={selectedDataLayers.includes("platform")}
                                                onChange={(e) => changeDataLayer(e, "platform")}
                                            />
                                            {t('requirementWizard.platformLayer')}
                                        </label>
                                    </div>
                                    
                                    <div className='flex items-center border border-gray-300 px-3 py-1 bg-gray-50 rounded-lg cursor-pointer'>
                                        <label className='flex items-center gap-2'>
                                            <input
                                                className='w-8 h-8'
                                                type="checkbox"
                                                checked={selectedDataLayers.includes("customer")}
                                                onChange={(e) => changeDataLayer(e, "customer")}
                                            />
                                            {t('requirementWizard.customerLayer')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {
                                selectedDataLayers.includes("customer") ? (
                                <div className=''>
                                    <div className='flex gap-2 items-end py-1'>
                                        <p>{t('requirementWizard.filterProjects')}</p>
                                        {
                                            selectedProject ? (
                                                <div onClick={() => setSelectedProject(null)}  className='flex gap-2 items-center bg-gray-50 px-2 rounded-full cursor-pointer border'>
                                                    <p>{t('requirementWizard.reset')}</p>
                                                    <div className='w-4 h-4 text-gray-400 cursor-pointer'><CrossIcon /></div>
                                                </div>
                                            ) : <></>
                                        }
                                    </div>
                                    {/* <DropdownMenuForFilter defaultLabel={'All'} resetSelectionLabel={'All'} options={selectedProjects} label={''} filteredData={selectionRequirementFiltered} filterKey={'selectionTitle'} setFilteredData={setSelectionRequirementFiltered} originalData={requirementTemplates} /> */}
                                    <SelectorDropdown items={selectionProjectTitles} itemValueKey={'l'} itemLabelKey={'l'} selectedItem={selectedProject} setSelectedItem={setSelectedProject} placeholderLabel={t('requirementWizard.all')} />
                                </div>
                                ) : <></>
                            }
                        </div>
                    </div>
                ) : (<></>)
            }

        </div>
    </FormModal>
  )
}

export default RequirementInputWizardModal