import React, { useEffect } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom';

const Index = () => {
  const { selectionId, setSelectedSelectionPhase, selectionTaskId, setSelectionTaskId, setSelectionPhase } = useOutletContext<any>();
  useEffect(() => {setSelectedSelectionPhase("presentations")},[])

  return (
    <div className='h-full'>
      {/* <Outlet context={{selectionId, selectionPhase, selectionTaskId, setSelectionTaskId}} /> */}
      <Outlet context={{selectionId, selectionTaskId, setSelectionTaskId, setSelectionPhase}} />
      {/* <div onClick={handleTest}>
        Test socket
      </div> */}
      {/* <SpeedDial speedDials={[]} defaultDials={true} currentContext='presentations' selectionId={selectionId} /> */}
    </div>  
  )
}

export default Index