import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { LoadingSpinner } from 'swap-frontend-library';

const PageLogo = () => {
  const [logoPath, setLogoPath] = useState(sessionStorage.getItem("logoPath"));


  useEffect(() => {
    const handleStorageChange = () => {
      setLogoPath(sessionStorage.getItem("logoPath"));
    };

    window.addEventListener("sessionStorageChange", handleStorageChange);

    return () => {
      window.removeEventListener("sessionStorageChange", handleStorageChange);
    };
  }, []);

  if (typeof sessionStorage.logoPath === "undefined") return <LoadingSpinner />
  return (
    <Link to={`${sessionStorage.domain}`} className="text-3xl font-semibold text-primary flex items-center gap-3 max-[900px]:text-2xl max-sm:text-xl ">
        <div>
          <img src={sessionStorage.logoPath} alt="" className="h-10 "></img>
          {/* <p className='pl-1 text-sm font-light italic'>by Softwarematch.de</p> */}
        </div>
    </Link>
  )
}

export default PageLogo