import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../../../components/MainButton';
import { fetchCustomerData, registerCustomerManager } from '../../../../../../data/UserCustomerRequests';

const AddVendorDetailsForm = () => {
    const { t } = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [createdSuccessfully, setCreatedSuccessfully] = useState(false);

    const [existingCompaniesData, setCxistingCompaniesData] = useState([]);

    const handleFetchCustomerData = () => {
        fetchCustomerData().then(data => {console.log(data.data); setCxistingCompaniesData(data.data.sort((a:any, b:any) => a.company.toLowerCase()>b.company.toLowerCase()));});
    }

    useEffect(() => {
        handleFetchCustomerData();
    }, [])

    const handleSubmit = () => {
        if (formRef.current?.checkValidity()) {
            handleAddCustomerAccountManager();
            // setOpen()
        } else {
            formRef.current?.reportValidity();
        }
    };

    const handleAddCustomerAccountManager = async () => {
        if ((await registerCustomerManager(email, password, firstname, lastname, companyId)).success) { setCreatedSuccessfully(true); }
        else {
            console.error("Error: Creating customer accoung not successful.")
        }
    }

    const handleGeneratePassword = () => {
        const pw = Math.random().toString(36).substr(2, 8);
        setPassword(pw);
    }
   
    return (
    <div className=''>
        <h3 className="mb-4 text-xl font-medium text-gray-900">{t("Create customer account")}</h3>

        {(createdSuccessfully) ? (<p className='w-96'>{t("createdSuccessfully")}</p>) : (
            <form ref={formRef} onSubmit={(e) => { e.preventDefault(); }} className="max-h-[75vh] overflow-y-auto p-2 space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-900">E-Mail</label>
                    <input placeholder="E-Mail" onChange={(event) => {setEmail(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t("password")}</label>
                    <div className='flex w-full gap-4'>
                        <div className='w-full'>
                            <input placeholder={`${t("password")}`} onChange={(event) => {setPassword(event.target.value)}} type="text" name="input" value={password} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required></input>
                        </div>
                        <div>
                            <MainButton text='Generate' height='h-11' func={() => handleGeneratePassword()} />
                        </div>
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t("firstname")}</label>
                    <input placeholder={`${t("firstname")}`} onChange={(event) => {setFirstname(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required></input>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900">{t("lastname")}</label>
                    <input placeholder={`${t("lastname")}`} onChange={(event) => {setLastname(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required></input>
                </div>

                <div>
                    <label htmlFor="countries" className="block text-sm font-medium text-gray-900">{t("companyName")}</label>
                    <select onChange={(event) => setCompanyId(event.target.value)}
                    id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" style={{WebkitAppearance: "none"}}>
                        <option value="" selected disabled hidden>{t("chooseHere")}</option>
                        {
                            existingCompaniesData.map((item : any, index : number) => {
                                const date : Date = new Date(Date.parse(item.createdDate));
                                const dateString : string = `${date.getDate().toString().padStart(2,"0")}.${(date.getMonth()+1).toString().padStart(2,"0")}.${date.getFullYear()}`;
                                return (<option key={index} value={item.id}>{item.company} ({item.id.slice(0,4)}..., {dateString})</option>);
                            })
                        }
                    </select>
                </div>
                {/* <div>
                    <label className="block text-sm font-medium text-gray-900">{t("companyName")}</label>
                    <input placeholder={`${t("companyName")}`} onChange={(event) => {setCompanyName(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required></input>
                </div> */}
                {/* () => handleAddCustomerAccountManager() */}
                <div className='w-full'><MainButton text={t("save")} link="" func={handleSubmit} /></div>
            </form>
        )}
    </div>
  )
}

export default AddVendorDetailsForm