import React, { useEffect, useState } from 'react'
import { ArrowDownIcon, CheckmarkIcon, CrossIcon, LoadingSpinnerIcon } from 'swap-frontend-library';

const InformationSourcesAnalysis = ({ links, finishedLinks, failedLinks, open, typing, plainStyle } : { links : any[], finishedLinks : string[], failedLinks : string[], open? : boolean | null, typing?: boolean, plainStyle?: boolean }) => {
    const [visible, setVisible] = useState(typeof open !== "undefined" && open != null ? open : true);

    // TEMPORARY
    const [temp_publicRegistriesLoaded, temp_setPublicRegistriesLoaded] = useState(false);
    const [temp_competitorsLoaded, temp_setCompetitorsLoaded] = useState(false);
    const [temp_socialsLoaded, temp_setSocialsLoaded] = useState(false);

    useEffect(() => {

        const getRandomDelay = () => Math.random() * 10000;
    
        const publicRegistriesTimer = setTimeout(() => {
          temp_setPublicRegistriesLoaded(true);
        }, getRandomDelay());
    
        const competitorsTimer = setTimeout(() => {
          temp_setCompetitorsLoaded(true);
        }, getRandomDelay());
    
        const socialsTimer = setTimeout(() => {
          temp_setSocialsLoaded(true);
        }, getRandomDelay());
    
        return () => {
          clearTimeout(publicRegistriesTimer);
          clearTimeout(competitorsTimer);
          clearTimeout(socialsTimer);
        };
    }, []);

    return (
        <div 
        // onClick={(e) => {e.stopPropagation(); !visible && open != null ? setVisible(true) : null}} 
        className={`w-full h-fit p-10 max-md:px-8 max-md:py-10 ${ plainStyle ? "" : "shadow border bg-white rounded-lg" } expand-down ${visible ? "" : "cursor-pointer"}`}>
            <div className='relative flex items-center justify-between'>
                <p className='font-semibold text-xl'>Information sources</p>
                { 
                    (open != null || typeof open === "undefined") ? (
                        <div onClick={() => setVisible(prev => !prev)} className={`cursor-pointer w-8 h-8 text-gray-300 ${visible ? "rotate-180" : ""}`}><ArrowDownIcon /></div>
                    ) : (
                        <></>
                    ) 
                }
            </div>
            <div className='expand-down'>
            {
                !visible ? <></> : (
                    <div>
                        <div className='mt-8 gap-y-2 gap-x-10 grid md:grid-cols-2'>
                        {
                            links.map((item : any, index : number) => {
                                let link : string;
                                let success : boolean | undefined = undefined;
                                
                                if (item instanceof String || typeof item !== 'string') {
                                    link = item.link;
                                    success = item.success;
                                } else {
                                    link = item;
                                    if (finishedLinks.includes(link)) {
                                        success = true;
                                    } else if (failedLinks.includes(link)) {
                                        success = false;
                                    }
                                }
                                
                                return (
                                    <div className='flex items-center truncate' key={index}>
                                        {
                                            success
                                            ? (<div className='w-6 h-6 text-green-600 mr-4'><CheckmarkIcon /></div>) 
                                            : typeof success !== "undefined"
                                            ? (<div className='w-6 h-6 text-red-600 mr-4'><CrossIcon /></div>) 
                                            : (<div className='w-6 h-6 text-primary mr-4'><LoadingSpinnerIcon /></div>)
                                        }
                                        <a href={link} target="_blank" className='w-full truncate underline underline-offset-1 hover:underline-offset-4 transition-all'>{link}</a>
                                    </div>
                                )
                            })
                        }
                        </div>

                        <div className='w-full mt-8 pt-8 border-t gap-y-2 gap-x-10 grid md:grid-cols-2'>
                            
                            <div className='flex items-center truncate'>
                                {
                                    temp_publicRegistriesLoaded
                                    ? (<div className='w-6 h-6 text-green-600 mr-4'><CheckmarkIcon /></div>) 
                                    : (<div className='w-6 h-6 text-primary mr-4'><LoadingSpinnerIcon /></div>)
                                }
                                <div className='w-full truncate'>Public registries (e.g. Handelsregister, Bundesanzeiger...)</div>
                            </div>
                            
                            <div className='flex items-center truncate'>
                                {
                                    temp_competitorsLoaded
                                    ? (<div className='w-6 h-6 text-green-600 mr-4'><CheckmarkIcon /></div>) 
                                    : (<div className='w-6 h-6 text-primary mr-4'><LoadingSpinnerIcon /></div>)
                                }
                                <div className='w-full truncate'>Your competitors and similar companies</div>
                            </div>
                            
                            <div className='flex items-center truncate'>
                                {
                                    temp_socialsLoaded
                                    ? (<div className='w-6 h-6 text-green-600 mr-4'><CheckmarkIcon /></div>) 
                                    : (<div className='w-6 h-6 text-primary mr-4'><LoadingSpinnerIcon /></div>)
                                }
                                <div className='w-full truncate'>Social media and news channels (e.g. LinkedIn, Facebook, Twitter, Trustpilot, DPA)</div>
                            </div>

                        </div>     
                    </div>
                )
            }
            </div>
        </div>
    )
}

export default InformationSourcesAnalysis