import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon, LoadingSpinner, SettingsFilledIcon, WarningIcon } from 'swap-frontend-library';
import { getSelectionDetails, updateSelectionData } from '../../../../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import TotalCostCell from './components/TotalCostCell';
import CostSettingsModal from './components/CostSettingsModal';
import TextInput from '../../../../../../../../components/TextInput';
import SelectorDropdown from '../../../../../../../../components/SelectorDropdown';
import MainButton from '../../../../../../../../components/MainButton';

const ComparisonCostsOperation = ({selectionDetails, vendorRanking, aggregateCosts, softwareProductFinancials, setOperatingSettingsElement} : {selectionDetails: any, vendorRanking:any[], aggregateCosts:any, softwareProductFinancials:any, setOperatingSettingsElement:React.Dispatch<React.SetStateAction<any>>}) => {
  const { t } = useTranslation();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [operatingCostsOpen, setOperatingCostsOpen] = useState(false);
  
  const [totalCosts, setTotalCosts] = useState<any>();
  
  // Settings
  const [initialNumberOfUsers, setInitialNumberOfUsers] = useState<any>();
  const [numberOfUsers, setNumberOfUsers] = useState<any>();
  const [timespan, setTimespan] = useState<string>("m");

  const handleUpdateCosts = () => {
    setTotalCosts((prev:any) => 
      Object.keys(softwareProductFinancials).reduce((prevReduce:any, curr:any) => {
        let oneTimeFees = softwareProductFinancials[curr].oneTimeFees || 0;
        let licenseCost = softwareProductFinancials[curr].pricePerLicenceMonthly || 0;
        let priceForMaintenance = softwareProductFinancials[curr].priceForMaintenanceMonthly || 0;
        let otherFees = softwareProductFinancials[curr].otherMonthlyFees || 0;

        let periodFactor = (timespan == "10y" ? 12*10 : timespan == "y" ? 12 : 1)

        prevReduce[curr] = {
          oneTimeFees: oneTimeFees,
          licenseCost: licenseCost * numberOfUsers * periodFactor,
          priceForMaintenance: priceForMaintenance * periodFactor,
          otherFees: otherFees * periodFactor,
        };

        return prevReduce;
      }, Object.create(null))
    )
  }

  useEffect(() => {
    handleUpdateCosts();

    setNumberOfUsers(selectionDetails.numberOfUsers);
    setInitialNumberOfUsers(selectionDetails.numberOfUsers);
  }, [])
  
  useEffect(() => {
    handleUpdateCosts();
  }, [numberOfUsers, timespan])

  // useEffect(() => {
  //   if (settingsOpen || initialNumberOfUsers == numberOfUsers) return;
  //   updateSelectionData(selectionId, {numberOfUsers})
  //   setInitialNumberOfUsers(numberOfUsers);
  // }, [settingsOpen])
  
  useEffect(() => {
    if (settingsOpen) {
      setOperatingSettingsElement(<div className={`flex justify-center`}><CostSettingsModal setOpen={setSettingsOpen}><SettingsOperatingCosts numberOfUsers={numberOfUsers} setNumberOfUsers={setNumberOfUsers} timespan={timespan} setTimespan={setTimespan} /></CostSettingsModal></div>)
    } else {
      setOperatingSettingsElement(undefined);
    }
  }, [settingsOpen])

  if (typeof totalCosts === "undefined") return <LoadingSpinner />

  return (
    <>
      <tr onClick={() => setOperatingCostsOpen(prev => !prev)}
        className='sticky top-56 z-[3] cursor-pointer'>
        <td className='sticky left-0 z-[3] py-2'>
            <div className={`h-24 sm:w-[400px] md:w-[600px] pl-2 flex items-center gap-4 bg-white/70 backdrop-blur-lg ml-4 border-l border-y border-primary rounded-l-lg text-xl font-bold text-primary`}>
                <div className={`w-4 h-4 ${operatingCostsOpen ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div>
                {t('comparisonCostsOperation.totalOperatingCosts')}
                <div onClick={(e) => {e.stopPropagation(); setSettingsOpen(prev => !prev)}} className={`relative ml-auto mr-4 w-10 h-10 rounded-full p-1.5 border border-primary group hover:bg-white hover:text-primary transition-all flex justify-center ${settingsOpen ? "bg-white text-primary" : "bg-primary text-white"}`}>
                    <SettingsFilledIcon />
                </div>
            </div>
        </td>
        {
            vendorRanking.map((vendorData:any, index:number) => {
                const lastVendor = index+1 == vendorRanking.length;
                const totalCost = {[vendorData.id]: (totalCosts[vendorData.id].oneTimeFees || 0) + (totalCosts[vendorData.id].licenseCost || 0) + (totalCosts[vendorData.id].priceForMaintenance || 0) + (totalCosts[vendorData.id].otherFees || 0)};
                
                return (
                    <TotalCostCell vendorData={vendorData} aggregateCosts={totalCost} softwareProductFinancials={softwareProductFinancials} isLastItem={lastVendor} warningElement={
                      <>
                        {
                          (!softwareProductFinancials[vendorData.id].oneTimeFees && !softwareProductFinancials[vendorData.id].pricePerLicenceMonthly) ? 
                          <div title={t('comparisonCostsOperation.checkFinancialData')} className='absolute top-2 right-2 w-5 h-5 text-amber-600'><WarningIcon /></div> : 
                          <></>
                        }
                      </>
                    } />
                )
            })
        }
      </tr>


      {
        operatingCostsOpen ? 
        <>
          <OperatingCost_OneTimeFees vendorRanking={vendorRanking} softwareProductFinancials={softwareProductFinancials} totalCosts={totalCosts} />
          <OperatingCost_Licenses vendorRanking={vendorRanking} softwareProductFinancials={softwareProductFinancials} totalCosts={totalCosts} />
          <OperatingCost_Maintenance vendorRanking={vendorRanking} softwareProductFinancials={softwareProductFinancials} totalCosts={totalCosts} />
          <OperatingCost_Other vendorRanking={vendorRanking} softwareProductFinancials={softwareProductFinancials} totalCosts={totalCosts} />
        </> :
        <></>
      }

      {/* <div className={`${settingsOpen ? "flex" : "hidden"} justify-center fixed right-5 bottom-5 z-[1000]`}><CostSettingsModal setOpen={setSettingsOpen}><SettingsOperatingCosts numberOfUsers={numberOfUsers} setNumberOfUsers={setNumberOfUsers} timespan={timespan} setTimespan={setTimespan} /></CostSettingsModal></div> */}
    </>
  )
}

const OperatingCost_OneTimeFees = ({vendorRanking, softwareProductFinancials, totalCosts} : {vendorRanking:any[], softwareProductFinancials:any, totalCosts : any}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (typeof totalCosts === "undefined") return <LoadingSpinner />
  
  return (
    <tr onClick={() => setOpen(prev => !prev)} className='cursor-pointer'>
      <td className='sticky left-0 z-[2] bg-gray-50'>
          <div className={`bg-white mt-4 ml-4 h-20 sm:w-[400px] md:w-[600px] py-2 pl-2 pr-4 border-l border-t rounded-tl-lg ${open ? "" : "border-b rounded-bl-lg"} text-xl font-semibold flex items-center gap-2`}>
              {/* <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div> */}
              <div className={`w-4 h-4`}></div>
              <p>{t('comparisonCostsOperation.oneTimeCosts')}</p>
          </div>
      </td>

      {
          vendorRanking?.map((currentVendorRanking:any, index:number) => {
              return (
                  <td key={index} className=''>
                      <div className={`bg-white mt-4 h-20 w-40 py-2 border-t ${open ? "" : "border-b"} ${index+1 == vendorRanking.length ? `border-r ${open ? "rounded-tr-lg" : "rounded-r-lg"}` : ""} text-xl font-semibold flex items-center justify-center`}>
                        {totalCosts[currentVendorRanking.id].oneTimeFees.toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[currentVendorRanking.id].preferredCurrency })}
                      </div>
                  </td>
              )
          })
      }
    </tr>
  )
}

const OperatingCost_Licenses = ({vendorRanking, softwareProductFinancials, totalCosts} : {vendorRanking:any[], softwareProductFinancials:any, totalCosts : any}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (typeof totalCosts === "undefined") return <LoadingSpinner />
  
  return (
    <tr onClick={() => setOpen(prev => !prev)} className='cursor-pointer'>
      <td className='sticky left-0 z-[2] bg-gray-50'>
          <div className={`bg-white mt-4 ml-4 h-20 sm:w-[400px] md:w-[600px] py-2 pl-2 pr-4 border-l border-t rounded-tl-lg ${open ? "" : "border-b rounded-bl-lg"} text-xl font-semibold flex items-center gap-2`}>
              {/* <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div> */}
              <div className={`w-4 h-4`}></div>
              <p>{t('comparisonCostsOperation.licenses')}</p>
          </div>
      </td>

      {
          vendorRanking?.map((currentVendorRanking:any, index:number) => {
              return (
                  <td key={index} className=''>
                      <div className={`bg-white mt-4 h-20 w-40 py-2 border-t ${open ? "" : "border-b"} ${index+1 == vendorRanking.length ? `border-r ${open ? "rounded-tr-lg" : "rounded-r-lg"}` : ""} text-xl font-semibold flex items-center justify-center`}>
                        {totalCosts[currentVendorRanking.id].licenseCost.toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[currentVendorRanking.id].preferredCurrency })}
                      </div>
                  </td>
              )
          })
      }
    </tr>
  )
}

const OperatingCost_Maintenance = ({vendorRanking, softwareProductFinancials, totalCosts} : {vendorRanking:any[], softwareProductFinancials:any, totalCosts : any}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  
  return (
    <tr className='cursor-pointer'>
      <td className='sticky left-0 z-[2] bg-gray-50'>
          <div className={`bg-white mt-4 ml-4 h-20 sm:w-[400px] md:w-[600px] py-2 pl-2 pr-4 border-l border-t rounded-tl-lg ${open ? "" : "border-b rounded-bl-lg"} text-xl font-semibold flex items-center gap-2`}>
              {/* <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div> */}
              <div className={`w-4 h-4`}></div>
              <p>{t('comparisonCostsOperation.maintenance')}</p>
          </div>
      </td>

      {
          vendorRanking?.map((currentVendorRanking:any, index:number) => {
              return (
                  <td key={index} className=''>
                      <div className={`bg-white mt-4 h-20 w-40 py-2 border-t ${open ? "" : "border-b"} ${index+1 == vendorRanking.length ? `border-r ${open ? "rounded-tr-lg" : "rounded-r-lg"}` : ""} text-xl font-semibold flex items-center justify-center`}>
                      {((totalCosts[currentVendorRanking.id].priceForMaintenance) || 0).toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[currentVendorRanking.id].preferredCurrency })}
                      </div>
                  </td>
              )
          })
      }
    </tr>
  )
}

const OperatingCost_Other = ({vendorRanking, softwareProductFinancials, totalCosts} : {vendorRanking:any[], softwareProductFinancials:any, totalCosts : any}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  
  return (
    <tr className='cursor-pointer'>
      <td className='sticky left-0 z-[2] bg-gray-50'>
          <div className={`bg-white my-4 ml-4 h-20 sm:w-[400px] md:w-[600px] py-2 pl-2 pr-4 border-l border-t rounded-tl-lg ${open ? "" : "border-b rounded-bl-lg"} text-xl font-semibold flex items-center gap-2`}>
              {/* <div className={`w-4 h-4 ${open ? "rotate-180" : ""} transition-all`}><ArrowDownIcon /></div> */}
              <div className={`w-4 h-4`}></div>
              <p>{t('comparisonCostsOperation.otherRecurringCosts')}</p>
          </div>
      </td>

      {
          vendorRanking?.map((currentVendorRanking:any, index:number) => {
              return (
                  <td key={index} className=''>
                      <div className={`bg-white my-4 h-20 w-40 py-2 border-t ${open ? "" : "border-b"} ${index+1 == vendorRanking.length ? `border-r ${open ? "rounded-tr-lg" : "rounded-r-lg"}` : ""} text-xl font-semibold flex items-center justify-center`}>
                      {((totalCosts[currentVendorRanking.id].otherFees) || 0).toLocaleString('de-DE', { style: 'currency', currency: softwareProductFinancials[currentVendorRanking.id].preferredCurrency })}
                      </div>
                  </td>
              )
          })
      }
    </tr>
  )
}

const SettingsOperatingCosts = ({numberOfUsers, setNumberOfUsers, timespan, setTimespan} : {numberOfUsers: number, setNumberOfUsers: React.Dispatch<React.SetStateAction<number>>, timespan : string, setTimespan: React.Dispatch<React.SetStateAction<string>>}) => {
  const { t } = useTranslation();
  const {selectionId} = useOutletContext<any>();
  const [initialNumberOfUsers, setInitialNumberOfUsers] = useState(numberOfUsers);
  const [internalNumberOfUsers, setInternalNumberOfUsers] = useState(numberOfUsers);

  useEffect(() => {
    setNumberOfUsers(internalNumberOfUsers);
  }, [internalNumberOfUsers])

  const handleSaveNumberOfUsers = () => {
    if (initialNumberOfUsers == internalNumberOfUsers) return;
    updateSelectionData(selectionId, {numberOfUsers: internalNumberOfUsers})
    setInitialNumberOfUsers(internalNumberOfUsers);
  }

  return (
      <div className='flex flex-col p-4 w-[33vw]'>
          <p className='text-xl'>{t('comparisonCostsOperation.operatingCostSettings')}</p>

          <div className='flex flex-col gap-4 mt-4'>
            <div>
              <div className='flex'>
                <p className='text-md font-semibold mb-2'>{t('comparisonCostsOperation.numberOfLicenses')}</p>
                {
                  initialNumberOfUsers == internalNumberOfUsers ? <></> : 
                  <div onClick={() => handleSaveNumberOfUsers()} className='mx-4 rounded-full bg-primary font-semibold text-white h-fit px-2 cursor-pointer'>{t('common.save')}</div>
                }
              </div>

              <div className='grid grid-cols-4 gap-4'>
                <Slider numberOfUsers={internalNumberOfUsers} setNumberOfUsers={setInternalNumberOfUsers} />
                <input type="text" value={internalNumberOfUsers} onChange={(e) => setInternalNumberOfUsers(Number(e.target.value))} min={1} className='border border-gray-300 rounded-lg bg-gray-50 font-normal p-1' />
              </div>

            </div>

            <div>
              <p className='text-md font-semibold mb-2'>{t('comparisonCostsOperation.costPeriod')}</p>
              <SelectorDropdown items={[{v:"m", l:t('comparisonCostsOperation.monthly')}, {v:"y", l:t('comparisonCostsOperation.yearly')}, {v:"10y", l:t('comparisonCostsOperation.decade') }]} itemValueKey={'v'} itemLabelKey={'l'} selectedItem={timespan} setSelectedItem={setTimespan} />
            </div>

          </div>
      </div>
  )
}

const Slider = ({numberOfUsers, setNumberOfUsers} : {numberOfUsers: number, setNumberOfUsers: React.Dispatch<React.SetStateAction<number>>}) => {
  const {selectionId} = useOutletContext<any>();
  const [maxValue, setMaxValue] = useState(1000);

  useEffect(() => {
    if (typeof numberOfUsers === "undefined" || numberOfUsers <= maxValue) return;
    setMaxValue(numberOfUsers)

  }, [numberOfUsers])

  return (
    <div className="relative mb-6 col-span-3">
      <input id="labels-range-input" type="range" value={numberOfUsers} onChange={(e) => setNumberOfUsers(Number(e.target.value))} min={1} max={maxValue} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer" />
      <span className="text-sm text-gray-500 absolute start-0 -bottom-6">1</span>
      <span className="text-sm text-gray-500 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">{maxValue/2}</span>
      <span className="text-sm text-gray-500 absolute end-0 -bottom-6">{maxValue}</span>
    </div>
  )
}
export default ComparisonCostsOperation